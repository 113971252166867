import React from "react";
import "./TechAssessesment.css";
import exampad from "../../assests/Exampad.png";

const TechAssessment = () => {
  return (
    <>
      <div className="wholeTech">
        <div className="subTech">
          <h1>Take Assessment</h1>
          <p>Some caption which sets more context for this page</p>
          <div className="assessmentGrid">
            <div className="assessmentCard">
              <div className="assessmentHead">
                <h2>Full Stack Developer</h2>
                <p>Beginner</p>
              </div>
              <div className="assessmentButton">
                <button>Take Assessment</button>
                <img src={exampad} alt="" />
              </div>
            </div>
            <div className="assessmentCard">
              <div className="assessmentHead">
                <h2>Full Stack Developer</h2>
                <p>Beginner</p>
              </div>
              <div className="assessmentButton">
                <button>Take Assessment</button>
                <img src={exampad} alt="" />
              </div>
            </div>
            <div className="assessmentCard">
              <div className="assessmentHead">
                <h2>Full Stack Developer</h2>
                <p>Beginner</p>
              </div>
              <div className="assessmentButton">
                <button>Take Assessment</button>
                <img src={exampad} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechAssessment;
