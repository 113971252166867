import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SignUpOTP.css";
import textLogo from "../../../../assests/Logo.svg";
import logo from "../../../../assests/LogoSvg.svg";
import edit from "../../../../assests/edit1.svg";
import { toast } from "react-toastify";
import { Slide } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import { useNavigate } from "react-router-dom";

const SignUpOTP = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [wrongOtpCount, setWrongOtpCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);
  let selector = useSelector((data) => data.userid);
  let email = useSelector((data) => data.signupdata.username);
  let userData = useSelector((data) => data.userdata);

  const emailVerification = async () => {
    setIsLoading(true);
    setErrorStatus(false);
    const otp = inputValues.join("");
    const payload = { email, otp };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${selector}`,
        payload
      );

      if (response.data.message === "User data Updated Successfully!!!") {
        if (userData.length !== 0) {
          let updatedObject = { ...userData[0], email_verification: true };
          setInputValues(["", "", "", "", "", ""]);
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        }
        toast.success("Email ID verified", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          setIsLoading(false);
          dispatch(storeAction.isPopUpHander());
          navigate("/user-details");
        }, 2000);
      } else {
        throw new Error("OTP Verification Failed");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorStatus(true);
      setWrongOtpCount(wrongOtpCount + 1);
    }
  };

  const sendMail = async () => {
    setErrorStatus(false);
    setWrongOtpCount(0);
    setInputValues(["", "", "", "", "", ""]);

    if (selector !== null) {
      try {
        await axios.post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${selector}`
        );
        setResendTimer(30);
      } catch (error) {
        console.error("Failed to send email verification request", error);
      }
    }
  };

  const handleInputChange = (e, index) => {
    const newValues = [...inputValues];
    newValues[index] = e.target.value;
    setInputValues(newValues);

    if (e.target.value && index < inputValues.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  return (
    <div className="newLogin displayHandler">
      <div className="subLogin">
        <div className="headerSide">
          <img src={logo} alt="HireIn5 Logo" />
          <h3>Welcome to HireIn5</h3>
          <h6>Your Next Opportunity Starts Here</h6>
          <p>
            Join thousands of tech professionals using HireIn5 to accelerate
            their careers.
          </p>
        </div>
        <div className="loginSide backGround">
          <div className="loginBody padding20">
            <img src={textLogo} alt="Text Logo" />
            <p className="fLogin">Enter OTP</p>
            <p className="fAcc">OTP has been sent to {email}</p>
          </div>
          <div className="formContainer">
            <div className="passwordDiv">
              <input className="input" type="text" value={email} readOnly />
              <img src={edit} alt="Edit Icon" />
            </div>

            <div className="inputs marginTop15">
              {inputValues.map((val, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  className="input1 border"
                  type="text"
                  value={val}
                  onChange={(e) => handleInputChange(e, index)}
                  maxLength="1"
                />
              ))}
            </div>

            {errorStatus && (
              <p className="errorText">Invalid OTP. Please try again.</p>
            )}

            <button
              className="button border"
              onClick={emailVerification}
              disabled={isLoading}
            >
              {isLoading ? "Verifying..." : "Verify & Continue"}
            </button>

            <div className="resend">
              <p>Resend OTP in {resendTimer}s</p>
              <button
                className="resendOTPBtn"
                onClick={sendMail}
                disabled={resendTimer > 0}
              >
                Resend OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpOTP;
