/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./NewClientSignup.css";
import { FaCheckCircle } from "react-icons/fa";
import logo from "../../../assests/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import SuccessResponse from "../../Reusable/SuccessResponse/SuccessResponse";
import axios from "axios";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countrycode from "../../../assests/CountryCodes.json";
import { jwtDecode } from "jwt-decode";
import { IoCloseOutline } from "react-icons/io5";
import { RiPlayFill } from "react-icons/ri";
import { FiLoader } from "react-icons/fi";
import Head from "../../Reusable/LogoHead/Head";
import country_and_states from "../../../assests/country-states";

const NewClientSignup = () => {
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const onboarding_status = useSelector((store) => store.onboarding_status);
  const token = useSelector((store) => store.token);

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerify, setemailVerify] = useState(false);
  const [phoneVerify, setphoneVerify] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [wrongotpcountemail, setwrongotpcountemail] = useState(0);

  const [registationdata, setregistationdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "",
    location: "",
    company_name: "",
    company_location: "",
  });

  const [registationdataerror, setregistationdataerror] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    location: false,
    company_name: false,
    company_location: false,
    agree: false,
  });
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [errorstatus, seterrorstatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );

  const [agree, setagree] = useState(false);
  const [finalerror, setfinalerror] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newloading1, setnewloading1] = useState(false);
  const [newloading3, setnewloading3] = useState(false);
  const [bottomerror, setbottomerror] = useState(false);

  useEffect(() => {
    newcapcha();
  }, [recaptchaId]);

  const newcapcha = () => {
    var newid = Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("");
    setTimeout(() => {
      setRecaptchaId(newid);
    }, 5000);
  };

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);

  const CheckStage = async () => {
    if (userdata.length != 0) {
      setemailVerify(userdata[0].email_verification);
      setphoneVerify(true);
      setregistationdata((values) => ({ ...values, email: userdata[0].email }));
      setregistationdata((values) => ({ ...values, phone: userdata[0].phone }));
      setupdateid(userdata[0].id);
      dispatch(storeAction.tokenHandler({ token: userdata[0].access_token }));
    }
  };

  const submitHandler = async () => {
    if (isPage == "page1") {
      setbottomerror(false);
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        location: false,
        company_name: false,
        company_location: false,
        agree: false,
      });
      if (registationdata.first_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, first_name: true }));
      } else if (registationdata.last_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, last_name: true }));
      } else if (registationdata.email.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, email: true }));
      } else if (emailVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, emailVerify: true }));
      } else if (registationdata.company_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({
          ...values,
          company_name: true,
        }));
      } else if (registationdata.country.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, country: true }));
      } else if (registationdata.phone.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phone: true }));
      } else if (phoneVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phoneverify: true }));
      } else if (registationdata.location == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({
          ...values,
          company_location: true,
        }));
      } else if (agree == false) {
        setregistationdataerror((values) => ({
          ...values,
          agree: true,
        }));
      } else {
        setnewloading3(true);
        var new_obj = {
          username: registationdata.email,
          first_name: `${registationdata.first_name} ${registationdata.last_name}`,
          company: {
            company_name: registationdata.company_name,
            company_location: registationdata.company_location,
            verified: false,
            terms: true,
            looking_for: [],
            agree_terms: true,
          },
          onboarding_status: 2,
          status: "New Client",
          country_code: registationdata.country,
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
          role: 2,
        };

        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            new_obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        const adminEmailObjList = [
          "sales@hirein5.com",
          "kaneeshvar@hirein5.com",
        ].map((adminEmail) => ({
          email: adminEmail,
          from_email: "connect@hirein5.com",
          subject: `New Client Registration: ${registationdata.first_name} ${registationdata.last_name} - ${registationdata.company_name}`,
          message: `
              <p>Dear Sales Team,</p>
              <p>We are pleased to inform you that a new client has successfully registered on the platform. Please find the details below:</p>
              <ul>
                <li><b>Client Name:</b> ${registationdata.first_name} ${registationdata.last_name}</li>
                <li><b>Company Name:</b> ${registationdata.company_name}</li>
                <li><b>Location:</b> ${registationdata.location}</li>
                <li><b>Phone:</b> ${registationdata.phone}</li>
                <li><b>Email:</b> ${registationdata.email}</li>
              </ul>
              <p>Please ensure that you capture this information in the CRM and update the status accordingly.</p>
              <p>If you have any questions, please feel free to reach out to the team.</p>
              <br>
              <p>Best regards,</p>
              <p><b>Team Hirein5</b></p>
            `,
        }));
        for (const adminEmailObj of adminEmailObjList) {
          await axios.post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
            adminEmailObj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );
        }
        const [firstName, lastName] = [
          `${registationdata.first_name} ${registationdata.last_name}`
            .split(" ")
            .slice(0, -1)
            .join(" "),
          `${registationdata.first_name} ${registationdata.last_name}`
            .split(" ")
            .pop(),
        ];
        var obj = {
          contacted_url: window.location.href,
          first_name: firstName,
          last_name: lastName,
          email: registationdata.email,
          phone: registationdata.phone,
          role:2,
          country: registationdata.location,
          company: registationdata.company_name,
          location: registationdata.company_location,
        };
        await axios
          .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/zoho/lead/`, obj, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: 2,
            })
          );
          let updatedObject = {
            ...userdata[0],
            onboarding_status: 2,
          };
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          setnewloading3(false);
          setIsPage("page5");
        }
      }
    }
  };

  const verifyHandler = async (e) => {
    setInputValues(["", "", "", "", "", ""]);
    if (e == "emailverification") {
      setfinalerror(false);
      if (registationdata.email.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else {
        setregistationdataerror((values) => ({
          ...values,
          email: false,
        }));
        if (updateid == null) {
          var newobj = {
            email: registationdata.email,
            username: registationdata.email,
            password: "Password@123",
            role: 2,
          };
          var createuser = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
              newobj
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (createuser.access_token !== undefined) {
            dispatch(storeAction.userdataHander({ userdata: [createuser] }));
            dispatch(
              storeAction.tokenHandler({ token: createuser.access_token })
            );
            dispatch(storeAction.isPopUpHander(e));
            setupdateid(createuser.id);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
              headers: {
                Authorization: `JWT ${createuser.access_token}`,
              },
            };
            axios
              .request(config)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                return error;
              });
            setstatus(true);
            setTimeout(() => {
              setstatus(false);
            }, 5000);
          } else {
            if (createuser.data.error == "User already exists") {
              var new_obj = {
                username: registationdata.email,
                email: registationdata.email,
                password: "Password@123",
              };
              var loginuser = await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                  new_obj
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (loginuser.access !== undefined) {
                const token = loginuser.access;
                const decoded = jwtDecode(token);
                var userinfo = await axios
                  .get(
                    `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${loginuser.access}`,
                      },
                    }
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .catch((err) => {
                    return err.response;
                  });

                if (userinfo) {
                  if (userinfo.status == null || userinfo.status.length == 0) {
                    await axios
                      .delete(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                    verifyHandler("emailverification");
                  } else {
                    setfinalerror(true);
                  }
                }
              } else {
                setfinalerror(true);
              }
            }
          }
        } else {
          dispatch(storeAction.isPopUpHander(e));
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander(e));
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setregistationdata((values) => ({ ...values, [name]: value }));
    setregistationdataerror((values) => ({ ...values, [name]: false }));
  };
  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const emailverification = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: registationdata.email,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      setemailVerify(true);
      if (userdata.length !== 0) {
        let updatedObject = {
          ...userdata[0],
          email_verification: true,
        };
        setInputValues(["", "", "", "", "", ""]);
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }
      settoastifystatus(true);
      toast.success("Email ID verified", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        linkedin: false,
      });
      setVerify(!verify);
      setTimeout(() => {
        setIsLoading(false);
        dispatch(storeAction.isPopUpHander());
      }, 2000);
      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
      setwrongotpcountemail(Number(wrongotpcountemail) + 1);
    }
  };

  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page5" || isPage === "page4") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const continuebtn = async () => {
    const [firstName, lastName] = [
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .slice(0, -1)
        .join(" "),
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .pop(),
    ];
    var obj = {
      contacted_url: window.location.href,
      first_name: firstName,
      last_name: lastName,
      email: registationdata.email,
      phone: registationdata.phone,
      role:2,
      country: registationdata.location,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/zoho/lead/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("https://hirein5.com/");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isPage == "page1" && event.key === "Enter") {
        submitHandler();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPage, registationdata]);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [verify, setVerify] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isResending, setIsResending] = useState(false);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const setVerifyHandler = async () => {
    setfinalerror(false);
    if (registationdata.email.length == 0) {
      setregistationdataerror((values) => ({
        ...values,
        email: true,
      }));
    } else {
      setregistationdataerror((values) => ({
        ...values,
        email: false,
      }));
      setnewloading1(true);
      if (updateid == null) {
        var newobj = {
          email: registationdata.email,
          username: registationdata.email,
          password: "Password@123",
          role: 3,
        };
        var createuser = await axios
          .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`, newobj)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (createuser.access_token !== undefined) {
          setVerify(!verify);
          dispatch(storeAction.userdataHander({ userdata: [createuser] }));
          dispatch(
            storeAction.tokenHandler({ token: createuser.access_token })
          );
          setupdateid(createuser.id);
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
            headers: {
              Authorization: `JWT ${createuser.access_token}`,
            },
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
          setstatus(true);
          setTimeout(() => {
            setstatus(false);
          }, 5000);
        } else {
          if (createuser.data.error == "User already exists") {
            var new_obj = {
              username: registationdata.email,
              email: registationdata.email,
              password: "Password@123",
            };
            var loginuser = await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                new_obj
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
            if (loginuser.access !== undefined) {
              const token = loginuser.access;
              const decoded = jwtDecode(token);
              var userinfo = await axios
                .get(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${loginuser.access}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (userinfo) {
                if (userinfo.status == null || userinfo.status.length == 0) {
                  await axios
                    .delete(
                      `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `JWT ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      return res.data;
                    })
                    .catch((err) => {
                      return err.response;
                    });
                  verifyHandler("emailverification");
                } else {
                  setfinalerror(true);
                }
              }
            } else {
              setfinalerror(true);
            }
          } else {
            setfinalerror(true);
          }
        }
      } else {
        setVerify(!verify);
      }
      setnewloading1(false);
    }
  };

  const SendMail = async () => {
    setstatus(false);
    seterrorstatus(false);
    setwrongotpcountemail(0);
    setIsResending(true);
    SendMail();
    setTimeout(() => {
      setIsResending(false);
      setTimer(30);
      setIsResendDisabled(true);
    }, 2000);
    setInputValues(["", "", "", "", "", ""]);
    if (updateid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);

  const allInputsFilled = inputValues.every((val) => val !== "");

  return (
    <div className="newClientSignup">
      {verify && (
        <div className="flex-center viewOverlay absolute">
          <div className="verifyPopup">
            <div className="justify-conten-end cursor">
              <IoCloseOutline onClick={setVerifyHandler} />
            </div>
            <p className="fLogin">Enter OTP</p>
            <p className="fAcc">
              OTP has been sent to <span>{registationdata.email}</span>
            </p>

            {status && (
              <p className="successerror">
                The OTP has been successfully sent to your email address.
              </p>
            )}
            <div className="inputs marginTop15">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="input1 border"
                />
              ))}
            </div>
            {wrongotpcountemail !== 5 ? (
              errorstatus && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountemail}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}

            {wrongotpcountemail !== 5 ? (
              allInputsFilled ? (
                isLoading == false ? (
                  <button
                    className="mobVerifyButton"
                    onClick={emailverification}
                  >
                    Verify & Continue
                  </button>
                ) : (
                  <button className="mobVerifyButton">
                    <FiLoader className="loadingIcon" />
                  </button>
                )
              ) : (
                <button className="mobVerifyButtondisable">
                  Verify & Continue
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">
                Verify & Continue
              </button>
            )}

            <div className="resend">
              {isResendDisabled ? (
                <p>Resend OTP in {timer}s</p>
              ) : (
                <button
                  className="resendOTPBtn"
                  onClick={() => {
                    SendMail();
                  }}
                  disabled={isResending}
                >
                  {isResending ? (
                    <FiLoader className="loadingIcon" />
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {isPage == "page5" ? (
        <div className="backGround w-full h-full">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow displayFlex justifyContentcenter height100vh sectionWidth">
            <div className="newClientFlowHead displayFlex pt-48">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Thank you! "
                  des="You’ve shared your details. Our sales team will contact you within 1 business day."
                  des1="Team Hirein5"
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="imageSide">
            <p className="imagesideptag">Hire Smarter, Faster</p>

            <div className="relative w-full max-w-lg mx-auto">
              <video
                ref={videoRef}
                className="w-full rounded-lg videoborder"
                onClick={handlePauseToggle}
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Intro%20video%2FHireIn5_Intro%20Video.mp4?alt=media&token=7b855d38-9f56-49e5-930b-3abb70c8b691"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              {!isPlaying && (
                <button
                  onClick={handlePlay}
                  className="absolute inset-0 flex items-center justify-center w-full h-full bg-opacity-50 rounded-lg videoborder"
                >
                  <RiPlayFill className="text-white text-6xl" />
                </button>
              )}
            </div>
            <div className="textvideoresume">
              <FaCheckCircle />
              <p>Hirein5 Video</p>
            </div>
            <div className="displayFlex alignItemsStart justifyContentStart paddingLeft50">
              <p className="videoptag">Why Join Hirein5</p>
              <p className="contenttag displayFlex flexDirectionRow alignItemsStart marginTop30 justifyContentStart">
                <FaCheckCircle className="checkCirlce" />
                No need for sourcing, searching, screening. Access Candidates
                directly
              </p>
              <p className="contenttag displayFlex flexDirectionRow justifyContentStart marginTop30 alignItemsStart">
                <FaCheckCircle className="checkCirlce" />
                Pre-interviewed, ready to hire talent pool.
              </p>
              <p className="contenttag  displayFlex flexDirectionRow justifyContentStart marginTop30 alignItemsStart">
                <FaCheckCircle className="checkCirlce" />
                Share shortlisted with Hiring Manager from the portal
              </p>
              <p className="contenttag displayFlex flexDirectionRow justifyContentStart marginTop30 alignItemsStart">
                <FaCheckCircle className="checkCirlce" />
                Build an entire team in hours with our HAT module
              </p>
              <p className="contenttag displayFlex flexDirectionRow justifyContentStart marginTop30 alignItemsStart">
                <FaCheckCircle className="checkCirlce" />
                Flexible engagement models: Direct Hire, C2H, C2C
              </p>
            </div>
          </div>
          <div className="formSide">
            <img src={logo} alt="" />
            <h6>Sign Up With Hirein5</h6>
            <p className="descriptiondiv">
              Join thousands of tech professionals using Hirein5 to accelerate
              their careers. Sign up today
            </p>
            <div className="formDiv">
              <div className="firstandlast">
                <div className="firstName">
                  <p>First & Middle Name</p>
                  <input
                    type="text"
                    name="first_name"
                    onChange={handlechange}
                    defaultValue={registationdata.first_name}
                    placeholder="First Name"
                    className="input border"
                  />
                  {registationdataerror.first_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Your First Name
                    </p>
                  )}
                </div>
                <div className="lastName">
                  <p>Last Name</p>
                  <input
                    type="text"
                    name="last_name"
                    onChange={handlechange}
                    defaultValue={registationdata.last_name}
                    placeholder="Last Name"
                    className="input border"
                  />
                  {registationdataerror.last_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Your Last Name
                    </p>
                  )}
                </div>
              </div>
              <div className="cmpEmail">
                <p className="label">Company Email</p>
                <div className="passwordDiv">
                  <input
                    className="input"
                    onChange={handlechange}
                    name="email"
                    type="email"
                    required
                    defaultValue={registationdata.email}
                    placeholder="Company Email"
                  />
                  {newloading1 == true ? (
                    <button
                      className={
                        emailVerify !== true
                          ? "verifyButton"
                          : "verifyButtondisable"
                      }
                    >
                      <FiLoader className="loadingIcon" />
                    </button>
                  ) : (
                    <button
                      className={
                        emailVerify !== true
                          ? "verifyButton"
                          : "verifyButtondisable"
                      }
                      onClick={emailVerify == true ? null : setVerifyHandler}
                    >
                      Verify
                    </button>
                  )}
                </div>
                <p className="smallInfo">
                  Non-official domain IDs may not be entertained
                </p>
                {registationdataerror.emailVerify && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Verify Email ID
                  </p>
                )}
                {registationdataerror.email && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Email ID
                  </p>
                )}
                {finalerror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    User already registered with email address.
                  </p>
                )}
              </div>
              <div className="cmpName">
                <p className="label">Company Name</p>
                <input
                  type="text"
                  placeholder="Company Name"
                  className="input border"
                  onChange={handlechange}
                  name="company_name"
                  defaultValue={registationdata.company_name}
                />
                {registationdataerror.company_name && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Your Company Name
                  </p>
                )}
              </div>
              <div className="width100">
                <p className="label">Phone Number</p>
                <div className="contentInput">
                  <select
                    className="selectBorder"
                    name="country"
                    defaultValue={registationdata.country}
                    onChange={handlechange}
                    // disabled={phoneVerify == false && emailVerify == false}
                  >
                    <option value="">Country</option>
                    {Countrycode.length !== 0
                      ? Countrycode.map((data, index) => (
                          <option
                            value={data.dial_code}
                            key={index}
                            selected={registationdata.country == data.dial_code}
                          >
                            {data.name} ({data.dial_code})
                          </option>
                        ))
                      : null}
                  </select>
                  <input
                    className="input inputBorder"
                    type="number"
                    placeholder="Phone Number"
                    defaultValue={registationdata.phone}
                    name="phone"
                    onChange={handlechange}
                  />
                </div>
                {registationdataerror.country && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Select Country Code
                  </p>
                )}
                {registationdataerror.phone && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Phone number
                  </p>
                )}
                {registationdataerror.phoneVerify && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Verify Phone number
                  </p>
                )}
              </div>
              <div>
                <p className="label">Location</p>
                <select
                  name="location"
                  value={registationdata.location}
                  onChange={handlechange}
                  placeholder="Location"
                  className="input border"
                  required
                >
                  <option value="">Select Location</option>
                  {country_and_states.country.length !== 0
                    ? country_and_states.country.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                {registationdataerror.company_location && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Your Location
                  </p>
                )}
              </div>

              <div className="termsConditions gap">
                <input
                  type="checkbox"
                  onChange={() => {
                    setagree(!agree);
                  }}
                />
                <p
                  className="terms cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  Accept terms & conditions
                </p>
              </div>

              {bottomerror && (
                <p className="text-red-500 text-sm font-semibold mb-2 text-start mt-2">
                  Please complete all the mandatory fields.
                </p>
              )}
              {registationdataerror.agree && (
                <p className="text-red-500 text-sm font-semibold mb-2 text-start mt-2">
                  Please Accept terms & conditions
                </p>
              )}
              {newloading3 === false ? (
                <div
                  className="buttonDiv marginTop20 marginBottom20"
                  onClick={submitHandler}
                >
                  <button>Sign Up</button>
                </div>
              ) : (
                <div className="buttonDiv marginTop20 marginBottom20">
                  <button className="signUpCompBodyButtonLoading buttonWidth100">
                    <FiLoader className="loadingIcon text-white" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewClientSignup;
