/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import editOutline from "../../assests/pencil.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import country_and_states from "../../assests/country-states-register";

const BillinginfoNew = () => {
  const singleuser = useSelector((store) => store.singleuser);
  const allcompanydata = useSelector((store) => store.allcompanydata);
  const alluserdata = useSelector((store) => store.alluserdata);
  const token = useSelector((store) => store.token);
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState("billing");
  const [statelist, setstatelist] = useState([]);

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const editHandler1 = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  const [loading, setIsLoading] = useState(false);
  const [companydata, setcompanydata] = useState({
    billing_address: "",
    billing_company: "",
    company_email: "",
    company_location: "",
    company_name: "",
    company_pan: "",
    company_url: "",
    country: "",
    duration: "",
    primary_email: "",
    primary_name: "",
    primary_phone: "",
    secondary_email: "",
    secondary_name: "",
    secondary_phone: "",
    pincode: "",
    company_register_no: "",
    linked_in: "",
    state: "",
    gst_no: "",
    primary_user_title: "",
    secondary_user_title: "",
  });
  useEffect(() => {
    getAllinfo();
  }, [singleuser, alluserdata]);

  const getAllinfo = async () => {
    if (singleuser.length !== 0) {
      if (singleuser[0].company !== null) {
        setcompanydata({
          billing_address:
            singleuser[0].company !== null
              ? singleuser[0].company.billing_address !== null
                ? singleuser[0].company.billing_address
                : ""
              : "",
          billing_company:
            singleuser[0].company !== null
              ? singleuser[0].company.billing_company !== null
                ? singleuser[0].company.billing_company
                : ""
              : "",
          company_email:
            singleuser[0].company !== null
              ? singleuser[0].company.company_email !== null
                ? singleuser[0].company.company_email
                : ""
              : "",
          company_location:
            singleuser[0].company !== null
              ? singleuser[0].company.company_location !== null
                ? singleuser[0].company.company_location
                : ""
              : "",
          company_name:
            singleuser[0].company !== null
              ? singleuser[0].company.company_name !== null
                ? singleuser[0].company.company_name
                : ""
              : "",
          company_pan:
            singleuser[0].company !== null
              ? singleuser[0].company.company_pan !== null
                ? singleuser[0].company.company_pan
                : ""
              : "",
          company_url:
            singleuser[0].company !== null
              ? singleuser[0].company.company_url !== null
                ? singleuser[0].company.company_url
                : ""
              : "",
          country:
            singleuser[0].company !== null
              ? singleuser[0].company.country !== null
                ? singleuser[0].company.country
                : ""
              : "",
          pincode:
            singleuser[0].company !== null
              ? singleuser[0].company.pincode !== null
                ? singleuser[0].company.pincode
                : ""
              : "",
          duration:
            singleuser[0].company !== null
              ? singleuser[0].company.duration !== null
                ? singleuser[0].company.duration
                : ""
              : "",
          primary_email:
            singleuser[0].company !== null
              ? singleuser[0].company.primary_email !== null
                ? singleuser[0].company.primary_email
                : ""
              : "",
          primary_name:
            singleuser[0].company !== null
              ? singleuser[0].company.primary_name !== null
                ? singleuser[0].company.primary_name
                : ""
              : "",
          primary_phone:
            singleuser[0].company !== null
              ? singleuser[0].company.primary_phone !== null
                ? singleuser[0].company.primary_phone
                : ""
              : "",
          secondary_email:
            singleuser[0].company !== null
              ? singleuser[0].company.secondary_email !== null
                ? singleuser[0].company.secondary_email
                : ""
              : "",
          secondary_name:
            singleuser[0].company !== null
              ? singleuser[0].company.secondary_name !== null
                ? singleuser[0].company.secondary_name
                : ""
              : "",
          secondary_phone:
            singleuser[0].company !== null
              ? singleuser[0].company.secondary_phone !== null
                ? singleuser[0].company.secondary_phone
                : ""
              : "",
          company_register_no:
            singleuser[0].company !== null
              ? singleuser[0].company.company_register_no !== null
                ? singleuser[0].company.company_register_no
                : ""
              : "",
          linked_in: singleuser[0].linked_in,
          state:
            singleuser[0].primary_company_info !== null
              ? singleuser[0].primary_company_info.state !== null
                ? singleuser[0].primary_company_info.state
                : ""
              : "",
          gst_no:
            singleuser[0].primary_company_info !== null
              ? singleuser[0].primary_company_info.gst_no !== null
                ? singleuser[0].primary_company_info.gst_no
                : ""
              : "",
          primary_user_title:
            singleuser[0].primary_company_info !== null
              ? singleuser[0].primary_company_info.primary_user_title !== null
                ? singleuser[0].primary_company_info.primary_user_title
                : ""
              : "",
          secondary_user_title:
            singleuser[0].primary_company_info !== null
              ? singleuser[0].primary_company_info.secondary_user_title !== null
                ? singleuser[0].primary_company_info.secondary_user_title
                : ""
              : "",
        });
        var country = await country_and_states.country.filter((data) => {
          return data.name == singleuser[0].company.company_location;
        });
        if (country.length !== 0) {
          setstatelist(country_and_states.states[country[0].code]);
        }
      }
    }
  };
  const handlechange = async (e) => {
    const { name, value } = e.target;
    if (name == "company_location") {
      var country = await country_and_states.country.filter((data) => {
        return data.name == value;
      });
      setcompanydata((values) => ({ ...values, [name]: value }));
      if (country.length !== 0) {
        setstatelist(country_and_states.states[country[0].code]);
      }
    } else {
      setcompanydata((values) => ({ ...values, [name]: value }));
    }
  };
  const handle_change = async (e) => {
    const { name, value } = e.target;
    setcompanydata((values) => ({ ...values, [name]: value }));
  };
  const savebilling = async () => {
    setIsLoading(true);
    var obj = {
      username: singleuser[0].username,
      company: {
        primary_name: companydata.primary_name,
        primary_email: companydata.primary_email,
        primary_phone: companydata.primary_phone,
        secondary_name: companydata.secondary_name,
        secondary_email: companydata.secondary_email,
        secondary_phone: companydata.secondary_phone,
      },
      primary_company_info: {
        primary_user_title: companydata.primary_user_title,
        secondary_user_title: companydata.secondary_user_title,
        state: companydata.state,
        gst_no: companydata.gst_no,
      },
    };
    var updatedatabilling = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${singleuser[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    if (
      updatedatabilling.message ===
      "User and Associated Info updated successfully"
    ) {
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedatabilling.user);
      setTimeout(() => {
        dispatch(
          storeAction.singleuserHander({ singleuser: [updatedatabilling.user] })
        );
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };
  const savebilling1 = async () => {
    setIsLoading(true);
    var obj = {
      username: singleuser[0].username,
      primary_company_info: {
        primary_user_title: companydata.primary_user_title,
        secondary_user_title: companydata.secondary_user_title,
        state: companydata.state,
        gst_no: companydata.gst_no,
      },
    };
    var updatedatabilling = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${singleuser[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    if (
      updatedatabilling.message ===
      "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...singleuser[0],
        primary_company_info: updatedatabilling.user.primary_company_info,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedatabilling.user);
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };
  const save_billing = async () => {
    setIsLoading(true);
    var obj = {
      username: singleuser[0].username,
      company: {
        company_name: companydata.company_name,
        billing_address: companydata.billing_address,
        company_location: companydata.company_location,
        pincode: companydata.pincode,
        company_url: companydata.company_url,
      },
      linked_in: companydata.linked_in,
      primary_company_info: {
        primary_user_title: companydata.primary_user_title,
        secondary_user_title: companydata.secondary_user_title,
        state: companydata.state,
        gst_no: companydata.gst_no,
      },
    };
    var updatedatabilling = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${singleuser[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    if (
      updatedatabilling.message ===
      "User and Associated Info updated successfully"
    ) {
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedatabilling.user);
      setTimeout(() => {
        dispatch(
          storeAction.singleuserHander({ singleuser: [updatedatabilling.user] })
        );
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };
  const getalldata = async (data) => {
    const index = allcompanydata.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      const updatedArray = [...allcompanydata];
      updatedArray[index] = { ...updatedArray[index], ...data };
      dispatch(
        storeAction.allcompanydataHander({ allcompanydata: updatedArray })
      );
    }
  };
  return (
    <div>
      {singleuser.length !== 0 ? (
        <div className="clientProfileOverview">
          {isToggle === "billing" && (
            <>
              <div className="ClientProfileViewCard">
                <div className="ClientProfileViewCardEdit">
                  <h1>Billing Information</h1>
                  <button id="adminbillinginformation" onClick={editHandler1}>
                    <img src={editOutline} alt="" />
                    Edit
                  </button>
                </div>
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Company Name</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.company_name !== null ? (
                        singleuser[0].company.company_name.length !== 0 ? (
                          <h3>{singleuser[0].company.company_name}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>State / County</h2>
                    {singleuser[0].primary_company_info !== null ? (
                      singleuser[0].primary_company_info.state !== null ? (
                        singleuser[0].primary_company_info.state.length !==
                        0 ? (
                          <h3>{singleuser[0].primary_company_info.state}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Pin Code / Zip Code</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.pincode !== null ? (
                        singleuser[0].company.pincode.length !== 0 ? (
                          <h3>{singleuser[0].company.pincode}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Country</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.company_location !== null ? (
                        singleuser[0].company.company_location.length !== 0 ? (
                          <h3>{singleuser[0].company.company_location}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Company Website Url</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.company_url !== null ? (
                        singleuser[0].company.company_url.length !== 0 ? (
                          <h3
                            onClick={() => {
                              window.open(
                                `${singleuser[0].company.company_url}`,
                                "_blank"
                              );
                            }}
                          >
                            {singleuser[0].company.company_url}
                          </h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Company Linkedin Profile</h2>
                    {singleuser[0].company !== null ? (
                      <h3>{singleuser[0].linked_in}</h3>
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="ClientProfileViewCard">
                <div className="ClientProfileViewCardEdit">
                  <h1>Primary Contact For Billing</h1>
                  <button id="adminprimarycontact" onClick={editHandler1}>
                    <img src={editOutline} alt="" />
                    Edit
                  </button>
                </div>
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Full Name</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.primary_name !== null ? (
                        singleuser[0].company.primary_name.length !== 0 ? (
                          <h3>{singleuser[0].company.primary_name}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Job Title / Designation</h2>
                    {singleuser[0].primary_company_info !== null ? (
                      singleuser[0].primary_company_info.primary_user_title !==
                      null ? (
                        singleuser[0].primary_company_info.primary_user_title
                          .length !== 0 ? (
                          <h3>
                            {
                              singleuser[0].primary_company_info
                                .primary_user_title
                            }
                          </h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Contact Number</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.primary_phone !== null ? (
                        singleuser[0].company.primary_phone.length !== 0 ? (
                          <h3>{singleuser[0].company.primary_phone}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Email Address</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.primary_email !== null ? (
                        singleuser[0].company.primary_email.length !== 0 ? (
                          <h3 className="lowerCase">
                            {singleuser[0].company.primary_email}
                          </h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="ClientProfileViewCard">
                <div className="ClientProfileViewCardEdit">
                  <h1>Secondary Contact For Billing</h1>
                  <button id="adminsecondarycontact" onClick={editHandler1}>
                    <img src={editOutline} alt="" />
                    Edit
                  </button>
                </div>
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Full Name</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.secondary_name !== null ? (
                        singleuser[0].company.secondary_name.length !== 0 ? (
                          <h3>{singleuser[0].company.secondary_name}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Job Title / Designation</h2>
                    {singleuser[0].primary_company_info !== null ? (
                      singleuser[0].primary_company_info
                        .secondary_user_title !== null ? (
                        singleuser[0].primary_company_info.secondary_user_title
                          .length !== 0 ? (
                          <h3>
                            {
                              singleuser[0].primary_company_info
                                .secondary_user_title
                            }
                          </h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Contact Number</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.secondary_phone !== null ? (
                        singleuser[0].company.secondary_phone.length !== 0 ? (
                          <h3>{singleuser[0].company.secondary_phone}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Email Address</h2>
                    {singleuser[0].company !== null ? (
                      singleuser[0].company.secondary_email !== null ? (
                        singleuser[0].company.secondary_email.length !== 0 ? (
                          <h3 className="lowerCase">
                            {singleuser[0].company.secondary_email}
                          </h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="ClientProfileViewCard">
                <div className="ClientProfileViewCardEdit">
                  <h1>Tax Information</h1>
                  <button id="taxinfo" onClick={editHandler1}>
                    <img src={editOutline} alt="" />
                    Edit
                  </button>
                </div>
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>VAT / GST Number</h2>
                    {singleuser[0].primary_company_info !== null ? (
                      singleuser[0].primary_company_info.gst_no !== null ? (
                        singleuser[0].primary_company_info.gst_no.length !==
                        0 ? (
                          <h3>{singleuser[0].primary_company_info.gst_no}</h3>
                        ) : (
                          <h3>-</h3>
                        )
                      ) : (
                        <h3>-</h3>
                      )
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {isPopUp == "adminbillinginformation" && (
            <>
              <div className="adminEditOverlay">
                <div className="adminEditOverlayHead">
                  <h1>Billing Information</h1>
                </div>
                <div className="adminEditOverlayBody">
                  <div className="adminEditOverlayContent">
                    <h2>Company Name</h2>
                    <input
                      type="text"
                      name="company_name"
                      onChange={handlechange}
                      defaultValue={companydata.company_name}
                    />
                  </div>

                  <div className="adminEditOverlayContent">
                    <h2>Country</h2>
                    <select
                      onChange={handlechange}
                      defaultValue={companydata.company_location}
                      name="company_location"
                      selected={companydata.company_location}
                    >
                      <option value="">Country</option>
                      {country_and_states.country.length !== 0
                        ? country_and_states.country.map((item, index) => (
                            <option
                              value={item.name}
                              key={index}
                              selected={companydata.company_location}
                            >
                              {item.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>State / County</h2>
                    <select
                      name="state"
                      onChange={handlechange}
                      defaultValue={companydata.state}
                    >
                      <option value="">State</option>
                      {statelist.length !== 0
                        ? statelist.map((data, index) => (
                            <option value={data.name} key={index}>
                              {data.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Company Pincode</h2>
                    <input
                      type="text"
                      name="pincode"
                      onChange={handlechange}
                      defaultValue={companydata.pincode}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Company URL</h2>
                    <input
                      type="text"
                      name="company_url"
                      onChange={handle_change}
                      defaultValue={companydata.company_url}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>LinkedIn Profile</h2>
                    <input
                      type="text"
                      name="linked_in"
                      onChange={handle_change}
                      defaultValue={companydata.linked_in}
                    />
                  </div>
                </div>
                <div className="editOverlayButton">
                  <button
                    className="discard"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                    }}
                  >
                    Discard Changes
                  </button>

                  {loading === false ? (
                    <button className="save" onClick={save_billing}>
                      Save & Close
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {isPopUp == "adminprimarycontact" && (
            <>
              <div className="adminEditOverlay">
                <div className="adminEditOverlayHead">
                  <h1>Primary contact for billing</h1>
                </div>
                <div className="adminEditOverlayBody">
                  <div className="adminEditOverlayContent">
                    <h2>Full Name</h2>
                    <input
                      type="text"
                      name="primary_name"
                      onChange={handlechange}
                      defaultValue={companydata.primary_name}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Job Title / Designation</h2>
                    <input
                      type="text"
                      name="primary_user_title"
                      onChange={handlechange}
                      defaultValue={companydata.primary_user_title}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Contact Number</h2>
                    <input
                      type="text"
                      name="primary_phone"
                      onChange={handlechange}
                      defaultValue={companydata.primary_phone}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Email Address</h2>
                    <input
                      type="text"
                      name="primary_email"
                      onChange={handlechange}
                      defaultValue={companydata.primary_email}
                    />
                  </div>
                </div>
                <div className="editOverlayButton">
                  <button
                    className="discard"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                    }}
                  >
                    Discard Changes
                  </button>

                  {loading === false ? (
                    <button className="save" onClick={savebilling}>
                      Save & Close
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {isPopUp == "adminsecondarycontact" && (
            <>
              <div className="adminEditOverlay">
                <div className="adminEditOverlayHead">
                  <h1>Secondary contact for billing</h1>
                </div>
                <div className="adminEditOverlayBody">
                  <div className="adminEditOverlayContent">
                    <h2>Full Name</h2>
                    <input
                      type="text"
                      name="secondary_name"
                      onChange={handlechange}
                      defaultValue={companydata.secondary_name}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Job Title / Designation</h2>
                    <input
                      type="text"
                      name="secondary_user_title"
                      onChange={handlechange}
                      defaultValue={companydata.secondary_user_title}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Contact Number</h2>
                    <input
                      type="text"
                      name="secondary_phone"
                      onChange={handlechange}
                      defaultValue={companydata.secondary_phone}
                    />
                  </div>
                  <div className="adminEditOverlayContent">
                    <h2>Email Address</h2>
                    <input
                      type="text"
                      name="secondary_email"
                      onChange={handlechange}
                      defaultValue={companydata.secondary_email}
                    />
                  </div>
                </div>
                <div className="editOverlayButton">
                  <button
                    className="discard"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                    }}
                  >
                    Discard Changes
                  </button>

                  {loading === false ? (
                    <button className="save" onClick={savebilling}>
                      Save & Close
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {isPopUp == "taxinfo" && (
            <>
              <div className="adminEditOverlay">
                <div className="adminEditOverlayHead">
                  <h1>Tax Information</h1>
                </div>
                <div className="adminEditOverlayBody">
                  <div className="adminEditOverlayContent">
                    <h2>VAT / GST Number</h2>
                    <input
                      type="text"
                      name="gst_no"
                      onChange={handlechange}
                      defaultValue={companydata.gst_no}
                    />
                  </div>
                </div>
                <div className="editOverlayButton">
                  <button
                    className="discard"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                    }}
                  >
                    Discard Changes
                  </button>

                  {loading === false ? (
                    <button className="save" onClick={savebilling1}>
                      Save & Close
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default BillinginfoNew;
