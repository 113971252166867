/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./HireMainComponent.css";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import Avatar from "react-avatar";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiLoader, FiPlusCircle } from "react-icons/fi";
import { storeAction } from "../../../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import Skilllist from "../../../assests/skillsJSON.json";
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { getDocument } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker";
import { FiBriefcase } from "react-icons/fi";
import Dragoption from "../../Candidate/CandidateSignUp/CandidateRegistration/Dragoption";
import axios from "axios";
import MobileCandidateProfile from "../../MobileScreens/MobileCandidateProfile/MobileCandidateProfile";
import CandidateProfileCard from "../../Reusable/CandidateProfileCard/CandidateProfileCard";
import { IoMdArrowBack } from "react-icons/io";
import { IoBookmarkOutline } from "react-icons/io5";
import editOutline from "../../../assests/pencil.svg";
import search from "../../../assests/search.png";
import { firebase } from "../../../database/firebase";
import { FaBookmark } from "react-icons/fa";
import { jsPDF } from "jspdf";
import { MdReplay } from "react-icons/md";
import { RxCross1, RxCross2 } from "react-icons/rx";
import country_and_states from "../../../assests/country-states";

const HireMainComponent = () => {
  const dispatch = useDispatch();
  const inputref = useRef("");
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const HATlist = useSelector((store) => store.HATlist);
  const userdata = useSelector((store) => store.userdata);
  const isPopUp = useSelector((store) => store.isPopUp);

  const [card, setCard] = useState("profile1");
  const [loadingpopup, setloadingpopup] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [lodingid, setlodingid] = useState(null);
  const [formdata, setformdata] = useState({
    project_name: "",
    duration: "",
    budget: "",
    amount: "",
    description: "",
    skills_required: "",
  });
  const [formdataerror, setformdataerror] = useState({
    project_name: false,
    duration: false,
    budget: false,
    amount: false,
    description: false,
    skills_required: false,
  });
  const [loader, setloader] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [skilllist, setskilllist] = useState("");
  const [teamid, setteamid] = useState(null);
  const [editteam, seteditteam] = useState(false);
  const [hireloading, sethireloading] = useState(false);
  const [count, setcount] = useState(0);
  const [alldata, setalldata] = useState([]);
  const [newalldata, setnewalldata] = useState([]);
  const [viewteam, setviewteam] = useState(false);
  const [displayTeam, setdisplayTeam] = useState([]);
  const [sumvalue, setsumvalue] = useState(0);
  const [AiChat, setAiChat] = useState(false);
  const [tableloading, settableloading] = useState(false);
  const [browseallloading, setbrowseallloading] = useState(false);
  const [singleuser, setsingleuser] = useState([]);
  const [row, setrow] = useState([
    { date: "", time1: "" },
    { date: "", time1: "" },
    { date: "", time1: "" },
  ]);
  const [timezone, settimezone] = useState("");
  const [dropDownList, setdropDownList] = useState([]);
  const [dropDownOpen, setdropDownClose] = useState(dropDownList);
  const [dropDown, setdropDown] = useState("");
  const [recordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [skill, setskill] = useState([]);
  const [responsibilitydata, setresponsibilitydata] = useState(null);
  const [newresponsibilitydata, setnewresponsibilitydata] = useState("");
  const [teamlist, setteamlist] = useState([
    {
      name: "Web App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Strong organizational, communication, and leadership skills.	",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, prototyping, UX research, and knowledge of design tools (e.g., Figma, Adobe XD).",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill: "HTML, CSS, JavaScript, and front-end frameworks.",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Server-side languages (e.g., Node.js, Python, Ruby, Java, PHP), databases (SQL, NoSQL), and RESTful API design.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
          job_des: "Responsible for building Manual Testing...",
          skill: "A combination of front-end and back-end technologies.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Database Administrator (DBA)",
          responsibility:
            "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Familiarity with testing frameworks (e.g., Selenium, Jest), and knowledge of test automation.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Vulnerability Assessment Tools, Programming Languages, Security Frameworks and Standards, Web Technologies, Authentication and Authorization Protocols, Cloud Security, Security Testing Techniques, Incident Response Tools,Network Security, Secure Development Practices",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        // {
        //   possition: "Content Writer/Copywriter",
        //   responsibility:
        //     "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
        //   job_des: "Responsible for building Manual Testing...",
        //   skill:
        //     "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis, Storytelling, Brand Voice Development",
        //   num_of_roles: "",
        //   jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
        //   location: "India",
        //   experience: "2",
        //   Availability: "Immediately",
        //   mode: "Full-time",
        //   working_hours: "Flexible with working hours",
        //   // duration: 0,
        //   gender: "",
        //   country: "",
        //   city: "",
        //   budget: "1000",
        // browse:"",
        //   profiles: [],
        // },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirements gathering, process mapping, and strong communication skills.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Owner",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Agile Methodologies, Product Management Tools, User Story Development, Web Technologies, Data Analysis, Market Research, UI/UX Principles, Stakeholder Management, Basic Technical Understanding",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "System Architect",
          responsibility:
            "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Architectural Frameworks, Cloud Platforms, Web Technologies, API Design and Management, Database Management, DevOps Practices, Security Architecture, Performance Optimization, System Integration, Monitoring and Logging",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    // {
    //   name: "Mobile App Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Project management methodologies (Agile, Scrum), communication, and team coordination.",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       num_of_roles: "",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Graphic design, user experience (UX) research, wireframing, prototyping,and using design tools like Sketch, Figma, or Adobe XD.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Responsive Design, UI/UX Principles, Version Control, API Integration, Performance Optimization, Testing Frameworks, Development Tools",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java),knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS,Azure, Google Cloud).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Server-Side Languages, Back-End Frameworks, SQL Databases, NoSQL Databases, RESTful Services, GraphQL, Mobile Development Tools, Version Control, Deployment Knowledge, Debugging, Performance Optimization, Mobile Security",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks, automated testing tools, and mobile-specific testing practices. Familiarity with manual and automated testing processes.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security,",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirement analysis, process modeling, and stakeholder communication.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Agile Methodologies, User Story Development, Roadmapping, Market Research and Analysis, Understanding of Mobile Technologies, API Knowledge, Analytics and Metrics, User Experience (UX) Principles, Stakeholder Management, Problem-Solving Skills",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Development Frameworks, Architectural Frameworks, Backend Technologies, Database Management, Cloud Platforms, DevOps Practices, Security Architecture, Performance Optimization, User Experience (UX) Considerations, Monitoring and Logging",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    // {
    //   name: "Web Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "User Research and Analysis,Wireframing and Prototyping,Visual Design",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design and Architecture,SQL,Database Scaling and High Availability,Backup and recovery,Database Performance Tuning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Content Writing,SEO,CMS,UX Writing and Microcopy,Social Media and Content Distribution,Content Strategy and Planning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirements gathering, process mapping, and strong communication skills.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Product Vision and Strategy,. Requirement Gathering and Definition,Backlog ManagemenT, Stakeholder Management,Agile and Scrum Methodologies,Data-Driven Decision Making",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "System Design and Architecture,DevOps and CI/CD,Front-End and Back-End Technologies,Process Mapping and Workflow Design,Agile Methodologie,Wireframing and Prototyping,Stakeholder Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    {
      name: "Website Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire web development process, from gathering requirements to delivery. Ensures that the project stays on track, within budget, and meets deadlines.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) and user experience (UX) design. They create wireframes, prototypes, and mockups to ensure the website is intuitive, aesthetically pleasing, and easy to navigate on various devices.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "User Research and Analysis,Wireframing and Prototyping,Visual Design	",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the website’s design using HTML, CSS, and JavaScript. They focus on ensuring the site is responsive, interactive, and compatible across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Works on the server side of the website, building databases, APIs, and server-side logic. They ensure that the website functions properly, processes data, and interacts with the front-end seamlessly.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "A full-stack developer has the skills to work on both the front-end and back-end, allowing for a more integrated development process. They can manage the website as a whole and streamline communication between the two areas.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "DevOps Engineer",
          responsibility:
            "	Focuses on the deployment, management, and scaling of the website. They ensure that the site is secure, efficiently hosted, and runs smoothly under different traffic loads.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Ensures that the website is free of bugs and performs well across different devices, browsers, and conditions. They conduct manual and automated testing for functionality, usability, security, and performance.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures that the website is secure from threats like hacking, malware, and data breaches. They set up SSL certificates, firewalls, and securitypatches.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "Graphic Designer/Illustrator",
          responsibility:
            "Creates custom graphics, logos, icons, and other visual elements. They ensure that the visual elements align with the website’s branding and are responsive across devices.",
          job_des: "Responsible for building Manual Testing...",
          skill: "",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    {
      name: "Mobile App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Project management methodologies (Agile, Scrum), communication, and team coordination.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, user experience (UX) research, wireframing, prototyping, and using design tools like Sketch, Figma, or Adobe XD.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Mobile App Developer",
          responsibility:
            "Writes the code to build the application. Depending on the platform (iOS, Android, or cross-platform), different types of developers are required: - iOS Developer: Specializes in building apps for Apple’s iOS platform using Swift or Objective-C. - Android Developer: Specializes in building apps for Android devices using Kotlin or Java. - Cross-Platform Developer: Builds apps that run on both iOS and Android using frameworks like Flutter or React Native.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in platform-specific programming languages (Swift, Kotlin, Java) or cross-platform frameworks (Flutter, React Native).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Manages the server-side of the app, including databases, APIs, and server logic that supports the app’s functionality. They handle data storage, authentication, and communication between the app and the server.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java), knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS, Azure, Google Cloud).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks, automated testing tools, and mobile- specific testing practices. Familiarity with manual and automated testing processes.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Experience with CI/CD tools (Jenkins, GitLab CI), cloud platforms (AWS,Azure, Google Cloud), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirement analysis, process modeling, and stakeholder communication.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Manager",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decision making.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Market research, product strategy, and business acumen.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the mobile application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
  ]);
  const [dummyteamlist, setdummyteamlist] = useState([
    {
      name: "Web App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Strong organizational, communication, and leadership skills.	",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, prototyping, UX research, and knowledge of design tools (e.g., Figma, Adobe XD).",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill: "HTML, CSS, JavaScript, and front-end frameworks.",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Server-side languages (e.g., Node.js, Python, Ruby, Java, PHP), databases (SQL, NoSQL), and RESTful API design.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
          job_des: "Responsible for building Manual Testing...",
          skill: "A combination of front-end and back-end technologies.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Database Administrator (DBA)",
          responsibility:
            "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Familiarity with testing frameworks (e.g., Selenium, Jest), and knowledge of test automation.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Vulnerability Assessment Tools, Programming Languages, Security Frameworks and Standards, Web Technologies, Authentication and Authorization Protocols, Cloud Security, Security Testing Techniques, Incident Response Tools,Network Security, Secure Development Practices",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        // {
        //   possition: "Content Writer/Copywriter",
        //   responsibility:
        //     "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
        //   job_des: "Responsible for building Manual Testing...",
        //   skill:
        //     "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis, Storytelling, Brand Voice Development",
        //   num_of_roles: "",
        //   jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
        //   location: "India",
        //   experience: "2",
        //   Availability: "Immediately",
        //   mode: "Full-time",
        //   working_hours: "Flexible with working hours",
        //   // duration: 0,
        //   gender: "",
        //   country: "",
        //   city: "",
        //   budget: "1000",
        // browse:"",
        //   profiles: [],
        // },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirements gathering, process mapping, and strong communication skills.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Owner",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Agile Methodologies, Product Management Tools, User Story Development, Web Technologies, Data Analysis, Market Research, UI/UX Principles, Stakeholder Management, Basic Technical Understanding",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "System Architect",
          responsibility:
            "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Architectural Frameworks, Cloud Platforms, Web Technologies, API Design and Management, Database Management, DevOps Practices, Security Architecture, Performance Optimization, System Integration, Monitoring and Logging",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    // {
    //   name: "Mobile App Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Project management methodologies (Agile, Scrum), communication, and team coordination.",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       num_of_roles: "",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Graphic design, user experience (UX) research, wireframing, prototyping,and using design tools like Sketch, Figma, or Adobe XD.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Responsive Design, UI/UX Principles, Version Control, API Integration, Performance Optimization, Testing Frameworks, Development Tools",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java),knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS,Azure, Google Cloud).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Server-Side Languages, Back-End Frameworks, SQL Databases, NoSQL Databases, RESTful Services, GraphQL, Mobile Development Tools, Version Control, Deployment Knowledge, Debugging, Performance Optimization, Mobile Security",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks, automated testing tools, and mobile-specific testing practices. Familiarity with manual and automated testing processes.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security,",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirement analysis, process modeling, and stakeholder communication.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Agile Methodologies, User Story Development, Roadmapping, Market Research and Analysis, Understanding of Mobile Technologies, API Knowledge, Analytics and Metrics, User Experience (UX) Principles, Stakeholder Management, Problem-Solving Skills",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Development Frameworks, Architectural Frameworks, Backend Technologies, Database Management, Cloud Platforms, DevOps Practices, Security Architecture, Performance Optimization, User Experience (UX) Considerations, Monitoring and Logging",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    // {
    //   name: "Web Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "User Research and Analysis,Wireframing and Prototyping,Visual Design",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design and Architecture,SQL,Database Scaling and High Availability,Backup and recovery,Database Performance Tuning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Content Writing,SEO,CMS,UX Writing and Microcopy,Social Media and Content Distribution,Content Strategy and Planning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirements gathering, process mapping, and strong communication skills.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Product Vision and Strategy,. Requirement Gathering and Definition,Backlog ManagemenT, Stakeholder Management,Agile and Scrum Methodologies,Data-Driven Decision Making",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "System Design and Architecture,DevOps and CI/CD,Front-End and Back-End Technologies,Process Mapping and Workflow Design,Agile Methodologie,Wireframing and Prototyping,Stakeholder Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    {
      name: "Website Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire web development process, from gathering requirements to delivery. Ensures that the project stays on track, within budget, and meets deadlines.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) and user experience (UX) design. They create wireframes, prototypes, and mockups to ensure the website is intuitive, aesthetically pleasing, and easy to navigate on various devices.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "User Research and Analysis,Wireframing and Prototyping,Visual Design	",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the website’s design using HTML, CSS, and JavaScript. They focus on ensuring the site is responsive, interactive, and compatible across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Works on the server side of the website, building databases, APIs, and server-side logic. They ensure that the website functions properly, processes data, and interacts with the front-end seamlessly.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "A full-stack developer has the skills to work on both the front-end and back-end, allowing for a more integrated development process. They can manage the website as a whole and streamline communication between the two areas.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "DevOps Engineer",
          responsibility:
            "	Focuses on the deployment, management, and scaling of the website. They ensure that the site is secure, efficiently hosted, and runs smoothly under different traffic loads.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Ensures that the website is free of bugs and performs well across different devices, browsers, and conditions. They conduct manual and automated testing for functionality, usability, security, and performance.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures that the website is secure from threats like hacking, malware, and data breaches. They set up SSL certificates, firewalls, and securitypatches.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "Graphic Designer/Illustrator",
          responsibility:
            "Creates custom graphics, logos, icons, and other visual elements. They ensure that the visual elements align with the website’s branding and are responsive across devices.",
          job_des: "Responsible for building Manual Testing...",
          skill: "",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    {
      name: "Mobile App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Project management methodologies (Agile, Scrum), communication, and team coordination.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, user experience (UX) research, wireframing, prototyping, and using design tools like Sketch, Figma, or Adobe XD.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Mobile App Developer",
          responsibility:
            "Writes the code to build the application. Depending on the platform (iOS, Android, or cross-platform), different types of developers are required: - iOS Developer: Specializes in building apps for Apple’s iOS platform using Swift or Objective-C. - Android Developer: Specializes in building apps for Android devices using Kotlin or Java. - Cross-Platform Developer: Builds apps that run on both iOS and Android using frameworks like Flutter or React Native.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in platform-specific programming languages (Swift, Kotlin, Java) or cross-platform frameworks (Flutter, React Native).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Manages the server-side of the app, including databases, APIs, and server logic that supports the app’s functionality. They handle data storage, authentication, and communication between the app and the server.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java), knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS, Azure, Google Cloud).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks, automated testing tools, and mobile- specific testing practices. Familiarity with manual and automated testing processes.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Experience with CI/CD tools (Jenkins, GitLab CI), cloud platforms (AWS,Azure, Google Cloud), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirement analysis, process modeling, and stakeholder communication.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Manager",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decision making.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Market research, product strategy, and business acumen.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the mobile application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          old_jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
  ]);
  const [addteam, setaddteam] = useState(false);
  const [jobdesdata, setjobdesdata] = useState("");
  const [gender, setgender] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [urlvalue, seturlvalue] = useState("");
  const [urlvaluestatus, seturlvaluestatus] = useState(false);
  const [jdloading, setjdloading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(teamlist[0]);
  const [selectedTeamNew, setSelectedTeamNew] = useState({});
  const [teamindex, setteamindex] = useState(0);
  const [browseloading, setbrowseloading] = useState(false);
  const [roleindexvalue, setroleindexvalue] = useState(null);
  const [deleteloading, setdeleteloading] = useState(false);
  const [singleteam, setsingleteam] = useState([]);
  const [roletext, setroletext] = useState("");
  const [uploadstatus, setuploadstatus] = useState(false);
  const [uploadstatuserror, setuploadstatuserror] = useState(false);
  const [loading, setloading] = useState(false);
  const [roleIndex1, setroleIndex1] = useState(null);
  const [teamindex1, setteamindex1] = useState(null);
  const [hatid, sethatid] = useState("");
  const [singleinterview, setsingleinterview] = useState([]);
  const [team_id, setteam_id] = useState(null);
  const [selectedCandidate, setselectedCandidate] = useState(null);

  const handlechange = async (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangeInterview = (index, field, value) => {
    const updatedRow = [...row];
    updatedRow[index][field] = value;
    setrow(updatedRow);
  };

  useEffect(() => {
    GetallTeam();
  }, []);

  const GetallTeam = async () => {
    settableloading(true);
    if (HATlist.count !== undefined) {
      if (HATlist.results.length !== 0) {
        settableloading(false);
        setcount(HATlist.count);
        setalldata(HATlist.results);
        setnewalldata(HATlist.results);
        var getalldata = await axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/client/hireTeam/${userid}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (getalldata.results.length !== 0) {
          settableloading(false);
          dispatch(storeAction.HATlistHander({ HATlist: getalldata }));
          setcount(getalldata.count);
          setalldata(getalldata.results);
          setnewalldata(getalldata.results);
        } else {
          settableloading(false);
        }
      } else {
        settableloading(false);
      }
    } else {
      var getalldata1 = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/client/hireTeam/${userid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (getalldata1.results.length !== 0) {
        settableloading(false);
        dispatch(storeAction.HATlistHander({ HATlist: getalldata1 }));
        setcount(getalldata1.count);
        setalldata(getalldata1.results);
        setnewalldata(getalldata1.results);
      } else {
        settableloading(false);
      }
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/search_object`
      );
      setSkillData(response.data.skills.filter((skill) => skill.trim() !== ""));
    } catch (error) {
      console.error("Error fetching skill data:", error);
    }
  };

  const createTaam = async () => {
    setformdataerror({
      project_name: false,
      duration: false,
      budget: false,
      amount: false,
      description: false,
      skills_required: false,
    });
    if (formdata.project_name.length == 0) {
      setformdataerror((values) => ({ ...values, project_name: true }));
    } else if (formdata.duration.length == 0) {
      setformdataerror((values) => ({ ...values, duration: true }));
    } else if (formdata.budget.length == 0) {
      setformdataerror((values) => ({ ...values, budget: true }));
    } else if (formdata.amount.length == 0) {
      setformdataerror((values) => ({ ...values, amount: true }));
    } else {
      setloader(true);
      setformdataerror({
        project_name: false,
        duration: false,
        budget: false,
        amount: false,
        description: false,
        skills_required: false,
      });
      var obj = {
        project_name: formdata.project_name,
        duration: formdata.duration,
        budget: formdata.budget,
        amount: formdata.amount,
        description: formdata.description,
        skills_required: skill.toString(),
        client_id: userid,
      };
      var craetedata = await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/`, obj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (craetedata.id !== undefined && craetedata.id !== null) {
        setloader(false);
        setteamid(craetedata.id);
      } else {
        setloader(false);
        setteamid(null);
      }
    }
  };

  const UpdateTaam = async () => {
    setloader(true);
    var obj = {
      project_name: formdata.project_name,
      duration: formdata.duration,
      budget: formdata.budget,
      amount: formdata.amount,
      description: formdata.description,
      skills_required: skill.toString(),
      client_id: userid,
    };
    var updatedata = await axios
      .put(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });

    if (updatedata.id !== undefined && updatedata.id !== null) {
      setTimeout(() => {
        setloader(false);
      }, 2000);
      setteamid(updatedata.id);
    } else {
      setTimeout(() => {
        setloader(false);
      }, 2000);
    }
  };

  const cardHandler = (teamIndex, roleIndex, profileIndex, rate) => {
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            const updatedProfiles = role.profiles.map((profile, pIndex) => {
              return {
                ...profile,
                active: profile.id === profileIndex,
              };
            });
            return {
              ...role,
              profiles: updatedProfiles,
            };
          }
          return role;
        });
        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });
    setteamlist(updatedTeams);
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex
            ? {
                ...role,
                profiles: role.profiles.map((profile, pIndex) => ({
                  ...profile,
                  active: profile.id === profileIndex,
                })),
              }
            : role
        ),
      }));
    }
  };

  const overlayHandlerNew = (teamIndex, roleIndex, field, value, data) => {
    setresponsibilitydata({
      teamIndex: teamIndex,
      roleIndex: roleIndex,
      field: field,
      data: data,
    });
    dispatch(storeAction.isPopUpHander(value));
  };

  const changeresponsibility = () => {
    if (newresponsibilitydata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                [field]: newresponsibilitydata,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? { ...role, [field]: newresponsibilitydata }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
    } else {
      alert("Please Enter Responsibility...");
    }
  };

  const changejob_des = () => {
    if (jobdesdata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                job_des: jobdesdata,
                skill: skilllist,
                jd_url: urlvalue,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? {
                  ...role,
                  job_des: jobdesdata,
                  skill: skilllist,
                  jd_url: urlvalue,
                }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
      setjobdesdata("");
      seturlvalue("");
    } else {
      alert("Please Enter Job Description...");
    }
  };

  const changefilter = () => {
    // Validate fields
    if (gender.length === 0) {
      alert("Please Select Gender");
      return; // Exit the function if validation fails
    }
    if (country.length === 0) {
      alert("Please Select Nationality");
      return; // Exit the function if validation fails
    }
    if (city.length === 0) {
      alert("Please Select Location");
      return; // Exit the function if validation fails
    }

    const { teamIndex, roleIndex, field } = responsibilitydata;

    // Update the team list immutably
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              gender: gender, // Update gender
              country: country, // Update country
              city: city, // Update city
              [field]: jobdesdata || "", // Update based on field, default to empty string if jobdesdata is falsy
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });

    // Set updated team list
    setteamlist(updatedTeams);
    setgender(gender);
    setcity(city);
    setcountry(country);

    // Synchronize selectedTeam if applicable
    if (selectedTeam && teamlist[teamIndex].name === selectedTeam.name) {
      const updatedSelectedRoles = selectedTeam.roles.map((role, rIndex) =>
        rIndex === roleIndex
          ? { ...role, gender, country, city, [field]: jobdesdata || "" }
          : role
      );

      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: updatedSelectedRoles,
      }));
    }

    // Dispatch any necessary actions
    dispatch(storeAction.isPopUpHander());

    // Clear job description data
    setjobdesdata("");
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };

  async function dropDownHandler() {
    const inputvalue = inputref.current.value.toLowerCase();
    setdropDown("");
    if (inputvalue.length !== 0) {
      var skillarrray = Skilllist;
      const uniqueSkills = Array.from(
        new Set(skillarrray.map((skill) => skill.Skill))
      );
      const inputvalueLower = inputvalue.toLowerCase();
      const matchingSkills = uniqueSkills.filter(
        (skill) =>
          typeof skill === "string" &&
          skill.toLowerCase().includes(inputvalueLower)
      );
      setdropDownClose(matchingSkills);
      setdropDown(inputvalue.length > 0 && matchingSkills.length > 0);
    } else {
      setdropDown(inputvalue.length > 0);
    }
  }

  function filterdata1() {
    setdropDown(!dropDown);
  }

  function getdata(event) {
    const getvalue = event;
    setdropDown(false);
    const updatedItems = skill.includes(getvalue)
      ? skill.filter((data) => data !== getvalue)
      : [...skill, getvalue];
    setskill(updatedItems);
    inputref.current.value = "";
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const ButtonHandler = (teamName, index) => {
    const team = teamlist.find((team) => team.name === teamName);
    setSelectedTeam(team);
    setteamindex(index);
  };

  const ButtonHandler1 = (teamName) => {
    const team = displayTeam.find((team) => team.name === teamName);
    setSelectedTeamNew(team);
  };

  const addRole = (teamIndex) => {
    const newRole = {
      possition: "",
      responsibility: "",
      job_des: "",
      jd_url: "",
      num_of_roles: 1,
      location: "",
      experience: "",
      Availability: "",
      mode: "",
      working_hours: "",
      duration: "",
      gender: "",
      country: "",
      city: "",
      budget: "",
      profiles: [],
    };

    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return { ...team, roles: [...team.roles, newRole] };
      }
      return team;
    });
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: [...prevState.roles, newRole],
    }));

    setteamlist(updatedTeams);
  };

  const removeRole = (teamIndex, roleIndex) => {
    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return {
          ...team,
          roles: team.roles.filter((_, i) => i !== roleIndex),
        };
      }
      return team;
    });

    setteamlist(updatedTeams);
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: prevState.roles.filter((_, i) => i !== roleIndex),
    }));
  };

  const handleChangeNew = (teamIndex, roleIndex, field, value) => {
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              [field]: value,
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });

    // Update the teamlist and selected team if it matches the currently selected team
    setteamlist(updatedTeams);

    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, [field]: value } : role
        ),
      }));
    }
  };
  const fileInputRef = useRef(null);

  const uploadHandler = () => {
    fileInputRef.current.click();
  };

  const getTextFromPage = async (page) => {
    const textContent = await page.getTextContent();
    const textItems = textContent.items.map((item) => item.str);
    return textItems.join(" ");
  };

  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    const supportedFileTypes = ["pdf", "doc", "docx"];

    if (!supportedFileTypes.includes(fileExtension)) {
      alert("Please upload a valid PDF or Word document.");
      return;
    }

    setjdloading(true);
    if (fileExtension === "pdf") {
      // PDF Handling
      const reader = new FileReader();
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result);
        const pdf = await getDocument(typedArray).promise;
        let extractedText = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const pageText = await getTextFromPage(page);
          extractedText += pageText + "\n";
        }
        setjobdesdata(extractedText);
        await parseQuery(extractedText);
      };
      reader.readAsArrayBuffer(file);
    }
    // else if (fileExtension === "doc" || fileExtension === "docx") {
    //   const reader = new FileReader();
    //   reader.onload = async () => {
    //     try {
    //       const arrayBuffer = reader.result; // Ensure this is not undefined
    //       const doc = await mammoth.extractRawText({ arrayBuffer }); // Use mammoth correctly
    //       const extractedText = doc.value; // Extract text from the Word document

    //       setjobdesdata(extractedText); // Update state with the extracted text
    //       await parseQuery(extractedText); // Process the extracted text
    //     } catch (error) {
    //       console.error("Error parsing Word document:", error);
    //     }
    //   };
    //   reader.readAsArrayBuffer(file);
    // }
    try {
      const fileUploadPromise = new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref(`jd${userid}` + file.name);
        storageRef.put(file).then((snapshot) => {
          storageRef.getDownloadURL().then((url) => {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });

      const imgurl2 = await fileUploadPromise;
      seturlvalue(imgurl2);
      seturlvaluestatus(true);
      setTimeout(() => {
        seturlvaluestatus(false);
      }, 2000);
    } catch (err) {
      console.log(err, "Error uploading file");
    }

    setjdloading(false);
  };

  const parseQuery = async (text) => {
    const skills = [];
    skillData.forEach((skill) => {
      const escapedSkill = skill.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`\\b${escapedSkill}\\b`, "i");
      if (regex.test(text)) skills.push(skill);
    });
    const skillsString = skills.join(", ");
    setskilllist(skillsString);
  };

  const BrowseBtn = async (teamIndex, roleIndex) => {
    setbrowseloading(true);
    setroleindexvalue(roleIndex);
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return { ...role, browse: "true" };
          }
          return role;
        });

        return { ...team, roles: updatedRoles };
      }
      return team;
    });
    setteamlist(updatedTeams);
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, browse: "true" } : role
        ),
      }));
    }
    const updateddata = await Promise.all(
      updatedTeams.map(async (team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = [...team.roles];
          const roleToUpdate = updatedRoles[roleIndex];

          const newobj = {
            search: {
              qualification: roleToUpdate.possition,
              experience: roleToUpdate.experience,
              location: roleToUpdate.location,
              skills: roleToUpdate.skill,
            },
          };
          const candidatedata = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/admin/search/`,
              newobj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });

          if (candidatedata.data.length !== 0) {
            updatedRoles[roleIndex].profiles = candidatedata.data.slice(0, 3);
          }

          return { ...team, roles: updatedRoles };
        }
        return team;
      })
    );

    setteamlist(updateddata);
    setSelectedTeam(updateddata[teamIndex]);

    setbrowseloading(false);
    setroleindexvalue(null);
  };

  const pageHandler = async (event, id) => {
    setIsPage("page1");
  };

  const HireaTeam = async () => {
    if (teamlist.length !== 0) {
      let hasProfiles = false;
      let hasSelectedCandidates = false;

      for (let i = 0; i < teamlist.length; i++) {
        const team = teamlist[i];
        if (team.roles && team.roles.length > 0) {
          for (const role of team.roles) {
            if (role.profiles && role.profiles.length > 0) {
              hasProfiles = true;
            }

            const selectedCandidates = role.profiles
              ? role.profiles.filter((profile) => profile.active)
              : [];

            if (selectedCandidates.length > 0) {
              hasSelectedCandidates = true;
            }

            if (hasProfiles && hasSelectedCandidates) {
              break; // Exit the loop early if both conditions are met
            }
          }
        }

        if (hasProfiles && hasSelectedCandidates) {
          break; // Exit the outer loop early if both conditions are met
        }
      }

      if (!hasProfiles) {
        alert("At least one profile is required.");
        return;
      }

      if (!hasSelectedCandidates) {
        alert("At least one selected candidate is required.");
        return;
      }

      // Proceed with the API calls if conditions are met
      sethireloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const team = teamlist[i];
        if (team.roles && team.roles.length > 0) {
          for (const role of team.roles) {
            let obj = {
              role: team.name || "",
              possition: role.possition || "",
              responsibility: role.responsibility || "",
              job_des: role.job_des || "",
              jd_url: role.jd_url || "",
              old_jd_url: role.old_jd_url || "",
              num_of_roles: role.num_of_roles || "",
              location: role.location || "",
              experience: role.experience || "",
              Availability: role.Availability || "",
              mode: role.mode || "",
              working_hours: role.working_hours || "",
              duration: team.duration || "",
              budget: role.budget || "",
              diversity_filter: role.gender || "",
              candidates: role.profiles
                ? role.profiles.map((profile) => profile.id).join(",")
                : "",
              selected_candidates: role.profiles
                ? role.profiles
                    .filter((profile) => profile.active)
                    .map((profile) => profile.id)
                    .join(", ")
                : "",
              status: "Success",
              engagement: role.mode || "",
              project_id: teamid,
              skill: role.skill || "",
            };

            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/developement/`,
                obj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => res.data)
              .catch((err) => err.response);

            var obj1 = {
              estimated_budget: sumvalue,
            };

            await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`,
                obj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => res.data)
              .catch((err) => err.response);
          }
        }
      }

      setteamid(null);
      setaddteam(false);
      GetallTeam();
      sethireloading(false);
    }
  };

  const UpdateaTeam = async () => {
    if (teamlist.length !== 0) {
      sethireloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const team = teamlist[i];
        if (team.roles && team.roles.length > 0) {
          team.roles.forEach(async (role) => {
            let obj = {
              role: team.name || "",
              possition: role.possition || "",
              skill: role.skill || "",
              responsibility: role.responsibility || "",
              job_des: role.job_des || "",
              jd_url: role.jd_url || "",
              old_jd_url: role.old_jd_url || "",
              num_of_roles: role.num_of_roles || "",
              location: role.location || "",
              experience: role.experience || "",
              Availability: role.Availability || "",
              mode: role.mode || "",
              working_hours: role.working_hours || "",
              duration: team.duration || "",
              budget: role.budget || "",
              diversity_filter: role.gender || "",
              candidates:
                role.profiles.map((profile) => profile.id).join(",") || "",
              selected_candidates:
                role.profiles
                  .filter((profile) => profile.active)
                  .map((profile) => profile.id)
                  .join(", ") || "",
              status: "Success",
              engagement: role.mode || "",
              project_id: teamid,
            };
            if (role.id !== undefined) {
              await axios
                .put(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${role.id}/`,
                  obj,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${token}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
            } else {
              await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/developement/`,
                  obj,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${token}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
            }
            var obj1 = {
              estimated_budget: sumvalue,
            };
            await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`,
                obj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          });
        }
      }
      setTimeout(() => {
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        sethireloading(false);
        window.location.reload();
      }, 3000);
    }
  };

  const deletebtn = async (data) => {
    var deleteuser = await axios
      .delete(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/delete/developement/${data.id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (deleteuser.success === "Data Deleted Successfully") {
      setTimeout(async () => {
        const teamlist = await axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => res.data);
        if (teamlist.length !== 0) {
          const transformedData = Object.values(
            teamlist.reduce((result, item) => {
              if (!result[item.role]) {
                result[item.role] = { name: item.role, roles: [] };
              }
              result[item.role].roles.push(item);
              return result;
            }, {})
          );

          const finaldata = transformedData
            .map((data) => {
              if (data.roles) {
                const filteredRoles = data.roles.filter(
                  (role) => role.selected_candidates?.length
                );
                if (filteredRoles.length > 0) {
                  return { ...data, roles: filteredRoles };
                }
              }
              return null;
            })
            .filter((data) => data !== null);
          setdisplayTeam(transformedData);

          setSelectedTeamNew(finaldata[0]);
        } else {
          setdisplayTeam([]);
        }
      }, 2000);
    }
  };

  const deleteBtn = async () => {
    if (singleteam.length !== 0) {
      dispatch(storeAction.isPopUpHander("deleteconfirm"));
    }
  };

  const deleteproject = async () => {
    if (singleteam.length !== 0) {
      setdeleteloading(true);
      const deleteteam = await axios
        .delete(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${singleteam[0].id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data);
      if (deleteteam.success === "Data Deleted Successfully") {
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        setIsPage("page1");
        sethireloading(false);
        setviewteam(true);
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        sethireloading(false);
        setdeleteloading(false);
        window.location.reload();
        // isPage === "page1" && addteam == false && viewteam == true
      }
    }
  };

  const BookmarkBtn = async (data) => {
    setlodingid(data.id);
    var obj = {
      bookmark: "true",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    const teamlist = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => res.data);
    if (teamlist.length !== 0) {
      const transformedData = Object.values(
        teamlist.reduce((result, item) => {
          if (!result[item.role]) {
            result[item.role] = { name: item.role, roles: [] };
          }
          result[item.role].roles.push(item);
          return result;
        }, {})
      );
      const finaldata = transformedData
        .map((data) => {
          if (data.roles) {
            const filteredRoles = data.roles.filter(
              (role) => role.selected_candidates?.length
            );
            if (filteredRoles.length > 0) {
              return { ...data, roles: filteredRoles };
            }
          }
          return null;
        })
        .filter((data) => data !== null);
      setdisplayTeam(transformedData);
      setSelectedTeamNew(finaldata[0]);
    } else {
      setdisplayTeam([]);
    }
    setTimeout(() => {
      setlodingid(null);
    }, 500);
  };

  const BookmarkBtn1 = async (data) => {
    setlodingid(data.id);
    var obj = {
      bookmark: "false",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    const teamlist = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => res.data);
    if (teamlist.length !== 0) {
      const transformedData = Object.values(
        teamlist.reduce((result, item) => {
          if (!result[item.role]) {
            result[item.role] = { name: item.role, roles: [] };
          }
          result[item.role].roles.push(item);
          return result;
        }, {})
      );
      const team = transformedData.find((team) => team.name === data.role);
      setdisplayTeam(transformedData);
      setSelectedTeamNew(team);
    } else {
      setdisplayTeam([]);
    }
    setTimeout(() => {
      setlodingid(null);
    }, 500);
  };

  const ViewTeam = async (data) => {
    setviewteam(true);
    setsingleteam([data]);
    setSelectedTeamNew([]);
    settableloading(true);
    setteam_id(data.id);
    try {
      const teamlist = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data);
      if (teamlist.length !== 0) {
        settableloading(false);
        const transformedData = Object.values(
          teamlist.reduce((result, item) => {
            if (!result[item.role]) {
              result[item.role] = { name: item.role, roles: [] };
            }
            result[item.role].roles.push(item);
            return result;
          }, {})
        );
        setdisplayTeam(transformedData);
        const finaldata = transformedData
          .map((data) => {
            if (data.roles) {
              const filteredRoles = data.roles.filter(
                (role) => role.selected_candidates?.length
              );
              if (filteredRoles.length > 0) {
                return { ...data, roles: filteredRoles };
              }
            }
            return null;
          })
          .filter((data) => data !== null);
        setSelectedTeamNew(finaldata[0]);
      } else {
        settableloading(false);
        setdisplayTeam([]);
      }
    } catch (err) {
      settableloading(false);
      console.error("Error fetching team list:", err);
    }
  };

  useEffect(() => {
    getNewData();
  }, [teamlist]);

  const getNewData = () => {
    if (teamlist.length !== 0) {
      const numbers = teamlist.flatMap((team) =>
        team.roles.flatMap((role) =>
          role.profiles
            .filter(
              (profile) =>
                profile.active === true && profile.rate_card_info !== null
            )
            .map((profile) => Number(profile.rate_card_info.remote_monthly))
        )
      );
      const sum = numbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setsumvalue(sum);
    }
  };

  const editbtn = async (displayTeam, singleteam) => {
    if (singleteam.length !== 0) {
      setaddteam(true);
      setteamid(singleteam[0].id);
      seteditteam(true);
      setformdata({
        project_name: singleteam[0].project_name,
        duration: singleteam[0].duration,
        budget: singleteam[0].budget,
        amount: singleteam[0].amount,
        description: singleteam[0].description,
      });
      setskill(singleteam[0].skills_required.split(","));
      if (displayTeam.length !== 0) {
        setSelectedTeam(displayTeam[0]);
        setteamlist(displayTeam);
      }
    }
  };

  const GenerateBtn = async () => {
    if (roletext.length !== 0) {
      setloading(true);
      const userMessage = {
        role: "user",
        content: `
          ${roletext} is the job role. Please create a detailed and well-structured job description for this role, adhering to global standards.
          
          - Use passive voice throughout the document.
          - Mark all subheadings clearly, without asterisks or special formatting.
          - Exclude any paragraph highlighting salary and benefits.
          - The job description should be concise and structured to fit within three pages, considering standard formatting.
          - Avoid specifying a specific number of years of experience.
          - Provide a general template without specifying salary, years of experience, or location.
        `,
      };

      try {
        const response = await fetch(
          "https://shark-app-5pxme.ondigitalocean.app/api/chat",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ messages: [userMessage] }),
          }
        );
        const data = await response.json();
        setjobdesdata(data.response);
        await parseQuery(data.response);

        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 10;
        const contentWidth = pageWidth - 2 * margin;
        const lineHeight = 7;
        const maxHeight = pageHeight - 2 * margin;

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Job Description", pageWidth / 2, margin + 5, {
          align: "center",
        });

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        const lines = doc.splitTextToSize(data.response, contentWidth);
        let yPosition = margin + 15;

        lines.forEach((line) => {
          if (yPosition + lineHeight > maxHeight) {
            doc.addPage(); // Add a new page when content overflows
            yPosition = margin; // Reset yPosition for the new page
          }
          doc.text(line, margin, yPosition);
          yPosition += lineHeight;
        });

        const pdfBlob = doc.output("blob");
        const fileUploadPromise = new Promise((resolve, reject) => {
          const storageRef = firebase.storage().ref(`jd${userid}.pdf`);
          storageRef
            .put(pdfBlob)
            .then((snapshot) => {
              snapshot.ref
                .getDownloadURL()
                .then((url) => {
                  resolve(url);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        });

        const imgurl2 = await fileUploadPromise;
        seturlvalue(imgurl2);
        setuploadstatus(true);
        seturlvaluestatus(true);
        setloading(false);

        setTimeout(() => {
          seturlvaluestatus(false);
          setroletext("");
          setuploadstatus(false);
        }, 2000);
      } catch (error) {
        console.error("Error fetching questions or uploading file:", error);
      }
    } else {
      setuploadstatuserror(true);
      setTimeout(() => {
        setuploadstatuserror(false);
      }, 2000);
    }
  };

  const anotherFunction = async (teamindex, roleIndex) => {
    setteamindex1(teamindex);
    setroleIndex1(roleIndex);
    setTimeout(() => {
      setteamindex1(null);
      setroleIndex1(null);
    }, 1000);
  };

  const changeTeam = async (e) => {
    if (e.length !== 0) {
      var filterlocation = [];
      for (var i = 0; i < newalldata.length; i++) {
        if (newalldata[i].project_name.toLowerCase().includes(e)) {
          filterlocation.push(newalldata[i]);
        }
      }
      setalldata(filterlocation);
    } else {
      setalldata(newalldata);
    }
  };

  const BrowseAllBtn = async () => {
    if (selectedTeam.roles.length !== 0) {
      setbrowseallloading(true);

      let updatedTeams = teamlist.map((team) => {
        if (team.name === selectedTeam.name) {
          return {
            ...team,
            roles: team.roles.map((role) => ({ ...role, browse: "true" })),
          };
        }
        return team;
      });

      setteamlist(updatedTeams);
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role) => ({ ...role, browse: "true" })),
      }));

      // Fetch all roles' data in parallel
      const updatedRoles = await Promise.all(
        selectedTeam.roles.map(async (role) => {
          const newobj = {
            search: {
              qualification: role.possition,
              experience: role.experience,
              location: role.location,
              skills: role.skill,
            },
          };

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/admin/search/`,
              newobj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            );

            return { ...role, profiles: response.data.data.slice(0, 3) };
          } catch (error) {
            return role;
          }
        })
      );

      // Update state with fetched profiles
      const updatedSelectedTeam = { ...selectedTeam, roles: updatedRoles };
      setSelectedTeam(updatedSelectedTeam);

      updatedTeams = updatedTeams.map((team) =>
        team.name === selectedTeam.name ? updatedSelectedTeam : team
      );

      setteamlist(updatedTeams);
      setbrowseallloading(false);
    }
  };

  const scheduleinterviewbtn = async (data) => {
    var singledata = await data.candidate_details.filter((item) => {
      return item.id == data.selected_candidates;
    });
    sethatid(data.id);
    dispatch(storeAction.isPopUpHander("scheduleinterview"));
    setsingleuser(singledata);
  };

  const submitbtn = async () => {
    setloading(true);
    try {
      const datetimeStrings = row
        .map((item) =>
          item.date && item.time1 ? `${item.date} ${item.time1}` : null
        )
        .filter(Boolean);

      if (datetimeStrings.length === 0) {
        alert("Please add at least one valid date and time.");
        setloading(false);
        return;
      }

      if (timezone.length === 0) {
        alert("Please select a time zone.");
        setloading(false);
        return;
      }

      const combinedDatetimeString = datetimeStrings.join(";");
      const obj = {
        user: userid,
        datetime: combinedDatetimeString,
        candidate: singleuser[0].id,
        time: "",
        time_zone: timezone,
        meeting_url: "",
        status: "true",
        hat_id: hatid,
      };

      const newobj = {
        status: "false",
        on_type: "Scheduled an interview",
        message: `Client <b>${userdata[0].first_name}</b> has scheduled an interview with candidate <b>${singleuser[0].first_name} in Hire a Team.</b>.`,
      };

      const emailobj = {
        email: `${userdata[0].username}`,
        from_email: "sales@hirein5.com",
        subject: `Client ${userdata[0].first_name} has scheduled an interview with candidate ${singleuser[0].first_name} in Hire a Team.`,
        message: `
          <p>Dear ${userdata[0].first_name},</p>
          <p>Thank you for providing your schedule for interviewing ${singleuser[0].first_name} in Hire a Team. We shall get back to you shortly with a confirmed slot.</p>
          <p><b>Schedule Interview Information:</b></p>
          <ul>
            <li>Date 1: ${row[0].date}, Time: ${row[0].time1}</li>
            <li>Date 2: ${row[1].date}, Time: ${row[1].time1}</li>
            <li>Date 3: ${row[2].date}, Time: ${row[2].time1}</li>
          </ul>
          <p>You can log in to your account to manage or review your interview schedules. Please click the link below:</p>
          <p><a href="https://app.hirein5.com/#/login" target="_blank" style="color: #007BFF; text-decoration: none;"><b>Click here to log in</b></a></p>
          <p>Regards,</p>
          <p><b>Team Hirein5</b></p>
        `,
      };

      const adminEmailObjList = [
        "sales@hirein5.com",
        "dhruv@hirein5.com",
        "shikha@hirein5.com",
      ].map((adminEmail) => ({
        email: adminEmail,
        from_email: "connect@hirein5.com",
        subject: `New Interview Scheduled Between ${userdata[0].first_name} and ${singleuser[0].first_name} in Hire a Team.`,
        message: `
          <p>Dear Admin,</p>
          <p>A new interview has been scheduled between the client <b>${userdata[0].first_name} ${userdata[0].last_name}</b> and the candidate <b>${singleuser[0].first_name} ${singleuser[0].last_name}</b>.</p>
          <p><b>Interview Details:</b></p>
          <ul>
            <li><b>Client Name:</b> ${userdata[0].first_name} ${userdata[0].last_name}</li>
            <li><b>Client Email:</b> ${userdata[0].username}</li>
            <li><b>Candidate Name:</b> ${singleuser[0].first_name} ${singleuser[0].last_name}</li>
            <li><b>Candidate Email:</b> ${singleuser[0].username}</li>
          </ul>
          <p><b>Available Interview Time Slots:</b></p>
          <ul>
            <li>Date 1: ${row[0].date}, Time: ${row[0].time1}</li>
            <li>Date 2: ${row[1].date}, Time: ${row[1].time1}</li>
            <li>Date 3: ${row[2].date}, Time: ${row[2].time1}</li>
          </ul>
          <p>Please log in to the admin portal for more details and to manage the interview schedule:</p>
          <p><a href="https://app.hirein5.com/#/admin/login" target="_blank" style="color: #007BFF; text-decoration: none;"><b>Click here to log in</b></a></p>
          <p>For any queries or assistance, feel free to contact the support team.</p>
          <p>Regards,</p>
          <p><b>Team Hirein5</b></p>
        `,
      }));

      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userdata[0].id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
        emailobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      for (const adminEmailObj of adminEmailObjList) {
        await axios.post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
          adminEmailObj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
      }

      try {
        const teamlist = await axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${team_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => res.data);
        if (teamlist.length !== 0) {
          settableloading(false);
          const transformedData = Object.values(
            teamlist.reduce((result, item) => {
              if (!result[item.role]) {
                result[item.role] = { name: item.role, roles: [] };
              }
              result[item.role].roles.push(item);
              return result;
            }, {})
          );
          setdisplayTeam(transformedData);
          const finaldata = transformedData
            .map((data) => {
              if (data.roles) {
                const filteredRoles = data.roles.filter(
                  (role) => role.selected_candidates?.length
                );
                if (filteredRoles.length > 0) {
                  return { ...data, roles: filteredRoles };
                }
              }
              return null;
            })
            .filter((data) => data !== null);
          setSelectedTeamNew(finaldata[0]);
        } else {
          settableloading(false);
          setdisplayTeam([]);
        }
      } catch (err) {
        settableloading(false);
      }

      // Success
      setloading(false);
      dispatch(storeAction.isPopUpHander());
    } catch (error) {
      alert("An error occurred while scheduling the interview.");
      setloading(false);
    }
  };

  const viewBtn = async (interview, role) => {
    setselectedCandidate(role.selected_candidates);
    var singledata = await role.candidate_details.filter((item) => {
      return item.id == role.selected_candidates;
    });
    setsingleuser(singledata);
    if (interview !== null) {
      const groupByDate = (datetimeString) => {
        return datetimeString.split(";").reduce((acc, datetime) => {
          const [date, time] = datetime.trim().split(" "); // Ensure no extra spaces
          if (acc[date]) {
            acc[date].push(time);
          } else {
            acc[date] = [time];
          }
          return acc;
        }, {});
      };
      const groupedArray = Object.entries(groupByDate(interview.datetime)).map(
        ([date, times]) => ({ date, times })
      );

      interview["groupdata"] = groupedArray;
      setsingleinterview([interview]);
      dispatch(storeAction.isPopUpHander("ViewInvoice"));
    } else {
      setsingleinterview([]);
      dispatch(storeAction.isPopUpHander());
    }
  };

  const StatusUpdate = async (status) => {
    var obj = {
      meeting_status: status,
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singleinterview[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
    try {
      const teamlist = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${team_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data);
      if (teamlist.length !== 0) {
        settableloading(false);
        const transformedData = Object.values(
          teamlist.reduce((result, item) => {
            if (!result[item.role]) {
              result[item.role] = { name: item.role, roles: [] };
            }
            result[item.role].roles.push(item);
            return result;
          }, {})
        );
        setdisplayTeam(transformedData);
        const finaldata = transformedData
          .map((data) => {
            if (data.roles) {
              const filteredRoles = data.roles.filter(
                (role) => role.selected_candidates?.length
              );
              if (filteredRoles.length > 0) {
                return { ...data, roles: filteredRoles };
              }
            }
            return null;
          })
          .filter((data) => data !== null);
        setSelectedTeamNew(finaldata[0]);
        dispatch(storeAction.isPopUpHander());
      } else {
        settableloading(false);
        setdisplayTeam([]);
        dispatch(storeAction.isPopUpHander());
      }
    } catch (err) {
      settableloading(false);
      dispatch(storeAction.isPopUpHander());
    }
  };

  const HireCandidate = async () => {
    dispatch(storeAction.isPopUpHander("Hireconformation"));
  };

  const yeshire = async () => {
    var wallet =
      userdata[0].wallet.length == 0 ? 0 : Number(userdata[0].wallet);
    if (wallet > 50) {
      setloadingpopup(true);
      setloading(true);
      var newobj = {
        wallet:
          userdata[0].wallet.length == 0 ? 0 : Number(userdata[0].wallet) - 50,
        username: userdata[0].username,
      };
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const randomValue =
        "pay_" +
        Array.from(
          { length: 14 },
          () => chars[Math.floor(Math.random() * chars.length)]
        ).join("");
      var obj = {
        client_id: userdata[0].id,
        opening_wallet: userdata[0].wallet.length == 0 ? 0 : userdata[0].wallet,
        closing_wallet:
          userdata[0].wallet.length == 0
            ? 0 - 50
            : Number(userdata[0].wallet) - 50,
        activity: "Hire candidate Debit",
        wallet_detection: "50",
        trasaction_id: randomValue,
      };
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/wallet/`, obj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      var updatedata = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
          newobj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (
        updatedata.message === "User and Associated Info updated successfully"
      ) {
        let updatedObject = {
          ...userdata[0],
          wallet:
            userdata[0].wallet.length == 0
              ? 0
              : Number(userdata[0].wallet) - 50,
        };
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        dispatch(storeAction.isPopUpHander());
        var obj = {
          client_id: userid,
          candidate_id: singleuser[0].id,
          status: false,
          approved_by: "",
        };
        var newobj = {
          status: "false",
          on_type: "Candidate Hiring",
          message: `<p>Client <b>${userdata[0].first_name}</b> wants to hire candidate <b>${singleuser[0].first_name}</b></p>`,
        };
        var new_obj = {
          username: singleuser[0].username,
          hired_status: "Applied",
        };

        const staticEmails = [
          "sales@hirein5.com",
          "dhruv@hirein5.com",
          "shikha@hirein5.com",
          // "dinesh@vimix.app",
          // "kaneeshvar@vimix.app",
        ];
        const dynamicEmails = userdata.map((user) => user.username);
        const allEmails = [...staticEmails, ...dynamicEmails];

        allEmails.forEach(async (email) => {
          const emailobj = {
            email: email,
            from_email: "connect@hirein5.com",
            subject: `Client ${userdata[0].first_name} wants to hire candidate ${singleuser[0].first_name}`,
            message: `
      <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
          <h2 style="text-align: center; color: #4CAF50;">Hiring Request</h2>
          <p style="font-size: 16px;">Dear Team,</p>
          <p style="font-size: 16px;">The following client is interested in hiring the mentioned candidate. Please find their details below:</p>
  
          <div style="margin-top: 20px;">
              <h3 style="color: #333; border-bottom: 2px solid #4CAF50; padding-bottom: 5px;">Client Information</h3>
              <p><strong>Name:</strong> ${userdata[0].first_name}</p>
              <p><strong>Email:</strong> ${userdata[0].username}</p>
              <p><strong>Phone:</strong> ${userdata[0].phone}</p>
              <p><strong>Company Name:</strong> ${
                userdata[0].company !== null && userdata[0].company.company_name
              }</p>
          </div>
  
          <div style="margin-top: 20px;">
              <h3 style="color: #333; border-bottom: 2px solid #4CAF50; padding-bottom: 5px;">Candidate Information</h3>
              <p><strong>Name:</strong> ${singleuser[0].first_name}</p>
              <p><strong>Email:</strong> ${singleuser[0].username}</p>
              <p><strong>Phone:</strong> ${singleuser[0].phone}</p>
              <p><strong>Monthly Rate Card:</strong> ${
                singleuser[0].rate_card_info !== null &&
                singleuser[0].rate_card_info.remote_monthly
              }</p>
              <p><strong>Address:</strong> ${
                singleuser[0].address !== null && singleuser[0].address.address
              }</p>
          </div>
  
          <div style="margin-top: 30px; text-align: center;">
              <p style="font-size: 14px; color: #555;">If you have any questions, please contact us.</p>
              <p style="font-size: 14px; color: #555;">Thank you,</p>
              <p style="font-size: 14px; color: #555;">Team Hirein5</p>
          </div>
      </div>
    `,
          };

          await axios
            .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
        });

        await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/create/hiredcandidates/`,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });

        await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userdata[0].id}/`,
            newobj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${singleuser[0].id}/`,
            new_obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          let updatedObject = {
            ...singleuser[0],
            hired_status: "Applied",
          };
          setloading(false);
          setloadingpopup(false);
          dispatch(storeAction.singleuserHander({ singleuser: [] }));
          setTimeout(() => {
            dispatch(
              storeAction.singleuserHander({ singleuser: [updatedObject] })
            );
          }, 10);
        } else {
          setloading(false);
          setloadingpopup(false);
        }
      } else {
        setloading(false);
        setloadingpopup(false);
      }
    } else {
      setloading(false);
      setloadingpopup(false);
      alert("Wallet Balance Low..");
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  const handlePageChange = async (page) => {
    settableloading(true);
    if (page >= 1 && page <= pageNumbers.length) {
      setCurrentPage(page);
    }
    const response = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/client/hireTeam/${userid}/?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (response.results.length !== 0) {
      settableloading(false);
      setcount(response.count);
      setalldata(response.results);
      setnewalldata(response.results);
    } else {
      settableloading(false);
      setcount(0);
      setalldata([]);
      setnewalldata([]);
    }
  };

  const getPaginationNumbers = () => {
    if (pageNumbers.length <= 1) return [];
    let pages = [];
    for (let i = 1; i <= pageNumbers.length; i++) {
      pages.push(i);
    }

    if (pages.length > 5) {
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(currentPage + 2, pageNumbers.length);
      const slicedPages = pages.slice(start - 1, end);

      return [
        ...(start > 1 ? [1, "..."] : []),
        ...slicedPages,
        ...(end < pageNumbers.length ? ["...", pageNumbers.length] : []),
      ];
    }
    return pages;
  };

  return (
    <div className="paddingLeft90">
      {isPage == "page1" && (
        <>
          {addteam == true && (
            <>
              <div className="HireMainComponent paddingRight90">
                <DashHead
                  head="Hire a Team"
                  desc="The Hire a Team module simplifies building skilled teams with customizable options and seamless onboarding."
                  highLight=""
                  descClass="dashBoardMainHeadDescBetween"
                />
                <div
                  className="hireBack1 pointer"
                  onClick={() => {
                    setaddteam(false);
                    seteditteam(false);
                    setformdata({
                      project_name: "",
                      duration: "",
                      budget: "",
                      amount: "",
                      description: "",
                      skills_required: "",
                    });
                    setskill([]);
                    setteamid(null);
                  }}
                >
                  <IoMdArrowBack className="backIcon1" />
                  <h3>Back to Teams</h3>
                </div>
                <div className="HireMainComponentFlex">
                  <div className="HireMainComponentLeft">
                    <h2>Project Details</h2>
                    <div className="flex gap-4">
                      <div className="HireMainComponentLeftContent">
                        <h3>Project name</h3>
                        <input
                          className="HireMainComponentLeftContentInput"
                          type="text"
                          name="project_name"
                          onChange={handlechange}
                          defaultValue={formdata.project_name}
                        />
                        {formdataerror.project_name && (
                          <p className="text-red-500 font-medium py-2 text-sm">
                            Please Enter Project name
                          </p>
                        )}
                      </div>
                      <div className="HireMainComponentLeftContent">
                        <h3>Project Duration (In Months)</h3>
                        <input
                          className="HireMainComponentLeftContentInput"
                          type="number"
                          name="duration"
                          onChange={handlechange}
                          defaultValue={formdata.duration}
                        />
                        {formdataerror.duration && (
                          <p className="text-red-500 font-medium py-2 text-sm">
                            Please Enter Project Duration
                          </p>
                        )}
                      </div>
                      <div className="HireMainComponentLeftContent">
                        <h3>Monthly Project Budget</h3>
                        <div className="flex">
                          <select
                            name="budget"
                            onChange={handlechange}
                            defaultValue={formdata.budget}
                          >
                            <option value="">Select</option>
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>
                            <option value="JPY">JPY</option>
                            <option value="SGD">SGD</option>
                            <option value="GBP">GBP</option>
                            <option value="AED">AED</option>
                            <option value="CAD">CAD</option>
                            <option value="SAR">SAR</option>
                          </select>

                          <input
                            className="HireMainComponentLeftContentInput HireMainComponentLeftContentInput1"
                            type="text"
                            name="amount"
                            onChange={handlechange}
                            defaultValue={formdata.amount}
                          />
                        </div>
                        {formdataerror.budget && (
                          <p className="text-red-500 font-medium py-2 text-sm">
                            Please Select Amount Type
                          </p>
                        )}
                        {formdataerror.amount && (
                          <p className="text-red-500 font-medium py-2 text-sm ml-2">
                            Please Enter Monthly Project Budget
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="HireMainComponentLeftContent">
                      <h3>Project Description</h3>
                      <textarea
                        className="HireMainComponentLeftContentInput"
                        name="description"
                        onChange={handlechange}
                        defaultValue={formdata.description}
                      ></textarea>
                      {/* {formdataerror.description && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Enter Project Description
                        </p>
                      )} */}
                    </div>
                    <div className="HireMainComponentLeftContent">
                      <h3>Technical Skills Required</h3>
                      <div className="searchBar">
                        <CiSearch />
                        <input
                          type="text"
                          ref={inputref}
                          onChange={dropDownHandler}
                        />
                        <FaAngleDown onClick={filterdata1} />
                      </div>
                      {/* {formdataerror.skills_required && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Select Technical Skills Required
                        </p>
                      )} */}
                      {dropDown && (
                        <div className="searchContent1">
                          <div className="searchContent">
                            {dropDownOpen.map((option, index) => (
                              <h3
                                onClick={() => {
                                  getdata(option);
                                }}
                                key={index}
                              >
                                {option}
                              </h3>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <Dragoption skill={skill} getdata={getdata} />
                    {teamid == null ? (
                      loader == true ? (
                        <button className="save buttonWidth100 marginLeft0 marginTop20">
                          Please Wait...
                        </button>
                      ) : (
                        <button
                          className="save buttonWidth100 marginLeft0 marginTop20"
                          onClick={createTaam}
                        >
                          Create Team
                        </button>
                      )
                    ) : editteam == true ? (
                      loader == true ? (
                        <button className="save buttonWidth100 marginLeft0 marginTop20">
                          Please Wait...
                        </button>
                      ) : (
                        <button
                          className="save buttonWidth100 marginLeft0 marginTop20"
                          onClick={UpdateTaam}
                        >
                          Update Team
                        </button>
                      )
                    ) : (
                      <button className="savedisable buttonWidth100 marginLeft0 marginTop10">
                        Create Team
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {teamid !== null && (
                <>
                  <div className="HireTeamTable paddingRight90">
                    <div className="HireTeamTableContent">
                      <div className="HireTeamTableTop">
                        {/* <button className="hireTableButton" >Add a Role</button> */}

                        {/* Display the team names */}
                        {teamlist.length !== 0
                          ? teamlist.map((data, index) => (
                              <h1
                                onClick={() => ButtonHandler(data.name, index)}
                                className={
                                  selectedTeam?.name === data.name
                                    ? "HireTeamTableToph1Active"
                                    : "HireTeamTableToph1"
                                }
                                key={index}
                              >
                                {data.name}
                              </h1>
                            ))
                          : null}

                        {/* <h1
                          onClick={() => ButtonHandler("Customized Team")}
                          className={
                            selectedTeam?.name === "Customized Team"
                              ? "HireTeamTableToph1Active"
                              : "HireTeamTableToph1"
                          }
                        >
                          Customized Team
                        </h1> */}
                      </div>

                      {selectedTeam && selectedTeam.roles && (
                        <table>
                          <thead>
                            <tr className="hireTableHead">
                              <th>Actions</th>
                              <th>Role</th>
                              {/* <th>Responsibility</th> */}
                              <th>Job Description</th>
                              <th>Skills</th>
                              <th>Number of roles</th>
                              <th>Location</th>
                              <th>Years of Experience</th>
                              <th>Availability</th>
                              <th>Mode of engagement</th>
                              {/* <th>Working hours</th>
                              <th>Budget per role (USD per month)</th> */}
                              <th>
                                <button
                                  className="hireTableButton"
                                  onClick={BrowseAllBtn}
                                >
                                  Browse All
                                </button>{" "}
                              </th>
                              <th>Profile 1</th>
                              <th>Profile 2</th>
                              <th>Profile 3</th>
                            </tr>
                          </thead>
                          <tbody className="hireTableBody">
                            {selectedTeam.roles.map((role, roleIndex) => (
                              <tr className="hireTablerow" key={roleIndex}>
                                <td>
                                  <div className="flex items-center justify-center">
                                    <FaRegCircleXmark
                                      className="xIcon"
                                      onClick={() =>
                                        removeRole(teamindex, roleIndex)
                                      }
                                    />
                                    <FiPlusCircle
                                      className="plusIcon"
                                      onClick={() => addRole(teamindex)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.possition}
                                      placeholder="Role"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "possition",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td>

                                {/* <td>
                          <h4 className="singlelinetext">
                            {role.responsibility}
                            <span
                              onClick={() => {
                                overlayHandlerNew(
                                  teamindex,
                                  roleIndex,
                                  "responsibility",
                                  "readjob_des",
                                  role.responsibility
                                );
                              }}
                              className="readMore"
                            >
                              Read More..
                            </span>
                          </h4>
                        </td> */}
                                <td>
                                  <h3>
                                    {role.jd_url.length !== 0 ? (
                                      <button
                                        id="job_des"
                                        onClick={() => {
                                          const fileExtension = role.jd_url
                                            .split("?")[0]
                                            .split(".")
                                            .pop()
                                            .toLowerCase();

                                          if (fileExtension === "pdf") {
                                            window.open(role.jd_url, "_blank");
                                          } else if (
                                            ["doc", "docx"].includes(
                                              fileExtension
                                            )
                                          ) {
                                            const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                                              role.jd_url
                                            )}`;
                                            window.open(viewerUrl, "_blank");
                                          }
                                        }}
                                        className={
                                          roleIndex1 == roleIndex &&
                                          teamindex1 == teamindex
                                            ? "hireTableButtonNew"
                                            : "hireTableButton"
                                        }
                                      >
                                        View
                                      </button>
                                    ) : null}

                                    <button
                                      className="hireTableButton ml-2"
                                      onClick={() => {
                                        // window.open(role.jd_url, "_blank");
                                        overlayHandlerNew(
                                          teamindex,
                                          roleIndex,
                                          "job_des",
                                          "job_des",
                                          role.job_des
                                        );
                                      }}
                                    >
                                      +
                                    </button>

                                    {role.jd_url.length !== 0 &&
                                      role.old_jd_url.length !== 0 && (
                                        <button
                                          className="hireTableButton1 ml-2"
                                          title="Replace old Job Description"
                                          onClick={() => {
                                            handleChangeNew(
                                              teamindex,
                                              roleIndex,
                                              "jd_url",
                                              role.old_jd_url
                                            );
                                            anotherFunction(
                                              teamindex,
                                              roleIndex
                                            ); // Your additional function
                                          }}
                                        >
                                          <MdReplay />
                                        </button>
                                      )}
                                  </h3>
                                </td>
                                {/* <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.skill}
                              placeholder="Skills"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "skill",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td> */}
                                <td>
                                  <h4
                                    className="singlelinetext"
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) =>
                                      handleChangeNew(
                                        teamindex,
                                        roleIndex,
                                        "skill",
                                        e.currentTarget.textContent
                                      )
                                    }
                                  >
                                    {role.skill}
                                  </h4>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.num_of_roles}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "num_of_roles",
                                          e.currentTarget.textContent
                                        )
                                      }
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      defaultValue={role.location}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "location",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select Location</option>
                                      {country_and_states.country.length !== 0
                                        ? country_and_states.country.map(
                                            (item, index) => (
                                              <>
                                                <option
                                                  value={item.name}
                                                  key={index}
                                                >
                                                  {item.name}
                                                </option>
                                              </>
                                            )
                                          )
                                        : null}
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.experience}
                                      placeholder="Years of Experience"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "experience",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.Availability}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "Availability",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Immediately">
                                        Immediately
                                      </option>
                                      <option value="In the next 30 days">
                                        In the next 30 days
                                      </option>
                                      <option value="post 30 days">
                                        post 30 days
                                      </option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.mode}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "mode",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="On Contract (Full Time)">
                                        On Contract (Full Time)
                                      </option>
                                      <option value="On Contract (Part Time)">
                                        On Contract (Part Time)
                                      </option>
                                      <option value="Employment">
                                        Employment
                                      </option>
                                    </select>
                                  </h3>
                                </td>
                                {/* <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.working_hours} // The current value is selected
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "working_hours",
                                          e.target.value // Capturing the selected value correctly
                                        )
                                      }
                                    >
                                      <option value="05:00 to 14:00 IST">
                                        05:00 to 14:00 IST
                                      </option>
                                      <option value="10:30 to 19:30 IST">
                                        10:30 to 19:30 IST
                                      </option>
                                      <option value="13:30 to 22:30 IST">
                                        13:30 to 22:30 IST
                                      </option>
                                      <option value="Flexible with working hours">
                                        Flexible with working hours
                                      </option>
                                    </select>
                                  </h3>
                                </td> */}

                                {/* <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.budget}
                                      placeholder="Budget per role"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "budget",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td> */}
                                <td>
                                  <h3>
                                    {browseloading == true &&
                                    roleindexvalue == roleIndex ? (
                                      <button className="hireTableButton">
                                        Loading...
                                      </button>
                                    ) : browseallloading === true ? (
                                      <button className="hireTableButton">
                                        Loading...
                                      </button>
                                    ) : (
                                      <button
                                        className="hireTableButton"
                                        onClick={() => {
                                          BrowseBtn(teamindex, roleIndex);
                                        }}
                                      >
                                        Browse
                                      </button>
                                    )}
                                  </h3>
                                </td>
                                {role.profiles.length !== 0 ? (
                                  <>
                                    {role.profiles.length !== 0 &&
                                      role.profiles.map((data, index) => (
                                        <td key={index}>
                                          <h3>
                                            <div
                                              onClick={() =>
                                                cardHandler(
                                                  teamindex,
                                                  roleIndex,
                                                  data.id,
                                                  data.rate_card_info
                                                    ? data.rate_card_info
                                                      ? data.rate_card_info
                                                          .remote_monthly
                                                      : 0
                                                    : 0
                                                )
                                              }
                                              className={`hireTeamCard cursor-pointer ${
                                                data.active !== undefined &&
                                                data.active == true
                                                  ? "hireTeamCardActive"
                                                  : ""
                                              }`}
                                            >
                                              <div className="mr-3 relative">
                                                <Avatar
                                                  name={data.first_name}
                                                  size={50}
                                                  round="50px"
                                                />
                                                <p className="percentagetag">
                                                  {data.matched_percentage}
                                                </p>
                                              </div>
                                              <div className="hireTeamCardFlex">
                                                <div className="hireTeamCardContent">
                                                  <h1 className="singlelinetext">
                                                    {
                                                      data.first_name.split(
                                                        " "
                                                      )[0]
                                                    }
                                                  </h1>
                                                  <h2
                                                    className={`singlelinetext ${
                                                      data.active !==
                                                        undefined &&
                                                      data.active == true
                                                        ? "hireTextColor"
                                                        : ""
                                                    }`}
                                                  >
                                                    {
                                                      data.preference_info
                                                        .qualification
                                                    }
                                                  </h2>
                                                  <div className="flex items-center justify-start mt-1">
                                                    <FiBriefcase
                                                      className={
                                                        data.active !==
                                                          undefined &&
                                                        data.active == true
                                                          ? "hirebriefColor"
                                                          : ""
                                                      }
                                                    />
                                                    <h5
                                                      className={
                                                        data.active !==
                                                          undefined &&
                                                        data.active == true
                                                          ? "hireTextColor"
                                                          : ""
                                                      }
                                                    >
                                                      {
                                                        data.preference_info
                                                          .year_of_experience
                                                      }{" "}
                                                      years of experience
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div className="hireTeamCardContent1">
                                                  {data.rate_card_info ? (
                                                    <h6>
                                                      $
                                                      {data.rate_card_info
                                                        ? data.rate_card_info
                                                            .remote_hourly
                                                        : 0}
                                                      /hr
                                                    </h6>
                                                  ) : (
                                                    <h6>UA</h6>
                                                  )}

                                                  <button
                                                    onClick={() => {
                                                      setIsPage("page2");
                                                      dispatch(
                                                        storeAction.singleuserHander(
                                                          {
                                                            singleuser: [data],
                                                          }
                                                        )
                                                      );
                                                    }}
                                                    className={`hireTableButton ${
                                                      data.active !==
                                                        undefined &&
                                                      data.active == true
                                                        ? "buttonColor"
                                                        : ""
                                                    }`}
                                                  >
                                                    View
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </h3>
                                        </td>
                                      ))}

                                    {[
                                      ...Array(
                                        Math.max(0, 3 - role.profiles.length)
                                      ),
                                    ].map((_, i) => (
                                      <td key={role.profiles.length + i}>
                                        <h3>
                                          <div className="hireTeamCard">
                                            <div className="hireTeamCardContent">
                                              {role.browse == "true" ? (
                                                <h1 className="text-center">
                                                  No Profiles found...
                                                </h1>
                                              ) : (
                                                <h1 className="text-center">
                                                  Click Browse to fetch profiles
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </h3>
                                      </td>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {[...Array(3)].map((_, i) => (
                                      <td key={i}>
                                        <h3>
                                          <div className="hireTeamCard">
                                            <div className="hireTeamCardContent">
                                              {role.browse == "true" ? (
                                                browseloading == true &&
                                                roleindexvalue == roleIndex ? (
                                                  <h1 className="text-center">
                                                    Fetching profile...
                                                  </h1>
                                                ) : browseallloading ===
                                                  true ? (
                                                  <h1 className="text-center">
                                                    Fetching profile...
                                                  </h1>
                                                ) : (
                                                  <h1 className="text-center">
                                                    No Profiles found...
                                                  </h1>
                                                )
                                              ) : (
                                                <h1 className="text-center">
                                                  Click Browse to fetch profiles
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </h3>
                                      </td>
                                    ))}
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="hireModuleCTA paddingRight90">
                    <h1>Est Budget (Verified User) $ {sumvalue}</h1>

                    {hireloading == true ? (
                      <button>Please Wait...</button>
                    ) : editteam == true ? (
                      <button onClick={UpdateaTeam}>Update Team</button>
                    ) : (
                      <button onClick={HireaTeam}>Create Team</button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {addteam == false && viewteam == false && (
            <>
              <div className="HireMainComponent1 paddingRight90">
                <div className="flex justify-between items-center">
                  <DashHead
                    head="Hire a Team"
                    desc="The Hire a Team module simplifies building skilled teams with customizable options and seamless onboarding."
                    highLight=""
                    descClass="dashBoardMainHeadDescBetween"
                  />
                  <button
                    className="viewButton"
                    onClick={() => {
                      setaddteam(true);
                      setformdata({
                        project_name: "",
                        duration: "",
                        budget: "",
                        amount: "",
                        description: "",
                        skills_required: "",
                      });
                      setskill([]);
                      setteamid(null);
                      setteamlist(dummyteamlist);
                    }}
                  >
                    Add New Team
                  </button>
                </div>
              </div>
              <div className="AdminClientProfileComp paddingRight90">
                <div className="AdminClientProfileCompSearch">
                  <input
                    type="text"
                    placeholder="Search Team"
                    onChange={(e) => {
                      changeTeam(e.target.value);
                    }}
                  />
                  <img src={search} alt="" />
                </div>
                <div className="AdminClientProfileCompTable">
                  <table className="AdminClientTable">
                    <thead>
                      <tr className="AdminTableHead">
                        <th>PROJECT NAME</th>
                        <th>PROJECT BUDGET</th>
                        <th>DURATION</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableloading == false ? (
                        alldata.length !== 0 ? (
                          alldata.map((data, index) => (
                            <tr className="adminTableRow" key={index}>
                              <td>
                                <h1>{data.project_name}</h1>
                              </td>
                              <td>
                                <h1>
                                  {data.budget} {data.amount}
                                </h1>
                              </td>
                              <td>
                                <h1>{data.duration} Months</h1>
                              </td>

                              <td>
                                <button
                                  className="viewButton"
                                  onClick={() => {
                                    ViewTeam(data);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td></td>
                            <td>
                              <div className="w-full py-5 ml-5">
                                No data found...
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td></td>
                          <td>
                            <div className="w-full py-5">
                              <h6 className="text-center flex gap-5 justify-center">
                                {" "}
                                <FiLoader className="loadingIconHAT" />
                              </h6>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="flex justify-center items-center py-5">
                    <button
                      className={`mx-1 px-4 py-2 rounded-lg ${
                        currentPage === 1
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "bg-blue-600 text-white hover:bg-blue-800"
                      }`}
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prev
                    </button>

                    {getPaginationNumbers().map((page, index) => (
                      <button
                        key={index}
                        className={`mx-1 px-4 py-2 rounded-lg ${
                          currentPage === page
                            ? "bg-blue-700 text-white"
                            : "bg-gray-300 text-black hover:bg-gray-400"
                        }`}
                        onClick={() => {
                          if (typeof page === "number") handlePageChange(page);
                        }}
                        disabled={page === "..."}
                      >
                        {page}
                      </button>
                    ))}

                    <button
                      className={`mx-1 px-4 py-2 rounded-lg ${
                        currentPage === pageNumbers.length
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "bg-blue-600 text-white hover:bg-blue-800"
                      }`}
                      disabled={currentPage === pageNumbers.length}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {isPopUp == "diversity" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Diversity Filter</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <div className="diversityPopUpBody">
                <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                <div className="HireMainComponentLeftContent">
                  <h3>Gender</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data1
                      }
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Nationality</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data2
                      }
                    >
                      <option value="">Select</option>
                      <option value="India">India</option>
                      <option value="USA">USA</option>
                      <option value="UAE">UAE</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Location</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data3
                      }
                    >
                      <option value="">Select</option>
                      <option value="Coimbatore">Coimbatore</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Bangalore">Bangalore</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changefilter}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
          {isPopUp == "job_des" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Job Description</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              {AiChat == false ? (
                <div className="diversityPopUpBody">
                  <div className="aadhaar cursor-pointer">
                    <div className="flex justify-between items-center">
                      <h3>Add your Job Description</h3>
                      <h4
                        className="text-blue-500 cursor-pointer font-medium text-sm"
                        onClick={() => {
                          setAiChat(true);
                        }}
                      >
                        Generate Using AI
                      </h4>
                    </div>
                    <div
                      className="aadhaarFront marginBottom20"
                      onClick={() => {
                        uploadHandler();
                      }}
                    >
                      <h3>
                        Drop your Files or{" "}
                        <span className="browser">browse</span>
                      </h3>
                      <p>Maximum size: 5MB</p>
                      <p title="">accepted formats: .pdf</p>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      name="aadhaarfront"
                      // accept=".pdf, .doc, .docx"
                      accept=".pdf"
                      onChange={handlePdfUpload}
                    />
                  </div>
                  {urlvaluestatus && (
                    <p className="text-green-500 pb-2 font-medium text-md">
                      Job Description Uploaded Successfully...
                    </p>
                  )}
                </div>
              ) : (
                <div className="diversityPopUpBody">
                  <div className="aadhaar cursor-pointer">
                    <div className="flex justify-between items-center">
                      <h3>Add your Job Description</h3>
                      <h4
                        className="text-blue-500 cursor-pointer font-medium text-sm"
                        onClick={() => {
                          setAiChat(false);
                        }}
                      >
                        Add Manually
                      </h4>
                    </div>
                    <div className="flex gap-5 items-center mt-5 mb-5 ">
                      <input
                        type="input"
                        name="text"
                        placeholder="Enter Job Role"
                        className="border rounded w-full px-2 py-2"
                        onChange={(e) => {
                          setroletext(e.target.value);
                        }}
                      />
                      {loading == true ? (
                        <button className="save">loading...</button>
                      ) : (
                        <button className="save" onClick={GenerateBtn}>
                          Generate
                        </button>
                      )}
                    </div>
                    {uploadstatus && (
                      <p className="successtag">
                        Job Description Generated Successfully...
                      </p>
                    )}
                    {uploadstatuserror && (
                      <p className="error_tag1">Please Enter Role...</p>
                    )}
                  </div>
                </div>
              )}

              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                {jdloading == true ? (
                  <button className="Hiresave">Please Wait..</button>
                ) : (
                  <button className="Hiresave" onClick={changejob_des}>
                    Save & Close
                  </button>
                )}
              </div>
            </div>
          )}
          {isPopUp == "readjob_des" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Responsibility</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <textarea
                className="border border-gray-500 rounded mx-4 mt-5 p-2 w-[95%]"
                placeholder="Responsibility"
                onChange={(e) => {
                  setnewresponsibilitydata(e.target.value);
                }}
                defaultValue={
                  responsibilitydata !== null && responsibilitydata.data
                }
              ></textarea>
              <div className="readjob_des py-2">
                <p>
                  Oversees the entire project, manages timelines, coordinates
                  team efforts
                </p>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changeresponsibility}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isPage === "page1" && addteam == false && viewteam == true && (
        <>
          <div className="paddingRight90 marginBottom35 paddingTop50">
            <div className="hireTeamOverview">
              <div className="hireTeamOverviewBack">
                <div
                  className="hireBack pointer"
                  onClick={() => {
                    setviewteam(false);
                    setformdata({
                      project_name: "",
                      duration: "",
                      budget: "",
                      amount: "",
                      description: "",
                      skills_required: "",
                    });
                    setskill([]);
                    setteamid(null);
                  }}
                >
                  <IoMdArrowBack className="backIcon1" />
                  <h3>Back to Teams</h3>
                </div>
                <div className="hireOnboard">
                  <button className="HireDelete" onClick={deleteBtn}>
                    Delete Project{" "}
                  </button>
                  {/* <button className="Hiresave">Onboarded</button>
                  <button className="HireDot">
                    <BsThreeDots />
                  </button> */}
                </div>
              </div>
              {singleteam.length !== 0 ? (
                <div className="hireTeamOverviewContent ">
                  <h1>{singleteam[0].project_name}</h1>
                  <p>
                    Budget : {singleteam[0].budget} {singleteam[0].amount}
                  </p>
                  <p>Duration : {singleteam[0].duration} Months</p>
                </div>
              ) : (
                <div className="hireTeamOverviewContent ">
                  <h1>Project Name</h1>
                  <p>Budget : $ 0</p>
                  <p>Team Size : 0</p>
                </div>
              )}
            </div>
            <div className="ClientProfileViewCard">
              <div className="ClientProfileViewCardEdit">
                <h1>Basic details</h1>
                <button
                  id="adminpersonal"
                  onClick={() => {
                    editbtn(displayTeam, singleteam);
                  }}
                >
                  <img src={editOutline} alt="" />
                  Edit
                </button>
              </div>
              {singleteam.length !== 0 && (
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Project Name</h2>
                    <h3>{singleteam[0].project_name}</h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Duration</h2>
                    <h3 className="lowerCase">
                      {singleteam[0].duration} Months
                    </h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Budget</h2>
                    <h3>
                      {singleteam[0].budget} {singleteam[0].amount}
                    </h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Description</h2>
                    <h3>{singleteam[0].description}</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="HireTeamTable paddingRight90">
            <div className="HireTeamTableContent1">
              <div className="HireTeamTableTop">
                {displayTeam.length !== 0 &&
                  displayTeam.map(
                    (data, index) =>
                      data.roles?.some(
                        (role) => role.selected_candidates?.length !== 0
                      ) && (
                        <h1
                          onClick={() => ButtonHandler1(data.name)}
                          className={
                            selectedTeamNew?.name === data.name
                              ? "HireTeamTableToph1Active"
                              : "HireTeamTableToph1"
                          }
                          key={index}
                        >
                          {data.name}
                        </h1>
                      )
                  )}
              </div>
              {tableloading == false ? (
                selectedTeamNew.length !== 0 ? (
                  selectedTeamNew.roles && (
                    <table>
                      <thead>
                        <tr className="hireTableHead">
                          <th>Remove</th>
                          <th>Role</th>
                          <th>Team Members</th>
                          <th>Availability</th>
                          <th>Working Hours</th>
                          <th>Cost per role (USD per month)</th>
                          <th>Schedule interview</th>
                          <th>Bookmark</th>
                          <th>Engagement Status</th>
                          <th>Engagement</th>
                        </tr>
                      </thead>

                      <tbody className="hireTableBody">
                        {selectedTeamNew.roles.length !== 0 &&
                          selectedTeamNew.roles.map(
                            (role, roleIndex) =>
                              role.selected_candidates.length !== 0 && (
                                <tr className="hireTablerow" key={roleIndex}>
                                  <td>
                                    <div className="flex items-center justify-center">
                                      <FaRegCircleXmark
                                        className="xIcon cursor-pointer"
                                        onClick={() => {
                                          deletebtn(role);
                                        }}
                                      />
                                      {/* <FiPlusCircle className="plusIcon" /> */}
                                    </div>
                                  </td>
                                  <td>
                                    <h3 className="pl-4">{role.possition}</h3>
                                  </td>
                                  <td>
                                    <h3>
                                      {role.candidate_details.length !== 0 ? (
                                        role.candidate_details.map(
                                          (item, index) =>
                                            item.id ==
                                              role.selected_candidates && (
                                              <div
                                                className={
                                                  card === "profile2"
                                                    ? "marginLeft20 hireTeamCard hireTeamCardActive"
                                                    : "marginLeft20 hireTeamCard"
                                                }
                                                key={index}
                                              >
                                                <div className="mr-3">
                                                  <Avatar
                                                    name={
                                                      // item.first_name
                                                      //   .split(" ")
                                                      //   .slice(0, -1)
                                                      //   .join(" ") +
                                                      // " " +
                                                      // item.first_name
                                                      //   .split(" ")
                                                      //   .pop()
                                                      //   .replace(/./g, "x")
                                                      item.first_name.split(
                                                        " "
                                                      )[0]
                                                    }
                                                    size={40}
                                                    round="50px"
                                                  />
                                                </div>
                                                <div className="hireTeamCardFlex">
                                                  <div className="hireTeamCardContent">
                                                    <h1 className="singlelinetext">
                                                      {" "}
                                                      {/* {item.first_name
                                                    .split(" ")
                                                    .slice(0, -1)
                                                    .join(" ") +
                                                    " " +
                                                    item.first_name
                                                      .split(" ")
                                                      .pop()
                                                      .replace(/./g, "x")} */}
                                                      {
                                                        item.first_name.split(
                                                          " "
                                                        )[0]
                                                      }
                                                    </h1>
                                                    <h2
                                                      className={
                                                        card ===
                                                        "singlelinetext profile2"
                                                          ? "singlelinetext hireTextColor"
                                                          : "singlelinetext"
                                                      }
                                                    >
                                                      {
                                                        item.preference_info
                                                          .qualification
                                                      }
                                                    </h2>
                                                    <div className="flex items-center justify-center mt-1">
                                                      <FiBriefcase
                                                        className={
                                                          card === "profile2"
                                                            ? "hirebriefColor"
                                                            : ""
                                                        }
                                                      />
                                                      <h5
                                                        className={
                                                          item.active !==
                                                            undefined &&
                                                          item.active == true
                                                            ? "hireTextColor"
                                                            : ""
                                                        }
                                                      >
                                                        {
                                                          item.preference_info
                                                            .year_of_experience
                                                        }{" "}
                                                        years of experience
                                                      </h5>
                                                    </div>
                                                  </div>
                                                  <div className="hireTeamCardContent1">
                                                    {item.rate_card_info ? (
                                                      <h6>
                                                        $
                                                        {item.rate_card_info
                                                          ? item.rate_card_info
                                                              .remote_hourly
                                                          : 0}
                                                        /hr
                                                      </h6>
                                                    ) : (
                                                      <h6>UA</h6>
                                                    )}

                                                    <button
                                                      onClick={() => {
                                                        setIsPage("page2");
                                                        dispatch(
                                                          storeAction.singleuserHander(
                                                            {
                                                              singleuser: [
                                                                item,
                                                              ],
                                                            }
                                                          )
                                                        );
                                                      }}
                                                      className={`hireTableButton ${
                                                        item.active !==
                                                          undefined &&
                                                        item.active == true
                                                          ? "buttonColor"
                                                          : ""
                                                      }`}
                                                    >
                                                      View
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        )
                                      ) : (
                                        <div className="marginLeft20 hireTeamCard">
                                          <div className="hireTeamCardContent">
                                            <h1 className="text-center">
                                              No Profile Found...
                                            </h1>
                                          </div>
                                        </div>
                                      )}
                                    </h3>
                                  </td>

                                  <td>
                                    <h3 className="pl-4">
                                      {role.Availability}
                                    </h3>
                                  </td>
                                  <td>
                                    <h3 className="pl-4">
                                      {role.working_hours}
                                    </h3>
                                  </td>
                                  <td>
                                    <h3 className="pl-4">{role.budget}</h3>
                                  </td>
                                  <td>
                                    <h3>
                                      {role.hat_interviews.length !== 0 ? (
                                        <button
                                          onClick={() => {
                                            viewBtn(
                                              role.hat_interviews[0],
                                              role
                                            );
                                          }}
                                          className="hireTableButton"
                                        >
                                          View
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            scheduleinterviewbtn(role);
                                          }}
                                          className="hireTableButton"
                                        >
                                          Schedule interview
                                        </button>
                                      )}
                                    </h3>
                                  </td>
                                  <td>
                                    <h3 className="pl-4">
                                      {role.bookmark == "true" ? (
                                        lodingid == role.id ? (
                                          <FiLoader className="normalloading normal_loading" />
                                        ) : (
                                          <FaBookmark
                                            className="cursor-pointer text-[#f59e0b]"
                                            onClick={() => {
                                              BookmarkBtn1(role);
                                            }}
                                          />
                                        )
                                      ) : lodingid == role.id ? (
                                        <FiLoader className="normalloading normal_loading" />
                                      ) : (
                                        <IoBookmarkOutline
                                          className="cursor-pointer"
                                          onClick={() => {
                                            BookmarkBtn(role);
                                          }}
                                        />
                                      )}
                                    </h3>
                                  </td>
                                  <td>
                                    {role.candidate_details
                                      ?.filter((item) =>
                                        role.selected_candidates.includes(
                                          item.id
                                        )
                                      )
                                      .map((selectedCandidate) => {
                                        let status = "";
                                        let statusClass = ""; // For dynamic class names

                                        if (
                                          selectedCandidate.hired_status ===
                                          "Applied"
                                        ) {
                                          status = "Waiting For Approval";
                                          statusClass =
                                            "statuswaiting-approval";
                                        } else if (
                                          selectedCandidate.hired_status === ""
                                        ) {
                                          if (
                                            role.hat_interviews?.length !== 0
                                          ) {
                                            status = "Interview Scheduled";
                                            statusClass = "statusinterview";
                                          } else {
                                            status = "Processing";
                                            statusClass = "statusprocesing";
                                          }
                                        } else if (
                                          selectedCandidate.hired_status ===
                                          "Approved"
                                        ) {
                                          status = "Hired";
                                          statusClass = "statuscontracted";
                                        } else {
                                          status =
                                            selectedCandidate.hired_status;
                                          statusClass =
                                            "custom-status text-red-500"; // Default for other statuses
                                        }

                                        return (
                                          <h3 key={selectedCandidate.id}>
                                            <p className={`${statusClass}`}>
                                              {status}
                                            </p>
                                          </h3>
                                        );
                                      })}
                                  </td>
                                  <td>
                                    <h3>
                                      {role.candidate_details
                                        ?.filter((item) =>
                                          role.selected_candidates.includes(
                                            item.id
                                          )
                                        )
                                        .map((selectedCandidate, index) =>
                                          selectedCandidate.status ===
                                          "Hired" ? (
                                            <select
                                              className="hireTableBodySelect"
                                              key={index}
                                            >
                                              <option value="Hired">
                                                Hired
                                              </option>
                                              <option value="Disengage">
                                                Disengage
                                              </option>
                                            </select>
                                          ) : (
                                            <select
                                              className="hireTableBodySelect"
                                              key={index}
                                              disabled
                                            >
                                              <option value="Not Hired">
                                                Not Hired
                                              </option>
                                            </select>
                                          )
                                        )}
                                    </h3>
                                  </td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                  )
                ) : (
                  <div className="w-full py-5 -ml-48">
                    <h6 className="text-center flex gap-5 justify-center">
                      No Data Found...
                    </h6>
                  </div>
                )
              ) : (
                <div className="w-full py-5 -ml-48">
                  <h6 className="text-center flex gap-5 justify-center">
                    {" "}
                    <FiLoader className="loadingIconHAT" />
                  </h6>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isPage === "page2" && (
        <>
          <div className="displayHandler">
            <CandidateProfileCard
              main="candidateProfile"
              fun={pageHandler}
              back="candidateBack"
            />
          </div>
          <div className="displayHandlerMob">
            <MobileCandidateProfile />
          </div>
        </>
      )}

      {isPopUp == "deleteconfirm" && (
        <div className="diversityContianer overlay">
          <div className="diversityPopUpHead">
            <h1>Delete Confirmation</h1>
            <FaRegCircleXmark
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
              className="xIcon"
            />
          </div>
          <div className="diversityPopUpBody">
            <h2>Are you sure you want to delete this project?</h2>
          </div>
          <div className="overlayBottomButton">
            <button
              className="Hirecancel"
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
            >
              Cancel
            </button>
            {deleteloading == true ? (
              <button className="Hiresave">Please Wait...</button>
            ) : (
              <button className="Hiresave" onClick={deleteproject}>
                Delete Project
              </button>
            )}
          </div>
        </div>
      )}

      {isPopUp == "scheduleinterview" && (
        <>
          <div className="adminEditOverlayInterview">
            <div className="adminEditOverlayHead">
              <h1>Schedule Interview (choose upto 3 slots)</h1>
              <RxCross1
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              />
            </div>
            <div className="adminEditOverlayBody">
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 1</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) =>
                      handleChangeInterview(0, "date", e.target.value)
                    }
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 1</h2>
                  <input
                    type="time"
                    onChange={(e) =>
                      handleChangeInterview(0, "time1", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 2</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) =>
                      handleChangeInterview(1, "date", e.target.value)
                    }
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 2</h2>
                  <input
                    type="time"
                    onChange={(e) =>
                      handleChangeInterview(1, "time1", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 3</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) =>
                      handleChangeInterview(2, "date", e.target.value)
                    }
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 3</h2>
                  <input
                    type="time"
                    onChange={(e) =>
                      handleChangeInterview(2, "time1", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="adminEditOverlayContent">
                <h2>Time Zone</h2>
                <select
                  onChange={(e) => {
                    settimezone(e.target.value);
                  }}
                  name="time_zone"
                >
                  <option value="">Select</option>
                  <option value="(GMT-11:00) Pacific/Midway">
                    (GMT-11:00) Pacific/Midway
                  </option>
                  <option value="(GMT-11:00) Pacific/Niue">
                    (GMT-11:00) Pacific/Niue
                  </option>
                  <option value="(GMT-11:00) Pacific/Pago_Pago">
                    (GMT-11:00) Pacific/Pago_Pago
                  </option>
                  <option value="(GMT-10:00) Pacific/Honolulu">
                    (GMT-10:00) Pacific/Honolulu
                  </option>
                  <option value="(GMT-10:00) Pacific/Rarotonga">
                    (GMT-10:00) Pacific/Rarotonga
                  </option>
                  <option value="(GMT-10:00) Pacific/Tahiti">
                    (GMT-10:00) Pacific/Tahiti
                  </option>
                  <option value="(GMT-09:30) Pacific/Marquesas">
                    (GMT-09:30) Pacific/Marquesas
                  </option>
                  <option value="(GMT-09:00) America/Anchorage">
                    (GMT-09:00) America/Anchorage
                  </option>
                  <option value="(GMT-09:00) Pacific/Gambier">
                    (GMT-09:00) Pacific/Gambier
                  </option>
                  <option value="(GMT-08:00) America/Los_Angeles">
                    (GMT-08:00) America/Los_Angeles
                  </option>
                  <option value="(GMT-08:00) America/Tijuana">
                    (GMT-08:00) America/Tijuana
                  </option>
                  <option value="(GMT-08:00) America/Vancouver">
                    (GMT-08:00) America/Vancouver
                  </option>
                  <option value="(GMT-07:00) America/Denver">
                    (GMT-07:00) America/Denver
                  </option>
                  <option value="(GMT-07:00) America/Phoenix">
                    (GMT-07:00) America/Phoenix
                  </option>
                  <option value="(GMT-06:00) America/Chicago">
                    (GMT-06:00) America/Chicago
                  </option>
                  <option value="(GMT-06:00) America/Mexico_City">
                    (GMT-06:00) America/Mexico_City
                  </option>
                  <option value="(GMT-06:00) America/Regina">
                    (GMT-06:00) America/Regina
                  </option>
                  <option value="(GMT-05:00) America/New_York">
                    (GMT-05:00) America/New_York
                  </option>
                  <option value="(GMT-05:00) America/Toronto">
                    (GMT-05:00) America/Toronto
                  </option>
                  <option value="(GMT-05:00) America/Bogota">
                    (GMT-05:00) America/Bogota
                  </option>
                  <option value="(GMT-04:30) America/Caracas">
                    (GMT-04:30) America/Caracas
                  </option>
                  <option value="(GMT-04:00) America/Halifax">
                    (GMT-04:00) America/Halifax
                  </option>
                  <option value="(GMT-04:00) America/Santiago">
                    (GMT-04:00) America/Santiago
                  </option>
                  <option value="(GMT-03:30) America/St_Johns">
                    (GMT-03:30) America/St_Johns
                  </option>
                  <option value="(GMT-03:00) America/Argentina/Buenos_Aires">
                    (GMT-03:00) America/Argentina/Buenos_Aires
                  </option>
                  <option value="(GMT-02:00) Atlantic/South_Georgia">
                    (GMT-02:00) Atlantic/South_Georgia
                  </option>
                  <option value="(GMT-01:00) Atlantic/Azores">
                    (GMT-01:00) Atlantic/Azores
                  </option>
                  <option value="(GMT+00:00) Europe/London">
                    (GMT+00:00) Europe/London
                  </option>
                  <option value="(GMT+00:00) Africa/Abidjan">
                    (GMT+00:00) Africa/Abidjan
                  </option>
                  <option value="(GMT+01:00) Europe/Paris">
                    (GMT+01:00) Europe/Paris
                  </option>
                  <option value="(GMT+01:00) Africa/Algiers">
                    (GMT+01:00) Africa/Algiers
                  </option>
                  <option value="(GMT+02:00) Europe/Athens">
                    (GMT+02:00) Europe/Athens
                  </option>
                  <option value="(GMT+02:00) Africa/Johannesburg">
                    (GMT+02:00) Africa/Johannesburg
                  </option>
                  <option value="(GMT+03:00) Europe/Moscow">
                    (GMT+03:00) Europe/Moscow
                  </option>
                  <option value="(GMT+03:00) Asia/Baghdad">
                    (GMT+03:00) Asia/Baghdad
                  </option>
                  <option value="(GMT+03:30) Asia/Tehran">
                    (GMT+03:30) Asia/Tehran
                  </option>
                  <option value="(GMT+04:00) Asia/Dubai">
                    (GMT+04:00) Asia/Dubai
                  </option>
                  <option value="(GMT+04:30) Asia/Kabul">
                    (GMT+04:30) Asia/Kabul
                  </option>
                  <option value="(GMT+05:00) Asia/Karachi">
                    (GMT+05:00) Asia/Karachi
                  </option>
                  <option value="(GMT+05:30) Asia/Kolkata">
                    (GMT+05:30) Asia/Kolkata
                  </option>
                  <option value="(GMT+05:45) Asia/Kathmandu">
                    (GMT+05:45) Asia/Kathmandu
                  </option>
                  <option value="(GMT+06:00) Asia/Dhaka">
                    (GMT+06:00) Asia/Dhaka
                  </option>
                  <option value="(GMT+06:30) Asia/Yangon">
                    (GMT+06:30) Asia/Yangon
                  </option>
                  <option value="(GMT+07:00) Asia/Bangkok">
                    (GMT+07:00) Asia/Bangkok
                  </option>
                  <option value="(GMT+08:00) Asia/Hong_Kong">
                    (GMT+08:00) Asia/Hong_Kong
                  </option>
                  <option value="(GMT+09:00) Asia/Tokyo">
                    (GMT+09:00) Asia/Tokyo
                  </option>
                  <option value="(GMT+09:30) Australia/Darwin">
                    (GMT+09:30) Australia/Darwin
                  </option>
                  <option value="(GMT+10:00) Australia/Sydney">
                    (GMT+10:00) Australia/Sydney
                  </option>
                  <option value="(GMT+11:00) Pacific/Noumea">
                    (GMT+11:00) Pacific/Noumea
                  </option>
                  <option value="(GMT+12:00) Pacific/Fiji">
                    (GMT+12:00) Pacific/Fiji
                  </option>
                  <option value="(GMT+13:00) Pacific/Tongatapu">
                    (GMT+13:00) Pacific/Tongatapu
                  </option>
                  <option value="(GMT+14:00) Pacific/Kiritimati">
                    (GMT+14:00) Pacific/Kiritimati
                  </option>
                </select>
              </div>
            </div>

            <div className="editOverlayButton">
              <button
                className="discard"
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              >
                Discard
              </button>

              {loading === false ? (
                <button className="save" onClick={submitbtn}>
                  Submit
                </button>
              ) : (
                <button className="save w-[10rem] flex justify-center items-center">
                  <FiLoader className="loadingIcon" />
                </button>
              )}
            </div>
          </div>
        </>
      )}

      {isPopUp === "ViewInvoice" && (
        <div className="reserveSuccessinterview">
          <h1 className="font-semibold text-xl">
            Schedule Interview Information
          </h1>
          {singleinterview.length !== 0 ? (
            <div className="flex gap-4 w-[70%] justify-between">
              {singleinterview[0].groupdata.length !== 0
                ? singleinterview[0].groupdata.map((data, index) => (
                    <div className="items-center mt-5" key={index}>
                      <h2 className="font-medium font-bold text-[#1D37E7]">
                        Schedule {index + 1}
                      </h2>
                      <h2 className="my-2">
                        <b>Date :</b> {data.date}
                      </h2>
                      <h2>
                        <b>Time :</b> {data.times.join(", ")}
                      </h2>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
          {singleinterview.length !== 0 ? (
            <div className="mt-5 w-[75%]">
              {singleinterview[0].time.length !== 0 ? (
                <div className="flex gap-4 items-center justify-start px-4 mb-2">
                  <h2>
                    <b>Preferred Date & Time:</b>{" "}
                  </h2>
                  <h3>{singleinterview[0].time}</h3>
                </div>
              ) : null}

              <div className="flex gap-4 items-center justify-start px-4 mb-2">
                <h2>
                  <b>Time Zone:</b>{" "}
                </h2>
                <h3>{singleinterview[0].time_zone}</h3>
              </div>

              <div className="flex gap-4 items-center justify-start px-4 mb-2">
                <h2>
                  <b>Meeting link:</b>{" "}
                </h2>
                {singleinterview[0].meeting_url.length ? (
                  <h3
                    onClick={() => {
                      window.open(
                        `${
                          singleinterview[0].meeting_url.length !== 0
                            ? singleinterview[0].meeting_url
                            : null
                        }`,
                        "_blank"
                      );
                    }}
                    className="cursor-pointer"
                  >
                    {singleinterview[0].meeting_url.length !== 0
                      ? singleinterview[0].meeting_url
                      : "Not Provided"}
                  </h3>
                ) : (
                  <h3>Not Provided</h3>
                )}
              </div>
              <div className="flex gap-4 items-center justify-start px-4 mb-2">
                <h2>
                  <b>Meeting Status:</b>{" "}
                </h2>
                {singleinterview[0].meeting_status.length !== 0 ? (
                  <h3>{singleinterview[0].meeting_status}</h3>
                ) : (
                  <h3>Not Updated</h3>
                )}
              </div>
              {singleinterview[0].meeting_status.length == 0 &&
                singleinterview[0].meeting_url.length !== 0 && (
                  <div className="flex gap-4 px-4 mt-5">
                    <button
                      className="completebtn"
                      onClick={() => {
                        StatusUpdate("Completed");
                      }}
                    >
                      Completed
                    </button>
                    <button
                      className="cancelbtn"
                      onClick={() => {
                        StatusUpdate("Cancel");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}

              {singleinterview[0].meeting_status == "Completed" &&
                (singleuser[0].hired_status == "" ? (
                  <button className="touchButtonHire" onClick={HireCandidate}>
                    Request For Hire
                  </button>
                ) : (
                  <button className="touchButtonHiredisable" disabled>
                    Request For Hire
                  </button>
                ))}
            </div>
          ) : null}
        </div>
      )}

      {isPopUp == "Hireconformation" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Request to Hire Candidate</h1>
              <span
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              >
                <RxCross2 />
              </span>
            </div>
            <div className="approveCandidateOverlayBody">
              <p>
                Are you sure you want to proceed with hiring this candidate?
                <br />
                <span className="font-bold mt-2">
                  {" "}
                  Note: Hiring this candidate will deduct 50 credits from your
                  wallet.
                </span>
              </p>

              <div className="approveCandidateOverlayButton">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Cancel
                </button>
                {loadingpopup == true ? (
                  <button className="save">Please Wait...</button>
                ) : (
                  <button onClick={yeshire} className="save">
                    Yes, Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HireMainComponent;
