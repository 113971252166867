/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./TravelHistory.css";
import user from "../../../../assests/User.svg";
import dropDown from "../../../../assests/arrowDown.svg";
import edit from "../../../../assests/edit.svg";
import dropUp from "../../../../assests/arrowUp.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import axios from "axios";
import { FiLoader } from "react-icons/fi";
import { GoGlobe } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoAlertCircle } from "react-icons/io5";
import Select from "react-select";
import moment from "moment";
import { firebase } from "../../../../database/firebase";

const TravelHistory = () => {
  const userdata = useSelector((store) => store.userdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);

  const dispatch = useDispatch();
  const [isArrow, setIsArrow] = useState(false);
  const [status, setstatus] = useState(false);
  const [travelrow, settravelrow] = useState([
    {
      country: "",
      year_of_travel: "",
      duration: "",
      purpose: "",
      type_of_visa: "",
      validity_of_visa: "",
    },
  ]);
  const [travelform, settravelform] = useState({
    current_place_of_residence: "",
    lived_at_current_residence: "",
    travel_readlines: "",
    duration: "",
    country: "",
    onlyfor: "",
  });
  const [workauth, setworkauth] = useState({
    work_auth: "",
    work_auth_name: "",
    security_clearance: "",
    relocation_preference: "",
    travel_preference: "",
    valid_date: "",
    eda_type: "",
    eda_type_name: "",
    eda_url: "",
    driving_licence: "",
  });
  const [driving_licencestatus, setdriving_licencestatus] = useState(false);
  const [eda_urlstatus, seteda_urlstatus] = useState(false);

  const handlechangeworkauth = async (e) => {
    const { name, value } = e.target;
    setworkauth((values) => ({ ...values, [name]: value }));
  };

  const addcounttravel = () => {
    var newobj = {
      country: "",
      year_of_travel: "",
      duration: "",
      purpose: "",
      type_of_visa: "",
      validity_of_visa: "",
    };
    settravelrow((prevState) => [...prevState, newobj]);
  };

  const dropDownhandler = () => {
    setIsArrow(!isArrow);
  };

  const [isPage, setIsPage] = useState("page2");
  const pagehandler = (e) => {
    setIsPage(e.target.id);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overLayHandler = () => {
    dispatch(storeAction.isPopUpHander("travel"));
    Getalldata();
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const handlechangetravel = (value, index, name) => {
    travelrow[index][name] = value;
    settravelrow([...travelrow]);
  };
  const handlechange_travel = (e) => {
    const { name, value } = e.target;
    settravelform((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    Getalldata();
  }, [userdata.length !== 0]);

  const Getalldata = async () => {
    if (userdata.length !== 0) {
      if (userdata[0].travel_info !== null) {
        if (userdata[0].travel_info.travelled_to.length !== 0) {
          var newarray = [];
          for (
            var i = 0;
            i < userdata[0].travel_info.travelled_to.length;
            i++
          ) {
            newarray.push({
              country:
                userdata[0].travel_info.travelled_to[i].split(":")[0].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[0]
                      .replace(/\s/g, "")
                  : "",
              year_of_travel:
                userdata[0].travel_info.travelled_to[i].split(":")[1].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[1]
                      .replace(/\s/g, "")
                  : "",
              duration:
                userdata[0].travel_info.travelled_to[i].split(":")[2].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[2]
                      .replace(/\s/g, "")
                  : "",
              purpose:
                userdata[0].travel_info.travelled_to[i].split(":")[3].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[3]
                      .replace(/\s/g, "")
                  : "",
              type_of_visa:
                userdata[0].travel_info.travelled_to[i].split(":")[4].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[4]
                      .replace(/\s/g, "")
                  : "",
              validity_of_visa:
                userdata[0].travel_info.travelled_to[i].split(":")[5].length !==
                0
                  ? userdata[0].travel_info.travelled_to[i]
                      .split(":")[5]
                      .replace(/\s/g, "")
                  : "",
            });
          }
          settravelrow(newarray);
        }
        settravelform({
          current_place_of_residence:
            userdata[0].current_place_of_residence || "",
          lived_at_current_residence:
            userdata[0].lived_at_current_residence || "",
        });

        if (userdata[0].travel_info.travel_to_for_work.length !== 0) {
          var new_array1 = [],
            countrylist = [];
          for (
            var j = 0;
            j < userdata[0].travel_info.travel_to_for_work.length;
            j++
          ) {
            countrylist.push(
              userdata[0].travel_info.travel_to_for_work[j].split(":")[0]
                .length !== 0
                ? userdata[0].travel_info.travel_to_for_work[j].split(":")[0]
                : ""
            );
            new_array1.push({
              country:
                userdata[0].travel_info.travel_to_for_work[j].split(":")[0]
                  .length !== 0
                  ? userdata[0].travel_info.travel_to_for_work[j].split(":")[0]
                  : "",
              only_for:
                userdata[0].travel_info.travel_to_for_work[j].split(":")[1]
                  .length !== 0
                  ? userdata[0].travel_info.travel_to_for_work[j].split(":")[1]
                  : "",
              duration:
                userdata[0].travel_info.travel_to_for_work[j].split(":")[2]
                  .length !== 0
                  ? userdata[0].travel_info.travel_to_for_work[j].split(":")[2]
                  : "",
              readlines:
                userdata[0].travel_info.travel_to_for_work[j].split(":")[2]
                  .length !== 0
                  ? userdata[0].travel_info.travel_to_for_work[j].split(":")[3]
                  : "",
            });
          }

          settravelwork(new_array1);
          if (countrylist.length !== 0) {
            var filter1 = [];
            for (var i = 0; i < countrylist.length; i++) {
              filter1.push({
                value: countrylist[i],
                label: countrylist[i],
              });
            }
            setskilloption(filter1);
          }
        }
        if (userdata[0].travel_info.prefered_countries.length !== 0) {
          if (userdata[0].travel_info.prefered_countries.length !== 0) {
            var filter1 = [];
            for (
              var i = 0;
              i < userdata[0].travel_info.prefered_countries.length;
              i++
            ) {
              filter1.push({
                value: userdata[0].travel_info.prefered_countries[i],
                label: userdata[0].travel_info.prefered_countries[i],
              });
            }
            setSelectedOption(filter1);
          }
        }
        setrelocate_for_work({
          willingness: userdata[0].travel_info.willingness,
          duration: userdata[0].travel_info.duration,
        });
      }
      if (userdata[0].work_auth_info !== null) {
        setworkauth({
          work_auth: userdata[0].work_auth_info.work_auth,
          work_auth_name: userdata[0].work_auth_info.work_auth_name,
          security_clearance: userdata[0].work_auth_info.security_clearance,
          relocation_preference:
            userdata[0].work_auth_info.relocation_preference,
          travel_preference: userdata[0].work_auth_info.travel_preference,
          valid_date: userdata[0].work_auth_info.valid_date,
          eda_type: userdata[0].work_auth_info.eda_type,
          eda_url: userdata[0].work_auth_info.eda_url,
          eda_type_name: userdata[0].work_auth_info.eda_type_name,
          driving_licence: userdata[0].work_auth_info.driving_licence,
        });
        setTimeout(() => {
          setworkauth({
            work_auth: userdata[0].work_auth_info.work_auth,
            work_auth_name: userdata[0].work_auth_info.work_auth_name,
            security_clearance: userdata[0].work_auth_info.security_clearance,
            relocation_preference:
              userdata[0].work_auth_info.relocation_preference,
            travel_preference: userdata[0].work_auth_info.travel_preference,
            valid_date: userdata[0].work_auth_info.valid_date,
            eda_type: userdata[0].work_auth_info.eda_type,
            eda_url: userdata[0].work_auth_info.eda_url,
            eda_type_name: userdata[0].work_auth_info.eda_type_name,
            driving_licence: userdata[0].work_auth_info.driving_licence,
          });
        }, 2000);
      }
    }
  };

  const [travelwork, settravelwork] = useState([
    {
      country: "",
      only_for: "",
      duration: "",
      readlines: "",
    },
  ]);

  const addcountwork = () => {
    var newobj = {
      country: "",
      only_for: "",
      duration: "",
      readlines: "",
    };
    settravelwork((prevState) => [...prevState, newobj]);
  };

  const handlechangework = (value, index, name) => {
    travelwork[index][name] = value;
    settravelwork([...travelwork]);
  };
  const [loading, setloading] = useState(false);

  const submitbtn = async () => {
    setloading(true);
    const arrayOfStrings = travelrow.map(
      (obj) =>
        `${obj.country}:${obj.year_of_travel}:${obj.duration}:${obj.purpose}:${obj.type_of_visa}:${obj.validity_of_visa}`
    );
    var valuesArray = [];
    if (selectedOption !== null && selectedOption !== undefined) {
      valuesArray = selectedOption.map((country) => country.value);
    }
    const arrayOfStrings2 = travelwork.map(
      (obj) => `${obj.country}:${obj.only_for}:${obj.duration}:${obj.readlines}`
    );
    var newobj1 = {
      username: userdata[0].username,
      travel_info: {
        travelled_to: arrayOfStrings,
        relocate_for_work: "",
        travel_to_for_work: arrayOfStrings2,
        willingness: relocate_for_work.willingness,
        duration: relocate_for_work.duration,
        prefered_countries: valuesArray,
      },
      work_auth_info: {
        work_auth: workauth.work_auth,
        work_auth_name: workauth.work_auth_name,
        security_clearance: workauth.security_clearance,
        relocation_preference: workauth.relocation_preference,
        travel_preference: workauth.travel_preference,
        valid_date: workauth.valid_date,
        eda_type: workauth.eda_type,
        eda_url: workauth.eda_url,
        eda_type_name: workauth.eda_type_name,
        driving_licence: workauth.driving_licence,
      },
      current_place_of_residence: travelform.current_place_of_residence || "",
      lived_at_current_residence: travelform.lived_at_current_residence || "",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
        newobj1,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...userdata[0],
        travel_info: updatedata.user.travel_info,
        work_auth_info: {
          work_auth: workauth.work_auth,
          work_auth_name: workauth.work_auth_name,
          security_clearance: workauth.security_clearance,
          relocation_preference: workauth.relocation_preference,
          travel_preference: workauth.travel_preference,
          valid_date: workauth.valid_date,
          eda_type: workauth.eda_type,
          eda_url: workauth.eda_url,
          eda_type_name: workauth.eda_type_name,
          driving_licence: workauth.driving_licence,
        },
      };
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setloading(false);
    } else {
      setloading(false);
    }
    Getalldata();
  };

  const [isComplete, setIsComplete] = useState(false);
  const completeHandler = () => {
    setIsComplete(true);
  };
  const completeHandlerLeave = () => {
    setIsComplete(false);
  };
  const [relocate_for_work, setrelocate_for_work] = useState({
    willingness: "",
    duration: "",
  });
  const changerelocatedata = (e) => {
    const { name, value } = e.target;
    setrelocate_for_work((values) => ({ ...values, [name]: value }));
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [skilloption, setskilloption] = useState([]);

  const handlechangedriving = async (e) => {
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`driving_lic${userid}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new1 = await file13;
    setworkauth((values) => ({ ...values, driving_licence: imgurl_new1 }));
    setdriving_licencestatus(true);
    setTimeout(() => {
      setdriving_licencestatus(false);
    }, 5000);
  };

  const handlechangeurl = async (e) => {
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`eda_url${userid}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new1 = await file13;
    setworkauth((values) => ({ ...values, eda_url: imgurl_new1 }));
    seteda_urlstatus(true);
    setTimeout(() => {
      seteda_urlstatus(false);
    }, 5000);
  };
  return (
    <div>
      <div className="travelHistory">
        <div className="innerTravelHistory">
          <div
            className={isArrow === true ? "travelHistoryHead" : "bottomBorder"}
          >
            <div className="travelHistoryHeadLeft">
              <span>
                <GoGlobe />
              </span>
              <h1>Work Authorization</h1>
            </div>
            <div className="travelHistoryLeftIcon">
              {userdata.length !== 0 ? (
                userdata[0].travel_info !== null ? (
                  <IoIosCheckmarkCircle className="profileCompleteIcon" />
                ) : (
                  <>
                    {" "}
                    <IoAlertCircle
                      onMouseEnter={completeHandler}
                      onMouseLeave={completeHandlerLeave}
                      className="warningIcon"
                    />
                    {isComplete == true && (
                      <div className="InCompleteIndication">
                        <p>Complete Travel History</p>
                      </div>
                    )}
                  </>
                )
              ) : null}
              {userdata.length !== 0 ? (
                userdata[0].nottify == true ? (
                  <img
                    className="travelHistoryLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                ) : (
                  <img
                    className="travelHistoryLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                )
              ) : null}

              {isArrow === true ? (
                <img onClick={dropDownhandler} src={dropUp} alt="" />
              ) : (
                <img onClick={dropDownhandler} src={dropDown} alt="" />
              )}
            </div>
          </div>
          {isArrow === true &&
            (userdata.length !== 0 ? (
              <div className="travelHistoryDesc">
                <h1>
                  Add your travel history here to stand out from other
                  candidates
                </h1>
                <div className="travelGrid">
                  {userdata[0].work_auth_info !== null ? (
                    <div className="travelGridOne">
                      <h1>WORK AUTHORIZATION</h1>
                      {userdata[0].work_auth_info.work_auth == "Yes" && (
                        <>
                          <h3>
                            Do you have Work Authorisation for US / Canada?:{" "}
                            <p>{userdata[0].work_auth_info.work_auth}</p>
                          </h3>
                          <h3>
                            Work Authorization Category:{" "}
                            <p>{userdata[0].work_auth_info.work_auth_name}</p>
                          </h3>
                          {(userdata[0].work_auth_info.work_auth_name ==
                            "H1Visa" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "Green Card" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "TN Permit" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "EAD" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "Others") && (
                            <h3>
                              Valid Until:{" "}
                              <p>{userdata[0].work_auth_info.valid_date}</p>
                            </h3>
                          )}

                          <h3>
                            Security Clearance:{" "}
                            <p>
                              {userdata[0].work_auth_info.security_clearance}
                            </p>
                          </h3>
                          <h3>
                            Relocation Preference:{" "}
                            <p>
                              {userdata[0].work_auth_info.relocation_preference}
                            </p>
                          </h3>
                          <h3>
                            Travel preference:{" "}
                            <p>
                              {userdata[0].work_auth_info.travel_preference}
                            </p>
                          </h3>
                          {userdata[0].work_auth_info.work_auth_name ==
                            "EAD" && (
                            <>
                              <h3>
                                Employment Authorization Document Type:{" "}
                                <p>{userdata[0].work_auth_info.eda_type}</p>
                              </h3>
                            </>
                          )}
                          {(userdata[0].work_auth_info.work_auth_name ==
                            "EAD" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "Others" ||
                            userdata[0].work_auth_info.work_auth_name ==
                              "Green Card") && (
                            <>
                              <h3>
                                Work Authorization Document Name:{" "}
                                <p>
                                  {userdata[0].work_auth_info.eda_type_name}
                                </p>
                              </h3>
                              <h3>
                                {userdata[0].work_auth_info.eda_type_name}{" "}
                                Document Proof:{" "}
                                <p
                                  className="cursor-pointer text-blue-500"
                                  onClick={() => {
                                    const fileExtension =
                                      userdata[0].work_auth_info.eda_url
                                        .split("?")[0]
                                        .split(".")
                                        .pop()
                                        .toLowerCase();

                                    if (fileExtension === "pdf") {
                                      window.open(
                                        userdata[0].work_auth_info.eda_url,
                                        "_blank"
                                      );
                                    } else if (
                                      ["doc", "docx"].includes(fileExtension)
                                    ) {
                                      const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                                        userdata[0].work_auth_info.eda_url
                                      )}`;
                                      window.open(viewerUrl, "_blank");
                                    } else {
                                      window.open(
                                        userdata[0].work_auth_info.eda_url,
                                        "_blank"
                                      );
                                    }
                                  }}
                                >
                                  View
                                </p>
                              </h3>
                            </>
                          )}
                          <h3>
                            Driving Licence{" "}
                            <p
                              className="cursor-pointer text-blue-500"
                              onClick={() => {
                                const fileExtension =
                                  userdata[0].work_auth_info.driving_licence
                                    .split("?")[0]
                                    .split(".")
                                    .pop()
                                    .toLowerCase();

                                if (fileExtension === "pdf") {
                                  window.open(
                                    userdata[0].work_auth_info.driving_licence,
                                    "_blank"
                                  );
                                } else if (
                                  ["doc", "docx"].includes(fileExtension)
                                ) {
                                  const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                                    userdata[0].work_auth_info.driving_licence
                                  )}`;
                                  window.open(viewerUrl, "_blank");
                                } else {
                                  window.open(
                                    userdata[0].work_auth_info.driving_licence,
                                    "_blank"
                                  );
                                }
                              }}
                            >
                              View
                            </p>
                          </h3>
                        </>
                      )}
                    </div>
                  ) : null}
                  {/* {userdata[0].travel_info !== null
                    ? userdata[0].travel_info.travelled_to.length !== 0
                      ? userdata[0].travel_info.travelled_to.map(
                          (data, index) => (
                            <div className="travelGridOne" key={index}>
                              <h1>Countries you’ve travelled to</h1>
                              {data.split(":")[0].length !== 0 ? (
                                <h2>{data.split(":")[0]}</h2>
                              ) : (
                                <h2>Not Provided</h2>
                              )}
                              {data.split(":")[1].length !== 0 ? (
                                <h3>
                                  Year of Travel : <p>{data.split(":")[1]}</p>
                                </h3>
                              ) : (
                                <h3>
                                  Year of Travel : <p>-</p>
                                </h3>
                              )}
                              {data.split(":")[2].length !== 0 ? (
                                <h3 className="marginBottom20">
                                  Duration : <p>{data.split(":")[2]}</p>
                                </h3>
                              ) : (
                                <h3 className="marginBottom20">
                                  Duration : <p>-</p>
                                </h3>
                              )}
                              {data.split(":")[3].length !== 0 ? (
                                <h3>
                                  Purpose : <p>{data.split(":")[3]}</p>
                                </h3>
                              ) : (
                                <h3>
                                  Purpose : <p>-</p>
                                </h3>
                              )}
                              {data.split(":")[4].length !== 0 ? (
                                <h3>
                                  Type of Visa : <p>{data.split(":")[4]}</p>
                                </h3>
                              ) : (
                                <h3>
                                  Type of Visa : <p>-</p>
                                </h3>
                              )}

                              {data.split(":")[5].length !== 0 ? (
                                <h3>
                                  Validity of Visa :{" "}
                                  <p>
                                    {moment(data.split(":")[5]).format(
                                      "DD-MMMM-YYYY"
                                    )}
                                  </p>
                                </h3>
                              ) : (
                                <h3>
                                  Validity of Visa : <p>-</p>
                                </h3>
                              )}
                            </div>
                          )
                        )
                      : null
                    : null}

                  {userdata[0].travel_info !== null ? (
                    <div className="travelGridOne">
                      <h1>Residency details</h1>
                      <h3 className="marginTop20">
                        Current Place of Residence: :{" "}
                        <p>{userdata[0].current_place_of_residence}</p>
                      </h3>
                      <h3>
                        Duration :{" "}
                        <p>{userdata[0].lived_at_current_residence}</p>
                      </h3>
                    </div>
                  ) : null} */}

                  {/* {userdata[0].travel_info !== null
                    ? userdata[0].travel_info.travel_to_for_work.length !== 0
                      ? userdata[0].travel_info.travel_to_for_work.map(
                          (data, index) => (
                            <div className="travelGridOne" key={index}>
                              <h1>Travel to for work</h1>
                              <h3>
                                Country : <p>{data.split(":")[0]}</p>
                              </h3>
                              <h3 className="marginBottom20">
                                Only For : <p>{data.split(":")[1]}</p>
                              </h3>
                              <h3>
                                Duration : <p>{data.split(":")[2]}</p>
                              </h3>
                              <h3>
                                Readiness : <p>{data.split(":")[3]}</p>
                              </h3>
                            </div>
                          )
                        )
                      : null
                    : null}
                  {userdata[0].travel_info !== null ? (
                    <div className="travelGridOne">
                      <h1>Willing to locate to</h1>

                      <h3>
                        Are you willing :{" "}
                        <p>{userdata[0].travel_info.willingness}</p>
                      </h3>
                      <h3 className="marginBottom20">
                        Preferred Countries :{" "}
                        <p>
                          {userdata[0].travel_info.prefered_countries.join(
                            ", "
                          )}
                        </p>
                      </h3>
                   
                    </div>
                  ) : null} */}
                </div>
              </div>
            ) : (
              <div className="educationDesc">
                <h1>
                  {" "}
                  Add your travel history here to stand out from other
                  candidates
                </h1>
                <button className="touchButtonnew" onClick={overLayHandler}>
                  <h4>Add Travel History</h4>
                </button>
              </div>
            ))}
          {isPopUp === "travel" && (
            <div
              className={
                isPage == "page1"
                  ? "travelHistoryDescOverlayPage1"
                  : "travelHistoryDescOverlay"
              }
            >
              <div className="travelHistoryHeadOverlay">
                <div className="travelHistoryHeadLeft">
                  <img src={user} alt="" />
                  <h1>Travel History</h1>
                </div>
                <div
                  onClick={exitOverlayHandler}
                  className="travelHistoryLeftIcon"
                >
                  <RxCross1 />
                </div>
              </div>

              {isPage === "page1" && (
                <div className="travelRadio">
                  <h1>
                    Have you ever travelled out of your home country for work or
                    otherwise?
                  </h1>
                  <div className="travelRadioOne">
                    <input
                      type="radio"
                      onChange={() => {
                        setstatus(true);
                      }}
                      checked={status === true}
                    />
                    <div
                      className="travelRadioOneDesc"
                      onClick={() => {
                        setstatus(true);
                      }}
                    >
                      <h3>Yes</h3>
                      <p>
                        Add your travel history here to stand out from other
                        candidates
                      </p>
                    </div>
                  </div>
                  <div className="travelRadioOne">
                    <input
                      type="radio"
                      onChange={() => {
                        setstatus(false);
                      }}
                      checked={status === false}
                    />
                    <div
                      className="travelRadioOneDesc"
                      onClick={() => {
                        setstatus(false);
                      }}
                    >
                      <h3>No</h3>
                      <p>
                        Travel History section will be left blank on your
                        profile
                      </p>
                    </div>
                  </div>
                  <div className="vedioResumeButtons">
                    <button
                      className="discard"
                      onClick={() => {
                        dispatch(storeAction.isPopUpHander());
                      }}
                    >
                      Close
                    </button>
                    <button id="page2" onClick={pagehandler} className="save">
                      Proceed
                    </button>
                  </div>
                </div>
              )}
              <div className="innerTravelHistoryOverlay">
                {isPage === "page2" && (
                  <>
                    <div className="">
                      <h6 className="travelHistoryDescOverlayh6">
                        Add your travel history here to stand out from other
                        candidates
                      </h6>
                      <div className="HistoryOfTravel">
                        <div className="residency">
                          <div>
                            <h6>Work Authorization</h6>
                            <div className="candidateInfo h-full">
                              <div className="flex gap-4">
                                <h3>
                                  Do you have Work Authorisation for US /
                                  Canada?
                                </h3>
                                <span className="flex gap-3">
                                  <div className="flex gap-1 items-center">
                                    <input
                                      type="radio"
                                      id="Yes"
                                      name="work_auth"
                                      value="Yes"
                                      checked={workauth.work_auth === "Yes"}
                                      onClick={handlechangeworkauth}
                                      defaultValue={workauth.work_auth}
                                    />
                                    <label for="Yes">Yes</label>
                                  </div>
                                  <div className="flex gap-1 items-center">
                                    <input
                                      type="radio"
                                      id="No"
                                      name="work_auth"
                                      value="No"
                                      defaultValue={workauth.work_auth}
                                      checked={workauth.work_auth === "No"}
                                      onClick={handlechangeworkauth}
                                    />
                                    <label for="No">No</label>
                                  </div>
                                </span>
                              </div>
                            </div>
                            {workauth.work_auth == "Yes" && (
                              <>
                                <div className="candidateInfo h-full">
                                  <h3>Select Work Authorization Category</h3>
                                  <select
                                    name="work_auth_name"
                                    defaultValue={workauth.work_auth_name}
                                    onChange={handlechangeworkauth}
                                  >
                                    <option value="">Select</option>
                                    <option value="US Citizen">
                                      US Citizen
                                    </option>
                                    <option value="Canadian Citizen">
                                      Canadian Citizen
                                    </option>
                                    <option value="H1Visa">H1Visa</option>
                                    <option value="Green Card">
                                      Green Card
                                    </option>
                                    <option value="TN Permit">TN Permit</option>
                                    <option value="EAD">
                                      EAD (Employment Authorization Document)
                                    </option>
                                    <option value="Others">Others</option>
                                  </select>
                                </div>

                                {workauth.work_auth_name == "EAD" && (
                                  <div className="candidateInfo h-full">
                                    <h3>
                                      Select EAD (Employment Authorization
                                      Document)
                                    </h3>
                                    <select
                                      name="eda_type"
                                      defaultValue={workauth.eda_type}
                                      onChange={handlechangeworkauth}
                                    >
                                      <option value="">Select</option>
                                      <option value="OPT">OPT</option>
                                      <option value="CPT">CPT</option>
                                      <option value="L2">L2</option>
                                      <option value="H4">H4</option>
                                      <option value="Others">Others</option>
                                    </select>
                                  </div>
                                )}
                                {(workauth.work_auth_name == "EAD" ||
                                  workauth.work_auth_name == "Others" ||
                                  workauth.work_auth_name == "Green Card") && (
                                  <>
                                    <div className="candidateInfo h-full">
                                      <h3>Work Authorization Document Proof</h3>
                                      <input
                                        name="eda_type_name"
                                        defaultValue={workauth.eda_type_name}
                                        onChange={handlechangeworkauth}
                                        type="text"
                                      />
                                    </div>
                                    <div className="candidateInfo h-full">
                                      <h3>
                                        {workauth.eda_type_name} Document Upload
                                        (Optional)
                                      </h3>
                                      <input
                                        name="eda_url"
                                        onChange={handlechangeurl}
                                        type="file"
                                        disabled={
                                          workauth.eda_type_name.length == 0
                                        }
                                      />
                                      {eda_urlstatus && (
                                        <h6 className="success_tag">
                                          {workauth.eda_type_name} Document
                                          Uploaded Successfully...
                                        </h6>
                                      )}
                                    </div>
                                  </>
                                )}
                                {(workauth.work_auth_name == "H1Visa" ||
                                  workauth.work_auth_name == "Green Card" ||
                                  workauth.work_auth_name == "TN Permit" ||
                                  workauth.work_auth_name == "EAD" ||
                                  workauth.work_auth_name == "Others") && (
                                  <div className="candidateInfo h-full">
                                    <h3>Valid Until</h3>
                                    <input
                                      name="valid_date"
                                      defaultValue={workauth.valid_date}
                                      onChange={handlechangeworkauth}
                                      type="date"
                                      min="1000-01-01"
                                      max="9999-12-31"
                                    />
                                  </div>
                                )}
                                <div className="candidateInfo h-full">
                                  <div className="flex gap-4">
                                    <h3>Security Clearance ?</h3>
                                    <span className="flex gap-3">
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="Yes"
                                          name="security_clearance"
                                          value="Yes"
                                          defaultValue={
                                            workauth.security_clearance
                                          }
                                          checked={
                                            workauth.security_clearance ===
                                            "Yes"
                                          }
                                          onClick={handlechangeworkauth}
                                        />
                                        <label for="Yes">Yes</label>
                                      </div>
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="No"
                                          name="security_clearance"
                                          value="No"
                                          defaultValue={
                                            workauth.security_clearance
                                          }
                                          checked={
                                            workauth.security_clearance === "No"
                                          }
                                          onClick={handlechangeworkauth}
                                        />
                                        <label for="No">No</label>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="newdivwork h-full">
                                  <div className="flex gap-4">
                                    <h3>Relocation Preference ?</h3>
                                    <span className="flex gap-3">
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="Yes"
                                          name="relocation_preference"
                                          value="Willing to relocate"
                                          defaultValue={
                                            workauth.relocation_preference
                                          }
                                          checked={
                                            workauth.relocation_preference ===
                                            "Willing to relocate"
                                          }
                                          onClick={handlechangeworkauth}
                                        />
                                        <label for="Yes">
                                          Willing to relocate
                                        </label>
                                      </div>
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="No"
                                          name="relocation_preference"
                                          value="Not willing to relocate"
                                          onClick={handlechangeworkauth}
                                          defaultValue={
                                            workauth.relocation_preference
                                          }
                                          checked={
                                            workauth.relocation_preference ===
                                            "Not willing to relocate"
                                          }
                                        />
                                        <label for="No">
                                          Not willing to relocate
                                        </label>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="newdivwork h-full">
                                  <div className="flex gap-4">
                                    <h3>Travel preference ?</h3>
                                    <span className="flex gap-3">
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="Yes"
                                          name="travel_preference"
                                          value="Open to travel"
                                          onClick={handlechangeworkauth}
                                          defaultValue={
                                            workauth.travel_preference
                                          }
                                          checked={
                                            workauth.travel_preference ===
                                            "Open to travel"
                                          }
                                        />
                                        <label for="Yes">Open to travel</label>
                                      </div>
                                      <div className="flex gap-1 items-center">
                                        <input
                                          type="radio"
                                          id="No"
                                          name="travel_preference"
                                          value="Not interested"
                                          defaultValue={
                                            workauth.travel_preference
                                          }
                                          checked={
                                            workauth.travel_preference ===
                                            "Not interested"
                                          }
                                          onClick={handlechangeworkauth}
                                        />
                                        <label for="No">Not interested</label>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="candidateInfo h-full">
                                  <h3>Driving Licence (Optional)</h3>
                                  <input
                                    name="driving_licence"
                                    onChange={handlechangedriving}
                                    type="file"
                                  />
                                  {driving_licencestatus && (
                                    <h6 className="success_tag">
                                      Driving Licence Uploaded Successfully...
                                    </h6>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {isPage != "page1" && (
                <div className="vedioResumeButtons">
                  <button className="discard" onClick={exitOverlayHandler}>
                    Discard Changes
                  </button>
                  {loading === false ? (
                    <button className="save" onClick={submitbtn}>
                      Save & Close
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TravelHistory;
