/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import Notification from "../Reusable/Notification/Notification";
import candidateNotificaionApprove from "../../assests/approveCandidate.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import ProgressBar from "./Progressbar";
import back from "../../assests/billingX.png";

const Dashboard = () => {
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const [target, setTarget] = useState("activity");

  const targetHandler = (e) => {
    setTarget(e.target.id);
  };

  const overLayHandler1 = async () => {
    dispatch(storeAction.isPopUpHander("approveconformation"));
  };

  const CloseOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const popUpHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  return (
    <div>
      <div className="adminHomePage paddingLeft100 paddingRight100 marginBottom20">
        <DashHead
          head="Home"
          desc=""
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="adminNotification">
          <div className="innerAdminNotification">
            <div className="adminNotificationHead">
              <h1>Notifications</h1>
              <h6>Mark all as read</h6>
            </div>
            <div className="adminNotificationTab">
              
              <h5
                id="activity"
                onClick={targetHandler}
                className={
                  target == "activity"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Activity
              </h5>
              <h5
                id="read"
                onClick={targetHandler}
                className={
                  target == "read"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Read
              </h5>
            </div>
          </div>
          {target == "activity" ? (
            <div className="adminNotificationLog">
              <Notification
                Img={candidateNotificaionApprove}
                message="Lorem Ipsum is simply dummy text."
                button="Approve Candidate"
                btnClass="notificationButton"
                fun={() => {
                  overLayHandler1();
                }}
                date="Sunday, Jan 22"
                type="unread"
              />
            </div>
          ) : null}
          {target == "read" ? (
            <div className="adminNotificationLog">
              <Notification
                Img={candidateNotificaionApprove}
                message="Lorem Ipsum is simply dummy text."
                button="Approve Candidate"
                btnClass="notificationButton"
                fun={() => {
                  overLayHandler1();
                }}
                date="Sunday, Jan 22"
                type="unread"
              />
            </div>
          ) : null}
        </div>
        <div className="homeProgress">
          <div className="homeProgressCandidatenew">
            <h1>Overview</h1>
            <div className="homeProgressCandidateFlexnew">
              <div className="homeProgressCandidateLeft">
                <ProgressBar />
              </div>
              <div className="homeProgressCandidateRightnew">
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication candidateHomeHired"></div>
                  <h2>Approved Profile - 20</h2>
                </div>
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication yetToBeHired"></div>
                  <h2>Pending Approval- 30</h2>
                </div>
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication yetToBeHired"></div>
                  <h2>Profile Hired/Billed- 30</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="homeProgressCandidatenew">
            <h1>Current Status</h1>
            <div className="homeProgressCandidateFlexnew">
              <div className="homeProgressCandidateLeft">
                <ProgressBar />
              </div>
              <div className="homeProgressCandidateRightnew">
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication candidateHomeHired"></div>
                  <h2>Approved Profile - 20</h2>
                </div>
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication yetToBeHired"></div>
                  <h2>Pending Approval- 30</h2>
                </div>
                <div className="homeProgressCandidateRightOne1">
                  <div className="indication yetToBeHired"></div>
                  <h2>Profile Hired/Billed- 30</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopUp == "interviewDetails" && (
        <div className="interViewDetailOverlay">
          <div className="interViewDetailOverlayHead">
            <h1>Interview details</h1>
            <img src={back} alt="" />
          </div>
        </div>
      )}
      {isPopUp == "approveconformation" && (
        <div className="approveCandidateOverlay">
          <div className="candidateRateCardOverlayHead">
            <h1>Approve Candidate</h1>
            <span onClick={CloseOverlay}>
              <RxCross2 />
            </span>
          </div>
          <div className="approveCandidateOverlayBody">
            <p>
              You’ve checked all the details and have confirmed that this
              candidate has completed their profile.
            </p>
            <div className="approveCandidateOverlayButton">
              <button
                className="discard"
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              >
                Cancel
              </button>
              <button onClick={popUpHandler} className="save">
                Yes, Approve
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
