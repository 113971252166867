/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoMdSave } from "react-icons/io";
import { FiLoader } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { MdOutgoingMail } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import country_and_states from "../../../assests/country-states";

const Profiletracker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((store) => store.token);

  const [teamlist, setteamlist] = useState([]);
  const [allteamlist, setallteamlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [searchfilter, setsearchfilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [select_id, setselect_id] = useState(null);
  const [loading1, setloading1] = useState(false);
  const [allassessmentdata, setallassessmentdata] = useState([]);
  const [PTsearch, setPTsearch] = useState({
    recruiter: "",
    location: "",
    percentage: "",
    first_name: "",
  });

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setPTsearch((values) => ({ ...values, [name]: value }));
  };

  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/ats-filter/profile-tracker/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
    if (response.results.length !== 0) {
      setloading(false);
      setcount(response.count);
      if (response.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      const allFaculties = response.results || [];
      const sortedCandidates = [...allFaculties].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setteamlist(sortedCandidates);
      setallteamlist(sortedCandidates);
      dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
    } else {
      dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
      setloading(false);
      setteamlist([]);
      setallteamlist([]);
    }
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
    setallassessmentdata(Alldata.results);
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      try {
        dispatch(storeAction.isPopUpHander("loading"));
        let response;
        if (searchfilter !== true) {
          response = await axios.get(newurl, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          });
        } else {
          response = await axios.post(
            newurl,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );
        }

        if (response.results.length !== 0) {
          setloading(false);
          setcount(response.count);
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          const allFaculties = response.results || [];
          const sortedCandidates = [...allFaculties].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setteamlist(sortedCandidates);
          setallteamlist(sortedCandidates);
          dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
        } else {
          dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
          setloading(false);
          setteamlist([]);
          setallteamlist([]);
        }
      } catch (error) {
        console.error("Error fetching candidates:", error);
      } finally {
        dispatch(storeAction.isPopUpHander());
        document.getElementById("Recruiterid").value = "";
      }
    }
  };

  useEffect(() => {
    GetFilterCandidate();
  }, [PTsearch]);

  const GetFilterCandidate = async () => {
    const hasValidValue = Object.values(PTsearch).some(
      (value) => value !== "" && value !== null && value !== undefined
    );
    if (!hasValidValue) {
      Getalldata();
    }
    const obj = {
      recruiter: PTsearch.recruiter,
      location: PTsearch.location,
      percentage: PTsearch.percentage,
      first_name: PTsearch.first_name,
    };
    setcount(0);
    setloading(true);
    setpagination_status(false);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/ats-filter/profile-tracker/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (response.results.length !== 0) {
        setloading(false);
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        const allFaculties = response.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setteamlist(sortedCandidates);
        setallteamlist(sortedCandidates);
        dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
      } else {
        dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
        setloading(false);
        setteamlist([]);
        setallteamlist([]);
      }
    } catch (error) {
      console.error("Error fetching candidates:", error);
    } finally {
      setloading(false);
    }
  };

  const getPaginationNumbers = () => {
    if (pageNumbers.length <= 1) return [];
    let pages = [];
    for (let i = 1; i <= pageNumbers.length; i++) {
      pages.push(i);
    }

    if (pages.length > 5) {
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(currentPage + 2, pageNumbers.length);
      const slicedPages = pages.slice(start - 1, end);

      return [
        ...(start > 1 ? [1, "..."] : []),
        ...slicedPages,
        ...(end < pageNumbers.length ? ["...", pageNumbers.length] : []),
      ];
    }
    return pages;
  };

  const handlePageChange = async (page) => {
    const hasValidValue = Object.values(PTsearch).some(
      (value) => value !== "" && value !== null && value !== undefined
    );
    if (hasValidValue == false) {
      setloading(true);
      if (page >= 1 && page <= pageNumbers.length) {
        setCurrentPage(page);
        dispatch(storeAction.currentPageHander({ currentPage: page }));
      }
      const response = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/ats-filter/profile-tracker/?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (response.results.length !== 0) {
        setloading(false);
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        const allFaculties = response.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setteamlist(sortedCandidates);
        setallteamlist(sortedCandidates);
        dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
      } else {
        dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
        setloading(false);
        setteamlist([]);
        setallteamlist([]);
      }
    } else {
      setloading(true);
      if (page >= 1 && page <= pageNumbers.length) {
        setCurrentPage(page);
        dispatch(storeAction.currentPageHander({ currentPage: page }));
      }
      const obj = {
        recruiter: PTsearch.recruiter,
        location: PTsearch.location,
        percentage: PTsearch.percentage,
        first_name: PTsearch.first_name,
      };
      setcount(0);
      setloading(true);
      setpagination_status(false);

      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/ats-filter/profile-tracker/?page=${page}`,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (response.results.length !== 0) {
          setloading(false);
          setcount(response.count);
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          const allFaculties = response.results || [];
          const sortedCandidates = [...allFaculties].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setteamlist(sortedCandidates);
          setallteamlist(sortedCandidates);
          dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
        } else {
          dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
          setloading(false);
          setteamlist([]);
          setallteamlist([]);
        }
      } catch (error) {
        console.error("Error fetching candidates:", error);
      } finally {
        setloading(false);
      }
    }
  };

  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      (profile.professional_details_info !== null &&
        profile.professional_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.project_details_info !== null &&
        profile.project_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.certificate_info !== null &&
        profile.certificate_info.length !== 0) ||
      profile.no_certificate == true
    )
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info !== null && profile.education_info.length !== 0)
      count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;
    return count;
  };

  const CheckUser = async (profile) => {
    let missingFields = [];
    setselect_id(profile.id);
    if (profile.address === null) missingFields.push("Address");
    if (profile.work_preference_info === null)
      missingFields.push("Work Preference");
    if (
      (profile.professional_details_info === null ||
        profile.professional_details_info.length === 0) &&
      !profile.fresher &&
      !profile.freshers_status
    )
      missingFields.push("Professional Details");

    if (
      (profile.project_details_info === null ||
        profile.project_details_info.length === 0) &&
      !profile.fresher &&
      !profile.freshers_status
    )
      missingFields.push("Project Details");

    if (
      (profile.certificate_info === null ||
        profile.certificate_info.length === 0) &&
      !profile.no_certificate
    )
      missingFields.push("Certifications");

    if (profile.travel_info === null) missingFields.push("Work Authorization");
    if (profile.education_info === null || profile.education_info.length === 0)
      missingFields.push("Education Details");
    if (profile.video_resume === null || profile.video_resume.length === 0)
      missingFields.push("Video Resume");

    let pendingItemsList = missingFields
      .map((item) => `<li style="margin-bottom: 8px;">${item}</li>`)
      .join("");

    let subject;
    if (missingFields.length === 0) {
      subject = `Great! Your Hirein5 Profile is Complete 🎉`;
    } else {
      subject = `Boost Your Chances of Getting Hired – Update Your Profile Now!`;
    }

    var emailobj = {
      email: profile.username,
      from_email: "connect@hirein5.com",
      subject: subject,
      message: `
      <div style="font-family: Arial, sans-serif; padding: 30px; max-width: 650px; background-color: #f9f9f9; border-radius: 10px; margin: auto; border: 1px solid #ddd;">
          <h2 style="color: #333; text-align: center; margin-bottom: 20px;">Complete Your Hirein5 Profile</h2>
          
          <p style="margin-bottom: 20px;">Dear ${
            profile.first_name || "User"
          },</p>
          
          <p style="line-height: 1.6; margin-bottom: 20px;">
            We noticed that some details in your Hirein5 profile are incomplete. 
            Completing your profile increases your chances of getting hired faster.
          </p>
          
          <div style="background: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0px 2px 5px rgba(0,0,0,0.1); margin-bottom: 20px;">
            <p style="font-weight: bold; margin-bottom: 10px;">Pending Sections:</p>
            <ul style="padding-left: 20px; margin-top: 10px; margin-bottom: 10px;">
                ${pendingItemsList}
            </ul>
          </div>
          
          <p style="margin-bottom: 20px;">
              <a href="https://app.hirein5.com/#/login" 
                 style="background-color: #007bff; color: #ffffff; padding: 12px 25px; text-decoration: none; 
                 border-radius: 5px; display: inline-block; font-weight: bold;">
                 Update Your Profile
              </a>
          </p>
          
          <p style="line-height: 1.6; margin-bottom: 20px;">
            If you need any assistance, feel free to contact us at 
            <a href="mailto:candidatesupport@hirein5.com" style="color: #007bff; text-decoration: none;">candidatesupport@hirein5.com</a>.
          </p>
          
          <p style="font-weight: bold; margin-top: 20px;">Best regards,</p>
          <p style="font-weight: bold;">Team Hirein5</p>
      </div>
  `,
    };

    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Mail send Successfully...", {
      autoClose: 5000,
      transition: Slide,
      style: customToastStyle,
    });
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
    setselect_id(null);
  };

  const SaveBtn = async (profile) => {
    setselect_id(profile.id);
    var singledata = await teamlist.filter((data) => {
      return data.id == profile.id;
    });

    var obj = {
      username: profile.username,
      ats_record_info: {
        recruiter: singledata[0]?.recruiter ?? "",
        current_company: singledata[0]?.current_company ?? "",
        current_designation: singledata[0]?.current_designation ?? "",
        international_clients: singledata[0]?.international_clients ?? "",
        screening_outcome_1: singledata[0]?.offline_screen1_outcome ?? "",
        call_date_1: singledata[0]?.call_date1 ?? "",
        last_contact_date: singledata[0]?.call_date2 ?? "",
        candidate_interest: singledata[0]?.candidate_interest ?? "",
        reasons_for_decline: singledata[0]?.reasons_for_decline ?? "",
        comments: singledata[0]?.comments ?? "",
        certificates: singledata[0]?.certifications ?? "",
      },
      preference_info: {
        qualification:
          singledata[0]?.qualification ??
          singledata[0]?.preference_info?.qualification ??
          "",
        year_of_experience:
          singledata[0]?.experience ??
          singledata[0]?.preference_info?.year_of_experience ??
          "",
      },
      work_preference_info: {
        key_skills:
          singledata[0]?.primary_skill?.split(",") ??
          singledata[0]?.work_preference_info?.key_skills ??
          [],
      },
      new_candidate_info: {
        location:
          singledata[0]?.location ??
          singledata[0]?.new_candidate_info?.location ??
          "",
      },
      first_name: singledata[0]?.first_name ?? "",
      phone: singledata[0]?.contact ?? singledata[0]?.phone ?? "",
      current_salary:
        singledata[0]?.current_ctc ?? singledata[0]?.current_salary ?? "",
      email: singledata[0]?.email ?? "",
      expected_salary:
        singledata[0]?.expected_ctc ?? singledata[0]?.expected_salary ?? "",
      notice_period: singledata[0].notice_period || "",
    };

    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${profile.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      settoastifystatus(true);
      toast.success("User Information Updated Successfully...", {
        autoClose: 5000,
        transition: Slide,
        style: customToastStyle,
      });
      setTimeout(() => {
        settoastifystatus(false);
      }, 2000);
      Getalldata();
      setselect_id(null);
    }
  };

  const MovetoApp = async () => {
    var status = false;
    teamlist.forEach(async (teamMember) => {
      const matchedAssessment = allassessmentdata.find(
        (assessment) => assessment.email === teamMember.email
      );
      if (teamMember.move == "true") {
        setloading1(true);
        if (matchedAssessment) {
          const obj = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
            candidate_registered: "Yes",
            sent_application_link: "Yes",
          };
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/candidate-appflow/${matchedAssessment.id}/`,
              obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        } else {
          const obj1 = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
            candidate_registered: "Yes",
            sent_application_link: "Yes",
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/candidate-appflow/`,
              obj1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
        await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${teamMember.id}/`,
            { username: teamMember.username, application_flow_move_to: true },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        status = true;
        setloading1(false);
        Getalldata();
      }
    });
    if (status == true) {
      settoastifystatus(true);
      toast.success("User Moved Successfully...", {
        autoClose: 5000,
        transition: Slide,
        style: customToastStyle,
      });
      setTimeout(() => {
        settoastifystatus(false);
      }, 2000);
    }
  };

  return (
    <div>
      <div className="flex gap-2 items-center mt-2">
        <input
          type="text"
          className="w-[20%] mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
          placeholder="Search Full Name"
          onChange={(e) =>
            setPTsearch((values) => ({ ...values, first_name: e.target.value }))
          }
          id="searchinput"
          defaultValue={PTsearch.first_name}
        />
        {Object.values(PTsearch).some(
          (value) => value !== "" && value !== null && value !== undefined
        ) && (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-black text-white font-medium"
            onClick={() => {
              setPTsearch({
                recruiter: "",
                location: "",
                percentage: "",
                first_name: "",
              });
              document.getElementById("searchinput").value = "";
            }}
          >
            Reset
          </button>
        )}
      </div>
      <table>
        <thead>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>View</th>
            <th className="flex gap-2 items-center mt-5">
              <span className="w-[70%]">Recruiter</span>
              <select
                onChange={handlechange}
                className="w-[30%] border rounded border-black p-1"
                id="recruiter"
                name="recruiter"
              >
                <option value="">All</option>
                <option value="Sanskriti">Sanskriti</option>
                <option value="Shikha">Shikha</option>
                <option value="Sanchan">Sanchan</option>
                <option value="Divya">Divya</option>
                <option value="Priya">Priya</option>
                <option value="Vamsi">Vamsi</option>
                <option value="Shalemraj">Shalemraj</option>
              </select>
            </th>
            <th>Full Name</th>
            <th>Role</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Primary skill</th>
            <th className="flex gap-2 items-center mt-5">
              <span className="w-[70%]">Profile %</span>
              <select
                onChange={handlechange}
                className="w-[30%] border rounded border-black p-1"
                id="percentage"
                name="percentage"
              >
                <option value="">All</option>
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
              </select>
            </th>
            <th>Experience</th>
            <th>Current Company</th>
            <th>Current Designation</th>
            <th className="flex gap-2 items-center mt-5">
              <span className="w-[70%]">Current Location</span>
              <select
                onChange={handlechange}
                className="w-[30%] border rounded border-black p-1"
                id="location"
                name="location"
              >
                <option value="">Country</option>
                {country_and_states.country.length !== 0
                  ? country_and_states.country.map((item, index) => (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    ))
                  : null}
              </select>
            </th>
            <th>International clients</th>
            <th>Current CTC</th>
            <th>Expected CTC</th>
            <th>Notice Period</th>
            <th>Certifications</th>
            <th>Offline Screen 1 Outcome</th>
            <th>Call Date 1</th>
            <th>Last Contact Date</th>
            <th>Candidate Interest in Hirein5</th>
            <th>Reasons for Decline</th>
            <th>Comments</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {loading ? (
            <tr>
              <td colSpan={12}>
                <div className="flex justify-center py-5">
                  <FiLoader className="loadingIconHAT" />
                </div>
              </td>
            </tr>
          ) : (
            teamlist.length !== 0 &&
            teamlist.map((role, roleIndex) => {
              const profileCompletion = calculateProfileCompletion(role);
              const percent = Math.round((profileCompletion / 8) * 100);
              return (
                <tr className="hireTablerow" key={roleIndex}>
                  <td>
                    <h3>
                      <input
                        type="checkbox"
                        checked={
                          role.application_flow_move_to == true ||
                          role.move == "true"
                        }
                        onChange={() => {
                          handleChangeNew(
                            roleIndex,
                            "move",
                            role.move == "true" ? "false" : "true"
                          );
                        }}
                        disabled={role.application_flow_move_to == true}
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <IoEye
                        className="plusIcon cursor-pointer"
                        onClick={() => {
                          dispatch(
                            storeAction.singleuserHander({
                              singleuser: [role],
                            })
                          );
                          dispatch(
                            storeAction.fromurlHander({ fromurl: "ats" })
                          );
                          navigate("/admincandidateview");
                        }}
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <select
                        className="hireTableBodySelect"
                        defaultValue={role.ats_record_info?.recruiter ?? ""}
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "recruiter",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Sanskriti">Sanskriti</option>
                        <option value="Shikha">Shikha</option>
                        <option value="Sanchan">Sanchan</option>
                        <option value="Divya">Divya</option>
                        <option value="Priya">Priya</option>
                        <option value="Vamsi">Vamsi</option>
                        <option value="Shalemraj">Shalemraj</option>
                      </select>
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.first_name}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "first_name",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>

                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.preference_info?.qualification ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "qualification",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>

                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.phone}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(roleIndex, "contact", e.target.value)
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.email}
                        type="email"
                        onChange={(e) =>
                          handleChangeNew(roleIndex, "email", e.target.value)
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={
                          role.work_preference_info?.key_skills.join(", ") ?? ""
                        }
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "primary_skill",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>{percent}%</h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={
                          role.preference_info?.year_of_experience ?? ""
                        }
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "experience",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.current_company ?? ""
                        }
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "current_company",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.current_designation ?? ""
                        }
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "current_designation",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.new_candidate_info?.location ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(roleIndex, "location", e.target.value)
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.international_clients ?? ""
                        }
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "international_clients",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.current_salary ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "current_ctc",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.expected_salary ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "expected_ctc",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.notice_period ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "notice_period",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.ats_record_info?.certificates ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "certifications",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <select
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.screening_outcome_1 ?? ""
                        }
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "offline_screen1_outcome",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        type="date"
                        min="1000-01-01"
                        max="9999-12-31"
                        defaultValue={role.ats_record_info?.call_date_1 ?? ""}
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "call_date1",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        type="date"
                        min="1000-01-01"
                        max="9999-12-31"
                        defaultValue={
                          role.ats_record_info?.last_contact_date ?? ""
                        }
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "call_date2",
                            e.target.value
                          )
                        }
                      />
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <select
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.candidate_interest ?? ""
                        }
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "candidate_interest",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Accept">Accept</option>
                        <option value="Decline">Decline</option>
                      </select>
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <select
                        className="hireTableBodySelect"
                        defaultValue={
                          role.ats_record_info?.reasons_for_decline ?? ""
                        }
                        onChange={(e) =>
                          handleChangeNew(
                            roleIndex,
                            "reasons_for_decline",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Currently not seeking for a change">
                          Currently not seeking for a change
                        </option>
                        <option value="Candidate not interested">
                          Candidate not interested
                        </option>
                        <option value="Recently switched jobs">
                          Recently switched jobs
                        </option>
                        <option value="Prefer to work in an MNC">
                          Prefer to work in an MNC
                        </option>
                        <option value="Seeking WFO but not willing to relocate">
                          Seeking WFO but not willing to relocate
                        </option>
                        <option value="Onboarding process on Hirein5 platform is too lengthy">
                          Onboarding process on Hirein5 platform is too lengthy
                        </option>
                        <option value="Candidates are not comfortable sharing their Aadhaar or PAN card details.">
                          Candidates are not comfortable sharing their Aadhaar
                          or PAN card details.
                        </option>
                        <option value="Lack of Required Skills">
                          Lack of Required Skills
                        </option>
                        <option value="Insufficient Experience">
                          Insufficient Experience
                        </option>
                        <option value="Poor Interview Performance">
                          Poor Interview Performance
                        </option>
                        <option value="Incomplete Application">
                          Incomplete Application
                        </option>
                        <option value="ackground Check Issues">
                          Background Check Issues
                        </option>
                        <option value="NA">NA</option>
                      </select>
                    </h3>
                  </td>
                  <td>
                    <h3>
                      <input
                        className="hireTableBodySelect"
                        defaultValue={role.ats_record_info?.comments ?? ""}
                        type="text"
                        onChange={(e) =>
                          handleChangeNew(roleIndex, "comments", e.target.value)
                        }
                      />
                    </h3>
                  </td>

                  <h3 className="px-5 mt-2">
                    {role.dissabled == false ? (
                      role.status === "Reserved" ? (
                        new Date(moment().format("YYYY-MM-DD")) <=
                        new Date(
                          moment(
                            new Date(
                              moment(role.block_expiry).format("YYYY-MM-DD")
                            )
                          ).format("YYYY-MM-DD")
                        ) ? (
                          <p className="status reserving">{role.status}</p>
                        ) : role.apprual === true ? (
                          <p className="processingstaus status">
                            Available For Hire
                          </p>
                        ) : (
                          <p className="processingstaus status">
                            {role.status}
                          </p>
                        )
                      ) : role.status === "Reject" ? (
                        <p className="status rejectstaus">{role.status}</p>
                      ) : role.status === "Hired" ? (
                        <p className="status hiringActive">{role.status}</p>
                      ) : (role.status === "Success" ||
                          role.status === "Mail send" ||
                          role.status === "Benched") &&
                        (role.nottify == true || role.nottify == false) &&
                        role.apprual == true ? (
                        <p className="processingstaus status">
                          Available For Hire
                        </p>
                      ) : (role.status === "Success" ||
                          role.status === "Mail send" ||
                          role.status === "Benched") &&
                        role.nottify === true &&
                        role.apprual === false &&
                        percent === 100 ? (
                        role.non_vetted == true ? (
                          <p className="status hiringActive">Pre Approved</p>
                        ) : (
                          <p className="status hiringActive">Submitted</p>
                        )
                      ) : role.nottify !== true ? (
                        <p className="status contracted">Onboarding</p>
                      ) : role.status == "Benched" ||
                        (role.status == "Success" &&
                          role.nottify == true &&
                          role.apprual == false) ? (
                        <p className="status hiringActive">Submitted</p>
                      ) : null
                    ) : (
                      <p className="status rejectstaus">Disabled</p>
                    )}
                  </h3>
                  <td>
                    <h3>
                      {select_id == role.id ? (
                        <button className="Save_btn mr-2">
                          <FiLoader className="loadingIcon" />
                        </button>
                      ) : (
                        <button
                          className="Save_btn mr-2"
                          onClick={() => {
                            SaveBtn(role);
                          }}
                        >
                          <IoMdSave />
                        </button>
                      )}
                      {percent !== 100 ? (
                        select_id == role.id ? (
                          <button className="Send_btn">
                            <FiLoader className="loadingIcon" />
                          </button>
                        ) : (
                          <button
                            className="Send_btn"
                            onClick={() => {
                              CheckUser(role);
                            }}
                          >
                            <MdOutgoingMail />
                          </button>
                        )
                      ) : (
                        <button className="Send_btn_disable">
                          <MdOutgoingMail />
                        </button>
                      )}
                    </h3>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="flex justify-start items-center py-5">
        <button
          className={`mx-1 px-4 py-2 rounded-lg ${
            currentPage === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-800"
          }`}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>

        {getPaginationNumbers().map((page, index) => (
          <button
            key={index}
            className={`mx-1 px-4 py-2 rounded-lg ${
              currentPage === page
                ? "bg-blue-700 text-white"
                : "bg-gray-300 text-black hover:bg-gray-400"
            }`}
            onClick={() => {
              if (typeof page === "number") handlePageChange(page);
            }}
            disabled={page === "..."}
          >
            {page}
          </button>
        ))}

        <button
          className={`mx-1 px-4 py-2 rounded-lg ${
            currentPage === pageNumbers.length
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-800"
          }`}
          disabled={currentPage === pageNumbers.length}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
      {loading1 ? (
        <button className="saveSubmit1">Please Wait...</button>
      ) : (
        <button className="saveSubmit1" onClick={MovetoApp}>
          Move to Candidate Application Flow
        </button>
      )}

      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default Profiletracker;
