import React from "react";
import "./Head.css";
// import { useEffect } from "react";
import logo from "../../../assests/Logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const Head = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeBtn = async () => {
    navigate(`/`);
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <div>
      <div onClick={removeBtn} className="head marginBottom15">
        <img src={logo} alt="" />
        {/* <h1>HireIn5</h1> */}
      </div>
    </div>
  );
};

export default Head;
