import React, { useEffect, useRef } from "react";
import axios from "axios";

const Test = () => {
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true; // Prevent further API calls
      getAlldata();
    }
  }, []);

  const getAlldata = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/recentlyvisited/1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT 1`,
          },
        }
      );
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return <div>Test</div>;
};

export default Test;
