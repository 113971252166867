/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import "./Login2.css";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { jwtDecode } from "jwt-decode";
import logo from "../../../assests/LogoSvg.svg";
import textLogo from "../../../assests/Logo.svg";
import { PiEyeSlashLight, PiEyeLight } from "react-icons/pi";
import google from "../../../assests/Google.svg";
import linkedin from "../../../assests/LinkedIn.svg";
import firebase from "firebase/app";
import "firebase/auth";
const provider = new firebase.auth.GoogleAuthProvider();

const Login2 = () => {
  const islogin = useSelector((store) => store.islogin);
  const loginrole = useSelector((store) => store.loginrole);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isButton, setIsButton] = useState(false);

  const passwordRef = useRef("null");

  const [isLoading, setIsLoading] = useState(false);
  const [logindata, setlogindata] = useState({
    username: "",
    password: "",
  });
  const [usernameerror, setusernameerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [finalerror, setfinalerror] = useState(false);
  const [finalerrormsg, setfinalerrormsg] = useState("");
  const [linkedinloading, setlinkedinloading] = useState(false);

  const handleLinkedInAuth = async () => {
    try {
      var linkedindata = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/linkedin/generate-auth-url/`
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      window.location.href = linkedindata.authorization_url;
    } catch (error) {
      console.log(error);
    }
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setlogindata((values) => ({ ...values, [name]: value }));
  };

  const [errorMessage, setErrorMessage] = useState(false);
  const [validations, setValidations] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  const ButtonHandler = (e) => {
    if (e.target.value.length !== 0) {
      setErrorMessage(true);
      setfinalerror(false);
      setfinalerrormsg("");
      setIsButton(true);
      setlogindata((values) => ({ ...values, password: e.target.value }));

      setlogindata((values) => ({ ...values, password: e.target.value }));
      setIsButton(true);
      setTimeout(() => {
        setErrorMessage(false);
      }, 2000);
    } else {
      setIsButton(false);
      setErrorMessage(false);
    }
  };

  const ButtonHandler1 = async () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setusernameerror(false);
    setpassworderror(false);
    setValidations({
      validLength: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasSpecialChar: false,
      hasNumber: false,
    });
    setErrorMessage(false);
    if (logindata.username.length === 0) {
      setusernameerror(true);
    } else if (logindata.username.match(validRegex)) {
      setusernameerror(false);
      if (logindata.password.length === 0) {
        setusernameerror(false);
        setpassworderror(true);
      } else {
        setIsLoading(true);
        var newobj = {
          username: logindata.username.toLowerCase(),
          email: logindata.username.toLowerCase(),
          password: logindata.password,
        };
        var loginuser = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
            newobj
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });

        if (loginuser.access !== undefined) {
          const token = loginuser.access;
          const decoded = jwtDecode(token);
          if (decoded.user_id !== null) {
            dispatch(storeAction.tokenHandler({ token: loginuser.access }));
            dispatch(storeAction.useridHandler({ userid: decoded.user_id }));
            dispatch(storeAction.loginroleHander({ loginrole: decoded.role }));
            dispatch(
              storeAction.onboarding_statusHander({
                onboarding_status: decoded.onboarding_status,
              })
            );
            var userinfo = await axios
              .get(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                {
                  maxRedirects: 0,
                  validateStatus: (status) => {
                    // Allow redirect status codes (301, 302) to pass without throwing an error
                    return status < 400;
                  },
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${loginuser.access}`,
                  },
                }
              )

              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });

            dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
            if (decoded.role == "2" || decoded.role == "3") {
              if (decoded.onboarding_status == 4) {
                if (decoded.role == "2") {
                  dispatch(storeAction.isloginHandler({ islogin: true }));
                  dispatch(storeAction.issidebarHandler({ issidebar: true }));
                  navigate("/discover");
                } else {
                  dispatch(storeAction.isloginHandler({ islogin: true }));
                  dispatch(storeAction.issidebarHandler({ issidebar: true }));
                  navigate("/profile");
                }
              } else {
                if (userinfo.email_verification == false) {
                  dispatch(storeAction.issidebarHandler({ issidebar: false }));
                  window.location.replace("/#/emailverification");
                } else if (userinfo.mobile_verification == false) {
                  dispatch(storeAction.issidebarHandler({ issidebar: false }));
                  window.location.replace(`/#/verification/${userinfo.id}`);
                } else if (userinfo.onboarding_status != 4) {
                  dispatch(storeAction.issidebarHandler({ issidebar: false }));
                  window.location.replace(`/#/registration`);
                }
                dispatch(storeAction.isloginHandler({ islogin: true }));
              }
            } else {
              if (decoded.role == "1") {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                dispatch(
                  storeAction.onboarding_statusHander({ onboarding_status: 4 })
                );
                navigate("/adminHome");
              } else if (decoded.role == "7") {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                dispatch(
                  storeAction.onboarding_statusHander({ onboarding_status: 4 })
                );
                navigate("/ats");
              } else {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                dispatch(
                  storeAction.onboarding_statusHander({ onboarding_status: 4 })
                );
                navigate("/vendordashboard");
              }
            }
          }
        } else {
          setIsLoading(false);
          setfinalerror(true);
          setfinalerrormsg(loginuser.data.detail);
        }
      }
    } else {
      setIsLoading(false);
      setusernameerror(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      Checkuser();
    }, 2000);
  }, [islogin]);

  const Checkuser = async () => {
    if (islogin === true) {
      if (loginrole == "2") {
        window.location.replace("/#/discover");
      } else if (loginrole == "3") {
        window.location.replace("/#/profile");
      } else if (loginrole == "6") {
        window.location.replace("/#/vendordashboard");
      } else if (loginrole == "7") {
        window.location.replace("/#/ats");
      } else {
        window.location.replace("/#/adminHome");
      }
    }
    const getTokenFromUrl = () => {
      const hashParams = new URLSearchParams(
        window.location.hash.split("?")[1]
      );
      return hashParams.get("token");
    };
    const token = getTokenFromUrl();
    if (token !== null) {
      setlinkedinloading(true);
      const decoded = jwtDecode(token);
      const loginuser = await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/emil/login/`, {
          username: decoded.email.toLowerCase(),
          email: decoded.email.toLowerCase(),
        })
        .then((res) => res.data)
        .catch((err) => err.response);
      if (loginuser.access !== undefined) {
        const token = loginuser.access;
        const decoded = jwtDecode(token);
        if (decoded.user_id !== null) {
          dispatch(storeAction.tokenHandler({ token: loginuser.access }));
          dispatch(storeAction.useridHandler({ userid: decoded.user_id }));
          dispatch(storeAction.loginroleHander({ loginrole: decoded.role }));
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: decoded.onboarding_status,
            })
          );
          var userinfo = await axios
            .get(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
              {
                maxRedirects: 0,
                validateStatus: (status) => {
                  return status < 400;
                },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${loginuser.access}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
          if (decoded.role == "2" || decoded.role == "3") {
            if (decoded.onboarding_status == 4) {
              if (decoded.role == "2") {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                navigate("/discover");
              } else {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                navigate("/profile");
              }
            } else {
              if (userinfo.email_verification == false) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace("/#/emailverification");
              } else if (userinfo.mobile_verification == false) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace(`/#/verification/${userinfo.id}`);
              } else if (userinfo.onboarding_status != 4) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace(`/#/registration`);
              }
              dispatch(storeAction.isloginHandler({ islogin: true }));
            }
          } else {
            if (decoded.role == "1") {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/adminHome");
            } else if (decoded.role == "7") {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/ats");
            } else {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/vendordashboard");
            }
          }
        }
      } else {
        setIsLoading(false);
        setfinalerror(true);
        setfinalerrormsg(loginuser.data.detail);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      ButtonHandler1();
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const userData = result.user;
      const email = userData.email;
      const loginPayload = {
        username: email.toLowerCase(),
        email: email.toLowerCase(),
        google_auth: true,
      };
      const loginuser = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emil/login/`,
          loginPayload
        )
        .then((res) => res.data)
        .catch((err) => err.response);
      if (loginuser.access !== undefined) {
        const token = loginuser.access;
        const decoded = jwtDecode(token);
        if (decoded.user_id !== null) {
          dispatch(storeAction.tokenHandler({ token: loginuser.access }));
          dispatch(storeAction.useridHandler({ userid: decoded.user_id }));
          dispatch(storeAction.loginroleHander({ loginrole: decoded.role }));
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: decoded.onboarding_status,
            })
          );
          var userinfo = await axios
            .get(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
              {
                maxRedirects: 0,
                validateStatus: (status) => {
                  return status < 400;
                },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${loginuser.access}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
          if (decoded.role == "2" || decoded.role == "3") {
            if (decoded.onboarding_status == 4) {
              if (decoded.role == "2") {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                navigate("/discover");
              } else {
                dispatch(storeAction.isloginHandler({ islogin: true }));
                dispatch(storeAction.issidebarHandler({ issidebar: true }));
                navigate("/profile");
              }
            } else {
              if (userinfo.email_verification == false) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace("/#/emailverification");
              } else if (userinfo.mobile_verification == false) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace(`/#/verification/${userinfo.id}`);
              } else if (userinfo.onboarding_status != 4) {
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                window.location.replace(`/#/registration`);
              }
              dispatch(storeAction.isloginHandler({ islogin: true }));
            }
          } else {
            if (decoded.role == "1") {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/adminHome");
            } else if (decoded.role == "7") {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/ats");
            } else {
              dispatch(storeAction.isloginHandler({ islogin: true }));
              dispatch(storeAction.issidebarHandler({ issidebar: true }));
              dispatch(
                storeAction.onboarding_statusHander({ onboarding_status: 4 })
              );
              navigate("/vendordashboard");
            }
          }
        }
      } else {
        setIsLoading(false);
        setfinalerror(true);
        setfinalerrormsg(loginuser.data.detail);
      }
    } catch (error) {
      alert("Google Sign-In failed.");
    }
  };

  return (
    <>
      <div className="newLogin displayHandler">
        <div className="subLogin">
          <div className="headerSide">
            <img src={logo} alt="" />
            <h3>Welcome to HireIn5</h3>
            <h6>Your Next Opportunity Starts Here</h6>
            <p>
              Join thousands of tech professionals using Hirein5 to accelerate
              their careers.
            </p>
          </div>
          <div className="loginSide backGround">
            <div className="loginBody">
              <img src={textLogo} alt="" />
              <p className="fLogin">Log in</p>
              <p className="fAcc">
                Don’t have an account?{" "}
                <Link to="/signup">
                  <span>Sign Up</span>
                </Link>
              </p>
            </div>
            <div className="formContainer">
              <p className="label">Email</p>
              <input
                className="input border"
                type="email"
                required
                placeholder="you@email.com"
                name="username"
                onChange={handlechange}
              />
              {usernameerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  Please Enter Valid Email
                </p>
              )}
              <p className="label justifyContentBetween">
                Password{" "}
                <Link to="/forgotPassword">
                  <span>Forgot password</span>
                </Link>
              </p>

              <div className="passwordDiv">
                <input
                  className="input"
                  type={showPassword ? "text" : "password"}
                  required
                  placeholder="Password"
                  ref={passwordRef}
                  onChange={ButtonHandler}
                  name="password"
                  onKeyDown={handleKeyPress}
                />

                {showPassword ? (
                  <PiEyeLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <PiEyeSlashLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(true)}
                  />
                )}
                {passworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Password
                  </p>
                )}
              </div>
              <div className="clientLoginCompBodyButton marginTop20 marginBottom20">
                {isButton && (
                  <button
                    onClick={ButtonHandler1}
                    className={
                      isLoading === true
                        ? "clientLoginCompBodyButtonLoading"
                        : "clientLoginCompBodyButtonEnable"
                    }
                  >
                    {isLoading === true ? (
                      <FiLoader className="loadingIcon" />
                    ) : (
                      "Log in"
                    )}
                  </button>
                )}
                {!isButton && (
                  <button disabled className="clientLoginCompBodyButtonDisable">
                    Log in
                  </button>
                )}
              </div>
              <div className="forOr">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div onClick={handleGoogleSignIn} className="google border">
                <img src={google} alt="" />
                <p>Continue with Google</p>
              </div>
              {linkedinloading == true ? (
                <div className="google border padding">
                  <FiLoader className="loadingIcon" />
                </div>
              ) : (
                <div
                  onClick={handleLinkedInAuth}
                  className="google border padding"
                >
                  <img src={linkedin} alt="" />
                  <p>Continue with LinkedIn</p>
                </div>
              )}

              <div className="termPrivacy">
                <p>
                  By creating an account, you accept to our <br />
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                        "_blank"
                      );
                    }}
                  >
                    Terms & Conditions
                  </span>{" "}
                  and <span>Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************** mobile *************** */}
      <div className="newLogin displayHandlerMob">
        <div className="subLogin">
          <div className="headerSide">
            <img src={logo} alt="" />
            <h3>
              Welcome <span>to HireIn5</span>
            </h3>
            <h6>Your Next Opportunity Starts Here</h6>
            <p>
              Join thousands of tech professionals using Hirein5 to accelerate
              their careers.
            </p>
          </div>
          <div className="loginSide backGround">
            <div className="loginBody">
              <img src={textLogo} alt="" />
              <p className="fLogin">Log in</p>
              <p className="fAcc">
                Don’t have an account? <span>Sign up</span>
              </p>
            </div>
            <div className="formContainer">
              <p className="label">Email</p>
              <input
                className="input border"
                type="email"
                required
                placeholder="you@email.com"
                name="username"
                onChange={handlechange}
              />
              {usernameerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  Please Enter Valid Email
                </p>
              )}
              <p className="label justifyContentBetween">
                Password{" "}
                <Link to="/forgotPassword">
                  <span>Forgot password</span>
                </Link>
              </p>
              <div className="passwordDiv">
                <input
                  className="input"
                  type={showPassword ? "text" : "password"}
                  required
                  placeholder="Password"
                  ref={passwordRef}
                  onChange={ButtonHandler}
                  name="password"
                  onKeyDown={handleKeyPress}
                />
                {showPassword ? (
                  <PiEyeLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <PiEyeSlashLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(true)}
                  />
                )}
                {passworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Password
                  </p>
                )}
              </div>
              <div className="clientLoginCompBodyButton marginTop20 marginBottom20">
                {isButton && (
                  <button
                    onClick={ButtonHandler1}
                    className={
                      isLoading === true
                        ? "clientLoginCompBodyButtonLoading"
                        : "clientLoginCompBodyButtonEnable"
                    }
                  >
                    {isLoading === true ? (
                      <FiLoader className="loadingIcon" />
                    ) : (
                      "Log in"
                    )}
                  </button>
                )}
                {!isButton && (
                  <button disabled className="clientLoginCompBodyButtonDisable">
                    Log in
                  </button>
                )}
              </div>
              <div className="forOr">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div onClick={handleGoogleSignIn} className="google border">
                <img src={google} alt="" />
                <p>Continue with Google</p>
              </div>
              <div
                onClick={handleLinkedInAuth}
                className="google border padding"
              >
                <img src={linkedin} alt="" />
                <p>Continue with LinkedIn</p>
              </div>
              <div className="termPrivacy">
                <p>
                  By creating an account, you accept to our <br />
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                        "_blank"
                      );
                    }}
                  >
                    Terms & Conditions
                  </span>{" "}
                  and <span>Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login2;
