import React from "react";
import Addclient from "../Components/AdminScreen/AdminProfile/AddClient/AddClient";

const AddClient = () => {
  return (
    <>
      <Addclient />
    </>
  );
};

export default AddClient;
