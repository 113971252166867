/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import "./Layout.css";
import Routing from "../Routing/Routing";
import SideBar from "../Components/Reusable/SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { LuBookMinus, LuScrollText } from "react-icons/lu";
import { FiUsers } from "react-icons/fi";
import { FiFileText } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import { RiGlobalLine } from "react-icons/ri";
import { FiCompass } from "react-icons/fi";
import { TbUsersGroup } from "react-icons/tb";
import { FiHelpCircle } from "react-icons/fi";
import { FiHome } from "react-icons/fi";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { FaMailBulk } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import UnderConstruction from "../pages/UnderConstruction";
import { storeAction } from "../Store/Store";
import axios from "axios";
import Popup from "../Components/Reusable/PopUp/Popup";
import { useLocation } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { GrSchedules } from "react-icons/gr";
import { FaChalkboardUser } from "react-icons/fa6";
import { matchPath } from "react-router-dom";
import { PiExam } from "react-icons/pi";
import { MdMenuBook } from "react-icons/md";
import { RiUserAddFill } from "react-icons/ri";

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Redux state selectors
  const { issidebar, isdynamicsidebar, loginrole, userid, token, isPopUp } =
    useSelector((store) => store);

  // Fetch user information
  const getUserinfo = useCallback(async () => {
    handleSessionExpiration();
  }, [dispatch, token]);

  // Handle session expiration
  const handleSessionExpiration = async (status) => {
    const allowedPaths = [
      "/login",
      "/",
      "/candidateregister",
      "/clientregister",
      "/forgotPassword",
      "/verification/:id",
      "/resetpassword",
      "/vendorregister",
      "/search",
      "/test",
      "/share/:token",
    ];

    const isPathAllowed = (pathname) =>
      allowedPaths.some((path) => matchPath({ path, exact: true }, pathname));

    if (
      !isPathAllowed(location.pathname) &&
      !location.pathname.includes("@") &&
      (status === 401 || status === undefined)
    ) {
      try {
        const response = await axios
          .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/validate/tokens/`, {
            access_token: token,
            refresh_token: "",
          })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (response.access_token == false) {
          dispatch(storeAction.isPopUpHander("contactus"));
        }
      } catch (err) {
        console.log(err.response?.status);
      }
    }
  };

  // Trigger API call on token or role change
  useEffect(() => {
    if (token && userid && loginrole) {
      getUserinfo();
    }
  }, [getUserinfo, token, userid, loginrole]);

  const logout = () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const getMenuForRole = (role) => {
    const menus = {
      1: [
        {
          title: "Home",
          icon: <FiHome />,
          router: "adminHome",
        },
        {
          title: "Profile Tracker",
          icon: <FaRegCircleUser />,
          router: "customerProfile",
        },
        // {
        //   title: "Registered Candidate",
        //   icon: <FaRegUser />,
        //   router: "onboardcandidate",
        // },
        {
          title: "Onboarding Client",
          icon: <FaChalkboardUser />,
          router: "onboardclient",
        },
        {
          title: "Vendors",
          icon: <FaUserCog />,
          router: "vendorlist",
        },
        {
          title: "ATS",
          icon: <IoIosStats />,
          router: "ats",
        },
        {
          title: "Contact Us",
          icon: <MdContactSupport />,
          router: "contact",
        },
        {
          title: "Bulk Email",
          icon: <FaMailBulk />,
          router: "bulkemail",
        },
        {
          title: "Team members",
          icon: <FaUsers />,
          router: "adminteam",
        },
        {
          title: "Add Client",
          icon: <RiUserAddFill />,
          router: "addclient",
        },
        {
          title: "Settings",
          icon: <FiSettings />,
          router: "settings",
        },
      ],
      2: [
        {
          title: "Discover Candidate",
          icon: <FiCompass />,
          router: "discover",
        },
        {
          title: "Hire a Team",
          icon: <TbUsersGroup />,
          router: "hireTeam",
        },

        // {
        //   title: "Hire a Team", // Used for routing and matching
        //   // Styled version for display
        //   displayTitle: (
        //     <div style={{ display: "flex", alignItems: "center" }}>
        //       Hire a Team{" "}
        //       <span
        //         style={{
        //           fontSize: "0.8rem",
        //           backgroundColor: "#FFD700",
        //           color: "#000",
        //           borderRadius: "5px",
        //           padding: "0 5px",
        //           marginLeft: "8px",
        //         }}
        //       >
        //         Beta
        //       </span>
        //     </div>
        //   ),
        //   icon: <TbUsersGroup />,
        //   router: "hireTeam",
        // },

        {
          title: "Shortlist & Interviews",
          icon: <FiUsers />,
          router: "interview",
        },
        {
          title: "Contracts",
          icon: <FiFileText />,
          router: "contract",
        },
        {
          title: "Wallet",
          icon: <IoWalletOutline />,
          router: "wallet",
        },
        {
          title: "Billing",
          icon: <LuBookMinus />,
          router: "billing",
        },
        {
          title: "Team members",
          icon: <FaUsers />,
          router: "teamlist",
        },
        {
          title: "Help & FAQs",
          icon: <FiHelpCircle />,
          router: "clientfaq",
        },
      ],
      3: [
        {
          title: "Profile",
          icon: <FaRegCircleUser />,
          router: "profile",
        },
        {
          title: "Schedule Interview",
          icon: <GrSchedules />,
          router: "candidateinterview",
        },
        // {
        //   title: "Assessment",
        //   icon: <PiExam />,
        //   router: "techassessment",
        // },
        // {
        //   title: "Course",
        //   icon: <MdMenuBook />,
        //   router: "courses",
        // },
        {
          title: "Help & FAQs",
          icon: <FiHelpCircle />,
          router: "candidatefaq",
        },
      ],
      7: [
        {
          title: "Profile Tracker",
          icon: <FaRegCircleUser />,
          router: "customerProfile",
        },
        {
          title: "Registered Candidate",
          icon: <FaRegUser />,
          router: "onboardcandidate",
        },

        {
          title: "ATS",
          icon: <IoIosStats />,
          router: "ats",
        },
      ],
      5: [
        {
          title: "Home",
          icon: <FiHome />,
          router: "adminHome",
        },
        {
          title: "Profile",
          icon: <FaRegCircleUser />,
          router: "customerProfile",
        },
        {
          title: "Registered Candidate",
          icon: <FaRegUser />,
          router: "onboardcandidate",
        },
        {
          title: "Onboarding Client",
          icon: <FaChalkboardUser />,
          router: "onboardclient",
        },
        {
          title: "Contact Us",
          icon: <MdContactSupport />,
          router: "contact",
        },
        {
          title: "Bulk Email",
          icon: <FaMailBulk />,
          router: "bulkemail",
        },
        {
          title: "Settings",
          icon: <FiSettings />,
          router: "settings",
        },
      ],
      6: [
        {
          title: "Home",
          icon: <FiHome />,
          router: "vendordashboard",
        },
        {
          title: "Candidates",
          icon: <FaRegUser />,
          router: "vendorcandidatelist",
        },
        {
          title: "Teams Members",
          icon: <RiGlobalLine />,
          router: "construction",
        },
        {
          title: "Billing",
          icon: <LuScrollText />,
          router: "vendorwallet",
        },
        {
          title: "Settings",
          icon: <FiSettings />,
          router: "construction2",
        },
        {
          title: "Vendor Support",
          icon: <FiHelpCircle />,
          router: "construction3",
        },
        {
          title: "FAQ’s",
          icon: <IoIosInformationCircleOutline />,
          router: "construction4",
        },
      ],
    };
    return menus[role] || [];
  };

  return (
    <div>
      {process.env.REACT_APP_UNDER_CONSTRUCTION === "false" ? (
        <>
          {/* Sidebar and Main Content */}
          {issidebar && (
            <div className="dashboard">
              {!isdynamicsidebar && (
                <div className="sideNavComp">
                  <SideBar role={loginrole} menu={getMenuForRole(loginrole)} />
                </div>
              )}
              <div
                className={
                  !isdynamicsidebar
                    ? "main displayHandler"
                    : "main width100 displayHandler"
                }
              >
                <Routing />
              </div>
            </div>
          )}

          {/* Routing for non-sidebar layout */}
          {!issidebar && <Routing />}

          {/* Mobile Pop-up Sidebar */}
          {isPopUp === "navbar" && (
            <div className="sidebarOverlay fadeIn">
              <SideBar role={loginrole} menu={getMenuForRole(loginrole)} />
            </div>
          )}
        </>
      ) : (
        <UnderConstruction />
      )}

      {/* Session Expired Popup */}
      {isPopUp === "contactus" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Session Expired"
            desc="Your session has expired. Please log in to continue."
            InputContainer="displayNone"
            PopupSingleButton="PopupSingleButton"
            PopupSingleText="Login"
            SingleBtnfun={logout}
          />
        </div>
      )}
    </div>
  );
};

export default Layout;
