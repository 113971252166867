/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "./SideBar.css";
import logout from "../../../assests/logout.png";
import logo from "../../../assests/Logo.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { RxCross1 } from "react-icons/rx";
import ProgressBar from "../../PrelineComponent/ProgressBar/Circlebar";
import { useLocation } from "react-router-dom";
import moment from "moment";

const SideBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const userdata = useSelector((store) => store.userdata);
  const loginrole = useSelector((store) => store.loginrole);
  const islogin = useSelector((store) => store.islogin);

  useEffect(() => {
    setTimeout(() => {
      getUserinfo();
    }, 1000);
  }, []);
  const getUserinfo = useCallback(async () => {
    if (loginrole == 2) {
      setIsHover("discover");
    } else if (loginrole == 3) {
      setIsHover("profile");
    } else {
      setIsHover("adminHome");
    }
    if (token !== null && userid !== null) {
      var userinfo = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(storeAction.isPopUpHander("contactus"));
          } else {
            dispatch(storeAction.isPopUpHander("contactus"));
          }
          return err.response;
        });
      if (userinfo !== undefined) {
        if (userinfo.id !== undefined) {
          dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
        } else {
          if (userinfo.data.code == "token_not_valid") {
            dispatch(storeAction.isPopUpHander("contactus"));
          }
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander("contactus"));
    }
    if (loginrole != 2 && loginrole != 3) {
      var getCompanies = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (getCompanies.companies !== undefined) {
        if (getCompanies.companies.length !== 0) {
          for (var i = 0; i < getCompanies.companies.length; i++) {
            if (getCompanies.companies[i].pricing_info.length !== 0) {
              const latestDateObject = getCompanies.companies[
                i
              ].pricing_info.reduce((latest, current) => {
                return new Date(current.plan_start) >
                  new Date(latest.plan_start)
                  ? current
                  : latest;
              });
              var today = moment();
              var planValidity = moment(latestDateObject.plan_validity);
              var diffInDays = planValidity.diff(today, "days");
              var newobj = {
                email: getCompanies.companies[i].username,
                subject: `Renewal Payment Due for ${latestDateObject.pricing_plan}!`,
                message: `We wanted to remind you that your subscription for ${latestDateObject.pricing_plan} is due for renewal soon. As valued members of our community, we appreciate your continued support.`,
              };
            }
          }
        }
      }
    }
    CheckToken();
  }, [token, userid, loginrole]);

  const CheckToken = async () => {
    if (!token) {
      dispatch(storeAction.isPopUpHander("contactus"));
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/validate/tokens/`,
        {
          access_token: token,
          refresh_token: "", // Consider adding refresh logic here if available
        }
      );

      if (response.data && response.data.access_token == false) {
        dispatch(storeAction.isPopUpHander("contactus"));
      }
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(storeAction.isPopUpHander("contactus"));
      }
    }
    if (token) {
      setTimeout(CheckToken, 10000);
    }
  };

  const [isHover, setIsHover] = useState(
    "discover" || "profile" || "adminHome"
  );
  const HoverHandler = (e) => {
    navigate(e.target.id);
    setIsHover(e.target.id);
  };

  const overLayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const [valueper, setvalueper] = useState(0);

  return (
    <div>
      <div className="sideNav">
        <div className="sideNavInner">
          <div className="sideNavTop">
            <div
              onClick={() =>
                navigate(
                  loginrole == "2"
                    ? "/discover"
                    : loginrole == "3"
                    ? "/profile"
                    : loginrole == "6"
                    ? "/vendordashboard"
                    : loginrole == "7"
                    ? "/ats"
                    : "/adminHome"
                )
              }
              className="sideNavHead marginBottom15"
            >
              <img className="marginTop15" src={logo} alt="" />
            </div>
            <div className="dashProfile">
              <div className="flex justify-between items-start py-2">
                <div className="profileName">
                  {userdata.length !== 0 ? (
                    <h2>
                      {userdata[0].first_name.length !== 0
                        ? userdata[0].first_name
                        : "Admin"}
                    </h2>
                  ) : null}
                  {loginrole == 2 ? (
                    userdata.length !== 0 ? (
                      userdata[0].company !== null ? (
                        <p>{userdata[0].company.company_name}</p>
                      ) : null
                    ) : null
                  ) : loginrole == 3 ? (
                    <p>Candidate</p>
                  ) : (
                    <p>HireIn5</p>
                  )}
                </div>
                <div className="profilePic">
                  {userdata.length !== 0 ? (
                    userdata[0].profile_picture !== null ? (
                      userdata[0].profile_picture.length !== 0 ? (
                        <img src={userdata[0].profile_picture} alt="" />
                      ) : (
                        <Avatar
                          name={userdata[0].first_name}
                          size={50}
                          round="50px"
                        />
                      )
                    ) : (
                      <Avatar
                        name={userdata[0].first_name}
                        size={50}
                        round="50px"
                      />
                    )
                  ) : null}
                </div>
              </div>
              {loginrole == 3 ? (
                userdata.length !== 0 ? (
                  userdata[0].vendor.length !== 0 ? (
                    <p className="border-t-2 border-[#dbdbdb] pt-2 text-[#71717a]">
                      Source : -
                    </p>
                  ) : (
                    <p className="border-t-2 border-[#dbdbdb] pt-2 text-[#71717a]">
                      Source : HIREIN5
                    </p>
                  )
                ) : null
              ) : null}
            </div>
            <div className="navMenu">
              {props.menu.map((data) => {
                if (data.router == location.pathname.slice(1)) {
                  return (
                    <div
                      onClick={HoverHandler}
                      id={data.router}
                      className="menu1Active"
                    >
                      <span id={data.router} className="menuIcon" alt="">
                        {data.icon}
                      </span>
                      <h4 id={data.router} className="menuName">
                        {data.title}
                      </h4>
                      {data.router == "hireTeam" && (
                        <span
                          id={data.router}
                          onClick={HoverHandler}
                          style={{
                            fontSize: "0.8rem",
                            backgroundColor: "#FFD700",
                            color: "#000",
                            borderRadius: "5px",
                            padding: "0 5px",
                            marginLeft: "8px",
                            fontWeight: "400",
                          }}
                        >
                          Beta
                        </span>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      id={data.router}
                      onClick={HoverHandler}
                      className="menu1"
                    >
                      <span id={data.router} className="menuIcon" alt="">
                        {data.icon}
                      </span>
                      <h4 id={data.router} className="menuName">
                        {data.title}
                      </h4>
                      {data.router == "hireTeam" && (
                        <span
                          id={data.router}
                          onClick={HoverHandler}
                          style={{
                            fontSize: "0.8rem",
                            backgroundColor: "#FFD700",
                            color: "#000",
                            borderRadius: "5px",
                            padding: "0 5px",
                            marginLeft: "8px",
                            fontWeight: "400",
                          }}
                        >
                          Beta
                        </span>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {loginrole == 3 ? (
            <div className="sidebarProgressBar">
              {/* <div className="sidebarProgressBarImg"> */}
              <ProgressBar setvalueper={setvalueper} />
              {/* </div> */}

              {valueper != 100 ? (
                <h3
                  onClick={() => {
                    window.location.replace("/#/profile");
                  }}
                >
                  Complete my Profile
                </h3>
              ) : userdata.length !== 0 ? (
                userdata[0].nottify == true && userdata[0].apprual !== true ? (
                  <h2 className="text-center">Profile Under Review</h2>
                ) : userdata[0].availability_status == false &&
                  userdata[0].availability_from.length == 0 ? (
                  <h4 className="text-center">Update Notice Period</h4>
                ) : userdata[0].nottify == true &&
                  userdata[0].apprual == true &&
                  userdata[0].dissabled !== true ? (
                  <h5 className="text-center">Profile Approved</h5>
                ) : userdata[0].nottify == true &&
                  userdata[0].dissabled == true ? (
                  <h4 className="text-center">Profile Disabled</h4>
                ) : (
                  <h5
                    className="text-center"
                    onClick={() => {
                      window.location.replace("/#/preview");
                    }}
                  >
                    Submit Profile for Review
                  </h5>
                )
              ) : null}
            </div>
          ) : null}
        </div>
        <div id="logoutPopUp" className="logout" onClick={overLayHandler}>
          <img id="logoutPopUp" src={logout} alt="" onClick={overLayHandler} />
          <h6 id="logoutPopUp" onClick={overLayHandler}>
            Log out
          </h6>
        </div>
      </div>
      <div onClick={overLayHandler} className="IconClose">
        <RxCross1 />
      </div>
    </div>
  );
};

export default SideBar;
