/* eslint-disable no-unused-vars */
import { React, useState } from "react";
import "./Signup2.css";
import Head from "../../../Reusable/LogoHead/Head";
import Foot from "../../../Reusable/Terms&Conditions/Foot";
import SectionHead from "../../../Reusable/SectionHead/SectionHead";
import eye from "../../../../assests/eye.png";
import { Link, useNavigate } from "react-router-dom";
import back from "../../../../assests/back.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import textLogo from "../../../../assests/Logo.svg";
import { PiEyeSlashLight, PiEyeLight } from "react-icons/pi";
import google from "../../../../assests/Google.svg";
import linkedin from "../../../../assests/LinkedIn.svg";
import logo from "../../../../assests/LogoSvg.svg";
import firebase from "firebase/app";
import "firebase/auth";
const Signup2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isButton, setIsButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const showPassword = () => {
    setShow(!show);
  };

  const backHandler = () => {
    window.location.replace("https://hirein5.com/");
  };
  const [signupdata, setsignupdata] = useState({
    username: "",
    password: "",
  });
  const [usernameerror, setusernameerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [finalerror, setfinalerror] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [validations, setValidations] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      if (e.target.value.length !== 0) {
        setErrorMessage(true);
        const newPassword = e.target.value;
        const isValidLength = newPassword.length >= 6;
        const hasLowerCase = /[a-z]/.test(newPassword);
        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNumber = /\d/.test(newPassword);
        setValidations({
          validLength: isValidLength,
          hasLowerCase: hasLowerCase,
          hasUpperCase: hasUpperCase,
          hasSpecialChar: hasSpecialChar,
          hasNumber: hasNumber,
        });
        if (!isValidLength) {
        } else if (!hasLowerCase) {
        } else if (!hasUpperCase) {
        } else if (!hasSpecialChar) {
        } else if (!hasNumber) {
        } else {
          setIsButton(true);
          setsignupdata((values) => ({ ...values, [name]: value }));
          setTimeout(() => {
            setErrorMessage(false);
          }, 2000);
        }
      } else {
        setErrorMessage(false);
      }
    } else {
      setsignupdata((values) => ({ ...values, [name]: value }));
    }
  };

  const ButtonHandler1 = async () => {
    setfinalerror(false);
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (signupdata.username.length === 0) {
      setusernameerror(true);
    } else if (signupdata.username.match(validRegex)) {
      setusernameerror(false);
      if (signupdata.password.length === 0) {
        setusernameerror(false);
        setpassworderror(true);
      } else {
        setpassworderror(false);
        setIsLoading(true);

        var newobj = {
          email: signupdata.username,
          username: signupdata.username,
          password: signupdata.password,
          role: 3,
          status: "New Candidate",
        };
        var createuser = await axios
          .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`, newobj)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (createuser.access_token !== undefined) {
          dispatch(
            storeAction.tokenHandler({ token: createuser.access_token })
          );
          dispatch(storeAction.loginroleHander({ loginrole: 3 }));
          dispatch(storeAction.isloginHandler({ islogin: true }));
          dispatch(storeAction.useridHandler({ userid: createuser.id }));
          dispatch(storeAction.userdataHander({ userdata: createuser }));
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: 1,
            })
          );
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
            headers: {
              Authorization: `JWT ${createuser.access_token}`,
            },
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });

          dispatch(
            storeAction.signupdataHandler({
              signupdata: {
                username: signupdata.username,
                password: signupdata.password,
              },
            })
          );
          navigate("/emailverification");
        } else {
          setIsLoading(false);
          setfinalerror(true);
        }
      }
    } else {
      setusernameerror(true);
    }
  };
  const [showPasswords, setShowPasswords] = useState(false);
  const provider = new firebase.auth.GoogleAuthProvider();
  const handleGoogleSignIn = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const userData = result.user;

      alert(`Welcome, ${userData.displayName}!`);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };
  return (
    <>
      <div className="newLogin displayHandler">
        <div className="subLogin">
          <div className="headerSide">
            <img src={logo} alt="" />
            <h3>Welcome to HireIn5</h3>
            <h6>Your Next Opportunity Starts Here</h6>
            <p>
              Join thousands of tech professionals using Hirein5 to accelerate
              their careers.
            </p>
          </div>
          <div className="loginSide backGround">
            <div className="loginBody">
              <img src={textLogo} alt="" />
              <p className="fLogin">SignUp</p>
              <p className="fAcc">
                Already have an account?{" "}
                <Link to="/login">
                  <span>Log In</span>
                </Link>
              </p>
            </div>
            <div className="formContainer">
              <p className="label">Email</p>
              <input
                className="input border"
                type="email"
                required
                placeholder="you@email.com"
                name="username"
                onChange={handlechange}
              />
              {usernameerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  Please Enter Valid Email
                </p>
              )}
              <p className="label">
                Password{" "}
                <Link to="/forgotPassword">
                  <span>Forgot password</span>
                </Link>{" "}
              </p>
              <div className="passwordDiv">
                <input
                  className="input"
                  type={showPasswords ? "text" : "password"}
                  required
                  placeholder="Password"
                  name="password"
                  onChange={handlechange}
                />
                {showPasswords ? (
                  <PiEyeLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPasswords(false)}
                  />
                ) : (
                  <PiEyeSlashLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPasswords(true)}
                  />
                )}
                {passworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Password
                  </p>
                )}
              </div>
              {finalerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  A user with that username already exists.
                </p>
              )}
              {errorMessage && (
                <div className="validation-indicators mt-2">
                  <div
                    className={validations.validLength ? "valid" : "invalid"}
                  >
                    {validations.validLength ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}

                    {!validations.validLength && (
                      <span className="error-message">
                        Password must be at least 6 characters long.
                      </span>
                    )}
                    {validations.validLength && (
                      <span>Password must be at least 6 characters long.</span>
                    )}
                  </div>
                  <div
                    className={validations.hasLowerCase ? "valid" : "invalid"}
                  >
                    {validations.hasLowerCase ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasLowerCase && (
                      <span>
                        Password must contain at least one lowercase letter.
                      </span>
                    )}
                    {!validations.hasLowerCase && (
                      <span className="error-message">
                        Password must contain at least one lowercase letter.
                      </span>
                    )}
                  </div>
                  <div
                    className={validations.hasUpperCase ? "valid" : "invalid"}
                  >
                    {validations.hasUpperCase ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}

                    {!validations.hasUpperCase && (
                      <span className="error-message">
                        Password must contain at least one uppercase letter.
                      </span>
                    )}
                    {validations.hasUpperCase && (
                      <span>
                        Password must contain at least one uppercase letter.
                      </span>
                    )}
                  </div>
                  <div
                    className={validations.hasSpecialChar ? "valid" : "invalid"}
                  >
                    {validations.hasSpecialChar ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasSpecialChar && (
                      <span>
                        Password must contain at least one special character.
                      </span>
                    )}

                    {!validations.hasSpecialChar && (
                      <span className="error-message">
                        Password must contain at least one special character.
                      </span>
                    )}
                  </div>
                  <div className={validations.hasNumber ? "valid" : "invalid"}>
                    {validations.hasNumber ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasNumber && (
                      <span>Password must contain at least one number.</span>
                    )}
                    {!validations.hasNumber && (
                      <span className="error-message">
                        Password must contain at least one number.
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="termsConditions">
                <input className="check" type="checkbox" />
                <p
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  I accept the Terms & Conditions.
                </p>
              </div>
              <div className="candidateSignUpCompBodyButton marginTop20 marginBottom20">
                {isButton === true ? (
                  <button
                    onClick={ButtonHandler1}
                    id="Signup"
                    className={
                      isLoading === true
                        ? "candidateSignUpCompBodyButtonLoading"
                        : "candidateSignUpCompBodyButtonEnable"
                    }
                  >
                    {isLoading === true ? (
                      <FiLoader className="loadingIcon" />
                    ) : (
                      "Sign up"
                    )}
                  </button>
                ) : (
                  <button
                    disabled
                    id="Signup"
                    className="candidateSignUpCompBodyButtonDisable"
                  >
                    Sign up
                  </button>
                )}
              </div>
              <div className="forOr">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div onClick={handleGoogleSignIn} className="google border">
                <img src={google} alt="" />
                <p>Continue with Google</p>
              </div>
              <div className="google border padding">
                <img src={linkedin} alt="" />
                <p>Continue with LinkedIn</p>
              </div>
              <div className="termPrivacy">
                <p>
                  By creating an account, you accept to our <br />
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                        "_blank"
                      );
                    }}
                  >
                    Terms & Conditions
                  </span>{" "}
                  and <span>Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="newLogin displayHandlerMob">
        <div className="subLogin">
          <div className="headerSide">
            <img src={logo} alt="" />
            <h3>
              Welcome <span>to HireIn5</span>
            </h3>
            <h6>Your Next Opportunity Starts Here</h6>
            <p>
              Join thousands of tech professionals using Hirein5 to accelerate
              their careers.
            </p>
          </div>
          <div className="loginSide backGround">
            <div className="loginBody">
              <img src={textLogo} alt="" />
              <p className="fLogin">SignUp</p>
              <p className="fAcc">
                Already have an account? <span>Log In</span>
              </p>
            </div>
            <div className="formContainer">
              <p className="label">Email</p>
              <input
                className="input border"
                type="email"
                required
                placeholder="you@email.com"
                name="username"
                onChange={handlechange}
              />
              {usernameerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  Please Enter Valid Email
                </p>
              )}
              <p className="label">
                Password{" "}
                <Link to="/forgotPassword">
                  <span>Forgot password</span>
                </Link>{" "}
              </p>
              <div className="passwordDiv">
                <input
                  className="input"
                  type={showPasswords ? "text" : "password"}
                  required
                  placeholder="Password"
                  name="password"
                  onChange={handlechange}
                />
                {showPasswords ? (
                  <PiEyeLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPasswords(false)}
                  />
                ) : (
                  <PiEyeSlashLight
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPasswords(true)}
                  />
                )}
                {passworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Password
                  </p>
                )}
                {finalerror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    A user with that username already exists.
                  </p>
                )}
                {errorMessage && (
                  <div className="validation-indicators mt-2">
                    <div
                      className={validations.validLength ? "valid" : "invalid"}
                    >
                      {validations.validLength ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations.validLength && (
                        <span className="error-message">
                          Password must be at least 6 characters long.
                        </span>
                      )}
                      {validations.validLength && (
                        <span>
                          Password must be at least 6 characters long.
                        </span>
                      )}
                    </div>
                    <div
                      className={validations.hasLowerCase ? "valid" : "invalid"}
                    >
                      {validations.hasLowerCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations.hasLowerCase && (
                        <span>
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                      {!validations.hasLowerCase && (
                        <span className="error-message">
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={validations.hasUpperCase ? "valid" : "invalid"}
                    >
                      {validations.hasUpperCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations.hasUpperCase && (
                        <span className="error-message">
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                      {validations.hasUpperCase && (
                        <span>
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations.hasSpecialChar ? "valid" : "invalid"
                      }
                    >
                      {validations.hasSpecialChar ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations.hasSpecialChar && (
                        <span>
                          Password must contain at least one special character.
                        </span>
                      )}

                      {!validations.hasSpecialChar && (
                        <span className="error-message">
                          Password must contain at least one special character.
                        </span>
                      )}
                    </div>
                    <div
                      className={validations.hasNumber ? "valid" : "invalid"}
                    >
                      {validations.hasNumber ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations.hasNumber && (
                        <span>Password must contain at least one number.</span>
                      )}
                      {!validations.hasNumber && (
                        <span className="error-message">
                          Password must contain at least one number.
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="termsConditions">
                <input className="check" type="checkbox" />
                <p
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  I accept the Terms & Conditions.
                </p>
              </div>
              <div className="candidateSignUpCompBodyButton marginTop20 marginBottom20">
                {isButton === true ? (
                  <button
                    onClick={ButtonHandler1}
                    id="Signup"
                    className={
                      isLoading === true
                        ? "candidateSignUpCompBodyButtonLoading"
                        : "candidateSignUpCompBodyButtonEnable"
                    }
                  >
                    {isLoading === true ? (
                      <FiLoader className="loadingIcon" />
                    ) : (
                      "Sign up"
                    )}
                  </button>
                ) : (
                  <button
                    disabled
                    id="Signup"
                    className="candidateSignUpCompBodyButtonDisable"
                  >
                    Sign up
                  </button>
                )}
              </div>
              <div className="forOr">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div onClick={handleGoogleSignIn} className="google border">
                <img src={google} alt="" />
                <p>Continue with Google</p>
              </div>
              <div className="google border padding">
                <img src={linkedin} alt="" />
                <p>Continue with LinkedIn</p>
              </div>
              <div className="termPrivacy">
                <p>
                  By creating an account, you accept to our <br />
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                        "_blank"
                      );
                    }}
                  >
                    Terms & Conditions
                  </span>{" "}
                  and <span>Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup2;
