import React, { useState } from "react";
import axios from "axios";

const ParsingComp = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [parsedData, setParsedData] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }
    setLoading(true);
    setParsedData(null);
    const formData = new FormData();
    formData.append("files", file);
    formData.append("question", question);

    try {
      const response = await axios.post(
        `https://seahorse-app-qm7dp.ondigitalocean.app/process_pdfs/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("API Response:", response.data);
      setParsedData(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    setLoading(false); // Stop loader
  };

  return (
    <div
      style={{ padding: "20px", fontFamily: "Arial" }}
      className="flex flex-col"
    >
      <div className="w-[50%] flex flex-col">
        <input
          type="file"
          accept=".pdf,.docx"
          onChange={handleFileChange}
          className="border rounded py-2 px-2"
        />
        <input
          type="text"
          placeholder="Enter question"
          onChange={(e) => setQuestion(e.target.value)}
          className="border rounded py-2 px-2 mt-2"
        />
        <button
          onClick={handleUpload}
          disabled={loading}
          className="bg-green-600 p-2 rounded mt-2 text-white"
          style={{ marginLeft: "10px" }}
        >
          {loading ? "Uploading..." : "Submit"}
        </button>
      </div>

      {loading && (
        <div className="mt-4 text-blue-500 text-lg">
          Processing, please wait...
        </div>
      )}

      {parsedData && !loading && (
        <div style={{ marginTop: "20px", textAlign: "left" }}>
          <h3>Parsed JSON Output:</h3>
          <pre
            style={{
              background: "#f4f4f4",
              padding: "15px",
              borderRadius: "8px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              overflowX: "auto",
              maxWidth: "100%",
            }}
          >
            {JSON.stringify(parsedData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default ParsingComp;
