import React from "react";
import Countrycode from "../../../assests/CountryCodes.json";
// import SuccessResponse from "../../Reusable/SuccessResponse/SuccessResponse";

const MobileInput = ({ setphone, phone, setcountrycode, countrycode }) => {
  return (
    <>
      <div className="mobile_verification">
        <div className="mobile_inner">
          <label
            for="hs-inline-leading-select-label"
            className="block text-sm font-medium mb-2 dark:text-white mx-auto w-[60%]"
          >
            Phone number
          </label>
          <div className="flex flex-row border-2 items-center mx-auto w-[60%] border-gray-300 shadow-sm rounded-lg">
            <select
              id="hs-inline-leading-select-country"
              name="hs-inline-leading-select-country"
              onChange={(e) => {
                setcountrycode(e.target.value);
              }}
              defaultValue={countrycode}
              className="block w-[35%] h-full border-black rounded-lg focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-0"
            >
              <option value="">Select</option>
              {Countrycode.length !== 0
                ? Countrycode.map((data, index) => (
                    <option value={data.dial_code} key={index}>
                      {data.name}
                      {data.dial_code}
                    </option>
                  ))
                : null}
            </select>
            <input
              type="number"
              id="hs-inline-leading-select-label"
              name="inline-add-on"
              className="py-3 px-4 block w-full border-black shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-0 dark:border-gray-900 dark:text-gray-700 dark:focus:ring-gray-600"
              placeholder="+1 (000) 000-0000"
              onChange={(e) => {
                setphone(e.target.value);
              }}
              defaultValue={phone}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileInput;
