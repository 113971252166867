import React, { useEffect, useState } from "react";
import { firebase } from "../database/firebase";
import logo from "../assests/Logo.svg";

const VideoUpload = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileURLs, setFileURLs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const handleFile = async (e) => {
    setLoading(true);
    let files = Array.from(e.target.files); // Convert FileList to Array

    // Upload all files concurrently
    const uploadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const storageRef = firebase
          .storage()
          .ref(`Rejected_interview/` + file.name);
        storageRef
          .put(file)
          .then(() => storageRef.getDownloadURL())
          .then((url) => {
            resolve({ name: file.name, url });
          })
          .catch((err) => {
            console.error(err, "Error during upload");
            reject(err);
          });
      });
    });

    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      setFileURLs((prevFiles) => [...prevFiles, ...uploadedFiles]);
      document.getElementById("Fileinput").value = "";
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.error("Error uploading files:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    GetAllData();
  }, []);

  const GetAllData = async () => {
    try {
      const storageRef = firebase.storage().ref("Rejected_interview/");
      const fileList = await storageRef.listAll();
      const files = await Promise.all(
        fileList.items.map(async (item) => {
          const url = await item.getDownloadURL();
          return { name: item.name, url };
        })
      );
      setFileURLs(files);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const totalPages = Math.ceil(fileURLs.length / itemsPerPage);
  const paginatedData = fileURLs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPaginationNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    if (pages.length > 5) {
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(currentPage + 2, totalPages);
      const slicedPages = pages.slice(start - 1, end);

      return [
        ...(start > 1 ? [1, "..."] : []),
        ...slicedPages,
        ...(end < totalPages ? ["...", totalPages] : []),
      ];
    }
    return pages;
  };

  return (
    <div className="min-h-screen bg-gray-100 pb-8">
      {/* Header */}
      <div className="flex items-center justify-between px-10 py-6 bg-white shadow-md">
        <img
          src={logo}
          alt="Logo"
          className="w-28 cursor-pointer"
          onClick={() => window.location.replace("/")}
        />
      </div>

      {/* Upload Section */}
      <div className="flex flex-col items-center mt-10 bg-white shadow-lg rounded-lg p-6 max-w-xl mx-auto">
        <h2 className="text-xl font-semibold text-gray-800">
          Upload Video Interviews
        </h2>
        <input
          type="file"
          className="mt-4 w-full border rounded-lg p-2"
          onChange={handleFile}
          id="Fileinput"
          multiple // Allow multiple file selection
        />
        {loading && (
          <p className="mt-2 text-blue-600 font-medium">
            Uploading files, please wait...
          </p>
        )}
        {success && (
          <p className="mt-2 text-green-600 font-medium">
            Videos uploaded successfully!
          </p>
        )}
      </div>

      {/* Uploaded Videos */}
      <div className="mt-10 px-10">
        <h2 className="text-center font-bold text-xl text-gray-800 mb-4">
          Uploaded Videos
        </h2>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-blue-700 text-white">
                <th className="py-3 px-4 text-left">S.No</th>
                <th className="py-3 px-4 text-left">File Name</th>
                <th className="py-3 px-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((file, index) => (
                  <tr
                    key={index}
                    className="border-b hover:bg-gray-100 transition"
                  >
                    <td className="py-3 px-4">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="py-3 px-4">{file.name}</td>
                    <td className="py-3 px-4 text-center">
                      <button
                        onClick={() => window.open(file.url, "_blank")}
                        className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800 transition"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center py-4 text-gray-600">
                    No videos uploaded yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex justify-center items-center mt-5">
            {getPaginationNumbers().map((page, index) => (
              <button
                key={index}
                className={`mx-1 px-4 py-2 rounded-lg ${
                  currentPage === index + 1
                    ? "bg-blue-700 text-white"
                    : "bg-gray-300 text-black hover:bg-gray-400"
                } transition`}
                onClick={() => {
                  if (typeof page === "number") handlePageChange(page);
                }}
                disabled={page === "..."}
              >
                {page}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoUpload;
