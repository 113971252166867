/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import CandidateScreening from "./CandidateScreening";
import CandidateApplicationFlow from "./CandidateApplicationFlow";
import AssessmentFlow from "./AssessmentFlow";
import PostMouPreApprovalReview from "./PostMouPreApprovalReview";
import FinalApproval from "./FinalApproval";
import { useSelector } from "react-redux";
import Error from "./Error";
import Profiletracker from "./Profiletracker";

const Ats = () => {
  const loginrole = useSelector((store) => store.loginrole);
  const [teamlist, setteamlist] = useState([
    {
      name: "Candidate Screening and Connect",
    },
    {
      name: "Candidate Application Flow",
    },
    {
      name: "Assessment Flow",
    },
    {
      name: "Post Mou PreApproval Review",
    },
    {
      name: "Final Approval",
    },
  ]);
  const [selectedTeam, setSelectedTeam] = useState(teamlist[0]);
  const [teamindex, setteamindex] = useState(0);

  const ButtonHandler = (teamName, index) => {
    const team = teamlist.find((team) => team.name === teamName);
    setSelectedTeam(team);
    setteamindex(index);
  };

  return (
    <div className="paddingLeft90">
      <div className="HireMainComponent paddingRight90">
        <DashHead
          head="ATS"
          desc="Search and find the right talent for your company. If you need help in shortlisting candidates or do not find a relevent skill match"
          highLight="Contact Us"
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="HireTeamTable paddingRight90">
          <div className="HireTeamTableContentNew">
            <div className="HireTeamTableTop">
              {teamlist.length !== 0
                ? teamlist.map((data, index) => (
                    <h1
                      onClick={() => ButtonHandler(data.name, index)}
                      className={
                        selectedTeam?.name === data.name
                          ? "HireTeamTableToph1Active"
                          : "HireTeamTableToph1"
                      }
                      key={index}
                    >
                      {data.name}
                    </h1>
                  ))
                : null}
            </div>

            {selectedTeam.name == "Candidate Screening and Connect" && (
              // <CandidateScreening />
              <Profiletracker />
            )}
            {selectedTeam.name == "Candidate Application Flow" && (
              <CandidateApplicationFlow />
            )}
            {loginrole !== "7" ? (
              <>
                {selectedTeam.name == "Assessment Flow" && <AssessmentFlow />}
                {selectedTeam.name == "Post Mou PreApproval Review" && (
                  <PostMouPreApprovalReview />
                )}
                {selectedTeam.name == "Final Approval" && <FinalApproval />}
              </>
            ) : (
              (selectedTeam.name == "Assessment Flow" ||
                selectedTeam.name == "Post Mou PreApproval Review" ||
                selectedTeam.name == "Final Approval") && <Error />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ats;
