/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./AdminHome.css";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import Notification from "../../Reusable/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import back from "../../../assests/billingX.png";
import ProgressBar from "../../PrelineComponent/ProgressBar/ProgressBar";
import candidateNotificaionApprove from "../../../assests/approveCandidate.svg";
import candidateNotificaionInterview from "../../../assests/office.svg";
import Profile from "../../../assests/Iconimg.png";
import greenArrow from "../../../assests/greenArrow.svg";
import redArrow from "../../../assests/redArrow.svg";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const AdminHome = () => {
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const alluserdata = useSelector((store) => store.alluserdata);
  const allcompanydata = useSelector((store) => store.allcompanydata);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const [interviewdata, setinterviewdata] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [unreadnoti, setunreadnoti] = useState([]);
  const [todaynoti, settodaynoti] = useState([]);
  const [hireddata, sethireddata] = useState([]);
  const [nothired, setnothired] = useState([]);
  const [plan1data, setplan1data] = useState([]);
  const [plan2data, setplan2data] = useState([]);
  const [clientNotification, setclientNotification] = useState([]);
  const [candidateNotification, setcandidateNotification] = useState([]);
  const [allnotificationdata, setallnotificationdata] = useState([]);
  const [vendorNotification, setvendorNotification] = useState([]);
  const [candidatecount, setcandidatecount] = useState({
    active_candidates: 0,
    available_for_hire: 0,
    total_candidates: 0,
  });
  
  const overLayHandler = async (data) => {
    var checkdata = await allcompanydata.filter((item) => {
      return item.id == data.user;
    });
    setinterviewdata(checkdata);
    dispatch(storeAction.isPopUpHander("interviewDetails"));
    let newdata = JSON.stringify({
      status: "true",
    });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${data.id}/`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: newdata,
    };
    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
    Getnotification();
  };

  const overLayHandler1 = async (data) => {
    var checkuser = await alluserdata.filter((item) => {
      return item.id == data.user;
    });
    if (checkuser.length !== 0) {
      setuserdata(checkuser);
      dispatch(storeAction.singleuserHander({ singleuser: checkuser }));
      navigate("/admincandidateview");
      let newdata = JSON.stringify({
        status: "TRUE",
      });
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${data.id}/`,
        headers: {
          Authorization: `JWT ${token}`,
        },
        data: newdata,
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      Getnotification();
    } else {
      var userinfo = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.user}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (userinfo.id !== undefined) {
        setuserdata([userinfo]);
        dispatch(storeAction.singleuserHander({ singleuser: [userinfo] }));
        navigate("/admincandidateview");
        // dispatch(storeAction.isPopUpHander("approveconformation"));
      }
    }
  };

  const [target, setTarget] = useState("today");

  const targetHandler = (e) => {
    setTarget(e.target.id);
  };

  const CloseOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const popUpHandler = () => {
    navigate("/admincandidateview");
    dispatch(storeAction.isPopUpHander());
  };

  useEffect(() => {
    Getnotification();
  }, []);

  const Getnotification = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userid}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    try {
      const response = await axios.request(config);
      const allnotification = response.data;

      if (allnotification) {
        const filteredNotifications = allnotification;
        if (filteredNotifications.length !== 0) {
          var today = moment();
          var todaynotification = filteredNotifications.filter((data) => {
            return (
              moment(today).format("DD-MM-YYYY") ==
              moment(data.created_at).format("DD-MM-YYYY")
            );
          });

          setallnotificationdata(allnotification);
          var candidatenotification = filteredNotifications.filter((data) => {
            return (
              (data.on_type == "Candidate has onboarded" ||
                data.on_type == "Candidate Profile Completion" ||
                data.on_type == "Interview Accepted") &&
              data.status === "false"
            );
          });
          var clientenotification = filteredNotifications.filter((data) => {
            return (
              (data.on_type == "Client has onboarded" ||
                data.on_type == "Client Meeting" ||
                data.on_type == "Candidate Hiring" ||
                data.on_type == "Scheduled an interview") &&
              data.status === "false"
            );
          });
          var vendornotification = filteredNotifications.filter((data) => {
            return (
              (data.on_type == "Interview Request Sent" ||
                data.on_type == "Interview Request Sent By Vendor") &&
              data.status === "false"
            );
          });
          var allnotificationdata = await filteredNotifications.filter(
            (data) => {
              return data.status === "false";
            }
          );

          const sortedCandidates = [...allnotificationdata]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.user === item.user && t.on_type === item.on_type
                )
            );
          const sortedCandidates2 = [...candidatenotification]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.user === item.user && t.on_type === item.on_type
                )
            );
          const sortedCandidates3 = [...clientenotification]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.user === item.user && t.on_type === item.on_type
                )
            );
          const sortedCandidates4 = [...vendornotification]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.user === item.user && t.on_type === item.on_type
                )
            );
          const sortedtoday = [...todaynotification]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.user === item.user && t.on_type === item.on_type
                )
            );
          settodaynoti(sortedtoday);
          setunreadnoti(sortedCandidates);
          setclientNotification(sortedCandidates3);
          setcandidateNotification(sortedCandidates2);
          setvendorNotification(sortedCandidates4);
        }
      }
    } catch (error) {
      return error;
    }
    var CandidateCount = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/candidates-status/count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setcandidatecount({
      active_candidates: CandidateCount.active_candidates,
      available_for_hire: CandidateCount.available_for_hire,
      total_candidates: CandidateCount.total_candidates,
    });
    var allfacility__1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    dispatch(
      storeAction.allcompanydataHander({
        allcompanydata: allfacility__1.companies,
      })
    );
    var allfacility_1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility_1.companies.length !== 0) {
      dispatch(
        storeAction.allcompanydataHander({
          allcompanydata: allfacility_1.companies,
        })
      );
      var planA = await allfacility_1.companies.filter((data) => {
        if (data.pricing_info !== null && data.pricing_info.length !== 0) {
          const latestDateObject = data.pricing_info.reduce(
            (latest, current) => {
              return new Date(current.plan_start) > new Date(latest.plan_start)
                ? current
                : latest;
            }
          );
          return latestDateObject.pricing_plan === "Starter";
        }
        return false; // Return false if pricing_info is null or empty
      });
      var planB = await allfacility_1.companies.filter((data) => {
        if (data.pricing_info !== null && data.pricing_info.length !== 0) {
          const latestDateObject = data.pricing_info.reduce(
            (latest, current) => {
              return new Date(current.plan_start) > new Date(latest.plan_start)
                ? current
                : latest;
            }
          );
          return latestDateObject.pricing_plan !== "Starter";
        }
        return false; // Return false if pricing_info is null or empty
      });
      setplan1data(planA);
      setplan2data(planB);
    }

    const countdata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/status-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata.Benched !== undefined) {
      sethireddata(countdata.Hired);
      setnothired(countdata.Benched);
    }
  };

  const markread = async () => {
    if (target == "activity") {
      if (allnotificationdata.length !== 0) {
        for (var i = 0; i < allnotificationdata.length; i++) {
          let newdata = JSON.stringify({
            status: "true",
          });
          let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${allnotificationdata[i].id}/`,
            headers: {
              Authorization: `JWT ${token}`,
            },
            data: newdata,
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
        }
        Getnotification();
      }
    } else if (target == "read") {
      if (clientNotification.length !== 0) {
        for (var i = 0; i < clientNotification.length; i++) {
          let newdata = JSON.stringify({
            status: "true",
          });
          let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${clientNotification[i].id}/`,
            headers: {
              Authorization: `JWT ${token}`,
            },
            data: newdata,
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
        }
        Getnotification();
      }
    } else if (target == "Candidate") {
      if (candidateNotification.length !== 0) {
        for (var i = 0; i < candidateNotification.length; i++) {
          let newdata = JSON.stringify({
            status: "true",
          });
          let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${candidateNotification[i].id}/`,
            headers: {
              Authorization: `JWT ${token}`,
            },
            data: newdata,
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
        }
        Getnotification();
      }
    } else if (target == "Vendor") {
      if (vendorNotification.length !== 0) {
        for (var i = 0; i < vendorNotification.length; i++) {
          let newdata = JSON.stringify({
            status: "true",
          });
          let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${candidateNotification[i].id}/`,
            headers: {
              Authorization: `JWT ${token}`,
            },
            data: newdata,
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
        }
        Getnotification();
      }
    }
  };

  const overLayHandlerView = async (data) => {
    var checkuser = await allcompanydata.filter((item) => {
      return item.id == data.user;
    });
    if (checkuser.length !== 0) {
      dispatch(storeAction.singleuserHander({ singleuser: checkuser }));
      navigate("/adminclientview");
    }
  };

  return (
    <div>
      <div className="adminHomePage paddingLeft50 paddingRight50 marginBottom20">
        <DashHead
          head="Home"
          desc=""
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="flex justify-between gap-4 mb-5">
          <div className="card div-card">
            <h3>Available For Hire</h3>
            <p>{candidatecount.available_for_hire}</p>
          </div>
          <div className="card div-card">
            <h3>Total Candidate</h3>
            <p>{candidatecount.total_candidates}</p>
          </div>
          <div className="card div-card">
            <h3>Active Candidate</h3>
            <p>{candidatecount.active_candidates}</p>
          </div>
        </div>
        <div className="adminNotification">
          <div className="innerAdminNotification">
            <div className="adminNotificationHead">
              <h1>Notifications</h1>
              <h6
                onClick={() => {
                  markread();
                }}
              >
                Mark all as read
              </h6>
            </div>
            <div className="adminNotificationTab">
              <h5
                id="today"
                onClick={targetHandler}
                className={
                  target == "today"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Today's Notifications
                <span className="alertnoti">{todaynoti.length}</span>
              </h5>
              <h5
                id="activity"
                onClick={targetHandler}
                className={
                  target == "activity"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                All Notifications{" "}
                <span className="alertnoti">{unreadnoti.length}</span>
              </h5>
              <h5
                id="read"
                onClick={targetHandler}
                className={
                  target == "read"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Client Notifications{" "}
                <span className="alertnoti">{clientNotification.length}</span>
              </h5>
              <h5
                id="Candidate"
                onClick={targetHandler}
                className={
                  target == "Candidate"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Candidate Notifications{" "}
                <span className="alertnoti">
                  {candidateNotification.length}
                </span>
              </h5>
              <h5
                id="Vendor"
                onClick={targetHandler}
                className={
                  target == "Vendor"
                    ? "adminNotificationTabActive pointer"
                    : "adminNotificationTabInactive pointer"
                }
              >
                Vendor Notifications{" "}
                <span className="alertnoti">{vendorNotification.length}</span>
              </h5>
            </div>
          </div>
          {target == "today" ? (
            <div className="adminNotificationLog">
              {todaynoti.length !== 0 ? (
                todaynoti.map((data, index) =>
                  data.on_type === "Candidate has onboarded" ||
                  data.on_type === "Candidate Profile Completion" ? (
                    <Notification
                      Img={candidateNotificaionApprove}
                      message={data.message}
                      button="View"
                      btnClass="notificationButton"
                      fun={() => {
                        overLayHandler1(data);
                      }}
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client has onboarded" ? (
                    <Notification
                      Img={candidateNotificaionInterview}
                      message={data.message}
                      button="has onboarded as a client subscribed to Starter plan"
                      btnClass="hideButton"
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client Meeting" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandler(data);
                      }}
                      button="Review Candidate"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Candidate Hiring" ||
                    data.on_type === "Scheduled an interview" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandlerView(data);
                      }}
                      button="View"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={overLayHandler}
                      // button="Review Candidate"
                      // btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  )
                )
              ) : (
                <p className="text-center flex justify-center pt-12 texfontfott-semibold">
                  Notification Not Available...
                </p>
              )}
            </div>
          ) : null}
          {target == "activity" ? (
            <div className="adminNotificationLog">
              {unreadnoti.length !== 0 ? (
                unreadnoti.map((data, index) =>
                  data.on_type === "Candidate has onboarded" ||
                  data.on_type === "Candidate Profile Completion" ? (
                    <Notification
                      Img={candidateNotificaionApprove}
                      message={data.message}
                      button="View"
                      btnClass="notificationButton"
                      fun={() => {
                        overLayHandler1(data);
                      }}
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client has onboarded" ? (
                    <Notification
                      Img={candidateNotificaionInterview}
                      message={data.message}
                      button="has onboarded as a client subscribed to Starter plan"
                      btnClass="hideButton"
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client Meeting" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandler(data);
                      }}
                      button="Review Candidate"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Candidate Hiring" ||
                    data.on_type === "Scheduled an interview" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandlerView(data);
                      }}
                      button="View"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={overLayHandler}
                      // button="Review Candidate"
                      // btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  )
                )
              ) : (
                <p className="text-center flex justify-center pt-12 texfontfott-semibold">
                  Notification Not Available...
                </p>
              )}
            </div>
          ) : null}
          {target == "read" ? (
            <div className="adminNotificationLog">
              {clientNotification.length !== 0 ? (
                clientNotification.map((data, index) =>
                  data.on_type === "Candidate has onboarded" ||
                  data.on_type === "Candidate Profile Completion" ? (
                    <Notification
                      Img={candidateNotificaionApprove}
                      message={data.message}
                      button="View"
                      btnClass="notificationButton"
                      fun={() => {
                        overLayHandler1(data);
                      }}
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client has onboarded" ? (
                    <Notification
                      Img={candidateNotificaionInterview}
                      message={data.message}
                      button="has onboarded as a client subscribed to Starter plan"
                      btnClass="hideButton"
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client Meeting" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandler(data);
                      }}
                      button="Review Candidate"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Candidate Hiring" ||
                    data.on_type === "Scheduled an interview" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandlerView(data);
                      }}
                      button="View"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={overLayHandler}
                      // button="Review Candidate"
                      // btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  )
                )
              ) : (
                <p className="text-center flex justify-center pt-12 texfontfott-semibold">
                  Notification Not Available...
                </p>
              )}
            </div>
          ) : null}
          {target == "Candidate" ? (
            <div className="adminNotificationLog">
              {candidateNotification.length !== 0 ? (
                candidateNotification.map((data, index) =>
                  data.on_type === "Candidate has onboarded" ||
                  data.on_type === "Candidate Profile Completion" ? (
                    <Notification
                      Img={candidateNotificaionApprove}
                      message={data.message}
                      button="View"
                      btnClass="notificationButton"
                      fun={() => {
                        overLayHandler1(data);
                      }}
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client has onboarded" ? (
                    <Notification
                      Img={candidateNotificaionInterview}
                      message={data.message}
                      button="has onboarded as a client subscribed to Starter plan"
                      btnClass="hideButton"
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client Meeting" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandler(data);
                      }}
                      button="Review Candidate"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Candidate Hiring" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandlerView(data);
                      }}
                      button="View"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={overLayHandler}
                      // button="Review Candidate"
                      // btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  )
                )
              ) : (
                <p className="text-center flex justify-center pt-12 texfontfott-semibold">
                  Notification Not Available...
                </p>
              )}
            </div>
          ) : null}
          {target == "Vendor" ? (
            <div className="adminNotificationLog">
              {vendorNotification.length !== 0 ? (
                vendorNotification.map((data, index) =>
                  data.on_type === "Candidate has onboarded" ||
                  data.on_type === "Candidate Profile Completion" ? (
                    <Notification
                      Img={candidateNotificaionApprove}
                      message={data.message}
                      button="View"
                      btnClass="notificationButton"
                      fun={() => {
                        overLayHandler1(data);
                      }}
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client has onboarded" ? (
                    <Notification
                      Img={candidateNotificaionInterview}
                      message={data.message}
                      button="has onboarded as a client subscribed to Starter plan"
                      btnClass="hideButton"
                      date={data.created_at}
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Client Meeting" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandler(data);
                      }}
                      button="Review Candidate"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : data.on_type === "Candidate Hiring" ? (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={() => {
                        overLayHandlerView(data);
                      }}
                      button="View"
                      btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  ) : (
                    <Notification
                      Img={Profile}
                      message={data.message}
                      date={data.created_at}
                      fun={overLayHandler}
                      // button="Review Candidate"
                      // btnClass="notificationButton"
                      key={index}
                      type="unread"
                    />
                  )
                )
              ) : (
                <p className="text-center flex justify-center pt-12 texfontfott-semibold">
                  Notification Not Available...
                </p>
              )}
            </div>
          ) : null}
        </div>

        <div className="homeProgress">
          <div className="homeProgressCandidate">
            <h1>Candidates</h1>
            <div className="homeProgressCandidateFlex">
              <div className="homeProgressCandidateLeft">
                <ProgressBar />
              </div>
              <div className="homeProgressCandidateRight">
                <div className="homeProgressCandidateRightOne">
                  <div className="indication candidateHomeHired"></div>
                  <h2>Hired</h2>
                  <h3>- {hireddata}</h3>
                </div>
                <div className="homeProgressCandidateRightOne">
                  <div className="indication yetToBeHired"></div>
                  <h2>Yet to be hired</h2>
                  <h3>- {nothired}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="homeProgressClient">
            <h1>Clients</h1>
            <div className="homeProgressClientFlex">
              <div className="homeProgressClientFlexContent">
                <div className="progressHike">
                  <img src={greenArrow} alt="" />
                  <h3>12.54%</h3>
                </div>
                <h4>100</h4>
                <h2>Onboarded</h2>
              </div>
              <div className="homeProgressClientFlexContent">
                <div className="progressDecrement">
                  <img src={redArrow} alt="" />
                  <h3>5.67%</h3>
                </div>
                <h4>5</h4>
                <h2>Dropped</h2>
              </div>
              <div className="homeProgressClientFlexContent">
                <div className="progressHike">
                  <img src={greenArrow} alt="" />
                  <h3>2.87%</h3>
                </div>
                <h4>$ 2.35 L</h4>
                <h2>Profit</h2>
              </div>
            </div>
            <div className="homeProgressClientSubscribe">
              <h2>PLANS SUBSCRIBED TO</h2>
              <div className="homeProgressClientSubscribeFlex">
                <div className="homeProgressClientFlexContent">
                  <h5>{plan1data.length}</h5>
                  <h6>Starter</h6>
                </div>
                <div className="homeProgressClientFlexContent">
                  <h5>{plan2data.length}</h5>
                  <h6>Pro</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopUp == "interviewDetails" && (
        <div className="interViewDetailOverlay">
          <div className="interViewDetailOverlayHead">
            <h1>Interview details</h1>
            <img src={back} alt="" />
          </div>
          {interviewdata.length !== 0 ? (
            <div className="interViewDetailOverlayContent">
              <h2>Candidate:</h2>
              <h3>{interviewdata[0].first_name}</h3>
              <h2>Company (client):</h2>
              <h3>{interviewdata[0].company.company_name}</h3>
              {/* <h2>Date & time:</h2>
              <h3>12 February, 2024 at 5:30 PM IST</h3>
              <h2>Meeting link:</h2>
              <h3>https://calendly.com/meet/usernamelink</h3> */}
            </div>
          ) : null}
        </div>
      )}
      {isPopUp == "approveconformation" && (
        <>
          {userdata.length !== 0 ? (
            <div className="approveCandidateOverlay">
              <div className="candidateRateCardOverlayHead">
                <h1>Approve Candidate</h1>
                <span onClick={CloseOverlay}>
                  <RxCross2 />
                </span>
              </div>
              <div className="approveCandidateOverlayBody">
                <p>
                  You’ve checked all the details and have confirmed that this
                  candidate has completed their profile.
                </p>
                <div className="approveCandidateOverlayButton">
                  <button
                    className="discard"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                    }}
                  >
                    Cancel
                  </button>
                  <button onClick={popUpHandler} className="save">
                    Yes, Approve
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="approveCandidateOverlay">
              <div className="candidateRateCardOverlayHead">
                <h1>User Not Found</h1>
                <span onClick={CloseOverlay}>
                  <RxCross2 />
                </span>
              </div>
              <div className="approveCandidateOverlayBody">
                <p>Cannot get user information</p>
              </div>
              <div className="approveCandidateOverlayButton">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminHome;
