// store.js
import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

// Define your slice
const StoreSlice = createSlice({
  name: "HireIn5",
  initialState: {
    role: null,
    signupdata: null,
    token: null,
    userid: null,
    islogin: false,
    issidebar: false,
    isdynamicsidebar: false,
    isPopUp: null,
    isPopUp2: false,
    bookmarkdata: [],
    userdata: [],
    loginrole: null,
    singleuser: [],
    onboarding_status: null,
    alluserdata: [],
    allcompanydata: [],
    searchuser: [],
    contractdata: [],
    latestbill: [],
    newcandidate: [],
    vendorlist: [],
    newclient: [],
    vendorcandidate: [],
    interviewdata: [],
    HATinterviewdata: [],
    singlevendor: [],
    HATlist: {},
    shortlistUser: [],
    adminsearch: "",
    ptCandidate: {},
    ocCandidate: {},
    currentPage: "1",
    fromurl: null,
  },
  reducers: {
    roleHandler(state, payload) {
      state.role = payload.payload.role;
    },
    signupdataHandler(state, payload) {
      state.signupdata = payload.payload.signupdata;
    },
    tokenHandler(state, payload) {
      state.token = payload.payload.token;
    },
    useridHandler(state, payload) {
      state.userid = payload.payload.userid;
    },
    isloginHandler(state, payload) {
      state.islogin = payload.payload.islogin;
    },
    issidebarHandler(state, payload) {
      state.issidebar = payload.payload.issidebar;
    },
    isdynamicsidebarHandler(state, payload) {
      state.isdynamicsidebar = payload.payload.isdynamicsidebar;
    },
    isPopUpHander(state, action) {
      state.isPopUp = action.payload;
    },
    isPopUpHander2(state, action) {
      state.isPopUp2 = action.payload;
    },
    bookmarkdataHander(state, action) {
      state.bookmarkdata = action.payload.bookmarkdata;
    },
    userdataHander(state, action) {
      state.userdata = action.payload.userdata;
    },
    loginroleHander(state, action) {
      state.loginrole = action.payload.loginrole;
    },
    singleuserHander(state, action) {
      state.singleuser = action.payload.singleuser;
    },
    onboarding_statusHander(state, action) {
      state.onboarding_status = action.payload.onboarding_status;
    },
    alluserdataHander(state, action) {
      state.alluserdata = action.payload.alluserdata;
    },
    allcompanydataHander(state, action) {
      state.allcompanydata = action.payload.allcompanydata;
    },
    searchuserHander(state, action) {
      state.searchuser = action.payload.searchuser;
    },
    contractdataHander(state, action) {
      state.contractdata = action.payload.contractdata;
    },
    latestbillHander(state, action) {
      state.latestbill = action.payload.latestbill;
    },
    newcandidateHander(state, action) {
      state.newcandidate = action.payload.newcandidate;
    },
    vendorlistHander(state, action) {
      state.vendorlist = action.payload.vendorlist;
    },
    newclientHander(state, action) {
      state.newclient = action.payload.newclient;
    },
    vendorcandidateHander(state, action) {
      state.vendorcandidate = action.payload.vendorcandidate;
    },
    interviewdataHander(state, action) {
      state.interviewdata = action.payload.interviewdata;
    },
    singlevendorHander(state, action) {
      state.singlevendor = action.payload.singlevendor;
    },
    HATlistHander(state, action) {
      state.HATlist = action.payload.HATlist;
    },
    shortlistUserHander(state, action) {
      state.shortlistUser = action.payload.shortlistUser;
    },
    adminsearchHander(state, action) {
      state.adminsearch = action.payload.adminsearch;
    },
    ptCandidateHander(state, action) {
      state.ptCandidate = action.payload.ptCandidate;
    },
    ocCandidateHander(state, action) {
      state.ocCandidate = action.payload.ocCandidate;
    },
    HATinterviewdataHander(state, action) {
      state.HATinterviewdata = action.payload.HATinterviewdata;
    },
    currentPageHander(state, action) {
      state.currentPage = action.payload.currentPage;
    },
    fromurlHander(state, action) {
      state.fromurl = action.payload.fromurl;
    },
    reset(state) {
      return {
        role: null,
        signupdata: null,
        token: null,
        userid: null,
        islogin: false,
        issidebar: false,
        isPopUp: null,
        isPopUp2: false,
        bookmarkdata: [],
        userdata: [],
        loginrole: null,
        singleuser: [],
        onboarding_status: null,
        alluserdata: [],
        allcompanydata: [],
        searchuser: [],
        contractdata: [],
        latestbill: [],
        newcandidate: [],
        vendorlist: [],
        newclient: [],
        vendorcandidate: [],
        interviewdata: [],
        singlevendor: [],
        HATlist: {},
        shortlistUser: [],
        adminsearch: "",
        ptCandidate: {},
        ocCandidate: {},
        HATinterviewdata: [],
        currentPage: "",
        fromurl: null,
      };
    },
  },
});

// Create a persisted reducer
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "role",
    "userid",
    "islogin",
    "token",
    "signupdata",
    "loginrole",
    "onboarding_status",
    "issidebar",
    "userdata",
    "alluserdata",
    "singleuser",
    "allcompanydata",
    "searchuser",
    "bookmarkdata",
    "contractdata",
    "latestbill",
    "newcandidate",
    "vendorcandidate",
    "singlevendor",
    "HATlist",
    "shortlistUser",
    "ptCandidate",
    "ocCandidate",
    "HATinterviewdata",
    "fromurl",
  ],
};

const persistedReducer = persistReducer(persistConfig, StoreSlice.reducer);
const Store = configureStore({
  reducer: persistedReducer,
});
export const storeAction = StoreSlice.actions;

const persistor = persistStore(Store);

export { Store, persistor };
