import React, { useState } from "react";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashHead from "../../../Reusable/DashBoardReusable/DashHead/DashHead";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import { useSelector } from "react-redux";

const AddClient = () => {
  const customToastStyle = {
    background: "#14B8A6",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const token = useSelector((store) => store.token);

  const [formdata, setformdata] = useState({
    name: "",
    email_id: "",
    company_name: "",
    phone: "",
  });
  const [formdataerror, setformdataerror] = useState({
    name: false,
    email_id: false,
    company_name: false,
    phone: false,
  });
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [toastifystatus, settoastifystatus] = useState(false);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
    setformdataerror((values) => ({ ...values, [name]: false }));
  };

  const CraeteClient = async () => {
    setformdataerror({
      name: false,
      email_id: false,
      company_name: false,
      phone: false,
    });
    seterror("");

    let errors = {};
    if (!formdata.name.trim()) errors.name = true;
    if (!formdata.company_name.trim()) errors.company_name = true;
    if (!formdata.email_id.trim()) errors.email_id = true;
    if (!formdata.phone.trim()) errors.phone = true;

    if (Object.keys(errors).length > 0) {
      setformdataerror((prev) => ({ ...prev, ...errors }));
      return;
    }

    setloading(true);

    try {
      const newUser = {
        email: formdata.email_id,
        username: formdata.email_id,
        password: "Password@123",
        role: 2,
      };

      const createUserResponse = await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
        newUser
      );

      if (!createUserResponse.data?.access_token) {
        throw new Error(
          createUserResponse.data.error || "User creation failed"
        );
      }

      const updateUser = {
        first_name: formdata.name,
        phone: formdata.phone,
        company: {
          company_name: formdata.company_name,
        },
        onboarding_status: "4",
        username: formdata.email_id,
      };

      await axios.put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${createUserResponse.data.id}/`,
        updateUser,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${createUserResponse.data.access_token}`,
          },
        }
      );
      var notification = {
        email: formdata.email_id,
        from_email: "connect@hirein5.com",
        subject: "Welcome to Hirein5 – Get Started Today!",
        message: `
          <html>
            <body style="font-family: Arial, sans-serif; color: #333; line-height: 1.6;">
              <p>Dear ${formdata.name},</p>
      
              <p>We are thrilled to welcome you to <strong>Hirein5</strong>! Your account has been successfully created, and you now have access to our platform to manage your hiring needs efficiently.</p>
      
              <p>To get started, please set up your password by clicking the link below:</p>
      
              <p>
                <a href="https://app.hirein5.com/#/clientpassword/${formdata.email_id}" 
                   target="_blank" 
                   style="background-color: #007bff; color: #ffffff; padding: 10px 20px; text-decoration: none; border-radius: 5px; display: inline-block;">
                  Set Up Your Password
                </a>
              </p>
      
              <p>If you have any questions or need assistance, feel free to reach out to us at 
                <a href="mailto:support@hirein5.com" style="color: #007bff; text-decoration: none;">support@hirein5.com</a>.
              </p>
      
              <p>We look forward to supporting you in finding the best talent and streamlining your hiring process.</p>
      
              <p>Best regards,</p>
              <p><strong>Team Hirein5</strong></p>
            </body>
          </html>
        `,
      };
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      settoastifystatus(true);
      toast.success("New Client Added Successfully...", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });

      document.getElementById("name").value = "";
      document.getElementById("company_name").value = "";
      document.getElementById("email_id").value = "";
      document.getElementById("phone").value = "";
      setformdata({
        name: "",
        email_id: "",
        company_name: "",
        phone: "",
      });
    } catch (error) {
      seterror(
        error.message === "User already exists"
          ? "This email already has an account"
          : error.message
      );
    } finally {
      setloading(false);
    }
  };

  return (
    <div className="teamMembers paddingLeft50 paddingRight50">
      <DashHead
        head="Add Client"
        desc="Share access, add, and manage clients seamlessly to enhance collaboration and efficiency."
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="adminTeamMember">
        <div className="teamMemberInputs">
          <h4>Client Name</h4>
          <input
            type="text"
            placeholder="E.g. Jhon Doe"
            onChange={handlechange}
            name="name"
            id="name"
            defaultValue={formdata.name}
          />
          {formdataerror.name && (
            <p className="text-red-500 text-sm font-medium">
              Please Enter Client Name
            </p>
          )}
        </div>
        <div className="teamMemberInputs">
          <h4>Client Company Name</h4>
          <input
            type="text"
            placeholder="E.g. Hirein5"
            onChange={handlechange}
            name="company_name"
            id="company_name"
            defaultValue={formdata.company_name}
          />
          {formdataerror.company_name && (
            <p className="text-red-500 text-sm font-medium">
              Please Enter Client Company Name
            </p>
          )}
        </div>
        <div className="teamMember">
          <div className="teamMemberInputs">
            <h4>Email ID</h4>
            <input
              type="text"
              placeholder="E.g. johndoe@gmail.com"
              onChange={handlechange}
              name="email_id"
              id="email_id"
              defaultValue={formdata.email_id}
            />
            {formdataerror.email_id && (
              <p className="text-red-500 text-sm font-medium">
                Please Enter Email ID
              </p>
            )}
          </div>
        </div>
        <div className="teamMember">
          <div className="teamMemberInputs">
            <h4>Phone Number</h4>
            <input
              type="number"
              placeholder="9876543210"
              onChange={handlechange}
              name="phone"
              id="phone"
              defaultValue={formdata.phone}
            />
            {formdataerror.phone && (
              <p className="text-red-500 text-sm font-medium">
                Please Enter Phone Number
              </p>
            )}
          </div>
        </div>
        {/* <div className="teamMember">
          <div className="teamMemberInputs">
            <h4>Password</h4>
            <input
              type="text"
              placeholder="Enter Password"
              onChange={handlechange}
              name="password"
              id="password"
              defaultValue={formdata.password}
            />
            {formdataerror.password && (
              <p className="text-red-500 text-sm font-medium">
                Please Enter Password
              </p>
            )}
          </div>
        </div> */}

        {error && <p className="error-tag">{error}</p>}

        <div className="TeamSend">
          {loading === false ? (
            <button onClick={CraeteClient}>Create Client</button>
          ) : (
            <button className="save w-[10rem] flex justify-center items-center">
              <FiLoader className="loadingIcon" />
            </button>
          )}
        </div>
      </div>

      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default AddClient;
