/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { useEffect } from "react";
import { useState } from "react";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import { MdKeyboardArrowDown } from "react-icons/md";
import Billingtick from "../../../assests/Vector1.png";
import { FiLoader } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import MobileHeader from "../../MobileScreens/MobileHeader/MobileHeader";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeAction } from "../../../Store/Store";

const Teamlist = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const [show, setshow] = useState(false);
  const [setvalue, setvalue1] = useState("Hiring Manager");
  const [loading, setloading] = useState(false);
  const [setvalue2, setvalue22] = useState("Edit access");
  const [setvalue3, setvalue33] = useState("Edit access");
  const [updateid, setupdateid] = useState(null);
  const [shownew, setshownew] = useState(false);
  const [singleuserdata, setsingleuserdata] = useState(null);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    email_id: "",
  });
  const [alldata, setalldata] = useState([]);
  const [nameerror, setnameerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);

  function toggle_Dropdown(event) {
    setupdateid(event);
    setshownew(!shownew);
  }

  function getvalue(e) {
    setvalue1(e);
    setvalue22(e);
    setshow(false);
  }

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const accessHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const openmodel = (item) => {
    dispatch(storeAction.isPopUpHander(item));
  };

  async function getpendingvalue(e, id) {
    let getdata = e;
    var obj = {
      role: getdata,
      status: "Pending",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/api/client-members/${id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setupdateid(null);
    if (updatedata !== null) {
      settoastifystatus(true);
      toast.success("User access changed", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setshownew(false);
      Getalldata();
      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
      setupdateid(null);
    }
  }

  async function getpendingvalue1(e, id) {
    let getdata = e;
    var obj = {
      role: getdata,
      status: "Success",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/api/client-members/${id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setupdateid(null);
    if (updatedata !== null) {
      settoastifystatus(true);
      toast.success("User access changed", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setshownew(false);
      Getalldata();
      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
      setupdateid(null);
    }
  }

  const GetRemoveUser = async (data) => {
    axios.delete(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/delete-client-member/${data.id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    Getalldata();
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const customToastStyle = {
    background: "#14B8A6",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };

  const sendinvite = async () => {
    setnameerror(false);
    setemailerror(false);
    if (formdata.name.length == 0) {
      setnameerror(true);
    } else if (formdata.email_id.length == 0) {
      setemailerror(true);
    } else {
      setloading(true);
      setnameerror(false);
      setemailerror(false);
      var obj = {
        name: formdata.name,
        email: formdata.email_id.toLowerCase(),
        role: setvalue,
        status: "Success",
        password: "Password@123",
        last_login: "",
      };
      //   var newobj = {
      //     email: formdata.email_id.toLowerCase(),
      //   };
      var createuser = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/client-members/${userid}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (createuser.message === "Client Member created successfully") {
        // await axios
        //   .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/sendEmail/`, newobj, {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `JWT ${token}`,
        //     },
        //   })
        //   .then((res) => {
        //     return res.data;
        //   })
        //   .catch((err) => {
        //     return err.response;
        //   });
        settoastifystatus(true);
        toast.success("Invite Sent...", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
        setshownew(false);
        document.getElementById("email").value = "";
        document.getElementById("name").value = "";
        setalldata(alldata);
        setTimeout(() => {
          settoastifystatus(false);
        }, 3000);
      } else {
        settoastifystatus(true);
        toast.error("User already exists", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
        setTimeout(() => {
          settoastifystatus(false);
        }, 3000);
      }
      Getalldata();
      setloading(false);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    var alldata = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get-client-members/${userid}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (alldata.client_members !== undefined) {
      if (alldata.client_members.length !== 0) {
        setalldata(alldata.client_members);
      } else {
        setalldata([]);
      }
    } else {
      setalldata([]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        sendinvite();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div className="displayHandlerMob">
        <MobileHeader />
      </div>
      <div className="teamMembers paddingLeft50 paddingRight50">
        <DashHead
          head="Team members"
          desc="Share access with your staff and team members by inviting them to this platform. "
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="adminTeamMember">
          <h3>Add members</h3>
          <div className="teamMemberInputs">
            <h4>Name</h4>
            <input
              type="text"
              placeholder="E.g. Jhon Doe"
              onChange={handlechange}
              name="name"
              id="name"
              defaultValue={formdata.name}
            />
            {nameerror && (
              <p className="text-red-500 text-sm font-medium">
                Please Enter Name
              </p>
            )}
          </div>
          <div className="teamMember">
            <div className="teamMemberInputs">
              <h4>Email ID</h4>
              <input
                type="text"
                placeholder="E.g. johndoe@gmail.com"
                onChange={handlechange}
                name="email_id"
                id="email"
                defaultValue={formdata.email_id}
              />
              {emailerror && (
                <p className="text-red-500 text-sm font-medium">
                  Please Enter Email ID
                </p>
              )}
            </div>
            <div className="teamMemberLevel">
              <h4>Level of Access</h4>
              <div className="editAccess">
                <div className="editAccess1">
                  <button
                    onClick={accessHandler}
                    placeholder="Hiring Manager"
                    id="access1"
                  >
                    {setvalue}
                    <MdKeyboardArrowDown className="checkicon" />
                  </button>
                  {isPopUp == "access1" && (
                    <div className="dropHandler">
                      <h3
                        onClick={() => {
                          getvalue("Hiring Manager");
                        }}
                        className="pointer"
                      >
                        Hiring Manager <img src={Billingtick} alt="" />
                      </h3>
                      <h3
                        onClick={() => {
                          getvalue("Billing Manager");
                        }}
                        className="pointer"
                      >
                        Billing Manager <img src={Billingtick} alt="" />
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="TeamSend">
            {loading === false ? (
              <button onClick={sendinvite}>Add Member</button>
            ) : (
              <button className="save w-[10rem] flex justify-center items-center">
                <FiLoader className="loadingIcon" />
              </button>
            )}
          </div>
        </div>
        <div className="adminTeamMember">
          <h3>Manage members</h3>
          {/* <div className="manageMember">
            <h2>Pending invites</h2>

            {alldata.length !== 0
              ? alldata.map((data, index) =>
                  data.status === "Pending" || data.status !== "Success" ? (
                    <div className="manageMemberEdit" key={index}>
                      <h4>{data.name.length != 0 ? data.name : "User"}</h4>
                      <p>{data.role}</p>
                      <div className="editAccess">
                        <div className="editAccess1">
                          <button
                            onClick={() => {
                              toggle_Dropdown(data.id);
                              openmodel("access2");
                              setsingleuserdata(data);
                            }}
                          >
                            {setvalue3}
                            <MdKeyboardArrowDown className="checkicon" />
                          </button>
                          {isPopUp == "access2" && updateid === data.id && (
                            <div className="dropHandler">
                              <h3
                                onClick={() => {
                                  getpendingvalue("Manager", data.id);
                                }}
                                className="pointer"
                              >
                                Manager
                                <img src={Billingtick} alt="" />
                              </h3>
                              <h3
                                onClick={() => {
                                  getpendingvalue("Staff", data.id);
                                }}
                                className="pointer"
                              >
                                Staff
                                <img src={Billingtick} alt="" />
                              </h3>

                              <h3
                                title=""
                                onClick={() => {
                                  GetRemoveUser(data);
                                }}
                                className="pointer"
                              >
                                Remove user{" "}
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null
                )
              : null}
          </div> */}
          <div className="manageMember">
            <h2>Users</h2>
            {alldata.length !== 0
              ? alldata.map(
                  (data, index) => (
                    // data.status !== "Success" ? (
                    <div className="manageMemberEdit" key={index}>
                      <h4>{data.name}</h4>
                      <p>{data.role}</p>
                      <div className="editAccess">
                        <div className="editAccess1">
                          <button
                            onClick={() => {
                              toggle_Dropdown(data.id);
                              openmodel("access2");
                              setsingleuserdata(data);
                            }}
                          >
                            {setvalue3}
                            <MdKeyboardArrowDown className="checkicon" />
                          </button>
                          {isPopUp == "access2" && updateid === data.id && (
                            <div className="dropHandler">
                              <h3
                                onClick={() => {
                                  getpendingvalue1("Hiring Manager");
                                }}
                                className="pointer"
                              >
                                Hiring Manager <img src={Billingtick} alt="" />
                              </h3>
                              <h3
                                onClick={() => {
                                  getpendingvalue1("Billing Manager");
                                }}
                                className="pointer"
                              >
                                Billing Manager <img src={Billingtick} alt="" />
                              </h3>
                              <h3
                                title=""
                                onClick={() => {
                                  GetRemoveUser(data);
                                }}
                                className="pointer"
                              >
                                Remove user
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                  // ) : null
                )
              : null}
          </div>
        </div>
        {toastifystatus && <ToastContainer />}
      </div>
    </div>
  );
};

export default Teamlist;
