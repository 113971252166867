import React from "react";
import Courses from "../Components/Courses/Courses";

const CoursesPage = () => {
  return (
    <div>
      <Courses />
    </div>
  );
};

export default CoursesPage;
