import React from "react";
import TechAssessment from "../Components/TechAssessessment/TechAssessment";

const Techassessment = () => {
  return (
    <div>
      <TechAssessment />
    </div>
  );
};

export default Techassessment;
