/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Head from "../Reusable/LogoHead/Head";
import { FiLoader } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { firebase, auth } from "../../database/firebase";
import SuccessResponse from "../Reusable/SuccessResponse/SuccessResponse";
import axios from "axios";
import country_and_states from "../../assests/country-states-register";
import Skilllist from "../../assests/skillsJSON.json";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countrycode from "../../assests/CountryCodes.json";
import Popup from "../Reusable/PopUp/Popup";

const Register = () => {
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const onboarding_status = useSelector((store) => store.onboarding_status);
  const token = useSelector((store) => store.token);

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerify, setemailVerify] = useState(false);
  const [phoneVerify, setphoneVerify] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [wrongotpcountemail, setwrongotpcountemail] = useState(0);
  const [wrongotpcountphone, setwrongotpcountphone] = useState(0);
  const [wrongotp, setwrongotp] = useState(false);
  const [buttonenable, setbuttonenable] = useState(false);

  const [registationdata, setregistationdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "+91",
    location: "",
    company_name: "",
  });
  const [registationdataerror, setregistationdataerror] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    country: false,
    location: false,
    company_name: false,
  });
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [errorstatus, seterrorstatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );

  const [final, setfinal] = useState(null);
  const [finalerror, setfinalerror] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newloading1, setnewloading1] = useState(false);
  const [newloading2, setnewloading2] = useState(false);
  const [newloading3, setnewloading3] = useState(false);
  const [bottomerror, setbottomerror] = useState(false);
  const [resenstatus, setresenstatus] = useState(false);
  const [cvfile, setcvfile] = useState("");

  useEffect(() => {
    newcapcha();
  }, [recaptchaId]);

  const newcapcha = () => {
    var newid = Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("");
    setTimeout(() => {
      setRecaptchaId(newid);
    }, 5000);
  };

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);
  const CheckStage = async () => {
    if (userdata.length != 0) {
      setemailVerify(
        userdata[0].email_verification == undefined
          ? false
          : userdata[0].email_verification
      );
      setphoneVerify(
        userdata[0].mobile_verification == undefined
          ? false
          : userdata[0].mobile_verification
      );
      setregistationdata((values) => ({ ...values, email: userdata[0].email }));
      setregistationdata((values) => ({ ...values, phone: userdata[0].phone }));
      setupdateid(userdata[0].id);
      dispatch(storeAction.tokenHandler({ token: userdata[0].access_token }));
    }
    if (onboarding_status == 2) {
      setIsPage("page2");
    }
  };

  const submitHandler = async (e) => {
    if (isPage == "page1") {
      setbottomerror(false);
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        company_name: false,
      });
      setnewloading3(false);
      setbottomerror(true);
      var values_Array = [];
      if (registationdata.first_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, first_name: true }));
      } else if (registationdata.last_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, last_name: true }));
      } else if (registationdata.email.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, email: true }));
      } else if (emailVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, emailVerify: true }));
      } else if (registationdata.phone.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phone: true }));
      } else if (phoneVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phoneVerify: true }));
      } else if (registationdata.company_name.length === 0) {
        setregistationdataerror((values) => ({
          ...values,
          company_name: true,
        }));
      } else if (registationdata.location.length === 0) {
        setregistationdataerror((values) => ({ ...values, location: true }));
      } else {
        setnewloading3(true);
        setbottomerror(false);
        var notification = {
          email: "connect@hirein5.com",
          from_email: "connect@hirein5.com",
          subject: "Profile Submission for Review!",
          message: `<p>Vendor <b>${registationdata.first_name} ${registationdata.last_name}</b> from <b>${registationdata.location}</b> submitted their profile for review.</p>`,
        };
        var newobj = {
          username: registationdata.email,
          first_name: `${registationdata.first_name} ${registationdata.last_name}`,
          vendor_info: {
            location: registationdata.location,
            vendor_id: "",
            company_name: registationdata.company_name,
          },
          phone: registationdata.phone,
        };
        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            newobj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              notification,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          setnewloading3(false);
          dispatch(storeAction.userdataHander({ userdata: [userdata] }));
          setnewloading3(false);
          setIsPage("page2");
        } else {
          setnewloading3(false);
        }
      }
    } else {
      setIsPage(e.target.id);
      setnewloading3(false);
    }
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const verifyHandler = async (e) => {
    setInputValues(["", "", "", "", "", ""]);
    if (e == "mobileverification") {
      if (registationdata.phone.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          phone: true,
        }));
      } else {
        setnewloading1(true);
        setregistationdataerror((values) => ({
          ...values,
          phone: false,
        }));
        if (registationdata.phone.length !== 0) {
          if (registationdata.phone.length === 10) {
            const recaptchaId = `recaptcha-${Math.random()
              .toString(36)
              .substring(2, 15)}`; // Generate unique ID
            const recaptchaContainer = document.createElement("div");
            recaptchaContainer.id = recaptchaId;
            recaptchaContainer.classList.add("forget");
            document.body.appendChild(recaptchaContainer); // Append the container to the document body

            const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
              size: "invisible",
            });

            setTimeout(() => {
              auth
                .signInWithPhoneNumber(
                  `${registationdata.country}${registationdata.phone}`,
                  verify
                )
                .then((result) => {
                  setfinal(result);

                  setresenstatus(true);
                  setbuttonenable(true);
                  // Handle verification result
                  // For example, setfinal(result) and setresenstatus(true)
                  setTimeout(() => {
                    recaptchaContainer.remove();
                    setresenstatus(false); // Remove the container after verification
                  }, 5000);
                })
                .catch((err) => {
                  // Handle error
                  // For example, display an error message
                  recaptchaContainer.remove();
                  setresenstatus(false); // Remove the container on error
                });
            }, 100);
          } else {
            setresenstatus(false);
            // Handle invalid phone number length
          }
        } else {
          setRecaptchaId(
            Array.from({ length: 6 }, () =>
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            ).join("")
          );
        }
        setnewloading1(false);
        dispatch(storeAction.isPopUpHander(e));
      }
    } else if (e == "emailverification") {
      setfinalerror(false);
      if (registationdata.email.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else {
        setnewloading2(true);
        setregistationdataerror((values) => ({
          ...values,
          email: false,
        }));
        if (updateid == null) {
          var newobj = {
            email: registationdata.email,
            username: registationdata.email,
            password: "Password@123",
            role: 6,
            status: "Pending",
          };
          var createuser = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
              newobj
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (createuser.access_token !== undefined) {
            dispatch(storeAction.userdataHander({ userdata: [createuser] }));
            dispatch(
              storeAction.tokenHandler({ token: createuser.access_token })
            );
            setnewloading2(false);
            dispatch(storeAction.isPopUpHander(e));
            setupdateid(createuser.id);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
              headers: {
                Authorization: `JWT ${createuser.access_token}`,
              },
            };
            axios
              .request(config)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                return error;
              });
          } else {
            setnewloading2(false);
            setfinalerror(true);
          }
        } else {
          dispatch(storeAction.isPopUpHander(e));
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander(e));
    }
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setregistationdata((values) => ({ ...values, [name]: value }));
  };

  const inputRefs = useRef([]);
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const emailverification = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: registationdata.email,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      if (userdata.length !== 0) {
        let updatedObject = {
          ...userdata[0],
          email_verification: true,
        };
        setInputValues(["", "", "", "", "", ""]);
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }
      settoastifystatus(true);
      toast.success("Email ID verified", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setemailVerify(true);
      setTimeout(() => {
        setIsLoading(false);
        dispatch(storeAction.isPopUpHander());
      }, 2000);

      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
      setwrongotpcountemail(Number(wrongotpcountemail) + 1);
    }
  };
  const SendMail = async () => {
    setstatus(false);
    seterrorstatus(false);
    setwrongotpcountemail(0);
    setInputValues(["", "", "", "", "", ""]);
    if (updateid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  const phoneverification = async () => {
    setIsLoading(true);
    setwrongotp(false);
    var otp = inputValues.join("");
    final
      .confirm(otp)
      .then(async (result) => {
        let data = JSON.stringify({
          username: registationdata.email,
          email: registationdata.email,
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
        });
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        var user_data = await axios
          .request(config)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
        if (
          user_data.message === "User and Associated Info updated successfully"
        ) {
          let updatedObject = {
            ...userdata[0],
            email_verification: true,
            mobile_verification: true,
            phone: registationdata.phone,
          };
          dispatch(storeAction.userdataHander({ userdata: [] }));
          setTimeout(() => {
            dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          }, 10);
        }
        settoastifystatus(true);
        setphoneVerify(true);
        toast.success("Phone number verified", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
        setTimeout(() => {
          dispatch(storeAction.isPopUpHander());
          setIsLoading(false);
        }, 2000);
        setTimeout(() => {
          settoastifystatus(false);
        }, 3000);
      })
      .catch((err) => {
        setwrongotp(true);
        setwrongotpcountphone(Number(wrongotpcountphone) + 1);
        setIsLoading(false);
      });
  };

  const resendbtn = async () => {
    setbuttonenable(false);
    setInputValues(["", "", "", "", "", ""]);
    setwrongotpcountphone(0);
    setwrongotp(false);
    if (registationdata.phone.length !== 0) {
      if (registationdata.phone.length === 10) {
        const recaptchaId = `recaptcha-${Math.random()
          .toString(36)
          .substring(2, 15)}`; // Generate unique ID
        const recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = recaptchaId;
        recaptchaContainer.classList.add("forget");
        document.body.appendChild(recaptchaContainer); // Append the container to the document body

        const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
          size: "invisible",
        });

        setTimeout(() => {
          auth
            .signInWithPhoneNumber(
              `${registationdata.country}${registationdata.phone}`,
              verify
            )
            .then((result) => {
              setfinal(result);

              setresenstatus(true);
              setbuttonenable(true);
              // Handle verification result
              // For example, setfinal(result) and setresenstatus(true)
              setTimeout(() => {
                recaptchaContainer.remove();
                setresenstatus(false); // Remove the container after verification
              }, 5000);
            })
            .catch((err) => {
              // Handle error
              // For example, display an error message
              recaptchaContainer.remove();
              setresenstatus(false); // Remove the container on error
            });
        }, 100);
      } else {
        setresenstatus(false);

        // Handle invalid phone number length
      }
    } else {
      setresenstatus(false);

      // Handle empty phone number
    }
  };

  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page2") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });
  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  const continuebtn = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("https://hirein5.com/");
  };

  const logout = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <div>
      {isPage == "page1" && (
        <div className="backGround">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow1 sectionWidth">
            <div className="newClientFlowHead pt-10">
              <Head />
              <h2>Thank You for your interest in Hirein5</h2>
              <h1>
                Please fill in the information below for our team to screen your
                profile
              </h1>
            </div>
            <div className="newClientFlowBody">
              <div className="newClientFlowContent">
                <div className="newClientFlowContent1">
                  <input
                    type="text"
                    placeholder="First & Middle Name"
                    defaultValue={registationdata.first_name}
                    name="first_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.first_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter First & Middle Name
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  <input
                    type="text"
                    placeholder="Last name"
                    defaultValue={registationdata.last_name}
                    name="last_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.last_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Last Name
                    </p>
                  )}
                </div>
              </div>

              <div className="newClientFlowContent">
                <div className="newClientFlowContentSelect marginBottom0">
                  <div className="newClientFlowContentInput">
                    <input
                      className="buttonWidth85"
                      type="text"
                      placeholder="yourname@gmail.com"
                      defaultValue={registationdata.email}
                      name="email"
                      onChange={handlechange}
                      disabled={emailVerify == true}
                    />
                    <button
                      onClick={
                        emailVerify == true
                          ? null
                          : () => verifyHandler("emailverification")
                      }
                      className={
                        emailVerify == true
                          ? "newClientFlowContentSelectEnable"
                          : "newClientFlowContentSelectDisable"
                      }
                    >
                      {emailVerify == true ? (
                        <h4 className="verifybtn">
                          Verified <FiCheck />
                        </h4>
                      ) : newloading2 == false ? (
                        "Verify"
                      ) : (
                        <FiLoader className="loadingIcon" />
                      )}
                    </button>
                  </div>
                  {registationdataerror.emailVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Email ID
                    </p>
                  )}
                  {registationdataerror.email && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Email ID
                    </p>
                  )}
                  {finalerror && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      User already registered with email address.
                    </p>
                  )}
                </div>
              </div>
              <div className="newClientFlowContent">
                <div className="newClientFlowContentSelect marginBottom0 marginTop20">
                  <div className="newClientFlowContentInput">
                    <select
                      name="country"
                      defaultValue={registationdata.country}
                      onChange={handlechange}
                      // disabled={phoneVerify == true || emailVerify == false}
                    >
                      <option value="">Select</option>
                      {Countrycode.length !== 0
                        ? Countrycode.map((data, index) => (
                            <option
                              value={data.dial_code}
                              key={index}
                              selected={
                                registationdata.country == data.dial_code
                              }
                            >
                              {data.name} ({data.dial_code})
                            </option>
                          ))
                        : null}
                    </select>
                    <input
                      type="number"
                      placeholder="Your Phone no"
                      defaultValue={registationdata.phone}
                      name="phone"
                      onChange={handlechange}
                      // disabled={phoneVerify == true || emailVerify == false}
                    />
                    <button
                      disabled={emailVerify == false}
                      onClick={
                        phoneVerify == true || emailVerify == false
                          ? null
                          : () => verifyHandler("mobileverification")
                      }
                      className={
                        phoneVerify == true
                          ? "newClientFlowContentSelectEnable"
                          : "newClientFlowContentSelectDisable"
                      }
                    >
                      {phoneVerify == true ? (
                        <h4 className="verifybtn">
                          Verified <FiCheck />
                        </h4>
                      ) : newloading1 == false ? (
                        "Verify"
                      ) : (
                        <FiLoader className="loadingIcon" />
                      )}
                    </button>
                  </div>
                  {registationdataerror.phone && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Phone number
                    </p>
                  )}
                  {registationdataerror.phoneVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Phone number
                    </p>
                  )}
                  <div id={recaptchaId} className="forget"></div>
                </div>
              </div>
              <div className="newClientFlow_Content marginTop15">
                <h2>Company Name</h2>
                <input
                  className="newClientFlowContent1Select"
                  type="text"
                  placeholder="Company Name"
                  defaultValue={registationdata.company_name}
                  name="company_name"
                  onChange={handlechange}
                ></input>
                {registationdataerror.company_name && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Company Name
                  </p>
                )}
              </div>
              <div className="newClientFlow_Content marginTop15">
                <h2>Your current location</h2>
                <select
                  className="newClientFlowContent1Select"
                  type="text"
                  placeholder="Select Country"
                  defaultValue={registationdata.location}
                  name="location"
                  onChange={handlechange}
                >
                  <option value="">Select Country</option>
                  {country_and_states.country.length !== 0
                    ? country_and_states.country.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                {registationdataerror.location && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Select Your current location
                  </p>
                )}
              </div>
            </div>
            {bottomerror && (
              <p className="text-red-500 text-sm font-semibold mb-2 text-start">
                Please complete all the mandatory fields.
              </p>
            )}
            <div className="QuestionsSubmitButton">
              {newloading3 === false ? (
                <button
                  className="nextbtn buttonWidth100"
                  id="page2"
                  onClick={submitHandler}
                >
                  Next
                </button>
              ) : (
                <button
                  id="Signup"
                  className="signUpCompBodyButtonLoading buttonWidth100"
                >
                  <FiLoader className="loadingIcon" />
                </button>
              )}
            </div>
            <div className="newClientFlowSupport">
              <h5>
                For any assistance, please contact
                <a href="">gohire@hirein5.com</a>
              </h5>
            </div>
            <div className="newClientFlowTermsAndConditions">
              <h6
                className="cursor-pointer"
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                    "_blank"
                  );
                }}
              >
                Terms & Conditions
              </h6>
              <h6>Privacy Policy</h6>
            </div>
          </div>
        </div>
      )}
      {isPage == "page2" && (
        <div className="backGround">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow height100vh displayFlex justifyContentcenter sectionWidth">
            <div className="newClientFlowHead displayFlex">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Thank you! "
                  des="We have received your details. If your profile matches our requirements, you will receive a link to complete the vendor application."
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPopUp == "emailverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Email ID</h1>
            <p>
              Enter the 6-digit code we’ve sent to your Email Address
              <span>{registationdata.email}</span>
            </p>
            {status && (
              <p className="successerror">
                The OTP has been successfully sent to your email address.
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>

            {wrongotpcountemail !== 5 ? (
              errorstatus && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountemail}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountemail !== 5 ? (
              isLoading === false ? (
                <button className="mobVerifyButton" onClick={emailverification}>
                  Verify
                </button>
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive the email? Check your Spam folder or{" "}
              <span
                onClick={() => {
                  SendMail();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}
      {isPopUp == "mobileverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Phone No.</h1>
            <p>
              Enter the 6-digit code we’ve sent to your phone number
              <span>
                {registationdata.country} {registationdata.phone}
              </span>
            </p>
            {resenstatus && (
              <p className="successerror">
                The OTP has been successfully sent to your phone number
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>
            {wrongotpcountphone !== 5 ? (
              wrongotp && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountphone}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountphone !== 5 ? (
              isLoading === false ? (
                buttonenable ? (
                  <button
                    className="mobVerifyButton"
                    onClick={phoneverification}
                  >
                    Verify
                  </button>
                ) : (
                  <button className="mobVerifyButtondisable">Verify</button>
                )
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive OTP?
              <span
                onClick={() => {
                  resendbtn();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}
      {toastifystatus && <ToastContainer />}

      {isPopUp == "contactus" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Session Expired"
            desc="Your Session has been Expired Please Login to continue."
            InputContainer="displayNone"
            PopupSingleButton="PopupSingleButton"
            PopupSingleText="Login"
            SingleBtnfun={logout}
            PopupDoubleButton="displayNone"
            DoubleBtn1="Cancel"
            DoubleBtn2="Submit"
            closeIcon="popupClose pointer"
            waricon="waricon"
          />
        </div>
      )}
    </div>
  );
};

export default Register;
