/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import search from "../../assests/search.png";
import country_and_states from "../../assests/country-states";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../Store/Store";
import Pagination from "../NewCandidateFlow/Pagination";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import { MdMarkEmailRead } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VendorCandidate = () => {
  const dispatch = useDispatch();

  const customToastStyle = {
    background: "#14B8A6",
    color: "white",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    "--toastify-icon-color-success": "white",
  };
  const navigate = useNavigate();
  const singlevendor = useSelector((store) => store.singlevendor);
  const token = useSelector((store) => store.token);
  const vendorcandidate = useSelector((store) => store.vendorcandidate);

  const [candidatedata, setcandidatedata] = useState([]);
  const [allcandidatedata, setallcandidatedata] = useState([]);
  const [recordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);
  const [loadingnew2, setloadingnew2] = useState(false);
  const [selectid, setselectid] = useState(null);
  const [toastifystatus, settoastifystatus] = useState(false);

  useEffect(() => {
    Getallcandidate();
  }, []);

  const Getallcandidate = async () => {
    if (singlevendor.length !== 0) {
      if (vendorcandidate.length !== 0) {
        setcandidatedata(vendorcandidate);
        setallcandidatedata(vendorcandidate);
        setloading(false);
        var newobj = {
          vendor: singlevendor[0].id,
        };
        var candidatedata = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/vendor/users/`,
            newobj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (candidatedata.length !== 0) {
          setcandidatedata(candidatedata);
          setallcandidatedata(candidatedata);
          candidatedata.sort((a, b) => a.created_at - b.created_at);
          dispatch(
            storeAction.vendorcandidateHander({
              vendorcandidate: candidatedata,
            })
          );
        } else {
          dispatch(
            storeAction.vendorcandidateHander({
              vendorcandidate: [],
            })
          );
          setcandidatedata([]);
          setallcandidatedata([]);
        }
      } else {
        var newobj1 = {
          vendor: singlevendor[0].id,
        };
        var candidatedata1 = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/vendor/users/`,
            newobj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (candidatedata1.length !== 0) {
          setcandidatedata(candidatedata1);
          setallcandidatedata(candidatedata1);
          setloading(false);
          candidatedata1.sort((a, b) => b.created_at - a.created_at);
          dispatch(
            storeAction.vendorcandidateHander({
              vendorcandidate: candidatedata1,
            })
          );
        } else {
          if (candidatedata1.length !== 0) {
            setcandidatedata([]);
            setallcandidatedata([]);
            setloading(false);
            dispatch(
              storeAction.vendorcandidateHander({
                vendorcandidate: [],
              })
            );
          }
        }
      }
    }
    setTimeout(() => {
      setloading(false);
    }, 5000);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    allcandidatedata.length !== 0
      ? allcandidatedata.slice(indexOfFirstRecord, indexOfLastRecord)
      : [];
  const nPages = Math.ceil(allcandidatedata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const viewbtn = (data) => {
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/vendorcandidateprofile");
  };

  const acceptbtn = async (data) => {
    setloadingnew(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
        
        <p>Thank you for registering with Hirein5! To get started, please set up your password by clicking the link below:</p>
        
        <p><a href="https://app.hirein5.com/#/createpassword/${data.email}" target="_blank" style="color: #007bff; text-decoration: none;">Set Up Your Password</a></p>
        
        <p>If you have any questions, feel free to contact us at <a href="mailto:careers@hirein5.com" style="color: #007bff; text-decoration: none;">careers@hirein5.com</a>.</p>
        
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
        
        <p>Best regards,<br>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };

    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getallcandidate();

    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };

  const acceptbtn1 = async (data) => {
    setloadingnew2(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
        
        <p>Thank you for registering with Hirein5! To get started, please set up your password by clicking the link below:</p>
        
        <p><a href="https://app.hirein5.com/#/createpassword/${data.email}" target="_blank" style="color: #007bff; text-decoration: none;">Set Up Your Password</a></p>
        
        <p>If you have any questions, feel free to contact us at <a href="mailto:careers@hirein5.com" style="color: #007bff; text-decoration: none;">careers@hirein5.com</a>.</p>
        
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
        
        <p>Best regards,<br>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getallcandidate();

    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const rejectbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      from_email: "sales@hirein5.com",
      subject: "Application Update!",
      message: `
      <html>
       <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
         <p>Hi ${data.first_name},</p>
 
         <p>Your account has been put on hold due to operational reasons. If you believe this is a mistake, please reach out to your customer success partner from Hirein5 or write to us at 
         <a href="mailto:gohire@hirein5.com" style="color: #007bff; text-decoration: none;">gohire@hirein5.com</a>.</p>
 
         <p>If you are a candidate, please ensure you complete the onboarding process by clicking  
         <a href="https://app.hirein5.com/#/candidateregister" target="_blank">
             here
           </a>
         </p>
         <p><strong>Team Hirein5</strong></p>
       </body>
     </html>
 
     `,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getallcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  return (
    <div>
      <div className="vendorResumeUploadComp paddingLeft100 paddingRight100">
        <div className="vendorResumeUploadCompHead">
          <DashHead
            head="Candidates"
            desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry "
            // button="Upload Resume"
            descClass="dashBoardMainHeadDesc"
            // btnClass="dashBoardMainHeadDescButton uploadResumeButtonColor"
          />

          <div className="AdminClientProfileComp">
            <div className="AdminClientProfileCompSearch">
              <input type="text" placeholder="Search..." />
              <img src={search} alt="" />
            </div>

            <div className="AdminClientProfileCompTable">
              <table className="AdminClientTable1 w-full">
                <thead>
                  <tr className="AdminTableHead">
                    <th>Name</th>
                    <th>Location</th>
                    <th>Skills</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {loading === false ? (
                    currentRecords.length !== 0 ? (
                      currentRecords.map((data, index) => (
                        <tr className="adminTableRow" key={index}>
                          <td>
                            <div className="tableName">
                              {data.profile_picture.length !== 0 ? (
                                <img src={data.profile_picture} alt="" />
                              ) : (
                                <Avatar
                                  name={data.first_name}
                                  size={30}
                                  round="50px"
                                />
                              )}

                              <h1>{data.first_name}</h1>
                            </div>
                          </td>
                          <td>
                            {data.address !== null &&
                            data.address.country.length !== 0 ? (
                              <h1>{data.address.country}</h1>
                            ) : (
                              <h1>-</h1>
                            )}
                          </td>
                          <td>
                            {data.preference_info !== null ? (
                              data.preference_info.skills.length !== 0 ? (
                                <h2>
                                  {data.preference_info.skills.join(", ")}
                                </h2>
                              ) : (
                                <h1>-</h1>
                              )
                            ) : (
                              <h1>-</h1>
                            )}
                          </td>

                          <td>
                            <h1>{data.username}</h1>
                          </td>
                          <td>
                            <h1>{data.phone}</h1>
                          </td>

                          <td>
                            {data.status == "Success" ? (
                              <p className="hiringActive px-2 text-sm rounded-full text-center">
                                Processing
                              </p>
                            ) : data.status == "Reject" ? (
                              <p className="rejectstaus px-2 text-sm rounded-full text-center">
                                On Hold
                              </p>
                            ) : data.status == "" ||
                              data.status == "Pending" ? (
                              <p className="contracted px-2 text-sm rounded-full text-center">
                                Pending
                              </p>
                            ) : data.status == "Mail send" ? (
                              <p className="processingstaus px-2 text-sm rounded-full text-center">
                                Reviewed
                              </p>
                            ) : (
                              <p className="hiringActive px-2 rounded-full">
                                -
                              </p>
                            )}
                          </td>
                          <td>
                            <button
                              className="viewButton"
                              onClick={() => viewbtn(data)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <h6 className="text-center py-8">No data found...</h6>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <h6 className="text-center py-8">Please wait...</h6>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {pageNumbers.length !== 0 ? (
              <div className="tablePagination">
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default VendorCandidate;
