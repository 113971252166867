/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import "./ProfileCompletion2.css";
import textLogo from "../../../../assests/Logo.svg";
import { PiEyeSlashLight, PiEyeLight } from "react-icons/pi";
import logo from "../../../../assests/LogoSvg.svg";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import { firebase } from "../../../../database/firebase";
import SuccessResponse from "../../../Reusable/SuccessResponse/SuccessResponse";
import axios from "axios";
import country_and_states from "../../../../assests/country-states";
import Skilllist from "../../../../assests/skillsJSON.json";
import { ImAttachment } from "react-icons/im";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countrycode from "../../../../assests/CountryCodes.json";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { TbCloudUpload } from "react-icons/tb";
import { IoCloseOutline } from "react-icons/io5";
import { FiLoader } from "react-icons/fi";
import Head from "../../../Reusable/LogoHead/Head";

const ProfileCompletion2 = () => {
  const [showPasswords, setShowPasswords] = useState(false);
  const navigate = useNavigate();
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const onboarding_status = useSelector((store) => store.onboarding_status);
  const token = useSelector((store) => store.token);
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIwMzg4MDM4LCJpYXQiOjE3MjAzNzAwMzgsImp0aSI6ImU0MGQyOTk5NTE5YTQ5NjJiOTJjNDViNTEyZGYwYzc0IiwidXNlcl9pZCI6NzAsImZpcnN0X25hbWUiOiJNIERpbmVzaGt1bWFyIiwiZW1haWwiOiJkaW5lc2hraXQxNUBnbWFpbC5jb20iLCJwaG9uZSI6IjgwNTY1MjE0NjEiLCJ0aXRsZSI6Im51bGwiLCJsaW5rZWRfaW4iOiIiLCJyb2xlIjoiMyIsIm9uYm9hcmRpbmdfc3RhdHVzIjoiNCJ9.KORsUfHLpYtLbmoRzJ4y2_i0dhF-VVMhvVMQ9l32eTY";

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerify, setemailVerify] = useState(false);
  const [phoneVerify, setphoneVerify] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [wrongotpcountemail, setwrongotpcountemail] = useState(0);
  const [cvuploaderror, setcvuploaderror] = useState(false);
  const [uploaderror, setuploaderror] = useState(false);
  const [uploadsuccess, setuploadsuccess] = useState(false);
  const [phone, setphone] = useState("");

  const [registationdata, setregistationdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "",
    location: "",
    linkedin: "",
    password: "",
    cpassword: "",
  });
  const [registationdataerror, setregistationdataerror] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    country: false,
    location: false,
    linkedin: false,
    password: false,
    cpassword: false,
  });
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [errorstatus, seterrorstatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );

  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [finalerror, setfinalerror] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newloading3, setnewloading3] = useState(false);
  const [bottomerror, setbottomerror] = useState(false);
  const [cvfile, setcvfile] = useState("");
  const [validations, setValidations] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });
  const [newloading1, setnewloading1] = useState(false);

  useEffect(() => {
    newcapcha();
  }, [recaptchaId]);

  const newcapcha = () => {
    var newid = Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("");
    setTimeout(() => {
      setRecaptchaId(newid);
    }, 5000);
  };

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);

  const CheckStage = async () => {
    if (userdata.length != 0) {
      setemailVerify(
        userdata[0].email_verification == undefined
          ? false
          : userdata[0].email_verification
      );
      setphoneVerify(true);
      setregistationdata((values) => ({ ...values, email: userdata[0].email }));
      setregistationdata((values) => ({ ...values, phone: userdata[0].phone }));
      setupdateid(userdata[0].id);
      // dispatch(storeAction.tokenHandler({ token: userdata[0].access_token }));
    }
    if (onboarding_status == 2) {
      setIsPage("page2");
    }
  };

  const submitHandler = async (e) => {
    if (isPage == "page1") {
      setbottomerror(false);
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        linkedin: false,
        password: false,
        cpassword: false,
      });
      setnewloading3(false);
      setbottomerror(true);
      var values_Array = [];
      if (selectedOptionskill !== null) {
        values_Array = selectedOptionskill.map((country) => country.value);
      }
      if (registationdata.first_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, first_name: true }));
      } else if (registationdata.last_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, last_name: true }));
      } else if (registationdata.email.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, email: true }));
      } else if (emailVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, emailVerify: true }));
      } else if (registationdata.password.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, password: true }));
      } else if (registationdata.location.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, location: true }));
      } else if (registationdata.country.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, country: true }));
      } else if (registationdata.phone.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phone: true }));
      } else if (values_Array.length == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, skill: true }));
      } else if (cvfile.length == 0) {
        setuploaderror(true);
      } else {
        setnewloading3(true);
        setbottomerror(false);
        setregistationdataerror({
          first_name: false,
          last_name: false,
          phone: false,
          email: false,
          country: false,
          location: false,
          linkedin: false,
          password: false,
          cpassword: false,
        });
        var notification = {
          email: "careers@hirein5.com",
          from_email: "connect@hirein5.com",
          subject: "Profile Submission for Review!",
          message: `<p>Candidate <b>${registationdata.first_name} ${
            registationdata.last_name
          }</b> from <b>${
            registationdata.location
          }</b> with <b>${values_Array.join(
            ", "
          )}</b> skills has submitted their profile for review.</p>
              <p>Please review the candidate's profile and proceed with the next steps as necessary.</p>
              <p>Best regards,<br>Team Hirein5</p>`,
        };

        var notification1 = {
          email: registationdata.email,
          from_email: "connect@hirein5.com",
          subject: "Welcome to Hirein5: Your Account is Ready!",
          message: `
              <p>Dear ${registationdata.first_name} ${registationdata.last_name},</p>
  
              <p>Congratulations! Your account has been successfully created and your password has been set up.</p>
  
              <p>You can now log in to your account to complete the next steps of your application process:</p>
              <p>
                <a href="https://app.hirein5.com/#/login" target="_blank" style="color: #007bff; text-decoration: none;">Log in to your account</a>
              </p>
  
              <p>Once you log in, please ensure you:</p>
              <ul>
                <li>Complete your profile with all the required information.</li>
                <li>Upload any necessary documents (if applicable).</li>
                <li>Review and submit your application.</li>
              </ul>
  
              <p>If you have any questions or need assistance, feel free to reach out to us at <a href="mailto:careers@hirein5.com" style="color: #007bff; text-decoration: none;">careers@hirein5.com</a>.</p>
  
              <p>We look forward to helping you take the next step in your career!</p>
  
              <p>Best regards,<br>Team Hirein5</p>`,
        };

        var new_obj = {
          username: registationdata.email,
          email: registationdata.email,
          first_name: `${registationdata.first_name} ${registationdata.last_name}`,
          onboarding_status: 4,
          password: registationdata.password,
          status: "Success",
          country_code: registationdata.country,
          new_candidate_info: {
            skills: values_Array,
            location: registationdata.location,
            linkedin: registationdata.linkedin,
            resume: cvfile,
          },
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
        };
        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            new_obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        var new_obj1 = {
          email: registationdata.email,
          new_password: registationdata.password,
        };
        await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/update/password/`,
            new_obj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          var new_1obj = {
            message: `
                <p>
                  <strong>${registationdata.first_name} ${registationdata.last_name}</strong>
                  has registered as a candidate from <strong>${registationdata.location}</strong>.
                </p>`,
            status: "false",
            on_type: "Candidate has onboarded",
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${updateid}/`,
              new_1obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          await Promise.all([
            axios.post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              notification,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            ),
            axios.post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              notification1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            ),
          ]);
          setnewloading3(false);
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: 2,
            })
          );
          let updatedObject = {
            ...userdata[0],
            onboarding_status: 2,
          };
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          setnewloading3(false);
          setIsPage("page2");
        } else {
          setnewloading3(false);
        }
        setTimeout(() => {
          setnewloading3(false);
        }, 5000);
      }
    } else {
      setIsPage(e.target.id);
      setnewloading3(false);
    }
  };

  const verifyHandler = async (e) => {
    setregistationdataerror({
      first_name: false,
      last_name: false,
      phone: false,
      email: false,
      country: false,
      location: false,
      linkedin: false,
    });
    setInputValues(["", "", "", "", "", ""]);
    if (e == "emailverification") {
      setfinalerror(false);
      if (registationdata.email.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else {
        setregistationdataerror((values) => ({
          ...values,
          email: false,
        }));
        if (updateid == null) {
          var newobj = {
            email: registationdata.email,
            username: registationdata.email,
            password: "Password@123",
            role: 3,
          };
          var createuser = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
              newobj
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (createuser.access_token !== undefined) {
            dispatch(storeAction.userdataHander({ userdata: [createuser] }));
            dispatch(
              storeAction.tokenHandler({ token: createuser.access_token })
            );
            dispatch(storeAction.isPopUpHander(e));
            setupdateid(createuser.id);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
              headers: {
                Authorization: `JWT ${createuser.access_token}`,
              },
            };
            axios
              .request(config)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                return error;
              });
            setstatus(true);
            setTimeout(() => {
              setstatus(false);
            }, 5000);
          } else {
            if (createuser.data.error == "User already exists") {
              var new_obj = {
                username: registationdata.email,
                email: registationdata.email,
                password: "Password@123",
              };
              var loginuser = await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                  new_obj
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (loginuser.access !== undefined) {
                const token = loginuser.access;
                const decoded = jwtDecode(token);
                var userinfo = await axios
                  .get(
                    `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${loginuser.access}`,
                      },
                    }
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .catch((err) => {
                    return err.response;
                  });
                if (userinfo) {
                  if (userinfo.status == null || userinfo.status.length == 0) {
                    await axios
                      .delete(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                    verifyHandler("emailverification");
                  } else {
                    setfinalerror(true);
                  }
                }
              } else {
                setfinalerror(true);
              }
            } else {
              setfinalerror(true);
            }
          }
        } else {
          dispatch(storeAction.isPopUpHander(e));
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander(e));
    }
  };

  const validatePassword = (password) => ({
    validLength: password.length >= 6,
    hasLowerCase: /[a-z]/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    hasNumber: /\d/.test(password),
  });

  const handlechange = (e) => {
    setregistationdataerror({
      first_name: false,
      last_name: false,
      phone: false,
      email: false,
      country: false,
      location: false,
      linkedin: false,
      password: false,
      cpassword: false,
    });
    const { name, value } = e.target;
    if (name == "phone") {
      setphone(value);
      if (value.length == 10) {
        setphoneVerify(true);
      } else {
        setphoneVerify(false);
      }
    }
    if (name === "password" || name === "cpassword") {
      const newValidations = validatePassword(value);
      setValidations(newValidations);
      // setErrorMessage(!Object.values(newValidations).every(Boolean));
    }
    setregistationdata((values) => ({ ...values, [name]: value }));
  };

  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const emailverification = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: registationdata.email,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      setemailVerify(true);
      if (userdata.length !== 0) {
        let updatedObject = {
          ...userdata[0],
          email_verification: true,
        };
        setInputValues(["", "", "", "", "", ""]);
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }
      settoastifystatus(true);
      toast.success("Email ID verified", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        linkedin: false,
      });
      setVerify(!verify);
      setTimeout(() => {
        setIsLoading(false);
        dispatch(storeAction.isPopUpHander());
      }, 2000);
      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
      setwrongotpcountemail(Number(wrongotpcountemail) + 1);
    }
  };

  const SendMail = async () => {
    setstatus(false);
    seterrorstatus(false);
    setwrongotpcountemail(0);
    setIsResending(true);
    SendMail();
    setTimeout(() => {
      setIsResending(false);
      setTimer(30);
      setIsResendDisabled(true);
    }, 2000);
    setInputValues(["", "", "", "", "", ""]);
    if (updateid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  useEffect(() => {
    Getskill();
  }, [Skilllist]);

  const Getskill = async () => {
    var skillarrray = Skilllist;
    const uniqueSkills = Array.from(
      new Set(skillarrray.map((skill) => skill.Skill))
    );
    if (uniqueSkills.length !== 0) {
      var filter = [];
      for (var i = 0; i < uniqueSkills.length; i++) {
        filter.push({
          value: uniqueSkills[i],
          label: uniqueSkills[i],
        });
      }
      setskilloption(filter);
    }
  };

  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page2") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const continuebtn = async () => {
    const [firstName, lastName] = [
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .slice(0, -1)
        .join(" "),
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .pop(),
    ];
    var obj = {
      contacted_url: window.location.href,
      first_name: firstName,
      last_name: lastName,
      email: registationdata.email,
      phone: phone,
      role: 3,
      country: registationdata.location,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/zoho/lead/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var userinfo = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}`, {
        maxRedirects: 0,
        validateStatus: (status) => {
          return status < 400;
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    dispatch(storeAction.issidebarHandler({ issidebar: true }));
    dispatch(storeAction.isloginHandler({ islogin: true }));
    dispatch(
      storeAction.onboarding_statusHander({
        onboarding_status: 4,
      })
    );
    dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
    dispatch(storeAction.tokenHandler({ token: token }));
    dispatch(storeAction.useridHandler({ userid: updateid }));
    dispatch(storeAction.loginroleHander({ loginrole: 3 }));
    dispatch(
      storeAction.onboarding_statusHander({
        onboarding_status: 4,
      })
    );
    window.location.replace("/#/profile");
  };

  const fileInputRef = useRef(null);

  const handleFileSelectClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const selectedImage = e.target.files[0];
    setuploaderror(false);
    setuploadsuccess(false);
    setnewloading3(true);
    if (selectedImage.size > 5 * 1024 * 1024) {
      fileInputRef.current.value = "";
      setcvuploaderror(true);
      fileInputRef.current.value = "";
      setnewloading3(false);
    } else {
      var id = Math.floor(100000 + Math.random() * 900000);
      settoastifystatus(true);
      toast.info("Please wait resume uploading... ", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref(`cv/${id}` + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl1 = await file13;
      settoastifystatus(false);
      setuploadsuccess(true);
      setcvfile(imgurl1);
      setnewloading3(false);
    }
    fileInputRef.current.value = "";
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isPage == "page1" && event.key === "Enter") {
        submitHandler();
      } else if (isPage == "page2" && event.key === "Enter") {
        continuebtn();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPage, registationdata]);

  const [verify, setVerify] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isResending, setIsResending] = useState(false);

  const setVerifyHandler = async () => {
    setfinalerror(false);
    if (registationdata.email.length == 0) {
      setregistationdataerror((values) => ({
        ...values,
        email: true,
      }));
    } else {
      setregistationdataerror((values) => ({
        ...values,
        email: false,
      }));
      setnewloading1(true);
      if (updateid == null) {
        var newobj = {
          email: registationdata.email,
          username: registationdata.email,
          password: "Password@123",
          role: 3,
        };
        var createuser = await axios
          .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`, newobj)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (createuser.access_token !== undefined) {
          setVerify(!verify);
          dispatch(storeAction.userdataHander({ userdata: [createuser] }));
          dispatch(
            storeAction.tokenHandler({ token: createuser.access_token })
          );
          setupdateid(createuser.id);
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
            headers: {
              Authorization: `JWT ${createuser.access_token}`,
            },
          };
          axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error;
            });
          setstatus(true);
          setTimeout(() => {
            setstatus(false);
          }, 5000);
        } else {
          if (createuser.data.error == "User already exists") {
            var new_obj = {
              username: registationdata.email,
              email: registationdata.email,
              password: "Password@123",
            };
            var loginuser = await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                new_obj
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
            if (loginuser.access !== undefined) {
              const token = loginuser.access;
              const decoded = jwtDecode(token);
              var userinfo = await axios
                .get(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${loginuser.access}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (userinfo) {
                if (userinfo.status == null || userinfo.status.length == 0) {
                  await axios
                    .delete(
                      `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `JWT ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      return res.data;
                    })
                    .catch((err) => {
                      return err.response;
                    });
                  verifyHandler("emailverification");
                } else {
                  setfinalerror(true);
                }
              }
            } else {
              setfinalerror(true);
            }
          } else {
            setfinalerror(true);
          }
        }
      } else {
        setVerify(!verify);
      }
      setnewloading1(false);
    }
  };

  const allInputsFilled = inputValues.every((val) => val !== "");

  const handleChangeNew = (selectedOptions) => {
    if (selectedOptions.length > 8) return;
    setSelectedOptionskill(selectedOptions);
  };

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);

  return (
    <>
      {isPage == "page2" ? (
        <div className="backGround">
          <div className="newClientFlow displayFlex height100vh justifyContentcenter sectionWidth">
            <div className="newClientFlowHead displayFlex pt-48">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Thank You for signing up with Hirein5!"
                  des="Click on Continue below to access your application. Please complete your application at the earliest possible."
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="newLogin height displayHandler">
            <div className="subLogin align">
              <div className="headerSide paddingTop">
                <img src={logo} alt="" />
                <h3>Welcome to HireIn5</h3>
                <h6>Your Next Opportunity Starts Here</h6>
                <p>
                  Join thousands of tech professionals using Hirein5 to
                  accelerate their careers.
                </p>
              </div>
              <div className="loginSide backGround marginTop width50 relative">
                <div className="loginBody">
                  <img src={textLogo} alt="" />
                  <p className="fLogin">Complete your Profile to Begin</p>
                  <p className="fAcc width">
                    Fill in your details to personalize your experience and
                    unlock full access
                  </p>
                </div>
                <div className="formContainer">
                  <div className="mergeLabel">
                    <div className="width50">
                      <p className="label">First Name</p>
                      <input
                        onChange={handlechange}
                        name="first_name"
                        className="input border"
                        type="text"
                        required
                        placeholder="First Name"
                        defaultValue={registationdata.first_name}
                      />
                      {registationdataerror.first_name && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter First Name
                        </p>
                      )}
                    </div>
                    <div className="width50">
                      <p className="label">Last Name</p>
                      <input
                        onChange={handlechange}
                        name="last_name"
                        className="input border"
                        type="text"
                        required
                        placeholder="Last Name"
                        defaultValue={registationdata.last_name}
                      />
                      {registationdataerror.last_name && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Last Name
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mergeLabel">
                    <div className="width50">
                      <p className="label">Email</p>
                      <div className="passwordDiv">
                        <input
                          onChange={handlechange}
                          name="email"
                          className="input"
                          type="email"
                          required
                          placeholder="you@email.com"
                          defaultValue={registationdata.email}
                          disabled={emailVerify == true}
                        />
                        {newloading1 == true ? (
                          <button
                            className={
                              emailVerify !== true
                                ? "verifyButton"
                                : "verifyButtondisable"
                            }
                          >
                            <FiLoader className="loadingIcon" />
                          </button>
                        ) : (
                          <button
                            className={
                              emailVerify !== true
                                ? "verifyButton"
                                : "verifyButtondisable"
                            }
                            onClick={
                              emailVerify == true ? null : setVerifyHandler
                            }
                          >
                            Verify
                          </button>
                        )}
                      </div>
                      {registationdataerror.emailVerify && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Verify Email ID
                        </p>
                      )}
                      {registationdataerror.email && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Email ID
                        </p>
                      )}
                      {finalerror && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          User already registered with email address.
                        </p>
                      )}
                    </div>
                    <div className="width50">
                      <p className="label">Password</p>
                      <div className="passwordDiv">
                        <input
                          onChange={handlechange}
                          name="password"
                          className="input"
                          type={showPasswords ? "text" : "password"}
                          required
                          placeholder="Password"
                        />
                        {showPasswords ? (
                          <PiEyeLight
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPasswords(false)}
                          />
                        ) : (
                          <PiEyeSlashLight
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPasswords(true)}
                          />
                        )}
                      </div>
                      {registationdataerror.password && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Password
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mergeLabel">
                    <div className="width100">
                      <p className="label">Your current location</p>
                      <select
                        className="input border"
                        type="text"
                        placeholder="Select Country"
                        defaultValue={registationdata.location}
                        name="location"
                        onChange={handlechange}
                      >
                        <option value="">Select Country</option>
                        {country_and_states.country.length !== 0
                          ? country_and_states.country.map((item, index) => (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            ))
                          : null}
                      </select>
                      {registationdataerror.location && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Select Your current location
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="width100">
                    <p className="label">Phone Number</p>
                    <div className="contentInput">
                      <select
                        className="selectBorder"
                        name="country"
                        defaultValue={registationdata.country}
                        onChange={handlechange}
                        // disabled={phoneVerify == false && emailVerify == false}
                      >
                        <option value="">Country</option>
                        {Countrycode.length !== 0
                          ? Countrycode.map((data, index) => (
                              <option
                                value={data.dial_code}
                                key={index}
                                selected={
                                  registationdata.country == data.dial_code
                                }
                              >
                                {data.name} ({data.dial_code})
                              </option>
                            ))
                          : null}
                      </select>
                      <input
                        className="input inputBorder"
                        type="number"
                        placeholder="Your Phone no"
                        defaultValue={registationdata.phone}
                        name="phone"
                        onChange={handlechange}
                      />
                    </div>
                    {registationdataerror.country && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Select Country Code
                      </p>
                    )}
                    {registationdataerror.phone && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Enter Phone number
                      </p>
                    )}
                    {registationdataerror.phoneVerify && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Verify Phone number
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="label">Key Skills</p>
                    <Select
                      className="marginTop15 cursor"
                      value={selectedOptionskill}
                      onChange={handleChangeNew}
                      options={skilloption}
                      isMulti
                    />
                    {registationdataerror.skill && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Select Key Skills
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="label">Upload CV</p>
                    <div
                      className="aadhaarFront flexColClass input border"
                      onClick={handleFileSelectClick}
                    >
                      <h3>
                        <TbCloudUpload />
                      </h3>
                      <h3>Click to upload </h3>
                      <p style={{ textAlign: "center", width: "100%" }}>
                        Supports .pdf, .doc, .docx formats only.
                      </p>
                      <p title="">Max file size: 5MB </p>
                    </div>
                    <input
                      className=""
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      name="aadhaarfront"
                      accept=".pdf,.doc,.docx,.txt"
                      onChange={handleFileInputChange}
                    />
                    {uploaderror && (
                      <h6 className="text-red-500 text-xs font-semibold mt-2">
                        Please Upload CV
                      </h6>
                    )}
                    {uploadsuccess && (
                      <h6 className="text-green-500 text-xs font-semibold mt-2">
                        CV Uploaded Successfully
                      </h6>
                    )}
                    {uploadsuccess && (
                      <div
                        className="gradeCertificate"
                        onClick={() => {
                          window.open(`${cvfile}`, "_blank");
                        }}
                      >
                        <ImAttachment className="attachicon text-2xl" />
                        <div className="gradeCertificateDesc">
                          <p className="text-black">View CV</p>
                        </div>
                      </div>
                    )}

                    {cvuploaderror && (
                      <h6 className="text-red-500 text-xs font-semibold mt-2">
                        Image size exceeds 5 MB limit.
                      </h6>
                    )}
                  </div>
                  {bottomerror && (
                    <p className="text-red-500 text-sm font-semibold mb-2 text-start">
                      Please complete all the mandatory fields.
                    </p>
                  )}
                  {newloading3 === false ? (
                    <button
                      className="marginTop30 input border bgc"
                      onClick={submitHandler}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      id="Signup"
                      className="signUpCompBodyButtonLoading buttonWidth100 marginTop30"
                    >
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="newLogin displayHandlerMob">
            <div className="subLogin">
              <div className="headerSide">
                <img src={logo} alt="" />
                <h3>Welcome to HireIn5</h3>
                <h6>Your Next Opportunity Starts Here</h6>
                <p>
                  Join thousands of tech professionals using Hirein5 to
                  accelerate their careers.
                </p>
              </div>
              <div className="loginSide backGround marginTop">
                <div className="loginBody">
                  <img src={textLogo} alt="" />
                  <p className="fLogin">Complete your Profile to Begin</p>
                  <p className="fAcc width">
                    Fill in your details to personalize your experience and
                    unlock full access
                  </p>
                </div>
                <div className="formContainer">
                  {/* {verify && (
                    <div className="flex-center viewOverlay absolute">
                      <div className="verifyPopup">
                        <div className="justify-conten-end cursor">
                          <IoCloseOutline onClick={setVerifyHandler} />
                        </div>
                        <p className="fLogin">Enter OTP</p>
                        <p className="fAcc">
                          OTP has been sent to{" "}
                          <span>{registationdata.email}</span>
                        </p>

                        {status && (
                          <p className="successerror">
                            The OTP has been successfully sent to your email
                            address.
                          </p>
                        )}
                        <div className="inputs marginTop15">
                          {inputValues.map((value, index) => (
                            <input
                              key={index}
                              ref={(el) => (inputRefs.current[index] = el)}
                              type="number"
                              maxLength="1"
                              value={value}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              className="input1 border"
                            />
                          ))}
                        </div>
                        {wrongotpcountemail !== 5 ? (
                          errorstatus && (
                            <p className="otperror">
                              The OTP you entered is incorrect. Please check and
                              enter again. Attempts left:{" "}
                              {5 - wrongotpcountemail}.{" "}
                            </p>
                          )
                        ) : (
                          <p className="otperror">
                            You have exceeded the maximum attempts. Please
                            regenerate a new OTP and try again.
                          </p>
                        )}

                        {wrongotpcountemail !== 5 ? (
                          allInputsFilled ? (
                            isLoading == false ? (
                              <button
                                className="mobVerifyButton"
                                onClick={emailverification}
                              >
                                Verify & Continue
                              </button>
                            ) : (
                              <button className="mobVerifyButton">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          ) : (
                            <button className="mobVerifyButtondisable">
                              Verify & Continue
                            </button>
                          )
                        ) : (
                          <button className="mobVerifyButtondisable">
                            Verify & Continue
                          </button>
                        )}

                        <div className="resend">
                          {isResendDisabled ? (
                            <p>Resend OTP in {timer}s</p>
                          ) : (
                            <button
                              className="resendOTPBtn"
                              onClick={() => {
                                SendMail();
                              }}
                              disabled={isResending}
                            >
                              {isResending ? (
                                <FiLoader className="loadingIcon" />
                              ) : (
                                "Resend OTP"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}
                  <div className="mergeLabel">
                    <div className="width50">
                      <p className="label">First Name</p>
                      <input
                        onChange={handlechange}
                        name="first_name"
                        className="input border"
                        type="text"
                        required
                        placeholder="First Name"
                        defaultValue={registationdata.first_name}
                      />
                      {registationdataerror.first_name && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter First Name
                        </p>
                      )}
                    </div>
                    <div className="width50">
                      <p className="label">Last Name</p>
                      <input
                        onChange={handlechange}
                        name="last_name"
                        className="input border"
                        type="text"
                        required
                        placeholder="Last Name"
                        defaultValue={registationdata.last_name}
                      />
                      {registationdataerror.last_name && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Last Name
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mergeLabel">
                    <div className="width50">
                      <p className="label">Email</p>
                      <div className="passwordDiv">
                        <input
                          onChange={handlechange}
                          name="email"
                          className="input"
                          type="email"
                          required
                          placeholder="you@email.com"
                          defaultValue={registationdata.email}
                          disabled={emailVerify == true}
                        />
                        {newloading1 == true ? (
                          <button
                            className={
                              emailVerify !== true
                                ? "verifyButton"
                                : "verifyButtondisable"
                            }
                          >
                            <FiLoader className="loadingIcon" />
                          </button>
                        ) : (
                          <button
                            className={
                              emailVerify !== true
                                ? "verifyButton"
                                : "verifyButtondisable"
                            }
                            onClick={
                              emailVerify == true ? null : setVerifyHandler
                            }
                          >
                            Verify
                          </button>
                        )}
                      </div>
                      {registationdataerror.emailVerify && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Verify Email ID
                        </p>
                      )}
                      {registationdataerror.email && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Email ID
                        </p>
                      )}
                      {finalerror && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          User already registered with email address.
                        </p>
                      )}
                    </div>
                    <div className="width50">
                      <p className="label">Password</p>
                      <div className="passwordDiv">
                        <input
                          onChange={handlechange}
                          name="password"
                          className="input"
                          type={showPasswords ? "text" : "password"}
                          required
                          placeholder="Password"
                        />
                        {showPasswords ? (
                          <PiEyeLight
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPasswords(false)}
                          />
                        ) : (
                          <PiEyeSlashLight
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPasswords(true)}
                          />
                        )}
                      </div>
                      {registationdataerror.password && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Password
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mergeLabel">
                    <div className="width50">
                      <p className="label">Your current location</p>
                      <select
                        className="input border"
                        type="text"
                        placeholder="Select Country"
                        defaultValue={registationdata.location}
                        name="location"
                        onChange={handlechange}
                      >
                        <option value="">Select Country</option>
                        {country_and_states.country.length !== 0
                          ? country_and_states.country.map((item, index) => (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            ))
                          : null}
                      </select>
                      {registationdataerror.location && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Select Your current location
                        </p>
                      )}
                    </div>
                    <div className="width50">
                      <p className="label">Phone Number</p>
                      <div className="contentInput">
                        <select
                          className="selectBorder"
                          name="country"
                          defaultValue={registationdata.country}
                          onChange={handlechange}
                          // disabled={phoneVerify == false && emailVerify == false}
                        >
                          <option value="">Country</option>
                          {Countrycode.length !== 0
                            ? Countrycode.map((data, index) => (
                                <option
                                  value={data.dial_code}
                                  key={index}
                                  selected={
                                    registationdata.country == data.dial_code
                                  }
                                >
                                  {data.name} ({data.dial_code})
                                </option>
                              ))
                            : null}
                        </select>
                        <input
                          className="input inputBorder"
                          type="number"
                          placeholder="Your Phone no"
                          defaultValue={registationdata.phone}
                          name="phone"
                          onChange={handlechange}
                        />
                      </div>
                      {registationdataerror.country && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Select Country Code
                        </p>
                      )}
                      {registationdataerror.phone && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Phone number
                        </p>
                      )}
                      {registationdataerror.phoneVerify && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Verify Phone number
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="label">Key Skills</p>
                    <Select
                      className="marginTop15 cursor"
                      value={selectedOptionskill}
                      onChange={handleChangeNew}
                      options={skilloption}
                      isMulti
                    />
                    {registationdataerror.skill && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Select Key Skills
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="label">Upload CV</p>
                    <div
                      className="aadhaarFront flexColClass input border"
                      onClick={handleFileSelectClick}
                    >
                      <h3>
                        <TbCloudUpload />
                      </h3>
                      <h3>Click or drag file to this area to upload </h3>
                      <p style={{ textAlign: "center", width: "100%" }}>
                        Supports .pdf, .doc, .docx formats only.
                      </p>
                      <p title="">Max file size: 5MB </p>
                    </div>
                    <input
                      className=""
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      name="aadhaarfront"
                      accept=".pdf,.doc,.docx,.txt"
                      onChange={handleFileInputChange}
                    />
                    {uploaderror && (
                      <h6 className="text-red-500 text-xs font-semibold mt-2">
                        Please Upload CV
                      </h6>
                    )}
                    {uploadsuccess && (
                      <h6 className="text-green-500 text-xs font-semibold mt-2">
                        CV Uploaded Successfully
                      </h6>
                    )}
                    {uploadsuccess && (
                      <div
                        className="gradeCertificate"
                        onClick={() => {
                          window.open(`${cvfile}`, "_blank");
                        }}
                      >
                        <ImAttachment className="attachicon text-2xl" />
                        <div className="gradeCertificateDesc">
                          <p className="text-black">View CV</p>
                        </div>
                      </div>
                    )}

                    {cvuploaderror && (
                      <h6 className="text-red-500 text-xs font-semibold mt-2">
                        Image size exceeds 5 MB limit.
                      </h6>
                    )}
                  </div>
                  {newloading3 === false ? (
                    <button
                      className="input border bgc marginTop30"
                      onClick={submitHandler}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      id="Signup"
                      className="signUpCompBodyButtonLoading buttonWidth100 marginTop30"
                    >
                      <FiLoader className="loadingIcon" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {verify && (
        <div className="flex-center viewOverlay absolute1">
          <div className="verifyPopup">
            <div className="justify-conten-end cursor">
              <IoCloseOutline onClick={setVerifyHandler} />
            </div>
            <p className="fLogin">Enter OTP</p>
            <p className="fAcc">
              OTP has been sent to <span>{registationdata.email}</span>
            </p>

            {status && (
              <p className="successerror">
                The OTP has been successfully sent to your email address.
              </p>
            )}
            <div className="inputs marginTop15">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="input1 border"
                />
              ))}
            </div>
            {wrongotpcountemail !== 5 ? (
              errorstatus && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountemail}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}

            {wrongotpcountemail !== 5 ? (
              allInputsFilled ? (
                isLoading == false ? (
                  <button
                    className="mobVerifyButton"
                    onClick={emailverification}
                  >
                    Verify & Continue
                  </button>
                ) : (
                  <button className="mobVerifyButton">
                    <FiLoader className="loadingIcon" />
                  </button>
                )
              ) : (
                <button className="mobVerifyButtondisable">
                  Verify & Continue
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">
                Verify & Continue
              </button>
            )}

            <div className="resend">
              {isResendDisabled ? (
                <p>Resend OTP in {timer}s</p>
              ) : (
                <button
                  className="resendOTPBtn"
                  onClick={() => {
                    SendMail();
                  }}
                  disabled={isResending}
                >
                  {isResending ? (
                    <FiLoader className="loadingIcon" />
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {toastifystatus && <ToastContainer />}
    </>
  );
};

export default ProfileCompletion2;
