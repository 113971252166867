/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./AdminCandidateProfile.css";
import search from "../../../../assests/search.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { storeAction } from "../../../../Store/Store";
import moment from "moment/moment";
import axios from "axios";
import { FiLoader } from "react-icons/fi";
import { MdMarkEmailRead } from "react-icons/md";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutgoingMail } from "react-icons/md";

const AdminCandidateProfile = ({
  alldata,
  totaldata,
  loading,
  setalldata,
  setnewurl,
  newurl,
  setcandidatenext,
  setcandidateprevious,
  Getcandidate,
  count,
  setcount,
  setpagination_status,
  settotaldata,
  statussearch,
  statussearchvalue,
  setstatussearch,
  setloading,
  handlechange,
  setdatefilter,
  datefilter,
  setPTsearch,
  PTsearch,
  setCurrentPage,
  currentPage,
}) => {
  const customToastStyle = {
    background: "#14B8A6",
    color: "white",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    "--toastify-icon-color-success": "white",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const adminsearch = useSelector((store) => store.adminsearch);

  const [search_value, setsearch_value] = useState("");
  const [search_status, setsearch_status] = useState(false);
  const [coundata, setcoundata] = useState({
    Benched: 0,
    Hired: 0,
    Reserved: 0,
    Archived: 0,
    Onboarding: 0,
    Submitted: 0,
    Non_Vetted: 0,
  });
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");

  const [selectid, setselectid] = useState(null);
  const [select_id, setselect_id] = useState(null);
  const [toastifystatus, settoastifystatus] = useState(false);

  const viewbtn = (data) => {
    var newobj = {
      admin_id: userid,
      user_id: data.id,
      message: `${
        userdata[0].first_name.length !== 0
          ? userdata[0].first_name
          : "Super Admin"
      } is viewing the detailed profile information for candidate ${
        data.first_name
      }.`,
      date_time: moment().format(),
      activity: "View Candidate Profile",
    };
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/admincandidateview");
  };

  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      (profile.professional_details_info !== null &&
        profile.professional_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.project_details_info !== null &&
        profile.project_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.certificate_info !== null &&
        profile.certificate_info.length !== 0) ||
      profile.no_certificate == true
    )
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info !== null && profile.education_info.length !== 0)
      count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;
    return count;
  };

  const searchbtn = async () => {
    setPTsearch((prev) => ({
      ...prev,
      first_name: search_value,
    }));
    setsearch_status(true);
  };

  useEffect(() => {
    Getcount();
  }, []);

  const Getcount = async () => {
    const countdata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/status-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata.Benched !== undefined) {
      setcoundata({
        Benched: countdata.Benched,
        Hired: countdata.Hired,
        Reserved: countdata.Reserved,
        Onboarding: countdata.Onboarding,
        Submitted: countdata.Submitted,
        Non_Vetted: countdata.Non_Vetted,
      });
    }
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const fetchCandidates = async (payload = {}, method = "get") => {
    try {
      setloading(true);
      setpagination_status(false);

      const options = {
        method,
        url: newurl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        data: method === "post" ? payload : undefined,
      };

      const response = await axios(options).then((res) => res.data);

      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setpagination_status(response.count > 20);
        const sortedCandidates = [...response.results].sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );

        setalldata(sortedCandidates);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
      } else {
        setalldata([]);
        setcandidateprevious(null);
        setcandidatenext(null);
      }
    } finally {
      setloading(false);
    }
  };

  const GetnewCandidate = async () => {
    if (!newurl) return;

    if (adminsearch.length === 0) {
      if (!search_status) {
        return statussearch
          ? fetchCandidates({ status: statussearchvalue }, "post")
          : fetchCandidates();
      }
      return fetchCandidates({ first_name: search_value }, "post");
    }

    if (
      ["today", "yesterday", "last_week", "last_month"].includes(adminsearch)
    ) {
      return fetchCandidates({ date_format: adminsearch }, "post");
    }

    if (adminsearch === "Custom") {
      return fetchCandidates(
        { start_date: startdate, end_date: enddate },
        "post"
      );
    }

    const key = search_status
      ? "first_name"
      : statussearch
      ? "status"
      : "country";

    return fetchCandidates({ [key]: adminsearch }, "post");
  };

  const changeDateFilter = async (e) => {
    if (e.length !== 0) {
      setdatefilter(e);
      setpagination_status(false);
      setcount(0);
      dispatch(storeAction.adminsearchHander({ adminsearch: e }));
      if (
        e == "today" ||
        e == "yesterday" ||
        e == "last_week" ||
        e == "last_month"
      ) {
        setloading(true);
        const filterdata = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/onboarding/date-filters/`,
            { date_format: e },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
        if (filterdata.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          if (filterdata.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          setcount(filterdata.count);
          const sortedCandidates = [...filterdata.results].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setalldata(sortedCandidates);
          setloading(false);
          setcandidateprevious(filterdata.previous);
          setcandidatenext(filterdata.next);
          dispatch(storeAction.ptCandidateHander({ ptCandidate: filterdata }));
        } else {
          setcount(0);
          dispatch(storeAction.isPopUpHander());
          setalldata([]);
          setcandidateprevious(null);
          setcandidatenext(null);
          setloading(false);
          setpagination_status(false);
        }
      }
    } else {
      newdata();
    }
  };

  const newdata = async () => {
    setloading(true);
    setcount(0);
    const response = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/onboardedFaculties/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (response.results.length !== 0) {
      setloading(false);
      setcount(response.count);
      if (response.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      const allFaculties = response.results || [];
      const sortedCandidates = [...allFaculties].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setstatussearch(false);
      setcandidateprevious(response.previous);
      setcandidatenext(response.next);
      setalldata(sortedCandidates);
      settotaldata(sortedCandidates);
      dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
    } else {
      dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
      setloading(false);
      setcandidateprevious(null);
      setcandidatenext(null);
      setalldata([]);
      settotaldata([]);
    }
  };

  // const filterbtn = async () => {
  //   if (startdate && enddate) {
  //     const filterdata = await axios
  //       .post(
  //         `${process.env.REACT_APP_LOCAL_HOST_URL}/onboarding/date-filters/`,
  //         { start_date: startdate, end_date: enddate },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `JWT ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         return res.data;
  //       });
  //     if (filterdata.results.length !== 0) {
  //       dispatch(storeAction.isPopUpHander());
  //       if (filterdata.count > 20) {
  //         setpagination_status(true);
  //       } else {
  //         setpagination_status(false);
  //       }
  //       setcount(filterdata.count);
  //       const sortedCandidates = [...filterdata.results].sort((a, b) => {
  //         return new Date(b.updated_at) - new Date(a.updated_at);
  //       });
  //       setalldata(sortedCandidates);
  //       setloading(false);
  //       setcandidateprevious(filterdata.previous);
  //       setcandidatenext(filterdata.next);
  //       dispatch(storeAction.ptCandidateHander({ ptCandidate: filterdata }));
  //     } else {
  //       setcount(0);
  //       dispatch(storeAction.isPopUpHander());
  //       setalldata([]);
  //       setcandidateprevious(null);
  //       setcandidatenext(null);
  //       setloading(false);
  //       setpagination_status(false);
  //     }
  //   } else {
  //     alert("Please Select Star date & End date");
  //   }
  // };

  const getPaginationNumbers = () => {
    if (pageNumbers.length <= 1) return [];
    let pages = [];
    for (let i = 1; i <= pageNumbers.length; i++) {
      pages.push(i);
    }

    if (pages.length > 5) {
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(currentPage + 2, pageNumbers.length);
      const slicedPages = pages.slice(start - 1, end);

      return [
        ...(start > 1 ? [1, "..."] : []),
        ...slicedPages,
        ...(end < pageNumbers.length ? ["...", pageNumbers.length] : []),
      ];
    }
    return pages;
  };

  const handlePageChange = async (page) => {
    const hasValidValue = Object.values(PTsearch).some(
      (value) => value !== "" && value !== null && value !== undefined
    );
    if (hasValidValue == false) {
      setloading(true);
      if (page >= 1 && page <= pageNumbers.length) {
        setCurrentPage(page);
        dispatch(storeAction.currentPageHander({ currentPage: page }));
      }
      const response = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/onboardedFaculties/?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (response.results.length !== 0) {
        setloading(false);
        setcount(response.count);
        setalldata(response.results);
        dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
        settotaldata(response.results);
      } else {
        setloading(false);
        setcount(0);
        setalldata([]);
        settotaldata([]);
        dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
      }
    } else {
      setloading(true);
      if (page >= 1 && page <= pageNumbers.length) {
        setCurrentPage(page);
        dispatch(storeAction.currentPageHander({ currentPage: page }));
      }
      const isCustomDateValid =
        PTsearch.date_format === "Custom" &&
        PTsearch.start_date &&
        PTsearch.end_date;
      if (PTsearch.date_format !== "Custom" || isCustomDateValid) {
        const obj = {
          status: PTsearch.status,
          country: PTsearch.country,
          first_name: PTsearch.first_name,
          date_format:
            PTsearch.date_format === "Custom" ? "" : PTsearch.date_format,
          start_date:
            PTsearch.date_format !== "Custom" ? "" : PTsearch.start_date,
          end_date: PTsearch.date_format !== "Custom" ? "" : PTsearch.end_date,
        };

        setcount(0);
        setloading(true);
        setpagination_status(false);

        try {
          const response = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/proflile-tracker/bulk-search/?page=${page}`,
              obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (response.results.length !== 0) {
            setloading(false);
            setcount(response.count);
            setalldata(response.results);
            settotaldata(response.results);
            dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
          } else {
            setloading(false);
            setcount(0);
            setalldata([]);
            settotaldata([]);
            dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
          }
        } catch (error) {
          console.error("Error fetching candidates:", error);
        } finally {
          setloading(false);
        }
      }
    }
  };

  const acceptbtn1 = async (data) => {
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
    
        <p>Thank you for registering with <strong>Hirein5</strong>! To get started, please complete your application.</p>
    
        <p>Kindly ensure that you fill out all fields in your application accurately.</p>
    
        <p>Click below to access your account:</p>
    
        <p>
          <a href="https://app.hirein5.com/#/login" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Log in to Your Account
          </a>
        </p>
    
        <p>
          If you're new to Hirein5, please 
          <a href="https://app.hirein5.com/#/candidateregister" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Sign Up Here
          </a>.
        </p>
    
        <p>
          If you have any questions, feel free to contact us at 
          <a href="mailto:candidatesupport@hirein5.com" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             candidatesupport@hirein5.com
          </a>.
        </p>
    
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
    
        <p><strong>Best regards,</strong><br>Team Hirein5</p>
      `,
    };

    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };

    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };

  const CheckUser = async (profile) => {
    let missingFields = [];
    setselect_id(profile.id);
    if (profile.address === null) missingFields.push("Address");
    if (profile.work_preference_info === null)
      missingFields.push("Work Preference");
    if (
      (profile.professional_details_info === null ||
        profile.professional_details_info.length === 0) &&
      !profile.fresher &&
      !profile.freshers_status
    )
      missingFields.push("Professional Details");

    if (
      (profile.project_details_info === null ||
        profile.project_details_info.length === 0) &&
      !profile.fresher &&
      !profile.freshers_status
    )
      missingFields.push("Project Details");

    if (
      (profile.certificate_info === null ||
        profile.certificate_info.length === 0) &&
      !profile.no_certificate
    )
      missingFields.push("Certifications");

    if (profile.travel_info === null) missingFields.push("Work Authorization");
    if (profile.education_info === null || profile.education_info.length === 0)
      missingFields.push("Education Details");
    if (profile.video_resume === null || profile.video_resume.length === 0)
      missingFields.push("Video Resume");

    let pendingItemsList = missingFields
      .map((item) => `<li style="margin-bottom: 8px;">${item}</li>`)
      .join("");

    // Generate Subject Without Mentioning Specific Fields
    let subject;
    if (missingFields.length === 0) {
      subject = `Great! Your Hirein5 Profile is Complete 🎉`;
    } else {
      subject = `Boost Your Chances of Getting Hired – Update Your Profile Now!`;
    }

    var emailobj = {
      email: profile.username,
      from_email: "connect@hirein5.com",
      subject: subject,
      message: `
      <div style="font-family: Arial, sans-serif; padding: 30px; max-width: 650px; background-color: #f9f9f9; border-radius: 10px; margin: auto; border: 1px solid #ddd;">
          <h2 style="color: #333; text-align: center; margin-bottom: 20px;">Complete Your Hirein5 Profile</h2>
          
          <p style="margin-bottom: 20px;">Dear ${
            profile.first_name || "User"
          },</p>
          
          <p style="line-height: 1.6; margin-bottom: 20px;">
            We noticed that some details in your Hirein5 profile are incomplete. 
            Completing your profile increases your chances of getting hired faster.
          </p>
          
          <div style="background: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0px 2px 5px rgba(0,0,0,0.1); margin-bottom: 20px;">
            <p style="font-weight: bold; margin-bottom: 10px;">Pending Sections:</p>
            <ul style="padding-left: 20px; margin-top: 10px; margin-bottom: 10px;">
                ${pendingItemsList}
            </ul>
          </div>
          
          <p style="margin-bottom: 20px;">
              <a href="https://app.hirein5.com/#/login" 
                 style="background-color: #007bff; color: #ffffff; padding: 12px 25px; text-decoration: none; 
                 border-radius: 5px; display: inline-block; font-weight: bold;">
                 Update Your Profile
              </a>
          </p>
          
          <p style="line-height: 1.6; margin-bottom: 20px;">
            If you need any assistance, feel free to contact us at 
            <a href="mailto:candidatesupport@hirein5.com" style="color: #007bff; text-decoration: none;">candidatesupport@hirein5.com</a>.
          </p>
          
          <p style="font-weight: bold; margin-top: 20px;">Best regards,</p>
          <p style="font-weight: bold;">Team Hirein5</p>
      </div>
  `,
    };

    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setselect_id(null);
  };

  // const ChangeProfile = async (profile) => {
  //   if (profile.length !== 0) {
  //     const response = await axios
  //       .get(
  //         `${process.env.REACT_APP_LOCAL_HOST_URL}/onboardedFaculties/${profile}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `JWT ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         return res.data;
  //       });
  //   }
  // };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  return (
    <div>
      <div className="AdminClientProfileComp">
        <div className="flex justify-between gap-4 mb-5">
          <div className="card div-card">
            <h3>Onboarding</h3>
            <p>{coundata.Onboarding}</p>
          </div>
          <div className="card div-card">
            <h3>Submitted</h3>
            <p>{coundata.Submitted}</p>
          </div>
          <div className="card div-card">
            <h3>Available For Hire</h3>
            <p>{coundata.Benched}</p>
          </div>
          <div className="card div-card">
            <h3>Hired</h3>
            <p>{coundata.Hired}</p>
          </div>
          <div className="card div-card">
            <h3>Reserved</h3>
            <p>{coundata.Reserved}</p>
          </div>
          <div className="card div-card">
            <h3>Pre Approve Candidate</h3>
            <p>{coundata.Non_Vetted}</p>
          </div>
        </div>
        <div className="flex gap-4 w-full items-center">
          <div className="AdminClientProfileCompSearch w-full">
            <input
              type="text"
              placeholder="Search..."
              onChange={(e) => setsearch_value(e.target.value)}
              id="seachinput"
            />
            <img src={search} alt="" />
            <div className="flex gap-4 divbutton">
              <button className="savesearch" onClick={searchbtn}>
                Search
              </button>
              {search_status && (
                <button
                  className="cancelsearch"
                  onClick={() => {
                    setsearch_status(false);
                    setalldata(totaldata);
                    document.getElementById("seachinput").value = "";
                    setpagination_status(true);
                    setnewurl(null);
                    setcandidateprevious(null);
                    setcandidatenext(null);
                    Getcandidate();
                    dispatch(
                      storeAction.adminsearchHander({ adminsearch: "" })
                    );
                    setPTsearch((prev) => ({
                      ...prev,
                      status: "",
                      country: "",
                      first_name: "",
                      date_format: "",
                      start_date: "",
                      end_date: "",
                    }));
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <select
            className="border border-[#dbdbdb] rounded py-2 px-3 w-[20%]"
            // onChange={(e) => {
            //   changeDateFilter(e.target.value);
            // }}
            name="date_format"
            onChange={handlechange}
            id="datefilterid"
          >
            <option value="">Filter</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_week">Last 7 Days</option>
            <option value="last_month">Last Month</option>
            <option value="Custom">Custom</option>
          </select>
          {datefilter == "Custom" && (
            <>
              <input
                type="date"
                className="border border-[#dbdbdb] rounded py-2 px-3"
                // onChange={(e) => {
                //   setstartdate(e.target.value);
                // }}
                onChange={handlechange}
                name="start_date"
              />
              <input
                type="date"
                className="border border-[#dbdbdb] rounded py-2 px-3"
                // onChange={(e) => {
                //   setenddate(e.target.value);
                // }}
                onChange={handlechange}
                name="end_date"
              />
              {/* <button className="savesearchfilter" onClick={filterbtn}>
                Filter
              </button> */}
            </>
          )}
          {datefilter !== null && (
            <button
              className="cancelsearch"
              onClick={() => {
                setdatefilter(null);
                dispatch(storeAction.adminsearchHander({ adminsearch: "" }));
                document.getElementById("datefilterid").value = "";
                newdata();
                setPTsearch((prev) => ({
                  ...prev,
                  status: "",
                  country: "",
                  first_name: "",
                  date_format: "",
                  start_date: "",
                  end_date: "",
                }));
              }}
            >
              Cancel
            </button>
          )}
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>NAME</th>
                <th>LOCATION</th>
                <th>LAST LOGIN</th>
                <th>STATUS</th>
                <th className="flex gap-2 items-center">
                  PROFILE %{" "}
                  {/* <select
                    className="border rounded p-2 mt-2 border-gray-600"
                    onChange={(e) => {
                      ChangeProfile(e.target.value);
                    }}
                  >
                    <option value="">Select </option>
                    <option value="ASC">Ascending </option>
                    <option value="DESC">Descending </option>
                  </select> */}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7}>
                    <div className="flex justify-center py-5">
                      <FiLoader className="loadingIconHAT" />
                    </div>
                  </td>
                </tr>
              ) : alldata.length !== 0 ? (
                alldata.map((data, index) => {
                  const profileCompletion = calculateProfileCompletion(data);
                  const percent = Math.round((profileCompletion / 8) * 100);
                  return (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}
                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        <div className="tableLocation">
                          {data.new_candidate_info !== null ? (
                            <h1>{data.new_candidate_info.location}</h1>
                          ) : (
                            <h1>-</h1>
                          )}
                        </div>
                      </td>
                      <td>
                        {data.last_login !== null &&
                        data.last_login.length !== 0 ? (
                          <h1>
                            {moment(data.last_login).format(
                              "Do MMM YY - hh:mm A"
                            )}
                          </h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.dissabled == false ? (
                          data.status === "Reserved" ? (
                            new Date(moment().format("YYYY-MM-DD")) <=
                            new Date(
                              moment(
                                new Date(
                                  moment(data.block_expiry).format("YYYY-MM-DD")
                                )
                              ).format("YYYY-MM-DD")
                            ) ? (
                              <p className="status reserving">{data.status}</p>
                            ) : data.apprual === true ? (
                              <p className="processingstaus status">
                                Available For Hire
                              </p>
                            ) : (
                              <p className="processingstaus status">
                                {data.status}
                              </p>
                            )
                          ) : data.status === "Reject" ? (
                            <p className="status rejectstaus">{data.status}</p>
                          ) : data.status === "Hired" ? (
                            <p className="status hiringActive">{data.status}</p>
                          ) : (data.status === "Success" ||
                              data.status === "Mail send" ||
                              data.status === "Benched") &&
                            (data.nottify == true || data.nottify == false) &&
                            data.apprual == true ? (
                            <p className="processingstaus status">
                              Available For Hire
                            </p>
                          ) : (data.status === "Success" ||
                              data.status === "Mail send" ||
                              data.status === "Benched") &&
                            data.nottify === true &&
                            data.apprual === false &&
                            percent === 100 ? (
                            data.non_vetted == true ? (
                              <p className="status hiringActive">
                                Pre Approved
                              </p>
                            ) : (
                              <p className="status hiringActive">Submitted</p>
                            )
                          ) : data.nottify !== true ? (
                            <p className="status contracted">Onboarding</p>
                          ) : data.status == "Benched" ||
                            (data.status == "Success" &&
                              data.nottify == true &&
                              data.apprual == false) ? (
                            <p className="status hiringActive">Submitted</p>
                          ) : null
                        ) : (
                          <p className="status rejectstaus">Disabled</p>
                        )}
                      </td>
                      <td>
                        <h1>{percent}%</h1>
                      </td>
                      <td className="flex gap-4">
                        <button
                          onClick={() => viewbtn(data)}
                          className="viewButton"
                        >
                          View
                        </button>
                        {selectid == data.id ? (
                          <button className="accept_btn">
                            <FiLoader className="loadingIcon" />
                          </button>
                        ) : (
                          <button
                            className="accept_btn"
                            onClick={() => {
                              acceptbtn1(data);
                            }}
                          >
                            <MdMarkEmailRead />
                          </button>
                        )}
                        {percent !== 100 ? (
                          select_id == data.id ? (
                            <button className="Send_btn">
                              <FiLoader className="loadingIcon" />
                            </button>
                          ) : (
                            <button
                              className="Send_btn"
                              onClick={() => {
                                CheckUser(data);
                              }}
                            >
                              <MdOutgoingMail />
                            </button>
                          )
                        ) : (
                          <button className="Send_btn_disable">
                            <MdOutgoingMail />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">No data found...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* {nPages > 1 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null} */}
        <div className="flex justify-center items-center py-5">
          <button
            className={`mx-1 px-4 py-2 rounded-lg ${
              currentPage === 1
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-800"
            }`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Prev
          </button>

          {getPaginationNumbers().map((page, index) => (
            <button
              key={index}
              className={`mx-1 px-4 py-2 rounded-lg ${
                currentPage === page
                  ? "bg-blue-700 text-white"
                  : "bg-gray-300 text-black hover:bg-gray-400"
              }`}
              onClick={() => {
                if (typeof page === "number") handlePageChange(page);
              }}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}

          <button
            className={`mx-1 px-4 py-2 rounded-lg ${
              currentPage === pageNumbers.length
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-800"
            }`}
            disabled={currentPage === pageNumbers.length}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>

        {/* {pagination_status && (
          <div className="tablePagination">
            <nav>
              <ul className="pagination">
                {candidateprevious == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidateprevious);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                )}

                {newurl == null ? (
                  <h6 className="page-link">1</h6>
                ) : search_status === false ? (
                  statussearch == true ? (
                    <h6 className="page-link active">
                      {newurl.includes("/status/") &&
                      newurl.split("/status/")[1]?.split("?page=")[1] !==
                        undefined
                        ? newurl.split("/status/")[1].split("?page=")[1]
                        : newurl.includes("/onboarding/date-filters/") &&
                          newurl
                            .split("/onboarding/date-filters/")[1]
                            ?.split("?page=")[1] !== undefined
                        ? newurl
                            .split("/onboarding/date-filters/")[1]
                            .split("?page=")[1]
                        : newurl.includes("/country-filter/") &&
                          newurl
                            .split("/country-filter/")[1]
                            ?.split("?page=")[1] !== undefined
                        ? newurl.split("/country-filter/")[1].split("?page=")[1]
                        : 1}
                    </h6>
                  ) : (
                    <h6 className="page-link active">
                      {newurl.includes("/onboardedFaculties/") &&
                      newurl
                        .split("/onboardedFaculties/")[1]
                        ?.split("?page=")[1] !== undefined
                        ? newurl
                            .split("/onboardedFaculties/")[1]
                            .split("?page=")[1]
                        : 1}
                    </h6>
                  )
                ) : (
                  <h6 className="page-link active">
                    {newurl.includes("/profile-tracker/search/") &&
                    newurl
                      .split("/profile-tracker/search/")[1]
                      ?.split("?page=")[1] !== undefined
                      ? newurl
                          .split("/profile-tracker/search/")[1]
                          .split("?page=")[1]
                      : 1}
                  </h6>
                )}

                {candidatenext == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidatenext);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                )}
              </ul>
            </nav>
            <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
              Total No Of Pages : {pageNumbers.length}
            </p>
          </div>
        )} */}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default AdminCandidateProfile;
