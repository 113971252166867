/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bulkemail = () => {
  const token = useSelector((store) => store.token);
  const editorRef = useRef(null);
  const [content, setcontent] = useState("");
  const [skilloption, setskilloption] = useState([]);
  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [allcandidate, setallcandidate] = useState([]);
  const [allclient, setallclient] = useState([]);
  const [type, settype] = useState(null);
  const [subject, setsubject] = useState("");
  const [loading, setloading] = useState(false);
  const [emaillist, setemaillist] = useState([]);
  const [toastestatus, settoastestatus] = useState(false);

  const config = {
    toolbar: true, // Show the toolbar
    toolbarAdaptive: false, // Adaptive toolbar based on window width
    toolbarSticky: true, // Stick the toolbar to the top of the editor
    toolbarButtonSize: "middle", // Size of toolbar buttons: 'small', 'middle', 'big'
    toolbarButtonIcons: true, // Show icons on toolbar buttons
    toolbarButtonText: false, // Show text on toolbar buttons
    theme: "default", // Editor theme: 'default', 'dark'
    language: "en", // Editor language
    spellcheck: true, // Enable spellchecking
    showCharsCounter: true, // Show character counter
    showWordsCounter: true, // Show word counter
    hidePlaceholderOnFocus: false, // Hide the placeholder when the editor gains focus
    showXPathInStatusbar: true, // Show the XPath of the selected element in the status bar
    allowScript: true, // Allow <script> tags in the editor content
    sanitize: false, // Sanitize the editor content to remove potentially unsafe elements and attributes
    enableDragAndDropFileToEditor: true, // Enable dragging and dropping files into the editor to insert them
    enableResizeEditor: true, // Enable resizing of the editor
    enableSearchReplace: true, // Enable search and replace functionality
    enableCodeMirror: true, // Enable CodeMirror syntax highlighting for HTML source mode
  };
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const submitbtn = async () => {
    if (subject.length == 0) {
      alert("Please enter subject");
    } else if (content.length == 0) {
      alert("Please enter Body");
    } else if (type == null) {
      alert("Please select Email type");
    } else {
      if (type == "all") {
        if (skilloption.length !== 0) {
          setloading(true);
          for (var a = 0; a < skilloption.length; a++) {
            var emailobj = {
              email: skilloption[a].value,
              subject: subject,
              message: content,
              from_email: "connect@hirein5.com",
            };
            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
                emailobj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          }
          setloading(false);
          settoastestatus(true);
          toast.success("Email Sent Successfully!!", {
            autoClose: 2000,
            transition: Slide,
            style: customToastStyle,
          });
          setTimeout(() => {
            settoastestatus(false);
            window.location.reload();
          }, 4000);
        }
      } else if (type == "Candidates" || type == "Clients") {
        if (skilloption.length !== 0) {
          setloading(true);
          for (var b = 0; b < skilloption.length; b++) {
            var emailobj1 = {
              email: skilloption[b].value,
              subject: subject,
              message: content,
              from_email: "connect@hirein5.com",
            };
            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
                emailobj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          }
          setloading(false);
          settoastestatus(true);
          toast.success("Email Sent Successfully!!", {
            autoClose: 2000,
            transition: Slide,
            style: customToastStyle,
          });
          setTimeout(() => {
            settoastestatus(false);
            window.location.reload();
          }, 4000);
        }
      } else if (type == "user") {
        if (selectedOptionskill !== null && selectedOptionskill.length !== 0) {
          setloading(true);
          for (var b = 0; b < selectedOptionskill.length; b++) {
            var emailobj1 = {
              email: selectedOptionskill[b].value,
              subject: subject,
              message: content,
              from_email: "connect@hirein5.com",
            };
            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
                emailobj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          }
          setloading(false);
          settoastestatus(true);
          toast.success("Email Sent Successfully!!", {
            autoClose: 2000,
            transition: Slide,
            style: customToastStyle,
          });
          setTimeout(() => {
            settoastestatus(false);
            window.location.reload();
          }, 4000);
        }
      } else if (type == "File") {
        if (emaillist.length !== 0) {
          setloading(true);
          for (var c = 0; c < emaillist.length; c++) {
            var emailobj2 = {
              email: emaillist[c],
              subject: subject,
              message: content,
              from_email: "connect@hirein5.com",
            };
            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
                emailobj2,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          }
          settoastestatus(true);
          setloading(false);
          setemaillist([]);
          toast.success("Email Sent Successfully!!", {
            autoClose: 2000,
            transition: Slide,
            style: customToastStyle,
          });
          setTimeout(() => {
            settoastestatus(false);
            window.location.reload();
          }, 4000);
        } else {
          alert("Please Upload File....");
        }
      }
    }
  };
  useEffect(() => {
    Getalluser();
  }, []);
  const Getalluser = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    const allfacility = response.data;
    if (allfacility.faculties.length !== 0) {
      setallcandidate(allfacility.faculties);
    }
    const response1 = await axios.get(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    const allfacility1 = response1.data;
    if (allfacility1.companies.length !== 0) {
      setallclient(allfacility1.companies);
    }
  };
  useEffect(() => {
    Getemail();
  }, [type]);
  const Getemail = async () => {
    if (type == "user" || type == "all") {
      var filter = [];
      if (allcandidate.length !== 0) {
        for (var i = 0; i < allcandidate.length; i++) {
          filter.push({
            value: allcandidate[i].email,
            label: allcandidate[i].email,
          });
        }
      }
      if (allclient.length !== 0) {
        for (var j = 0; j < allclient.length; j++) {
          filter.push({
            value: allclient[j].email,
            label: allclient[j].email,
          });
        }
      }
      setskilloption(filter);
    } else if (type == "Candidates") {
      var filter1 = [];
      if (allcandidate.length !== 0) {
        for (var i = 0; i < allcandidate.length; i++) {
          filter1.push({
            value: allcandidate[i].email,
            label: allcandidate[i].email,
          });
        }
      }
      setskilloption(filter1);
    } else if (type == "Clients") {
      var filter2 = [];
      if (allclient.length !== 0) {
        for (var j = 0; j < allclient.length; j++) {
          filter2.push({
            value: allclient[j].email,
            label: allclient[j].email,
          });
        }
      }
      setskilloption(filter2);
    }
  };
  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const extractedEmails = jsonSheet
        .flat()
        .filter((cell) => typeof cell === "string" && cell.includes("@"));
      setemaillist(extractedEmails);
      fileInputRef.current.value = "";
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        submitbtn();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div className="dashBoardMain paddingLeft100 paddingRight100">
        <DashHead
          head="Bulk Email Option"
          desc="The Bulk Email Option enables efficient mass communication by allowing users to send customized, scheduled, and trackable emails to large groups of recipients."
          highLight=""
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="companyDetails2 h-full">
          <h3>Subject</h3>
          <input
            className="profileselect"
            onChange={(e) => {
              setsubject(e.target.value);
            }}
          />
        </div>
        <div className="companyDetails2 h-full">
          <h3>Body</h3>
          <JoditEditor
            ref={editorRef}
            value={content}
            config={config}
            className="h-[50rem]"
            tabIndex={1}
            onBlur={(newContent) => setcontent(newContent)}
          />
        </div>
        <div className="companyDetails2 h-full">
          <h3>Select Email</h3>
        </div>
        <div>
          <div className="flex flex-start gap-4 mb-5">
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="all"
                name="fav_language"
                value="All"
                onChange={() => {
                  settype("all");
                }}
              />
              <label
                for="all"
                onClick={() => {
                  settype("all");
                }}
              >
                All Users
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="candidate"
                name="fav_language"
                value="Candidates"
                onChange={() => {
                  settype("Candidates");
                }}
              />
              <label
                for="candidate"
                onClick={() => {
                  settype("Candidates");
                }}
              >
                Candidates
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="client"
                name="fav_language"
                value="Clients"
                onChange={() => {
                  settype("Clients");
                }}
              />
              <label
                for="client"
                onClick={() => {
                  settype("Clients");
                }}
              >
                Clients
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="select"
                name="fav_language"
                value="Select User"
                onChange={() => {
                  settype("user");
                }}
              />
              <label
                for="select"
                onClick={() => {
                  settype("user");
                }}
              >
                Select Email
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="File"
                name="fav_language"
                value="File"
                onChange={() => {
                  settype("File");
                }}
              />
              <label
                for="File"
                onClick={() => {
                  settype("File");
                }}
              >
                Upload File
              </label>
            </div>
          </div>
          {type == "user" && (
            <Select
              defaultValue={selectedOptionskill}
              onChange={setSelectedOptionskill}
              options={skilloption}
              isMulti
            />
          )}
          {type == "File" && (
            <input
              className="profileselect"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
          )}
        </div>
        <div className="registerBottom mt-5">
          {loading == true ? (
            <button className="nextbtn">Please Wait...</button>
          ) : (
            <button className="nextbtn" onClick={submitbtn}>
              Submit
            </button>
          )}
        </div>
      </div>
      {toastestatus && <ToastContainer />}
    </div>
  );
};

export default Bulkemail;
