/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import "./WorkExperience.css";
import user from "../../../../assests/User.svg";
import dropDown from "../../../../assests/arrowDown.svg";
import edit from "../../../../assests/edit.svg";
import dropUp from "../../../../assests/arrowUp.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import countryimg1 from "../../../../assests/Vector.png";
import Select from "react-select";
import Skilllist from "../../../../assests/skillsJSON.json";
import axios from "axios";
import { FiLoader } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoAlertCircle } from "react-icons/io5";
import { FaCircleInfo } from "react-icons/fa6";
import country_and_states from "../../../../assests/country-states-register";

const WorkExperience = () => {
  const userdata = useSelector((store) => store.userdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const dispatch = useDispatch();
  const [isArrow, setIsArrow] = useState(false);
  const dropDownhandler = () => {
    setIsArrow(!isArrow);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overLayHandler = () => {
    dispatch(storeAction.isPopUpHander("Experience"));
  };
  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [Experiencedata, setExperiencedata] = useState({
    website_url: " ",
    hackerrank_url: " ",
    github_url: " ",
    preferred_mode_of_engagement: " ",
    current_employment_status: " ",
    preference1: " ",
    preference2: " ",
    preference3: " ",
    location1: " ",
    location2: " ",
    location3: " ",
    preffered_work_timings: " ",
    linkedin: " ",
    year_of_experience: "",
  });
  const [errormsg, seterrormsg] = useState({
    qualification: false,
    skills: false,
    length: false,
    method_of_work: false,
    preferred_work_location: false,
  });

  const [loading, setloading] = useState(false);
  function exitOverlayHandler(params) {
    dispatch(storeAction.isPopUpHander());
  }
  useEffect(() => {
    Getskill();
  }, [Skilllist]);

  const Getskill = async () => {
    var skillarrray = Skilllist;
    const uniqueSkills = Array.from(
      new Set(skillarrray.map((skill) => skill.Skill))
    );
    if (uniqueSkills.length !== 0) {
      var filter = [];
      for (var i = 0; i < uniqueSkills.length; i++) {
        filter.push({
          value: uniqueSkills[i],
          label: uniqueSkills[i],
        });
      }
      setskilloption(filter);
    }
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setExperiencedata((values) => ({ ...values, [name]: value }));
    seterrormsg((values) => ({ ...values, [name]: false }));
  };

  const savebtn = async () => {
    seterrormsg({
      qualification: false,
      skills: false,
      length: false,
      method_of_work: false,
      preferred_work_location: false,
    });

    let values_Array = selectedOptionskill
      ? selectedOptionskill.map((skill) => skill.value)
      : [];
    let arrayOfStrings = row.map((obj) => `${obj.languages}: ${obj.level}`);

    let methodOfWork = [
      Experiencedata.preference1 || "",
      Experiencedata.preference2 || "",
      Experiencedata.preference3 || "",
    ].filter(Boolean); // Remove empty strings

    let preferredWorkLocation = [
      Experiencedata.location1 || "",
      Experiencedata.location2 || "",
      Experiencedata.location3 || "",
    ].filter(Boolean); // Remove empty strings

    // Validation checks
    if (Experiencedata.qualification.length === 0) {
      seterrormsg((prev) => ({ ...prev, qualification: true }));
    } else if (values_Array.length === 0) {
      seterrormsg((prev) => ({ ...prev, skills: true }));
    } else if (values_Array.length < 5) {
      seterrormsg((prev) => ({ ...prev, length: true }));
    } else if (methodOfWork.length === 0) {
      seterrormsg((prev) => ({ ...prev, method_of_work: true }));
    } else if (preferredWorkLocation.length === 0) {
      seterrormsg((prev) => ({ ...prev, preferred_work_location: true }));
    } else {
      seterrormsg({
        qualification: false,
        skills: false,
        length: false,
        method_of_work: false,
        preferred_work_location: false,
      });

      setloading(true);

      let newobj = {
        username: userdata[0].username,
        work_preference_info: {
          key_skills: values_Array,
          current_employment_status:
            Experiencedata.current_employment_status || "",
          preferred_mode_of_engagement:
            Experiencedata.preferred_mode_of_engagement || "",
          website_url: Experiencedata.website_url || "",
          hackerrank_url: Experiencedata.hackerrank_url || "",
          github_url: Experiencedata.github_url || "",
          preffered_work_timings: Experiencedata.preffered_work_timings || "",
          method_of_work: methodOfWork, // Ensuring at least one is selected
          preffered_work_location: preferredWorkLocation, // Ensuring at least one is selected
        },
        preference_info: {
          language: arrayOfStrings,
          skills: values_Array,
          linkedin: Experiencedata.linkedin || "",
          qualification: Experiencedata.qualification || "",
          hackerrank: Experiencedata.hackerrank_url || "",
          github: Experiencedata.github_url || "",
          personal_website: Experiencedata.personal_website || "",
          year_of_experience: Experiencedata.year_of_experience || "",
        },
      };

      let updatedata = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
          newobj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response);

      if (
        updatedata.message === "User and Associated Info updated successfully"
      ) {
        let updatedObject = {
          ...userdata[0],
          work_preference_info: updatedata.user.work_preference_info,
          preference_info: updatedata.user.preference_info,
        };

        dispatch(storeAction.userdataHander({ userdata: [] }));
        setTimeout(() => {
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        }, 10);
        getUserinfo();
        dispatch(storeAction.isPopUpHander());
        setloading(false);
      } else {
        setloading(false);
      }
    }
  };

  const inputref1 = useRef("");
  const [row, setrow] = useState([{ languages: "", level: "" }]);
  const addcount = () => {
    var newobj = {
      languages: "",
      level: "",
    };
    setrow((prevState) => [...prevState, newobj]);
  };
  function filterdata(event, index) {
    row[index]["level"] = event;
    setrow([...row]);
  }
  const get_value = (e, index) => {
    row[index]["languages"] = e;
    setrow([...row]);
  };
  useEffect(() => {
    getUserinfo();
  }, [userdata]);

  const getUserinfo = async () => {
    if (!userdata || userdata.length === 0 || !userdata[0]) {
      return;
    }

    const workPref = userdata[0]?.work_preference_info;
    const travelInfo = userdata[0]?.travel_info;
    const prefInfo = userdata[0]?.preference_info;
    if (workPref) {
      setExperiencedata({
        website_url: workPref.website_url || "",
        year_of_experience: prefInfo.year_of_experience || "",
        hackerrank_url: workPref.hackerrank_url || "",
        github_url: workPref.github_url || "",
        preferred_mode_of_engagement:
          workPref.preferred_mode_of_engagement || "",
        current_employment_status: workPref.current_employment_status || "",
        preference1: workPref.method_of_work?.[0] || "",
        preference2: workPref.method_of_work?.[1] || "",
        preference3: workPref.method_of_work?.[2] || "",
        location1: workPref.preffered_work_location?.[0] || "",
        location2: workPref.preffered_work_location?.[1] || "",
        location3: workPref.preffered_work_location?.[2] || "",
        preffered_work_timings: workPref.preffered_work_timings || "",
        linkedin:
          prefInfo?.linkedin || userdata[0].new_candidate_info.linkedin || "",
        qualification: prefInfo?.qualification || "",
      });
    } else if (prefInfo) {
      setExperiencedata({
        website_url: prefInfo.personal_website || "",
        year_of_experience: prefInfo.year_of_experience || "",
        github_url: prefInfo.github || "",
        linkedin:
          prefInfo.linkedin || userdata[0].new_candidate_info.linkedin || "",
        preferred_mode_of_engagement: " ",
        current_employment_status: " ",
        preference1: " ",
        preference2: " ",
        preference3: " ",
        location1: travelInfo?.prefered_countries?.[0] || " ",
        location2: travelInfo?.prefered_countries?.[1] || " ",
        location3: travelInfo?.prefered_countries?.[2] || " ",
        preffered_work_timings: " ",
        qualification: prefInfo?.qualification || "",
      });
    } else {
      setExperiencedata({
        linkedin: userdata[0].new_candidate_info.linkedin || "",
        website_url: " ",
        hackerrank_url: " ",
        github_url: " ",
        preferred_mode_of_engagement: " ",
        current_employment_status: " ",
        preference1: " ",
        preference2: " ",
        preference3: " ",
        location1: " ",
        location2: " ",
        location3: " ",
        preffered_work_timings: " ",
        qualification: "",
        year_of_experience: "",
      });
    }

    if (prefInfo?.language?.length > 0) {
      const newfilter = prefInfo.language.map((lang) => {
        const [language, level] = lang.split(":").map((s) => s.trim());
        return { languages: language, level: level };
      });
      setrow(newfilter);
    }
    if (prefInfo?.skills?.length > 0) {
      const filter = prefInfo.skills.map((skill) => ({
        value: skill,
        label: skill,
      }));
      setSelectedOptionskill(filter);
    } else {
      if (
        userdata.length !== 0 &&
        userdata[0].work_preference_info !== null &&
        userdata[0].work_preference_info.key_skills.length !== 0
      ) {
        const filter = userdata[0].work_preference_info.key_skills.map(
          (skill) => ({
            value: skill,
            label: skill,
          })
        );
        setSelectedOptionskill(filter);
      } else if (
        userdata.length !== 0 &&
        userdata[0].new_candidate_info.skills.length !== 0
      ) {
        const filter = userdata[0].new_candidate_info.skills.map((skill) => ({
          value: skill,
          label: skill,
        }));
        setSelectedOptionskill(filter);
      }
    }
  };

  const [isComplete, setIsComplete] = useState(false);
  const completeHandler = () => {
    setIsComplete(true);
  };
  const completeHandlerLeave = () => {
    setIsComplete(false);
  };

  const Tooltip = ({ text, children }) => {
    return (
      <div className="relative group flex">
        {children}
        <span className="absolute bottom-full mb-2 hidden w-[600px] break-words whitespace-normal rounded bg-black text-white text-xs px-2 py-1 group-hover:block">
          {text}
        </span>
      </div>
    );
  };

  const handleChangeNew = (selectedOptions) => {
    // Limit selection to 8 skills
    if (selectedOptions.length > 8) return;

    setSelectedOptionskill(selectedOptions);
  };
  return (
    <div>
      <div className="workExperience">
        <div className="innerWorkExperience">
          <div
            className={isArrow === true ? "workExperienceHead" : "bottomBorder"}
            bottomBorder
          >
            <div className="workExperienceHeadLeft">
              <img src={user} alt="" />
              <h1>Work Preference</h1>
            </div>
            <div className="workExperienceHeadLeftIcon">
              {userdata.length !== 0 ? (
                userdata[0].work_preference_info !== null ? (
                  <IoIosCheckmarkCircle className="profileCompleteIcon" />
                ) : (
                  <>
                    <IoAlertCircle
                      onMouseEnter={completeHandler}
                      onMouseLeave={completeHandlerLeave}
                      className="warningIcon"
                    />
                    {isComplete == true && (
                      <div className="InCompleteIndication">
                        <p>Complete Work Preference</p>
                      </div>
                    )}
                  </>
                )
              ) : null}
              {userdata.length !== 0 ? (
                userdata[0].nottify == true ? (
                  <img
                    className="workExperienceHeadLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                ) : (
                  <img
                    className="workExperienceHeadLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                )
              ) : null}

              {isArrow === true ? (
                <img onClick={dropDownhandler} src={dropUp} alt="" />
              ) : (
                <img onClick={dropDownhandler} src={dropDown} alt="" />
              )}
            </div>
          </div>
          {isArrow === true &&
            (userdata.length !== 0 ? (
              userdata[0].work_preference_info !== null ? (
                <div className="workExperienceDesc">
                  <div className="workExperience1">
                    <h3>
                      Add your Details to help us match you with the perfect
                      opportunity
                    </h3>
                    <div className="workExperience2">
                      <div className="workExperienceleft">
                        <div className="workTopskills">
                          <h4>Job Title / Role</h4>
                          <h2>
                            {userdata[0].preference_info.qualification
                              .length !== 0
                              ? userdata[0].preference_info.qualification
                              : "Not Provided"}
                          </h2>
                        </div>
                        <div className="workTopskills">
                          <h4>Years of Experience (all time)</h4>
                          <h2>
                            {userdata[0].preference_info.year_of_experience
                              .length !== 0
                              ? userdata[0].preference_info.year_of_experience
                              : "Not Provided"}
                          </h2>
                        </div>
                        <div className="workTopskills">
                          <h4>Top Skills</h4>
                          <div className="workbtns">
                            {userdata[0].work_preference_info.key_skills
                              .length !== 0
                              ? userdata[0].work_preference_info.key_skills.map(
                                  (data, index) => (
                                    <button key={index}>{data}</button>
                                  )
                                )
                              : null}
                          </div>
                        </div>

                        <div className="workTopskills">
                          <h4> Preferred Work Model</h4>
                          <h2>
                            {userdata[0].work_preference_info.method_of_work.every(
                              (item) => item.trim() === ""
                            )
                              ? "Not Provided"
                              : userdata[0].work_preference_info.method_of_work.join(
                                  ", "
                                )}
                          </h2>
                        </div>

                        <div className="workTopskills">
                          <h4>Languages known</h4>

                          {userdata[0].preference_info !== null
                            ? userdata[0].preference_info.language.length !== 0
                              ? userdata[0].preference_info.language.map(
                                  (data, index) => (
                                    <div
                                      className="worklanguages flex gap-4"
                                      key={index}
                                    >
                                      <h2 title="">
                                        {data.split(":")[0]} -{" "}
                                        <p> {data.split(":")[1]}</p>
                                      </h2>
                                    </div>
                                  )
                                )
                              : null
                            : null}
                        </div>
                      </div>
                      <div className="workExperienceright">
                        <div className="workTopskills">
                          <h4>Current Employment Status</h4>
                          <h2>
                            {userdata[0].work_preference_info
                              .current_employment_status.length !== 0
                              ? userdata[0].work_preference_info
                                  .current_employment_status
                              : "Not Provided"}
                          </h2>
                        </div>

                        <div className="workTopskills">
                          <h4>Preferred mode of engagement</h4>
                          <h2>
                            {userdata[0].work_preference_info
                              .preferred_mode_of_engagement.length !== 0
                              ? userdata[0].work_preference_info
                                  .preferred_mode_of_engagement
                              : "Not Provided"}
                          </h2>
                        </div>
                        <div className="workTopskills">
                          <h4>Personal Website URL</h4>
                          {userdata[0].work_preference_info.website_url
                            .length !== 0 ? (
                            <h5
                              onClick={() => {
                                window.open(
                                  userdata[0].work_preference_info.website_url,
                                  "_blank"
                                );
                              }}
                              className="pointer underline"
                            >
                              {userdata[0].work_preference_info.website_url}
                            </h5>
                          ) : (
                            <h5>Not Provided</h5>
                          )}
                        </div>

                        <div className="workTopskills">
                          <h4>GitHub</h4>
                          {userdata[0].work_preference_info.github_url
                            .length !== 0 ? (
                            <h5
                              onClick={() => {
                                window.open(
                                  userdata[0].work_preference_info.github_url,
                                  "_blank"
                                );
                              }}
                              className="pointer underline"
                            >
                              {userdata[0].work_preference_info.github_url}
                            </h5>
                          ) : (
                            <h5>Not Provided</h5>
                          )}
                        </div>
                        <div className="workTopskills">
                          <h4>Linked In</h4>
                          {userdata[0].preference_info !== null ? (
                            userdata[0].preference_info.length !== 0 ? (
                              <h5
                                onClick={() => {
                                  window.open(
                                    userdata[0].preference_info.linkedin,
                                    "_blank"
                                  );
                                }}
                                className="pointer underline"
                              >
                                {userdata[0].preference_info.linkedin}
                              </h5>
                            ) : (
                              <h5>Not Provided</h5>
                            )
                          ) : (
                            <h5>Not Provided</h5>
                          )}
                        </div>

                        <div className="workTopskills">
                          <h4>Preferred Work Locations</h4>
                          {userdata[0].work_preference_info
                            .preffered_work_location.length !== 0
                            ? userdata[0].work_preference_info.preffered_work_location.map(
                                (data, index) =>
                                  data.length !== 0 ? (
                                    <h2 key={index}>
                                      <img src={countryimg1} alt="" />
                                      {data}
                                    </h2>
                                  ) : null
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="educationDesc">
                  <h1>
                    Add your Details to help us match you with the perfect
                    opportunity
                  </h1>
                  <button className="touchButtonnew" onClick={overLayHandler}>
                    <h4>Add Work Preference</h4>
                  </button>
                </div>
              )
            ) : (
              <div className="educationDesc">
                <h1>
                  Add your Details to help us match you with the perfect
                  opportunity
                </h1>
                <button className="touchButtonnew" onClick={overLayHandler}>
                  <h4>Add Work Preference</h4>
                </button>
              </div>
            ))}
          {isPopUp == "Experience" && (
            <div className="workExperinceOverlay">
              <div className="innerWorkExperience">
                <div className="workExperienceHead">
                  <div className="workExperienceHeadLeft">
                    <img src={user} alt="" />
                    <h1>Work Preference</h1>
                  </div>
                  <div
                    onClick={exitOverlayHandler}
                    className="workExperienceHeadLeftIcon"
                  >
                    <RxCross1 />
                  </div>
                </div>
              </div>
              <h6>
                Add your Details to help us match you with the perfect
                opportunity
              </h6>

              <div className="workExperinceOverlayFlex">
                <div className="workExperinceOverlayLeft">
                  <div className="keySkills">
                    <div className="keyskills1">
                      <h3>
                        Job Title / Role <span className="text-red-500">*</span>
                      </h3>
                    </div>
                    <div className="keyskills2">
                      <div className="keylink1">
                        <input
                          type="text"
                          placeholder="Web developer"
                          name="qualification"
                          onChange={handlechange}
                          defaultValue={Experiencedata.qualification}
                        />
                      </div>
                    </div>
                    {errormsg.qualification && (
                      <h4 className="errormag">
                        Please Enter Job Title / Role
                      </h4>
                    )}
                  </div>
                  <div className="keySkills">
                    <div className="keyskills1">
                      <div className="flex gap-2 items-center">
                        <h3>
                          Top Key Skills <span className="text-red-500">*</span>
                        </h3>
                        <Tooltip text="Your profile is likely to be scored and shortlisted based on your choice of top 3 skills. In case your skill is missing from the database, please let us know via candidatesupport@hirein5.com">
                          <FaCircleInfo />
                        </Tooltip>
                      </div>
                      <p>Min 5 max 8 skills and top 3 skills</p>
                    </div>
                    <Select
                      value={selectedOptionskill}
                      onChange={handleChangeNew}
                      options={skilloption}
                      isMulti
                    />
                    {errormsg.skills && (
                      <h4 className="errormag">Please Select Key Skills</h4>
                    )}
                    {errormsg.length && (
                      <h4 className="errormag">
                        Ensures at least 5 skills are required
                      </h4>
                    )}
                  </div>
                  <div className="keySkills">
                    <div className="keyskills1">
                      <h3>Current Employment Status</h3>
                    </div>
                    <div className="keyskills2">
                      <select
                        placeholder="Select"
                        name="current_employment_status"
                        onChange={handlechange}
                        defaultValue={Experiencedata.current_employment_status}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="On Contract">On Contract</option>
                        <option value="Full-time">Freelancer</option>
                        <option value="Part-time">Part-time</option>
                        <option value="Full-time">Full-time</option>
                        <option value="Not working">Not working</option>
                      </select>
                    </div>
                  </div>
                  <div className="keySkills">
                    <div className="keyskills1">
                      <h3>Preferred mode of engagement</h3>
                    </div>
                    <div className="keyskills2">
                      <select
                        placeholder="Select"
                        name="preferred_mode_of_engagement"
                        onChange={handlechange}
                        defaultValue={
                          Experiencedata.preferred_mode_of_engagement
                        }
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="On Contract (Full Time)">
                          On Contract (Full Time)
                        </option>
                        <option value="On Contract (Part Time)">
                          On Contract (Part Time)
                        </option>
                        <option value="Employment">Employment</option>
                      </select>
                    </div>
                  </div>
                  <div className="keySkills" title="">
                    <div className="keyskills1">
                      <h3>
                        Preferred Work Model{" "}
                        <span className="text-red-500">*</span>
                      </h3>
                      <p>Rank in order of preference</p>
                    </div>
                    <div className="keyskills2">
                      <select
                        name="preference1"
                        onChange={handlechange}
                        defaultValue={Experiencedata.preference1}
                      >
                        <option value="">Select preference #1</option>
                        <option value="Remote">Remote</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Work from Office (Client / Onsite)">
                          Work from Office (Client / Onsite)
                        </option>
                        <option value="Work from Office (Home Location)">
                          Work from Office (Home Location)
                        </option>
                      </select>
                    </div>
                    <div className="keyskills2">
                      <select
                        name="preference2"
                        onChange={handlechange}
                        defaultValue={Experiencedata.preference2}
                      >
                        <option value="">Select preference #2</option>
                        <option value="Remote">Remote</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Work from Office (Client / Onsite)">
                          Work from Office (Client / Onsite)
                        </option>
                        <option value="Work from Office (Home Location)">
                          Work from Office (Home Location)
                        </option>
                      </select>
                    </div>
                    {errormsg.method_of_work && (
                      <h4 className="errormag">
                        At least one Preferred Work Model must be selected.
                      </h4>
                    )}
                  </div>

                  <div className="languagesnew">
                    {row.length !== 0
                      ? row.map((datanew, index) => (
                          <div className="addLanguages" key={index}>
                            <div className="addLanguageInner">
                              <h3>Languages</h3>
                              <input
                                type="text"
                                placeholder="e.g. English"
                                name="language"
                                onChange={(e) => {
                                  get_value(e.target.value, index);
                                }}
                                defaultValue={datanew.languages}
                              />
                            </div>
                            <div className="selectLanguages">
                              <h3>Proficiency</h3>

                              <div className="candidateState">
                                <select
                                  defaultValue={datanew.level}
                                  ref={inputref1}
                                  onChange={(e) => {
                                    filterdata(e.target.value, index);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Basic">Basic</option>
                                  <option value="Conversational">
                                    Conversational
                                  </option>
                                  <option value="Proficient">Proficient</option>
                                  <option value="Native">Native</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}

                    <button className="addLanguagesButton" onClick={addcount}>
                      + Add more
                    </button>
                  </div>
                </div>
                <div className="workExperinceOverlayRight">
                  <div className="workExperienceright1">
                    <div className="keySkills">
                      <div className="keyskills1">
                        <h3>Years of Experience (all time)</h3>
                      </div>
                      <div className="keyskills2">
                        <div className="keylink1">
                          <input
                            type="number"
                            placeholder="5"
                            name="year_of_experience"
                            onChange={handlechange}
                            defaultValue={Experiencedata.year_of_experience}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="keySkills">
                      <div className="keyskills1">
                        <h3>Personal Website URL</h3>
                      </div>
                      <div className="keyskills2">
                        <div className="keylink1">
                          <input
                            type="text"
                            placeholder="Pending"
                            name="website_url"
                            onChange={handlechange}
                            defaultValue={Experiencedata.website_url}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="keySkills">
                      <div className="keyskills1">
                        <h3>LinkedIn URL</h3>
                      </div>
                      <div className="keyskills2">
                        <div className="keylink1">
                          <input
                            type="text"
                            placeholder="Linked In"
                            name="linkedin"
                            onChange={handlechange}
                            defaultValue={Experiencedata.linkedin}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="keySkills">
                      <div className="keyskills1">
                        <h3>GitHub URL</h3>
                      </div>
                      <div className="keyskills2">
                        <div className="keylink1">
                          <input
                            type="text"
                            placeholder="Pending"
                            name="github_url"
                            onChange={handlechange}
                            defaultValue={Experiencedata.github_url}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="keySkills">
                      <div className="keyskills1 mb-2">
                        <h3>
                          Preferred Work Locations{" "}
                          <span className="text-red-500">*</span>
                        </h3>
                        <p>Top 2 countries</p>
                      </div>

                      <div className="keyskills4">
                        <select
                          name="location1"
                          onChange={handlechange}
                          defaultValue={Experiencedata.location1}
                        >
                          <option value="">Country preference #1</option>
                          {country_and_states.country.length !== 0
                            ? country_and_states.country.map((item, index) => (
                                <option value={item.name} key={index}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div className="keyskills4">
                        <select
                          name="location2"
                          onChange={handlechange}
                          defaultValue={Experiencedata.location2}
                        >
                          <option value="">Country preference #2</option>
                          {country_and_states.country.length !== 0
                            ? country_and_states.country.map((item, index) => (
                                <option value={item.name} key={index}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      {errormsg.preferred_work_location && (
                        <h4 className="errormag -mt-2">
                          At least one Preferred Work Location must be selected.
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="vedioResumeButtons">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Discard Changes
                </button>
                {loading === false ? (
                  <button className="save" onClick={savebtn}>
                    Save & Close
                  </button>
                ) : (
                  <button className="save w-[10rem] flex justify-center items-center">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
