/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import {
  BrowserRouter,
  HashRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import Layout from "./Layout/Layout";
import { storeAction } from "./Store/Store";
import { useEffect } from "react";
import axios from "axios";
import { LuAlignJustify } from "react-icons/lu";
import back from "../src/assests/billingX.png";
import LoadingGif from "../src/assests/loading-circle-5922100-4936396.webp";
import Routing from "./Routing/Routing";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const issidebar = useSelector((store) => store.issidebar);
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const isPopUp2 = useSelector((store) => {
    return store.isPopUp2;
  });
  const CloseOverlay = () => {
    dispatch(storeAction.isPopUpHander());
    dispatch(storeAction.isPopUpHander2(false));
  };
  // const CloseOverlay2 = () => {
  //   dispatch(storeAction.isPopUpHander2(false));
  // };
  const navbarHandler = () => {
    dispatch(storeAction.isPopUpHander("navbar"));
  };
  // useEffect(() => {
  //   Getuserinfo();
  //   // GetsyncData();
  // }, [token, userid]);
  // const Getuserinfo = async () => {
  //   if (token !== null && userid !== null) {
  //     var userinfo = await axios
  //       .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `JWT ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((err) => {
  //         return err.response;
  //       });
  //     if (userinfo.id !== undefined) {
  //       dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
  //     } else {
  //       dispatch(storeAction.isPopUpHander("contactus"));
  //     }
  //   }
  // };
  // const GetsyncData = async () => {
  //   if (token !== null && userid !== null) {
  //     await axios
  //       .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/update-zohouser/`)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((err) => {
  //         return err.response;
  //       });
  //   }
  // };
  useEffect(() => {
    // Prevent right-click
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Prevent keydown for Print Screen and other keys
    const handleKeyDown = (e) => {
      if (e.key === "PrintScreen") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.key === "p") {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const logoutbtn = () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <>
      <HelmetProvider>
        <div className="App">
          <HashRouter>
            <Layout />
          </HashRouter>
        </div>
        {/* <div className="App">
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </div> */}
        {issidebar && (
          <div onClick={navbarHandler} className="navButton">
            <LuAlignJustify />
          </div>
        )}
        {isPopUp == "video" ||
        isPopUp == "HireOptions" ||
        isPopUp == "DirectHirePopup" ||
        isPopUp == "Subcontract" ||
        isPopUp == "hiringprocessinitiated" ||
        isPopUp == "loader" ||
        isPopUp == "evaluation" ||
        isPopUp == "emailverification" ||
        isPopUp == "mobileverification" ||
        isPopUp == "scheduleinterview" ||
        isPopUp == "sort" ||
        isPopUp == "paySuccess" ||
        isPopUp == "addcontract" ||
        isPopUp == "upgradesuccess" ||
        isPopUp == "approvedropdownMob" ||
        isPopUp == "mobfilter" ||
        isPopUp == "navbar" ||
        isPopUp == "personal" ||
        isPopUp == "professional" ||
        isPopUp == "Experience" ||
        isPopUp == "education" ||
        isPopUp == "certificate" ||
        isPopUp == "travel" ||
        isPopUp == "reserve" ||
        isPopUp == "filter" ||
        isPopUp == "monthly" ||
        isPopUp == "cancel" ||
        isPopUp == "reserveSuccess" ||
        isPopUp == "reserveConfirm" ||
        isPopUp == "ViewInvoice" ||
        isPopUp == "ViewInvoiceHAT" ||
        isPopUp == "upload" ||
        isPopUp == "export" ||
        isPopUp == "achievements" ||
        isPopUp == "candidateRate" ||
        isPopUp == "conditionalcheck" ||
        isPopUp == "interviewDetails" ||
        isPopUp == "ViewInterview" ||
        isPopUp == "logoutPopUp" ||
        isPopUp == "adminpersonal" ||
        isPopUp == "personality_assessment_popup" ||
        isPopUp == "personality_technical_popup" ||
        isPopUp == "bgv_popup" ||
        isPopUp == "contactus1" ||
        isPopUp == "adminbasicdetails" ||
        isPopUp == "adminprofessionaldetails" ||
        isPopUp == "adminpropreferencedetails" ||
        isPopUp == "countriestravelledto" ||
        isPopUp == "workauth" ||
        isPopUp == "adminresidencydetails" ||
        isPopUp == "willingnesstorelocate" ||
        isPopUp == "travelforwork" ||
        isPopUp == "adminprojectdetails" ||
        isPopUp == "adminprofessional" ||
        isPopUp == "admincertification" ||
        isPopUp == "admineducation" ||
        isPopUp == "adminvedioresume" ||
        isPopUp == "noticeperiod" ||
        isPopUp == "admincompanydetails" ||
        isPopUp == "adminbillingcontact" ||
        isPopUp == "adminbillinginformation" ||
        isPopUp == "adminprimarycontact" ||
        isPopUp == "approveavailability" ||
        isPopUp == "adminsecondarycontact" ||
        isPopUp == "taxinfo" ||
        isPopUp == "aadhar" ||
        isPopUp == "passport" ||
        isPopUp == "resumeError" ||
        isPopUp == "approveconformation" ||
        isPopUp == "preapproveconformation" ||
        isPopUp == "Hireconformation" ||
        isPopUp == "HireReject" ||
        isPopUp == "project" ||
        isPopUp == "diversity" ||
        isPopUp == "job_des" ||
        isPopUp == "addMoney" ||
        isPopUp == "lowBalance" ||
        isPopUp == "PaypalTrigger" ||
        isPopUp == "deleteconfirm" ||
        isPopUp == "readjobdescription" ||
        isPopUp == "ResumeParsing" ||
        isPopUp == "ShareProfile" ||
        isPopUp == "optionalPop" ||
        isPopUp == "aijdupload" ||
        isPopUp == "genAi" ||
        isPopUp == "unreserve" ? (
          <div onClick={CloseOverlay} id="overlay"></div>
        ) : null}
        {isPopUp == "trigger" ||
        isPopUp == "contactus" ||
        isPopUp == "loading" ||
        isPopUp2 == "ResumeparsingInfo" ||
        isPopUp == "CheckUser" ||
        isPopUp == "LinkExpired" ||
        isPopUp == "ViewJD" ||
        isPopUp == "FirstPopup" ||
        isPopUp == "Timepopup" ? (
          <div id="overlay"></div>
        ) : null}
        {isPopUp == "hourly" ||
        isPopUp == "location" ||
        isPopUp == "mobfilter" ||
        isPopUp == "access2" ||
        isPopUp == "approvedropdown" ? (
          <div onClick={CloseOverlay} id="overlayTransparent"></div>
        ) : null}

        {isPopUp == "logoutPopUp" && (
          <div className="logoutPopUp">
            <div className="logoutPopUpHead">
              <h1>Logout</h1>
              <img onClick={exitOverlayHandler} src={back} alt="" />
            </div>
            <h2>
              Are you sure you want to logout? You’ll have to log in again to
              use this platform
            </h2>
            <button onClick={logoutbtn} className="logoutActiveButton">
              Yes, logout
            </button>
            <button
              onClick={exitOverlayHandler}
              className="logoutInaciveButton"
            >
              Cancel
            </button>
          </div>
        )}
        {isPopUp == "loading" && (
          <>
            <img
              src={LoadingGif}
              alt=""
              className="w-[100px] h-[100px] overlay"
            />
          </>
        )}
      </HelmetProvider>
    </>
  );
}

export default App;
