import React from "react";
import "./CoursesPage.css";
import course from "../../assests/Course.png";
import { IoMdStarOutline } from "react-icons/io";

const Courses = () => {
  return (
    <div className="courses">
      <div className="subCourses">
        <h1>Course</h1>
        <p>Some caption which sets more context for this page</p>
        <div className="courseGrid">
          <div className="courseCard">
            <img src={course} alt="" />
            <h3>Data Analytics Course</h3>
            <div className="courseDetails">
              <div className="details">
                <span>🗓️</span>
                <p>6 Weeks</p>
              </div>
              <div className="details">
                <span>📚</span>
                <p>6 Module</p>
              </div>
              <div className="details">
                <span>🧑‍🎓</span>
                <p>248 Students</p>
              </div>
            </div>
            <hr />
            <div className="ratingandBtn">
              <p>
                <span>
                  <IoMdStarOutline />
                </span>{" "}
                4.9
              </p>
              <button>Book Now</button>
            </div>
          </div>
          <div className="courseCard">
            <img src={course} alt="" />
            <h3>Data Analytics Course</h3>
            <div className="courseDetails">
              <div className="details">
                <span>🗓️</span>
                <p>6 Weeks</p>
              </div>
              <div className="details">
                <span>📚</span>
                <p>6 Module</p>
              </div>
              <div className="details">
                <span>🧑‍🎓</span>
                <p>248 Students</p>
              </div>
            </div>
            <hr />
            <div className="ratingandBtn">
              <p>
                <span>
                  <IoMdStarOutline />
                </span>{" "}
                4.9
              </p>
              <button>Book Now</button>
            </div>
          </div>
          <div className="courseCard">
            <img src={course} alt="" />
            <h3>Data Analytics Course</h3>
            <div className="courseDetails">
              <div className="details">
                <span>🗓️</span>
                <p>6 Weeks</p>
              </div>
              <div className="details">
                <span>📚</span>
                <p>6 Module</p>
              </div>
              <div className="details">
                <span>🧑‍🎓</span>
                <p>248 Students</p>
              </div>
            </div>
            <hr />
            <div className="ratingandBtn">
              <p>
                <span>
                  <IoMdStarOutline />
                </span>{" "}
                4.9
              </p>
              <button>Book Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
